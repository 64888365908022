import { createSlice } from '@reduxjs/toolkit';

const requestSlice = createSlice({
  name: 'request',
  initialState: {
    isExploreOpen: false,
    requestForm: {},
    productIdCode: '',
    aoiListData: [],
    requestPermission: {},
    overallData: [],
    dataProducts: []
  },
  reducers: {
    doGetOverall(state, action) {
      state.overallData = action.payload.data;
    },
    doGetDataProducts(state, action) {
      state.dataProducts = action.payload.data;
    },
    doGetAoiListData(state, action) {
      state.aoiListData = action.payload.data;
    },
    doCheckPermission(state, action) {
      state.requestPermission = action.payload.data;
    },
    doRequstFormUpdate(state, action) {
      state.requestForm = action.payload.data;
    },
    doClearState(state, action) {
      state.requestPermission = {};
    },
    enableExploreSection(state, action) {
      state.isExploreOpen = true;
    },
    disableExploreSection(state, action) {
      state.isExploreOpen = false;
    },
    setProductIdCode(state, value) {
      state.productIdCode = value.payload;
    }
  }
});

export const requestAction = requestSlice.actions;

export default requestSlice.reducer;
