import { patchRequest } from '../utils/http-helper';
import { UPDATE_ROLE } from '../utils/url-helper';

interface UpdateUserResponse {
  data: {
    message: string;
  };
  status: number;
}

export const updateUser = async (
  user_id: number,
  payload: any
): Promise<UpdateUserResponse> => {
  try {
    const response = await patchRequest({
      url: ` ${UPDATE_ROLE}/${user_id}/update-user`,
      headers: true,
      Auth: true,
      data: payload
    });
    return response as UpdateUserResponse;
  } catch (e) {
    throw e;
  }
};
