import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';

import './index.css';

import DraftsTreeView from '../draftTreeView';
import { getDraftRequest } from '../../store/drafts/drafts-actions';

const DraftsTree = ({ getDraftProduct }) => {
  const dispatch = useDispatch();
  const { draftTreeData, draftReqeustData } = useSelector(
    (state) => state.draft
  );
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [reqeustSelect, setRequestSelect] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (draftTreeData && draftTreeData.length !== 0) {
      setTreeData(draftTreeData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftTreeData]);

  useEffect(() => {
    if (searchText === '') {
      setTreeData(draftTreeData);
    } else {
      const options = {
        keys: ['name'],
        includeScore: true,
        includeMatches: true,
        threshold: 0.0
      };

      const fuse = new Fuse(draftTreeData, options);
      const result = fuse.search(searchText);
      setTreeData(result.map((tree) => tree.item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleMouseEnter = (event, draft) => {
    setHoveredItem(draft.id);
    setHoverPosition({
      top: event.currentTarget.offsetTop,
      left: event.currentTarget.offsetLeft
    });
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const toggle = (id) => {
    setOpen(!open);
    setSelectedItem(id);
    dispatch(getDraftRequest(id));
  };

  const onHandleSelectRequest = (id, title) => {
    setRequestSelect(id);
    getDraftProduct(id, title);
  };

  return (
    <div className="drafts-comp-content-wrapper">
      <div className="drafts-wrapper">
        <div className="form-group">
          <input
            className="drafts-search"
            type="text"
            placeholder="Search Drafts"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className="project-tree-container">
        {treeData && treeData.length !== 0 ? (
          <ul>
            {treeData &&
              treeData.length !== 0 &&
              treeData.map((draft) => {
                return (
                  <DraftsTreeView
                    isRequest={'project'}
                    title={draft?.name}
                    onToggle={() => toggle(draft?.id)}
                    open={selectedItem === draft.id}
                  >
                    {draftReqeustData &&
                      draftReqeustData.length !== 0 &&
                      draftReqeustData.map((item) => {
                        return (
                          <DraftsTreeView
                            isRequest={'request'}
                            title={item?.name}
                            onToggle={() =>
                              onHandleSelectRequest(item?.id, draft?.name)
                            }
                            isActive={reqeustSelect === item.id}
                          ></DraftsTreeView>
                        );
                      })}
                  </DraftsTreeView>
                );
              })}
          </ul>
        ) : (
          <span className="no-request">No Data Available</span>
        )}
      </div>
    </div>
  );
};

export default DraftsTree;
