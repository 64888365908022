import React, { useState, useEffect, useRef } from 'react';

// SCSS
import styles from './index.module.scss';

// IMAGES
import { images } from '../../assets/images';

// HELPER
import { roleOptions } from '../../utils/constants';
import { UserSearchProps } from '../../types/user.types';

const UserSearch: React.FC<UserSearchProps> = ({
  handleSearch,
  hasPermission,
  handleRoleCheckboxChange,
  handleStatusCheckboxChange,
  selectedRoles,
  activeStatus
}) => {
  const [roleDropdownOpen, setRoleDropdownOpen] = useState<boolean>(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);
  const roleDropdownRef = useRef<HTMLDivElement>(null);
  const statusDropdownRef = useRef<HTMLDivElement>(null);

  const toggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        roleDropdownRef.current &&
        !roleDropdownRef.current.contains(event.target as Node)
      ) {
        setRoleDropdownOpen(false);
      }
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target as Node)
      ) {
        setStatusDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.search_field_wrapper}>
      <div className={styles.search_input_container}>
        <label className={styles.search_input}>
          <input
            type="text"
            placeholder="Search by name or email"
            onChange={handleSearch}
          />
        </label>

        <span className={styles.search_icon}>
          <img src={images.searchIcon} alt="Search Icon" />
        </span>
      </div>

      <div className={styles.filter_options_container}>
        <p className={styles.filter_label}>Filter by</p>
        <div
          className={styles.dropdown}
          ref={roleDropdownRef}
          data-testid="role-dropdown"
        >
          <button className={styles.filter_button} onClick={toggleRoleDropdown}>
            Role
            <span className={styles.arrow_icon_user}>
              <img src={images.down} alt="Down Arrow" />
            </span>
          </button>
          {roleDropdownOpen && (
            <ul className={styles.filter_dropdown_menu}>
              {roleOptions.map((item) => {
                const isSelected = selectedRoles.includes(item.id);
                return (
                  <li key={item.id}>
                    <span className={styles.form_checkbox}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleRoleCheckboxChange(item.id)}
                      />
                    </span>
                    <label>{item.label}</label>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {hasPermission && (
          <div
            className={styles.dropdown}
            ref={statusDropdownRef}
            data-testid="status-dropdown"
          >
            <button
              className={styles.filter_button}
              onClick={toggleStatusDropdown}
            >
              Status
              <span className={styles.arrow_icon_user}>
                <img src={images.down} alt="Down Arrow" />
              </span>
            </button>
            {statusDropdownOpen && (
              <ul className={styles.filter_dropdown_menu}>
                <li>
                  <span>
                    <input
                      type="checkbox"
                      checked={activeStatus.includes('active')}
                      onChange={() => handleStatusCheckboxChange('active')}
                    />
                  </span>
                  <label>Active</label>
                </li>
                <li>
                  <span>
                    <input
                      type="checkbox"
                      checked={activeStatus.includes('inactive')}
                      onChange={() => handleStatusCheckboxChange('inactive')}
                    />
                  </span>
                  <label>Inactive</label>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSearch;
