/**
 * Represents data related to a product.
 */
export class ProductData {
  /**
   * Constructs an instance of ProductData.
   * @param {object} productData - The data object containing product data.
   */
  constructor(productData) {
    this.assignProductData(productData);
  }

  /**
   * Assigns product data to the instance properties.
   * @param {object} productData - The data object containing product data.
   */
  assignProductData(productData) {
    this.aoi_data = productData.aoi_data;
    this.data_delivery_format = productData.data_delivery_format;
    this.from_date = productData.from_date;
    this.id = productData.id;
    this.product_admin = productData.product_admin;
    this.product_id = productData.product_id;
    this.name = productData.product_name;
    this.role = productData.role;
    this.sop = productData.sop;
    this.status_in_percentage = productData.status_in_percentage;
    this.to_date = productData.to_date;
  }

  /**
   * Retrieves product data information.
   * @returns {object} - An object containing product data information.
   */
  getProductDataInfo() {
    return {
      aoi_data: this.aoi_data,
      data_delivery_format: this.data_delivery_format,
      from_date: this.from_date,
      id: this.id,
      product_admin: this.product_admin,
      product_id: this.product_id,
      name: this.product_name,
      role: this.role,
      sop: this.sop,
      status_in_percentage: this.status_in_percentage,
      to_date: this.to_date
    };
  }
}
