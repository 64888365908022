import { getRequest, postRequest, patchRequest } from '../utils/http-helper';
import {
  UPDATE_PASSWORD_URL,
  USER_DETAILS,
  ADD_ROLE,
  GET_ALL_USERS,
  UPDATE_ROLE,
  SUBMIT_URL
} from '../utils/url-helper';
import { getUserDetails } from '../utils/user-helper';
import { USER_DETAILS_KEY } from '../utils/constants';

export const getUserDetailsValue = async (emailId) => {
  try {
    return await getRequest({
      url: `${USER_DETAILS}/${emailId}`,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const postUserDetailsValue = async (emailId, payload) => {
  try {
    return await postRequest({
      url: `${USER_DETAILS}/${emailId}`,
      headers: true,
      data: payload,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const postSubmitFormValue = async (payload, queryType) => {
  let userEmail = getUserDetails(USER_DETAILS_KEY)?.userEmail;
  try {
    return await postRequest({
      url: `${SUBMIT_URL}/${userEmail}/${queryType}/querysets`,
      headers: true,
      data: payload,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const changePassword = async (payload) => {
  try {
    let userData = getUserDetails(USER_DETAILS_KEY);

    return await postRequest({
      url: `${UPDATE_PASSWORD_URL}/${userData?.userEmail}`,
      data: payload,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const postAddUser = async (payload) => {
  try {
    return await postRequest({
      url: ADD_ROLE,
      headers: true,
      data: payload,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getUserManageDetails = async () => {
  try {
    return await getRequest({
      url: GET_ALL_USERS,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const updateUser = async (user_id, payload) => {
  try {
    return await patchRequest({
      url: ` ${UPDATE_ROLE}/${user_id}/update-user`,
      headers: true,
      Auth: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};
