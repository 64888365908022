import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

// SCSS
import styles from './index.module.scss';

// IMAGES
import { images } from '../../assets/images';

// SERVICES
import { updateUser } from '../../services/setting-service';
import { fetchUserData } from '../../store/userManage/user-actions';
import { UserActionsDropdownProps } from '../../types/user.types';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '40rem',
    padding: '0rem',
    borderRadius: '0.8rem',
    zIndex: '10000'
  }
};

interface UserStatusToggleProps {
  usersData: Array<{ id: number }>;
  index: number;
  userDetails: { is_active: boolean };
}

export const UserStatusToggle: React.FC<UserStatusToggleProps> = ({
  usersData,
  index,
  userDetails
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(userDetails.is_active);

  const userId = usersData[index]?.id;

  /**
   * Handles the change in checkbox state.
   * @param e - The event object representing the checkbox change.
   */

  const handleCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    const payload = {
      is_active: checked
    };

    try {
      const response = await updateUser(userId, payload);
      const { data, status } = response as { data: any; status: number };

      if (status === 200) {
        toast.success(data?.message);
        await fetchUserData();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <td
      className={`${styles.table_data} ${styles.width_10} ${styles.table_data_align}`}
    >
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className={styles.slider + ' ' + styles.round}></span>
      </label>
      {isChecked}
    </td>
  );
};

export const UserActionsDropdown: React.FC<UserActionsDropdownProps> = ({
  handleEdit,
  userId
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRemoveConfirm = () => {
    closeModal();
  };

  return (
    <>
      <td className={styles.table_data_align}>
        <div
          data-testId="dropdown-container"
          className={styles.dropdown_container}
          tabIndex={-1}
        >
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              setIsDropdownVisible(true);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setIsDropdownVisible(true);
              }
            }}
          >
            <img
              src={images.vertical}
              alt="action_dropdown"
              data-testId="action_dropdown"
            />
          </span>
          {isDropdownVisible && (
            <ul className={styles.dropdown_menu}>
              <li
                onClick={() => {
                  handleEdit(userId);
                  setIsDropdownVisible(false);
                }}
              >
                <label>
                  <img src={images.editIcon} alt="EditIcon" />
                </label>
                <span data-testId="edit-option">Edit</span>
              </li>
              {/* Uncomment the lines below if needed */}
              {/* <li>
                <label>
                  <img src={images.keyIcon} alt="KeyIcon" />
                </label>
                <span> Reset password</span>
              </li> */}
              {/* <li onClick={() => setShowModal(true)}>
                <label>
                  <img src={images.trashIcon} alt="TrashIcon" />
                </label>
                <span> Remove</span>
              </li> */}
            </ul>
          )}
        </div>
      </td>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Remove User Modal"
      >
        <div className={styles.user_remove_modal}>
          <div className={styles.remove_modal_header}>
            <h2>Removing Member</h2>
          </div>
          <div className={styles.remove_modal_body}>
            <span>
              <img src={images.remove} alt="remove-user" />
            </span>
            <p>
              Are you sure you want to <small>remove</small> this member from
              the platform? This action cannot be undone.
            </p>
          </div>
          <div className={styles.remove_modal_footer}>
            <button onClick={closeModal} className={styles.cancelButton}>
              Cancel
            </button>
            <button
              onClick={handleRemoveConfirm}
              className={styles.removeButton}
            >
              Remove
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
