import React from 'react';
import { useLocation } from 'react-router-dom';
import SidebarDocs from '../../components/sidebar_docs';

// CSS
import './index.css';

const Documentation = () => {
  const location = useLocation();
  const path = location.pathname.split('/');

  return (
    <>
      <div className="documentation-container">
        <div className="documentation-sections">
          <SidebarDocs tab="documentation" />
        </div>
      </div>
    </>
  );
};

export default Documentation;
