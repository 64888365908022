import { images } from './images';

export interface Product {
  product_name: string;
  product_img: string;
  short_desc: string;
}

export const solutionCatalogSample: Product[] = [
  {
    product_name: 'Sales Planning',
    product_img: images.sales,
    short_desc:
      'Estimate macro and micro markets potential with crop area and irrigation condition (historical and in season).'
  },
  {
    product_name: 'Supply Estimate',
    product_img: images.supply,
    short_desc:
      'Spatial distribution of crop area to identify dense crop clusters and plan logistics and collection centres.'
  },
  {
    product_name: 'Inventory Management',
    product_img: images.inventory,
    short_desc:
      'Targeted sales by being informed about the exact number and locations of the farm in your sales territory.'
  },
  {
    product_name: 'Procurement Planning',
    product_img: images.proPlanning,
    short_desc:
      'Continuous remote crop health monitoring for efficient in-season intervention in the procurement plan.'
  },
  {
    product_name: 'Risk Assessment',
    product_img: images.risk,
    short_desc:
      'Crop and farm performance evaluation with crop health monitoring & agriculture condition risk assessment. '
  },
  {
    product_name: 'Claim Validation',
    product_img: images.claim,
    short_desc:
      'Analyse the area under flood along with the extent of crop damage and potential yield loss.'
  },
  {
    product_name: 'Deforestation Analysis',
    product_img: images.deforestation,
    short_desc:
      'User-friendly solution for EUDR compliance, combining traceability and deforestation validation analysis.'
  },
  {
    product_name: 'Procurement Execution',
    product_img: images.proExecution,
    short_desc:
      'Harvest data to deploy resources at the right time at the right locations,  achieving max resource utilisation.'
  }
];
