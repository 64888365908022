import React from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import RangeSliders from '../components/range-slider';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getSignedUrl, uploadTiffFile } from '../services/products-service';

// Images
import DisabledUpload from '../assets/images/icons/disabled-upload.svg';
import Upload from '../assets/images/icons/upload.svg';

export const formatOptions = [
  { value: 'GeoTIFF', label: 'GeoTIFF' },
  { value: 'PNG', label: 'PNG' }
];

export const getInputFields = (
  flow,
  type,
  options,
  formData,
  onChange,
  firstIndex,
  secondIndex,
  thirdIndex,
  disabled,
  parentAoiId,
  setFiles,
  setReCallQuantity,
  maskingOption,
  setMaskingOption
) => {
  if (type === 'dropdown') {
    return getDropDown(
      options,
      formData,
      onChange,
      firstIndex,
      secondIndex,
      thirdIndex,
      disabled
    );
  } else if (type === 'multiselect_dropdown') {
    return getMultiDropDown(
      options,
      formData,
      onChange,
      firstIndex,
      secondIndex,
      thirdIndex,
      disabled,
      maskingOption,
      setMaskingOption
    );
  } else if (type === 'slider') {
    return getSlider(
      options,
      formData,
      onChange,
      firstIndex,
      secondIndex,
      thirdIndex,
      disabled
    );
  } else if (type === 'file' && flow === 'generator') {
    return (
      <UploadTiff
        formData={formData}
        parentAoiId={parentAoiId}
        setFiles={setFiles}
        setReCallQuantity={setReCallQuantity}
      />
    );
  } else if (type === 'text') {
    return getTextBox(
      formData,
      onChange,
      firstIndex,
      secondIndex,
      thirdIndex,
      disabled
    );
  } else if (type === 'string') {
    return getSpectralBandsDropdown(
      formData,
      firstIndex,
      secondIndex,
      thirdIndex
    );
  }
};

export const getSlider = (
  options,
  formData,
  onChange,
  firstIndex,
  secondIndex,
  thirdIndex,
  disabled
) => {
  const value =
    formData[firstIndex]?.input_data[secondIndex]?.input_data[thirdIndex];
  const onChangeHandler = (value) => {
    onChange(value, firstIndex, secondIndex, thirdIndex);
  };
  return (
    <>
      <RangeSliders
        value={value?.selected_value}
        onChangeHandler={onChangeHandler}
        disabled={disabled}
      />
    </>
  );
};

export const getDropDown = (
  options,
  formData,
  onChange,
  firstIndex,
  secondIndex,
  thirdIndex,
  disabled
) => {
  const value =
    formData[firstIndex]?.input_data[secondIndex]?.input_data[thirdIndex];

  return (
    <Select
      classNamePrefix="list-select"
      options={options}
      value={value?.selected_value}
      isDisabled={disabled}
      onChange={(e) => onChange(e, firstIndex, secondIndex, thirdIndex)}
    />
  );
};

export const getSpectralBandsDropdown = (
  formData,
  firstIndex,
  secondIndex,
  thirdIndex
) => {
  const value =
    formData[firstIndex]?.input_data[secondIndex]?.input_data[thirdIndex];
  return <div className="display-bands">{value?.selected_value[0]}</div>;
};

export const getMultiDropDown = (
  options,
  formData,
  onChange,
  firstIndex,
  secondIndex,
  thirdIndex,
  disabled,
  maskingOption,
  setMaskingOption
) => {
  const value =
    formData[firstIndex]?.input_data[secondIndex]?.input_data[thirdIndex];
  return (
    <Select
      classNamePrefix="list-select"
      placeholder="No mask"
      isMulti
      isDisabled={disabled}
      options={options}
      isOptionDisabled={(option) => {
        if (Array.isArray(value?.selected_value[0])) {
          const isNoMaskingSelected = value?.selected_value[0].some(
            (item) => item.value === 'None'
          );
          return isNoMaskingSelected && option.value !== 'None';
        }
      }}
      value={value?.selected_value[0]}
      menuPlacement="auto"
      onChange={(e) => {
        setMaskingOption(e);
        onChange(e, firstIndex, secondIndex, thirdIndex);
      }}
    />
  );
};

export const getTextBox = (
  formData,
  onChange,
  firstIndex,
  secondIndex,
  thirdIndex,
  disabled
) => {
  const { selected_value } =
    formData[firstIndex]?.input_data[secondIndex]?.input_data[thirdIndex];
  return (
    <input
      className="dynamic-text-box"
      value={selected_value}
      disabled={disabled}
      onChange={(e) =>
        onChange(e.target.value, firstIndex, secondIndex, thirdIndex)
      }
    ></input>
  );
};

export const UploadTiff = ({ parentAoiId, setFiles, setReCallQuantity }) => {
  const { manualUploadBtn } = useSelector((state) => state?.mapCompare);
  const inputRef = useRef();

  const onUploadTiff = async (e, manualUploadBtn) => {
    const fileName = e?.target?.files[0]?.name;
    const file = e.target.files[0];

    if (fileName) {
      const { data, status, error } = await getSignedUrl(
        fileName,
        manualUploadBtn?.id
      );
      if (status === 200) {
        onPostFile(file, data?.data);
      }
      if (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const onPostFile = async (file, payload) => {
    let { url, fields } = payload;
    let formData = new FormData();

    Object.keys(fields).forEach((item) => {
      formData.append(item, fields[item]);
    });
    formData.append('file', file);

    const { status, error } = await uploadTiffFile(url, formData);
    if (status === 204) {
      toast.success('Successfully Uploaded');
      setReCallQuantity(manualUploadBtn);
    }
    if (error) {
      toast.error(error.response.data.message);
    }
    handleReset();
  };

  const handleReset = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.type = 'text';
      inputRef.current.type = 'file';
    }
  };

  return (
    <>
      <label
        htmlFor="file-upload"
        className={
          manualUploadBtn?.id ? 'upload-button' : 'disabled-upload-button'
        }
      >
        Upload
        {manualUploadBtn?.id ? (
          <img src={Upload} alt="upload" />
        ) : (
          <img src={DisabledUpload} alt="disable-upload" />
        )}
        {manualUploadBtn?.id && (
          <input
            id="file-upload"
            ref={inputRef}
            type="file"
            accept=".geojson,.kml"
            onChange={(e) => onUploadTiff(e, manualUploadBtn)}
          />
        )}
      </label>
    </>
  );
};
