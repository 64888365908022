import {
  getAoiList,
  onWorldWideAvailablity
} from '../../services/aoi-services';
import {
  getProducts,
  onCheckRequestPermisson
} from '../../services/products-service';
import { requestAction } from './request-slice';

export const checkRequestPermisson = (id) => {
  return async (dispatch) => {
    const { data, status } = await onCheckRequestPermisson(id);
    if (status === 200) {
      dispatch(requestAction.doCheckPermission({ data: data?.message }));
    }
  };
};

export const getOverallDataProduct = () => {
  return async (dispatch) => {
    const { data, status } = await onWorldWideAvailablity();
    if (status === 200) {
      dispatch(requestAction.doGetOverall({ data: data?.data }));
    }
  };
};

export const getAoiListData = () => {
  return async (dispatch) => {
    const { data, status } = await getAoiList();
    if (status === 200) {
      dispatch(requestAction.doGetAoiListData({ data: data?.data }));
    }
  };
};

export const getDataProducts = () => {
  return async (dispatch) => {
    const { data, status } = await getProducts();

    if (status === 200) {
      dispatch(requestAction.doGetDataProducts({ data: data?.data }));
    }
  };
};
