import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { REQUEST, INPROGRESS, COMPLETED, DECLINED } from './constants';

import { store, persistor } from '../store/index.js';

import inprogress from '../assets/images/icons/loader.svg';
import complete from '../assets/images/icons/check.svg';
import request from '../assets/images/icons/pending.svg';
import decline from '../assets/images/icons/declined.svg';

// Product
import NDVI from '../assets/images/legends/ndvi.svg';
import NDWI from '../assets/images/legends/ndwi.svg';
import MNDWI from '../assets/images/legends/mnwdi.svg';
import CS from '../assets/images/legends/crop-sown-area.svg';
import ACR from '../assets/images/legends/acr.svg';
import FCC from '../assets/images/legends/fcc.svg';

export const formatOptions = [
  { value: 'GeoTIFF', label: 'GeoTIFF' },
  { value: 'PNG', label: 'PNG' }
];

export const extractionOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];

export const granulityOption = [
  { id: 0, name: '(None)', isSelected: true },
  { id: 1, name: 'District' },
  { id: 2, name: 'Sub-district' },
  { id: 3, name: 'Village' }
];

export const forceLogout = () => {
  persistor.flush().then(() => {
    return persistor.purge();
  });
  window.location.replace('/');
};

export const getUserDetails = (key) => {
  try {
    const cookieValue = Cookies.get(key);
    if (cookieValue !== undefined) {
      const parsedData = JSON?.parse(cookieValue);
      if (parsedData !== null) {
        return parsedData;
      } else {
        console.warn(`Failed to parse JSON data from cookie "${key}"`);
        return null;
      }
    } else {
      console.warn(`Cookie "${key}" not found.`);
      return null;
    }
  } catch (error) {
    console.error(`Error retrieving or parsing user details: ${error}`);
    return null;
  }
};

export const storeUserDetails = (key, data) => {
  Cookies.set(key, JSON.stringify(data));
};

export const getToken = (key) => {
  const storeData = store.getState();
  const { authToken } = storeData?.user;

  const token = storeData?.user?.authToken?.refreshToken;

  let decodeToken = jwtDecode(token);

  if (decodeToken.exp <= Math.round(new Date().getTime() / 1000)) {
    forceLogout();
  } else {
    return authToken?.refreshToken;
  }
};

/**
 * Retrieves the status icon and title based on the provided status code.
 * @param {number} status - The status code.
 * @returns {object|null} An object containing the image and title, or null if status is invalid.
 */
export const getStatusIcon = (status) => {
  return status === 0
    ? { img: request, title: 'Requested' }
    : status === 100
      ? { img: complete, title: 'Completed' }
      : status > 0 && status < 100
        ? { img: inprogress, title: 'In-Progress' }
        : null;
};

/**
 * Returns the status icon and title based on the provided status code.
 * @param {string} status - The status code representing the current status.
 * @returns {object|null} An object containing the icon and title corresponding to the status, or null if status is invalid.
 */
export const getDateStatusIcon = (status) => {
  const statusCode = {
    [REQUEST]: { img: request, title: 'Requested' },
    [INPROGRESS]: { img: inprogress, title: 'In-Progress' },
    [COMPLETED]: { img: complete, title: 'Completed' },
    [DECLINED]: { img: decline, title: 'Declined' }
  };

  return statusCode[status] || null;
};

// Product Legend Img
export const getProductLegendImg = (productIdCode) => {
  switch (productIdCode) {
    case 'NDVI':
      return NDVI;
    case 'CS':
      return CS;
    case 'NDWI':
      return NDWI;
    case 'MNDWI':
      return MNDWI;
    case 'ACR':
      return ACR;
    case 'FCC':
      return FCC;
    default:
      return null;
  }
};
/**
 * Get Status Color based on Progress
 * @param {*} progress
 * @returns
 */
export const getProgressColor = (progress) => {
  switch (progress) {
    case 0:
      return '#2F80ED';
    case 100:
      return '#27AE60';
    default:
      return '#F2994A';
  }
};
