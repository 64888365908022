import { createSlice } from '@reduxjs/toolkit';

// Define the mapCompare slice
const mapCompareSlice = createSlice({
  name: 'mapCompare',
  initialState: {
    // Initial state for mapCompare slice
    isDisableCompare: true,
    isCompare: false,
    singleView: true,
    compareView: false,
    mergeView: false,
    overlapView: false,
    dateStatusView: false,
    currentDate: '',
    activeDates: [],
    preview: false,
    singleMapLayer: {
      layerData: [],
      id: ''
    },
    leftLayer: {
      layerData: [],
      id: ''
    },
    rightLayer: {
      layerData: [],
      id: ''
    },
    manualUploadBtn: '',
    dataProcessingDate: ''
  },
  reducers: {
    // Action creator to toggle date status view
    doDateStatus(state, action) {
      state.dateStatusView = action.payload.data;
    },
    // Action creator to enable/disable compare
    doEnableCompare(state, action) {
      state.isDisableCompare = action.payload.data;
    },
    // Action creator to handle compare
    doHandleCompare(state, action) {
      state.isCompare = action.payload.data;
    },
    // Action creator to enable single view
    doEnableSingleView(state, action) {
      state.singleView = action.payload.data;
    },
    // Action creator to enable compare view
    doEnableCompareView(state, action) {
      state.compareView = action.payload.data;
      state.overlapView = false;
    },
    // Action creator to enable merge view
    doEnableMergeView(state, action) {
      state.mergeView = action.payload.data;
      state.compareView = false;
    },
    // Action creator to enable overlap view
    doEnableOverlapView(state, action) {
      state.overlapView = action.payload.data;
      state.mergeView = false;
    },
    // Action creator to enable preview
    doEnablePreview(state, action) {
      state.preview = action.payload.data;
    },
    // Action creator to set current date
    doSetCurrentDate(state, action) {
      state.currentDate = action.payload.data;
    },
    // Action creator to set active dates
    doSetActiveDates(state, action) {
      state.activeDates = action.payload.data;
    },
    // Action creator to set single map layer
    doSetSingleMapLayer(state, action) {
      state.singleMapLayer.layerData = action.payload.data;
      state.singleMapLayer.id = action.payload.id;
    },
    // Action creator to set left layer
    doSetLeftLayer(state, action) {
      state.leftLayer.layerData = action.payload.data;
      state.leftLayer.id = action.payload.id;
    },
    // Action creator to set right layer
    doSetRightLayer(state, action) {
      state.rightLayer.layerData = action.payload.data;
      state.rightLayer.id = action.payload.id;
    },
    // Action creator to set manual upload button status
    doManualUploadBtntatus(state, action) {
      state.manualUploadBtn = action.payload.data;
    },
    // Action creator to set manual data processing date
    doManualDataProcessingDate(state, action) {
      state.dataProcessingDate = action.payload.data;
    },
    // Action creator to clear mapCompare slice states
    clearMapStates(state) {
      state.isDisableCompare = true;
      state.singleView = true;
      state.preview = false;
      state.compareView = false;
      state.mergeView = false;
      state.isCompare = false;
      state.dateStatusView = false;
      state.currentDate = '';
      state.activeDates = [];
      state.singleMapLayer = {
        layerData: [],
        id: ''
      };
      state.leftLayer = {
        layerData: [],
        id: ''
      };
      state.rightLayer = {
        layerData: [],
        id: ''
      };
      state.manualUploadBtn = '';
      state.dataProcessingDate = '';
    }
  }
});

// Export action creators and reducer
export const mapCompareAction = mapCompareSlice.actions;
export default mapCompareSlice.reducer;
