import React, { useMemo } from 'react';
import { getDateStatusIcon } from '../../utils/user-helper';

// Images
import imgDown from '../../assets/images/icons/down-arrow.svg';

interface TreeHeaderProps {
  toggle: (requestType: string) => void;
  open: boolean;
  status: number;
  title: string;
  isActive: boolean;
  requestType: string;
}

interface StatusIcon {
  img: string;
  title: string;
}

const TreeHeader: React.FC<TreeHeaderProps> = ({
  toggle,
  open,
  status,
  title,
  isActive,
  requestType
}) => {
  const handleClick = () => {
    toggle(requestType);
  };

  const statusIcon: StatusIcon | null = useMemo(
    () => getDateStatusIcon(String(status)) as StatusIcon | null,
    [status]
  );

  const lineClassName = useMemo(() => {
    const classNames = {
      project: 'project-expand',
      request: 'request-expand',
      product: 'product-expand',
      aoi: 'quantity-expand'
    };
    return (classNames as { [key: string]: string })[requestType] || '';
  }, [requestType]);

  return (
    <div
      className={`tree-wrapper ${status === 4 ? `disabled-tree-title` : ``}`}
      data-testid="tree-wrapper"
      onClick={(e) => {
        if (status !== 4) {
          e.stopPropagation();
          handleClick();
        }
      }}
    >
      <label className={`vertical-line ${lineClassName}`} />
      <label className={`horizontal-line ${lineClassName}`} />
      <div
        className={`arrow-icon ${status === 4 ? `disabled-tree-title` : ``}`}
      >
        {status !== 4 ? (
          <img
            src={imgDown}
            style={{
              transform: open ? 'rotate(0deg)' : 'rotate(-90deg)'
            }}
            alt="down-arrow"
          />
        ) : (
          <img
            src={imgDown}
            style={{
              transform: 'rotate(-90deg)'
            }}
            alt="down-arrow"
          />
        )}
      </div>
      <div
        className={`tree-container ${isActive ? 'tree-background' : ''} ${status === 4 ? 'disabled-tree-title' : ''}`}
      >
        <div
          className={`tree-status-icon ${status === 4 ? 'disabled-tree-title' : ''}`}
        >
          {statusIcon && <img alt="request-status" src={statusIcon.img} />}
        </div>
        <div className="tree-title-content">
          <span
            className={`tree-title ${status === 4 ? `disabled-tree-title` : ``}`}
          >
            {title}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TreeHeader;
