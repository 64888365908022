import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';

import Modal from 'react-modal';
import { getProductAvailableDates } from '../../services/product-available-dates-services';
import { DATE_SLASH_FORMAT } from '../../utils/constants';

// Images
import CalendarArrow from '../../assets/images/icons/calendar.svg';
import DateArrow from '../../assets/images/icons/right-black-arrow.svg';
import Close from '../../assets/images/icons/close.svg';

// Css
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.css';

import { getDateFormat } from '../../utils/function-helper.js';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0rem',
    borderRadius: '1.6rem',
    background: '#fff',
    boxShadow: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25)'
  }
};

const DatePicker = ({
  selectedDateRange,
  setSelectedDateRange,
  selectedDate,
  UploadGeoJsonGeometry,
  setSelectedDate,
  disabledStatus,
  onChangeDate,
  setModifyCard,
  modifyCard,
  modifyCardData,
  isDisabled,
  sopData,
  setSopData,
  type,
  draftData
}) => {
  const [expandDate, setExpandDate] = useState(false);
  const [availableDates, setAvailableDates] = useState('');
  const [isStartDateClicked, setIsStartDateClicked] = useState(false);
  const [isEndDateClicked, setIsEndDateClicked] = useState(false);
  const [labelDates, setLabelDates] = useState({
    startDate: getDateFormat(new Date()),
    endDate: getDateFormat(new Date())
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (
      modifyCardData?.value &&
      UploadGeoJsonGeometry &&
      UploadGeoJsonGeometry.length !== 0 &&
      expandDate
    ) {
      fetchProductAvailableDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandDate]);

  useEffect(() => {
    if (!isDisabled) {
      if (
        type === 'sop' &&
        sopData?.endDate !== '' &&
        sopData?.startDate !== ''
      ) {
        setSelectedDateRange([
          {
            startDate: moment(sopData?.startDate, 'DD/MM/YYYY').toDate(),
            endDate: moment(sopData?.endDate, 'DD/MM/YYYY').toDate(),
            key: 'selection'
          }
        ]);
      } else if (modifyCardData?.startDate) {
        setSelectedDateRange([
          {
            startDate: moment(modifyCardData?.startDate, 'DD/MM/YYYY').toDate(),
            endDate: moment(modifyCardData?.endDate, 'DD/MM/YYYY').toDate(),
            key: 'selection'
          }
        ]);
      } else if (type === 'drafts') {
        setSelectedDateRange([
          {
            startDate: moment(draftData?.from_date, 'DD/MM/YYYY').toDate(),
            endDate: moment(draftData?.to_date, 'DD/MM/YYYY').toDate(),
            key: 'selection'
          }
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sopData, modifyCardData, draftData]);

  const closeModal = () => {
    setExpandDate(false);
    handleDateReset();
  };

  const handleInputDate = (dateValue, dateType) => {
    if (isValidDate(dateValue, dateType)) {
      let [day, month, year] = dateValue.split('/');
      if (dateType === 'startDate') {
        setIsStartDateClicked(false);
        let prevDateRange = [...selectedDateRange];
        prevDateRange[0].startDate = new Date(+year, +month - 1, +day);

        setSelectedDateRange(prevDateRange);
        let prevSelectedDate = selectedDate;
        prevSelectedDate.startDate = dateValue;

        setSelectedDate([prevSelectedDate]);
      } else {
        setIsEndDateClicked(false);
        let prevDateRange = [...selectedDateRange];
        prevDateRange[0].endDate = new Date(+year, +month - 1, +day);
        setSelectedDateRange(prevDateRange);
        let prevSelectedDate = selectedDate;
        prevSelectedDate.endDate = dateValue;
        setSelectedDate([prevSelectedDate]);
      }
    }
    setLabelDates({ ...labelDates, [dateType]: dateValue });
  };

  const isValidDate = (date, dateType) => {
    if (labelDates.startDate === '' && date === '' && dateType === 'endDate') {
      setError('Please Provide Start Date and End Date');
      return false;
    }
    if (labelDates.endDate === '' && date === '' && dateType === 'startDate') {
      setError('Please Provide Start Date and End Date');
      return false;
    }
    if (date === '' && dateType === 'startDate') {
      setError('Please Provide Start Date');
      return false;
    }
    if (date === '' && dateType === 'endDate') {
      setError('Please Provide End Date');
      return false;
    }
    if (!moment(date, DATE_SLASH_FORMAT, true).isValid()) {
      setError(
        'Please Provide Start Date and End Date in DD/MM/YYYY format or Valid Date.'
      );
      return false;
    }
    if (
      moment(date, DATE_SLASH_FORMAT, true).isValid() &&
      moment(date, DATE_SLASH_FORMAT, true).isAfter(
        moment(new Date(new Date().getFullYear(), 11, 31)),
        'day'
      )
    ) {
      setError('Start Date or End Date cannot be in the future.');
      return false;
    }
    if (
      moment(labelDates.endDate, DATE_SLASH_FORMAT, true).isValid() &&
      moment(date, DATE_SLASH_FORMAT, true).isValid() &&
      moment(labelDates.endDate, DATE_SLASH_FORMAT, true).isBefore(
        moment(date, DATE_SLASH_FORMAT, true)
      )
    ) {
      setError('Please ensure that Start Date is earlier than End Date.');
      return false;
    }
    if (
      moment(labelDates.startDate, DATE_SLASH_FORMAT, true).isValid() &&
      moment(date, DATE_SLASH_FORMAT, true).isValid() &&
      moment(labelDates.startDate, DATE_SLASH_FORMAT, true).isAfter(
        moment(date, DATE_SLASH_FORMAT, true)
      )
    ) {
      setError('Please ensure that Start Date is earlier than End Date.');
      return false;
    }
    setError('');
    return true;
  };

  const handleDateReset = () => {
    setSelectedDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
    setSelectedDate([
      {
        startDate: '',
        endDate: ''
      }
    ]);
    setLabelDates({
      startDate: moment(new Date()).format(DATE_SLASH_FORMAT),
      endDate: moment(new Date()).format(DATE_SLASH_FORMAT)
    });
    setError('');
  };

  const handleDone = () => {
    setExpandDate(false);
    setSelectedDate({
      startDate: moment(selectedDateRange[0]?.startDate).format(
        DATE_SLASH_FORMAT
      ),
      endDate: moment(selectedDateRange[0]?.endDate).format(DATE_SLASH_FORMAT)
    });
    if (type === 'sop') {
      setSopData({
        ...sopData,
        startDate: moment(selectedDateRange[0]?.startDate).format(
          DATE_SLASH_FORMAT
        ),
        endDate: moment(selectedDateRange[0]?.endDate).format(DATE_SLASH_FORMAT)
      });
    }

    if (type !== 'sop') {
      setModifyCard(
        modifyCard.map((item) => {
          if (item.label === modifyCardData.label) {
            return {
              ...item,
              startDate: moment(selectedDateRange[0]?.startDate).format(
                DATE_SLASH_FORMAT
              ),
              endDate: moment(selectedDateRange[0]?.endDate).format(
                DATE_SLASH_FORMAT
              )
            };
          }
          return item;
        })
      );
    }
  };

  const fetchProductAvailableDates = async () => {
    let payload = {};
    if (modifyCardData?.product_code === 'CA') {
      payload = {
        crop_id: modifyCardData?.crop ? modifyCardData?.crop?.value : '',
        geometry: UploadGeoJsonGeometry.map((item) => {
          if (typeof item.aoi === 'string') {
            return JSON.parse(item.aoi);
          } else {
            if (item?.aoi?.features) {
              return item?.aoi?.features?.map((item) => {
                return item.geometry;
              })[0];
            } else {
              return item?.aoi;
            }
          }
        })
      };
    } else {
      payload = {
        geometry: UploadGeoJsonGeometry.map((item) => {
          if (typeof item.aoi === 'string') {
            return JSON.parse(item.aoi);
          } else {
            if (item?.aoi?.features) {
              return item?.aoi?.features?.map((item) => {
                return item.geometry;
              })[0];
            } else {
              return item?.aoi;
            }
          }
        })
      };
    }

    const { data, status } = await getProductAvailableDates(
      modifyCardData?.value,
      payload
    );

    if (status === 200) {
      setAvailableDates(data?.data);
    }
  };

  const customDayContent = (day) => {
    const dayString = moment(day).format('yyyy-MM-DD'); //day.toISOString().split('T')[0];

    var availableDate = null;
    var futureDate = null;

    if (availableDates && availableDates?.available_dates.includes(dayString)) {
      availableDate = (
        <div
          style={{
            height: '0.5rem',
            width: '0.5rem',
            borderRadius: '100%',
            background: '#62D0A2',
            position: 'absolute',
            bottom: 0,
            right: 22
          }}
        />
      );
      return (
        <div>
          {availableDate}
          <span>{format(day, 'd')}</span>
        </div>
      );
    }
    if (availableDates && availableDates.future_dates.includes(dayString)) {
      futureDate = (
        <div
          style={{
            height: '0.5rem',
            width: '0.5rem',
            borderRadius: '100%',
            background: '#F2994A',
            position: 'absolute',
            bottom: 0,
            right: 22
          }}
        />
      );
      return (
        <div>
          {futureDate}
          <span>{format(day, 'd')}</span>
        </div>
      );
    }
  };

  const getDates = () => {
    if (
      type === 'sop' &&
      sopData?.endDate !== '' &&
      sopData?.startDate !== ''
    ) {
      return `${sopData.startDate} - ${sopData.endDate}`;
    } else if (modifyCardData?.startDate) {
      return `${modifyCardData?.startDate} - ${modifyCardData?.endDate}`;
    } else if (type === 'drafts') {
      return `${draftData?.from_date} - ${draftData?.to_date}`;
    }
    return '';
  };

  const latestAvailableDates = () => {
    if (availableDates && availableDates.available_dates.length > 0) {
      const lastAvailableDate = moment(
        availableDates.available_dates[
          availableDates.available_dates.length - 1
        ],
        'YYYY-MM-DD'
      ).toDate();

      setSelectedDateRange([
        {
          startDate: lastAvailableDate,
          endDate: lastAvailableDate,
          key: 'selection'
        }
      ]);
    }
  };

  const latestReadyToProcess = () => {
    if (availableDates && availableDates.future_dates.length > 0) {
      const lastReadyToProcessDate = moment(
        availableDates.future_dates[availableDates.future_dates.length - 1],
        'YYYY-MM-DD'
      ).toDate();

      setSelectedDateRange([
        {
          startDate: lastReadyToProcessDate,
          endDate: lastReadyToProcessDate,
          key: 'selection'
        }
      ]);
    }
  };

  return (
    <div className="date-picker-container">
      <div
        className={type === 'sop' ? 'input-field sop' : 'input-field'}
        onClick={() => {
          if (!(disabledStatus || isDisabled)) setExpandDate(!expandDate);
        }}
      >
        <input
          className="date-input"
          type="text"
          placeholder="Select Date Range"
          value={getDates()}
          disabled={disabledStatus || isDisabled}
          title={
            disabledStatus || isDisabled
              ? 'Select Region before choosing date range'
              : ''
          }
          data-testid="date-range-input"
        />
        <div
          className={`calendar-icon ${disabledStatus || isDisabled ? `disabled-image` : ``}`}
        >
          <img src={CalendarArrow} alt="calendar" />
        </div>
      </div>

      <Modal
        isOpen={expandDate}
        ariaHideApp={false}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className="date-picker" data-testid="date-picker">
          <div className="date-picker-header">
            <h3>Select date range for {modifyCardData?.label}</h3>
            <div className="date-picker-close" onClick={() => closeModal()}>
              <img src={Close} alt="Close" />
            </div>
          </div>
          <div className="date-picker-content">
            <ul className="date-info-list">
              <li className="date-process available">
                <label></label>
                <span
                  onClick={() => {
                    latestAvailableDates();
                  }}
                >
                  Available
                </span>
              </li>
              <li className="date-process process">
                <label></label>
                <span
                  onClick={() => {
                    latestReadyToProcess();
                  }}
                >
                  Ready to Process
                </span>
              </li>
            </ul>
            <div>
              <DateRange
                onChange={(item) => {
                  onChangeDate([item.selection]);
                  setSelectedDateRange([item.selection]);
                  setLabelDates({
                    startDate: moment(item.selection.startDate).format(
                      DATE_SLASH_FORMAT
                    ),
                    endDate: moment(item.selection.endDate).format(
                      DATE_SLASH_FORMAT
                    )
                  });
                }}
                maxDate={new Date(new Date().getFullYear(), 11, 31)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={true}
                months={2}
                ranges={selectedDateRange}
                direction="horizontal"
                rangeColors={['rgba(61, 178, 103, 0.66)']}
                showMonthAndYearPickers={true}
                editableDateInputs={true}
                autoApply={false}
                dayContentRenderer={customDayContent}
              />
              <div className="date-picker-footer">
                <div className="date-picker-dates">
                  <div className="date-picker-selection">
                    {selectedDateRange &&
                      selectedDateRange[0]?.startDate &&
                      !isStartDateClicked && (
                        <label
                          onClick={(e) => setIsStartDateClicked(true)}
                          data-testid="start-date-label"
                        >
                          {moment(selectedDateRange[0]?.startDate).format(
                            DATE_SLASH_FORMAT
                          )}
                        </label>
                      )}
                    {selectedDateRange &&
                      selectedDateRange[0]?.startDate &&
                      isStartDateClicked && (
                        <input
                          value={labelDates.startDate}
                          onChange={(e) => {
                            handleInputDate(e.target.value, 'startDate');
                          }}
                          onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                              handleInputDate(event.target.value, 'startDate');
                            }
                          }}
                          data-testid="start-date-input"
                        />
                      )}
                    {selectedDateRange &&
                      selectedDateRange[0]?.endDate &&
                      !isEndDateClicked && (
                        <>
                          <span>
                            <img src={DateArrow} alt="arrow" />
                          </span>
                          <label
                            onClick={(e) => setIsEndDateClicked(true)}
                            data-testid="end-date-label"
                          >
                            {moment(selectedDateRange[0]?.endDate).format(
                              DATE_SLASH_FORMAT
                            )}
                          </label>
                        </>
                      )}
                    {selectedDateRange &&
                      selectedDateRange[0]?.endDate &&
                      isEndDateClicked && (
                        <>
                          <span>
                            <img src={DateArrow} alt="arrow" />
                          </span>
                          <input
                            defaultValue={moment(
                              selectedDateRange[0]?.endDate
                            ).format(DATE_SLASH_FORMAT)}
                            value={labelDates.endDate}
                            onChange={(e) =>
                              handleInputDate(e.target.value, 'endDate')
                            }
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) {
                                handleInputDate(event.target.value, 'endDate');
                              }
                            }}
                            data-testid="end-date-input"
                          />
                        </>
                      )}
                  </div>

                  {error !== '' ? <p id="date-format-error">{error}</p> : null}
                </div>
                <div className="date-picker-btn">
                  <button
                    className="btn reset"
                    onClick={() => handleDateReset()}
                  >
                    Reset
                  </button>
                  <button
                    className="btn done"
                    onClick={() => handleDone()}
                    disabled={error !== ''}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DatePicker;
