import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import { Stroke, Style } from 'ol/style';
import { GeoJSON } from 'ol/format';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';
import LayerTile from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { Group as LayerGroup } from 'ol/layer.js';
import { Vector as VectorLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS.js';
import TileLayer from 'ol/layer/Tile.js';

// SCSS
import styles from '../../containers/catalog/index.module.scss';

//IMAGES
import { images } from '../../assets/images';
import MapControls from '../map-controls';

interface MapComponentProps {
  product: any;
  map_data: MapDataItem[];
}

interface MapDataItem {
  dates: string;
  location: string;
  coordinates: any;
  center_coordinates: any;
  map_data_url: string;
}

const CatalogMapComponent: React.FC<MapComponentProps> = ({
  product,
  map_data
}) => {
  const [mapViewType, setMapViewType] = useState<'satellite' | 'osm'>(
    'satellite'
  );
  const [mapData, setMapData] = useState<MapDataItem>(map_data[0]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const mapRef = useRef<HTMLDivElement>(null);
  const map = useRef<Map>();

  const osmLayer = new LayerTile({
    visible: mapViewType === 'osm',
    source: new XYZ({
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
    })
  });

  const satelliteLayer = new LayerTile({
    visible: mapViewType === 'satellite',
    source: new XYZ({
      url: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
    })
  });

  const handleDownload = () => {
    let url = product?.tiff_url;

    if (url) {
      const link = document.createElement('a');
      link.setAttribute('download', 'file');
      link.setAttribute('target', '_blank');
      link.href = url;
      link.click();
    }
  };

  useEffect(() => {
    if (mapRef.current === null) return;
    const centerCoordinates = fromLonLat([
      Number(78.80448062575913),
      Number(23.455640710144843)
    ]);

    map.current = new Map({
      target: mapRef.current,
      layers: [osmLayer, satelliteLayer],
      view: new View({
        center: centerCoordinates,
        zoom: 10
      })
    });
    const geoJsonFormat = new GeoJSON();

    const data = {
      coordinates: [mapData.coordinates],
      type: 'Polygon'
    };

    const features = geoJsonFormat.readFeatures(data, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    }) as Feature<Geometry>[];

    const source = new VectorSource({
      features: features
    });

    const wmsParams = {
      CRS: 'EPSG:3857'
    };
    const wmsLayer = new LayerGroup({
      layers: [
        new TileLayer({
          source: new TileWMS({
            url: mapData.map_data_url,
            params: wmsParams,
            serverType: 'geoserver',
            transition: 0
          })
        }),
        new VectorLayer({
          source: source,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, 0.7)',
              width: 3
            })
          })
        })
      ]
    });
    map.current.addLayer(wmsLayer);

    return () => {
      if (map.current) map.current.setTarget(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapViewType, mapData]);

  useEffect(() => {
    osmLayer.setVisible(mapViewType === 'osm');
    satelliteLayer.setVisible(mapViewType === 'satellite');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapViewType]);

  return (
    <div className={styles.data_product_map_wrapper}>
      <div ref={mapRef} className={styles.data_product_map}>
        <ul className={styles.map_view}>
          <li onClick={() => setMapViewType('osm')}>Street</li>
          <li onClick={() => setMapViewType('satellite')}>Satellite</li>
        </ul>
        <div className={styles.map_view_locations}>
          <span>
            <img src={images.pin} alt="location" />
          </span>
          <p>{mapData.location}</p>
        </div>

        <div className={styles.map_controls_list}>
          <MapControls
            map={map.current}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
        </div>

        <span className={styles.map_control_legend}>
          <img src={product?.legend_img} alt="Legend" />
        </span>
      </div>

      <div className={styles.date_container}>
        <ul className={styles.date_list}>
          {map_data?.map((item) => (
            <li key={item.dates} className={styles.date_button_with_radio}>
              <button
                className={`${styles.mapdate_available} ${mapData.dates === item.dates ? styles.activeDateButton : ''}`}
                onClick={() => setMapData(item)}
              >
                {item.dates}
              </button>
            </li>
          ))}
        </ul>
        <button className={styles.download_btn} onClick={handleDownload}>
          Download
        </button>
      </div>
    </div>
  );
};

export default CatalogMapComponent;
