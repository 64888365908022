import ndviTiff_1 from './catalog/ndvi_1.tif';
import ndviTiff_2 from './catalog/ndvi_2.tif';
import ndviTiff_3 from './catalog/ndvi_3.tif';
import ndwiTiff_1 from './catalog/ndwi_1.tif';
import ndwiTiff_2 from './catalog/ndwi_2.tif';
import ndwiTiff_3 from './catalog/ndwi_3.tif';

export const tiff = {
  ndviTiff_1,
  ndviTiff_2,
  ndviTiff_3,
  ndwiTiff_1,
  ndwiTiff_2,
  ndwiTiff_3
};
