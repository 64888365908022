import React from 'react';

//SCSS
import styles from './index.module.scss';

interface DataCardProps {
  imageUrl: string;
  title: string;
  description: string;
  setDataProductSelected: (data: any) => void;
  clickable?: boolean;
}

const DataCard: React.FC<DataCardProps> = ({
  imageUrl,
  title,
  description,
  setDataProductSelected,
  clickable = true
}) => {
  const handleClick = () => {
    if (clickable) {
      setDataProductSelected(title);
    }
  };

  return (
    <button
      className={`${styles.data_card_container}`}
      onClick={handleClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleClick();
        }
      }}
      type="button"
    >
      <span className={styles.card_images}>
        <img src={imageUrl} alt={title} />
      </span>
      <label className={styles.dataCard_title_header}>
        <h2 className={styles.dataCard_title}>{title}</h2>
        <p className={styles.card_description}>{description}</p>
      </label>
    </button>
  );
};

export default DataCard;
