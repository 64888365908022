import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUserPermisson } from '../../utils/permission-helper';
import Back from '../../assets/images/back.svg';

// SCSS
import styles from './index.module.scss';

const SettingSideBar = ({ active, setActive }) => {
  const navigate = useNavigate();
  const [userCanManageRoles, setUserCanManageRoles] = useState(
    checkUserPermisson('user.create')
  );

  return (
    <>
      <div className={styles.setting_header}>
        <span onClick={() => navigate('/d3', { replace: true })}>
          <img src={Back} alt="back button" />
        </span>
        <label>Settings</label>
      </div>

      <ul className={styles.sidebar_tab_list}>
        <li
          className={`${styles.sidebar_list} ${
            active === 'profile' && styles.active
          }
          `}
          onClick={() => setActive('profile')}
        >
          <label>Profile</label>
        </li>
        <li
          className={`${styles.sidebar_list} ${
            active === 'password' && styles.active
          }
          `}
          onClick={() => setActive('password')}
        >
          <label>Password</label>
        </li>
        <li
          className={`${styles.sidebar_list} ${active === 'user-management' && styles.active}`}
          onClick={() => setActive('user-management')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setActive('user-management');
            }
          }}
          role="button"
          tabIndex={0}
        >
          <label>{userCanManageRoles ? 'User Management' : 'Users'}</label>
        </li>
        {/* {userCanManageRoles && (
          <li
            className={`${styles.sidebar_list} ${
              active === 'user-management-roles' && styles.active
            }
          `}
          >
            {/* <label>Roles & Permissions</label> */}
        {/* </li>
        } */}
        {/* <li
          className={`${styles.sidebar_list} ${
            active === 'user-management-project' && styles.active
          }
      `}
        >
          <label>Project & Permissions</label>
        </li> */}
      </ul>
    </>
  );
};

export default SettingSideBar;
