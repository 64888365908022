import { images } from './images';
import { tiff } from './images/tiff';

export interface Product {
  product_name: string;
  product_img: string;
  legend_img: string;
  description: string;
  short_desc: string;
  tags: string[];
  note: string;
  product_data: {
    spatial_coverage: string;
    data_resolution: string;
    source: string;
    temporal_frequency: string;
    data_format: string[];
    unit: string;
    bands: {
      name: string;
      value: string;
      explanation: string;
    }[];
  };
  use_cases: {
    img: string;
    title: string;
    desc: string;
  }[];
  api_data: {
    request: any;
    response: any;
  };
  map_data: {
    id: number;
    location: string;
    dates: string;
    centerCoordinates: number[];
    coordinates: [number, number, number, number][];
    map_data_url: string;
  }[];
  tiff_url: any;
  tiff_file: string[];
}

export const dataCatalogSample: Product[] = [
  {
    product_name: 'NDVI',
    product_img: images.ndvi,
    legend_img: images.ndviLegend,
    description:
      'The Normalized Difference Vegetation Index (NDVI) is a remote sensing technique widely used to assess the health, density, and distribution of vegetation across landscapes. NDVI is calculated by comparing the reflectance of near-infrared (NIR) and visible red light, providing insights into plant photosynthetic activity and biomass production. Healthy vegetation absorbs more visible light for photosynthesis and reflects more NIR light, resulting in higher NDVI values.NDVI data products offer a range of applications in agriculture, forestry, ecology, and climate studies. They aid in monitoring crop health, detecting vegetation stress, assessing land cover changes, and quantifying carbon sequestration in forests. NDVI maps also contribute to habitat assessment, biodiversity monitoring, and ecosystem resilience analysis.Furthermore, NDVI is crucial in drought monitoring, desertification studies, and land degradation assessment. By analyzing NDVI trends over time, researchers can evaluate the impact of environmental factors on vegetation dynamics, predict crop yields, and inform land management decisions for sustainable resource use. NDVI data products are essential tools for understanding and managing terrestrial ecosystems worldwide. Unscaled version NDVI will have values -1 to +1 and scaled version will have values 0 to 200.',
    short_desc:
      'NDVI is a remote sensing index measuring vegetation health and biomass production based on near-infrared and red light reflectance.',
    tags: ['Agriculture', 'Environment', 'Crop', 'Vegetation'],
    note: 'Global coverage available, AOI outside India requires additional  processing time',
    product_data: {
      spatial_coverage: 'Global*',
      data_resolution: '10x10m Pixel level',
      source: 'Sentinel 2 L2A',
      temporal_frequency: '5 Days',
      data_format: ['Geotif', 'png', 'csv'],
      unit: 'NA',
      bands: [
        {
          name: 'Band 1 (Scaled)',
          value: '0 to 200',
          explanation:
            'When the values are closer to 200 that indicate a healthier vegetation while values which are closer to 50 indicate an unhealthier vegetation'
        },
        {
          name: 'Band 1 (Unscaled)',
          value: '-1 to +1',
          explanation:
            'When the values are closer to +1 that indicate a healthier vegetation while values which are closer to -1 indicate an unhealthier vegetation.'
        }
      ]
    },
    use_cases: [
      {
        img: images.useCase,
        title: 'Agricultural Monitoring',
        desc: 'NDVI data is used for monitoring crop health and estimating yields.'
      }
    ],
    api_data: {
      request: `import requests
                  url = 'https://beta.api.sparta.satsure.co/v1/data/product/{user_email}/a55d8ae5-9a6a-43a0-937b-8f5e24846f16/datasets'
                  header = {}
                  header['Authorization'] = 'lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      response = requests.get(url, headers=header)`,
      response: [
        {
          assets: {
            ndvi: {
              'eo:bands': [{ name: 'ndvi' }],
              href: 's3://satsure-satimg/TCX8021/T44QKM/20230301_T44QKM_TCX8021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndvi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.73727063749743, 23.337269891496106, 78.94353809593883,
            23.53205657441754
          ],
          geometry: {
            coordinates: [
              [
                [78.94353809593883, 23.53205657441754],
                [78.73727063749743, 23.445539654531558],
                [78.89265831409125, 23.337269891496106],
                [78.94353809593883, 23.53205657441754]
              ]
            ],
            type: 'Polygon'
          },
          id: '83b33372-ea25-4edb-bb7b-d3a412ee14a7',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-03-01 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDVI&bbox=78.73727063749743,23.337269891496106,78.94353809593883,23.53205657441754&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2023-03-01 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2023-03-01T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDVI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.73727063749743, 10, 0, 23.53205657441754, 0, -10
            ],
            'ss:crop': 'TCX8021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndvi: {
              'eo:bands': [{ name: 'ndvi' }],
              href: 's3://satsure-satimg/TCX8021/T44QKM/20230311_T44QKM_TCX8021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndvi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.73727063749743, 23.337269891496106, 78.94353809593883,
            23.53205657441754
          ],
          geometry: {
            coordinates: [
              [
                [78.94353809593883, 23.53205657441754],
                [78.73727063749743, 23.445539654531558],
                [78.89265831409125, 23.337269891496106],
                [78.94353809593883, 23.53205657441754]
              ]
            ],
            type: 'Polygon'
          },
          id: 'b51da993-977d-4774-97fe-e9b63a56c787',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-03-11 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDVI&bbox=78.73727063749743,23.337269891496106,78.94353809593883,23.53205657441754&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2023-03-11 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2023-03-11T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDVI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.73727063749743, 10, 0, 23.53205657441754, 0, -10
            ],
            'ss:crop': 'TCX8021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndvi: {
              'eo:bands': [{ name: 'ndvi' }],
              href: 's3://satsure-satimg/TCX8021/T44QKM/20230321_T44QKM_TCX8021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndvi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.73727063749743, 23.337269891496106, 78.94353809593883,
            23.53205657441754
          ],
          geometry: {
            coordinates: [
              [
                [78.94353809593883, 23.53205657441754],
                [78.73727063749743, 23.445539654531558],
                [78.89265831409125, 23.337269891496106],
                [78.94353809593883, 23.53205657441754]
              ]
            ],
            type: 'Polygon'
          },
          id: 'a1ba8eb6-c10e-4519-acc9-adda5708d3ff',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-03-21 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDVI&bbox=78.73727063749743,23.337269891496106,78.94353809593883,23.53205657441754&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2023-03-21 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2023-03-21T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDVI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.73727063749743, 10, 0, 23.53205657441754, 0, -10
            ],
            'ss:crop': 'TCX8021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndvi: {
              'eo:bands': [{ name: 'ndvi' }],
              href: 's3://satsure-satimg/TCX8021/T44QKM/20230401_T44QKM_TCX8021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndvi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.73727063749743, 23.337269891496106, 78.94353809593883,
            23.53205657441754
          ],
          geometry: {
            coordinates: [
              [
                [78.94353809593883, 23.53205657441754],
                [78.73727063749743, 23.445539654531558],
                [78.89265831409125, 23.337269891496106],
                [78.94353809593883, 23.53205657441754]
              ]
            ],
            type: 'Polygon'
          },
          id: '1b8b9b01-d1ef-4d86-9137-0e1b87fa5f4f',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-04-01 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDVI&bbox=78.73727063749743,23.337269891496106,78.94353809593883,23.53205657441754&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2023-04-01 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2023-04-01T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDVI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.73727063749743, 10, 0, 23.53205657441754, 0, -10
            ],
            'ss:crop': 'TCX8021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndvi: {
              'eo:bands': [{ name: 'ndvi' }],
              href: 's3://satsure-satimg/TCX8021/T44QKM/20230411_T44QKM_TCX8021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndvi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.73727063749743, 23.337269891496106, 78.94353809593883,
            23.53205657441754
          ],
          geometry: {
            coordinates: [
              [
                [78.94353809593883, 23.53205657441754],
                [78.73727063749743, 23.445539654531558],
                [78.89265831409125, 23.337269891496106],
                [78.94353809593883, 23.53205657441754]
              ]
            ],
            type: 'Polygon'
          },
          id: '693affc6-1530-4e52-8d6b-e2953c5b2802',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-04-11 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDVI&bbox=78.73727063749743,23.337269891496106,78.94353809593883,23.53205657441754&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2023-04-11 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2023-04-11T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDVI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.73727063749743, 10, 0, 23.53205657441754, 0, -10
            ],
            'ss:crop': 'TCX8021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndvi: {
              'eo:bands': [{ name: 'ndvi' }],
              href: 's3://satsure-satimg/TCX8021/T44QKM/20230421_T44QKM_TCX8021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndvi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.73727063749743, 23.337269891496106, 78.94353809593883,
            23.53205657441754
          ],
          geometry: {
            coordinates: [
              [
                [78.94353809593883, 23.53205657441754],
                [78.73727063749743, 23.445539654531558],
                [78.89265831409125, 23.337269891496106],
                [78.94353809593883, 23.53205657441754]
              ]
            ],
            type: 'Polygon'
          },
          id: 'c205ed95-f45c-4207-8335-29db465ef08d',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-04-21 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDVI&bbox=78.73727063749743,23.337269891496106,78.94353809593883,23.53205657441754&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2023-04-21 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2023-04-21T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDVI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.73727063749743, 10, 0, 23.53205657441754, 0, -10
            ],
            'ss:crop': 'TCX8021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndvi: {
              'eo:bands': [{ name: 'ndvi' }],
              href: 's3://satsure-satimg/TCX8021/T44QKM/20230501_T44QKM_TCX8021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndvi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.73727063749743, 23.337269891496106, 78.94353809593883,
            23.53205657441754
          ],
          geometry: {
            coordinates: [
              [
                [78.94353809593883, 23.53205657441754],
                [78.73727063749743, 23.445539654531558],
                [78.89265831409125, 23.337269891496106],
                [78.94353809593883, 23.53205657441754]
              ]
            ],
            type: 'Polygon'
          },
          id: 'b72008de-5907-4008-8e92-fb34b5fa0b19',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-05-01 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDVI&bbox=78.73727063749743,23.337269891496106,78.94353809593883,23.53205657441754&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2023-05-01 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2023-05-01T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDVI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.73727063749743, 10, 0, 23.53205657441754, 0, -10
            ],
            'ss:crop': 'TCX8021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        }
      ]
    },

    map_data: [
      {
        id: 1,
        location: 'Mandki, Madhya Pradesh',
        dates: '02 Jan 2024',
        centerCoordinates: [78.80448062575913, 23.455640710144843],
        coordinates: [
          [78.94353809593883, 23.53205657441754, 0, 0],
          [78.73727063749743, 23.445539654531558, 0, 0],
          [78.89265831409125, 23.337269891496106, 0, 0],
          [78.94353809593883, 23.53205657441754, 0, 0]
        ],
        map_data_url:
          'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wms?service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-03-01 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857&api_key=lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      },

      {
        id: 2,
        location: 'Mandki, Madhya Pradesh',
        dates: '17 Feb 2024',
        centerCoordinates: [78.80448062575913, 23.455640710144843],
        coordinates: [
          [78.94353809593883, 23.53205657441754, 0, 0],
          [78.73727063749743, 23.445539654531558, 0, 0],
          [78.89265831409125, 23.337269891496106, 0, 0],
          [78.94353809593883, 23.53205657441754, 0, 0]
        ],
        map_data_url:
          'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wms?service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-04-01 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857&api_key=lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      },

      {
        id: 3,
        location: 'Mandki, Madhya Pradesh',
        dates: '09 April 2024',
        centerCoordinates: [78.80448062575913, 23.455640710144843],
        coordinates: [
          [78.94353809593883, 23.53205657441754, 0, 0],
          [78.73727063749743, 23.445539654531558, 0, 0],
          [78.89265831409125, 23.337269891496106, 0, 0],
          [78.94353809593883, 23.53205657441754, 0, 0]
        ],
        map_data_url:
          'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wms?service=wms&request=GetMap&layers=NDVI&format=image/png&version=1.3.0&TIME=2023-05-01 00:00:00&aoi_id=a55d8ae5-9a6a-43a0-937b-8f5e24846f16&CRS=EPSG:3857&api_key=lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      }
    ],
    tiff_url:
      'https://media.satsure.co/sparta-catalogue/Sample_NDVI_Mandki.zip',
    tiff_file: [tiff.ndviTiff_1, tiff.ndviTiff_2, tiff.ndviTiff_3]
  },
  {
    product_name: 'NDWI',
    product_img: images.ndwi,
    legend_img: images.ndwiLegend,
    description:
      "The Normalized Difference Water Index (NDWI) is a remote sensing technique used to assess water bodies' presence and extent within a landscape. It utilizes the differences in near-infrared (NIR) and short-wave infrared (SWIR) reflectance, where water absorbs more SWIR radiation and reflects more NIR radiation compared to other land cover types. NDWI values closer to 1 indicate higher water content, while lower values signify less water or non-water surfaces. NDWI data products are instrumental in various applications such as hydrology, environmental monitoring, and land use planning. They help in mapping and monitoring surface water resources, assessing changes in wetland areas, identifying water stress in vegetation, and studying the impact of climate change on water availability. NDWI is particularly valuable in drought monitoring, flood risk assessment, and managing water resources sustainably. Unscaled version NDWI will have values -1 to +1 and scaled version will have values 0 to 200.",
    short_desc:
      'NDWI is a remote sensing index assessing water presence and extent in landscapes by comparing near-infrared and short-wave infrared reflectance.',
    tags: ['Agriculture', 'Environment', 'Crop', 'Moisture'],
    note: 'Global coverage available, AOI outside India requires additional  processing time',
    product_data: {
      spatial_coverage: 'Global*',
      data_resolution: '10x10m Pixel levels',
      source: 'Sentinel 2 L2A',
      temporal_frequency: '5 Days',
      data_format: ['Geotif', 'png', 'csv'],
      unit: 'NA',
      bands: [
        {
          name: 'Band 1 (Scaled)',
          value: '0 to 200',
          explanation:
            'When the values are closer to 200 indicate a good moisture content in vegetation and stressfull it is while the values are closer to 0.'
        },
        {
          name: 'Band 1 (Unscaled)',
          value: '-1 to +1',
          explanation:
            'When the values are closer to +1 indicate a good moisture content in vegetation and stressfull it is while the values are closer to -1.'
        }
      ]
    },
    use_cases: [
      {
        img: images.useCase,
        title: 'Agricultural Monitoring',
        desc: 'NDWI data is used for monitoring crop health and estimating yields.'
      }
    ],
    api_data: {
      request: `import requests
      url = 'https://beta.api.sparta.satsure.co/v1/data/product/{user_email}/6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6/datasets'
      header = {}
      header['Authorization'] = 'lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      response = requests.get(url, headers=header)`,
      response: [
        {
          assets: {
            ndwi: {
              'eo:bands': [{ name: 'ndwi' }],
              href: 's3://satsure-satimg/TCX9021/T44QKM/20220801_T44QKM_TCX9021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndwi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.75448062575913, 23.323344969540926, 78.9425391054931,
            23.519662014189834
          ],
          geometry: {
            coordinates: [
              [
                [78.9425391054931, 23.519662014189834],
                [78.75448062575913, 23.445640710144843],
                [78.88695906897277, 23.323344969540926],
                [78.9425391054931, 23.519662014189834]
              ]
            ],
            type: 'Polygon'
          },
          id: '3b422f95-cfed-40be-8772-1b78b3b77d6c',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDWI&format=image/png&version=1.3.0&TIME=2022-08-01 00:00:00&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDWI&bbox=78.75448062575913,23.323344969540926,78.9425391054931,23.519662014189834&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2022-08-01 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2022-08-01T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDWI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.75448062575913, 10, 0, 23.519662014189834, 0, -10
            ],
            'ss:crop': 'TCX9021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndwi: {
              'eo:bands': [{ name: 'ndwi' }],
              href: 's3://satsure-satimg/TCX9021/T44QKM/20220811_T44QKM_TCX9021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndwi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.75448062575913, 23.323344969540926, 78.9425391054931,
            23.519662014189834
          ],
          geometry: {
            coordinates: [
              [
                [78.9425391054931, 23.519662014189834],
                [78.75448062575913, 23.445640710144843],
                [78.88695906897277, 23.323344969540926],
                [78.9425391054931, 23.519662014189834]
              ]
            ],
            type: 'Polygon'
          },
          id: '6a02fbf9-f018-4a1f-8a48-8480091a7fe6',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDWI&format=image/png&version=1.3.0&TIME=2022-08-11 00:00:00&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDWI&bbox=78.75448062575913,23.323344969540926,78.9425391054931,23.519662014189834&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2022-08-11 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2022-08-11T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDWI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.75448062575913, 10, 0, 23.519662014189834, 0, -10
            ],
            'ss:crop': 'TCX9021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        },
        {
          assets: {
            ndwi: {
              'eo:bands': [{ name: 'ndwi' }],
              href: 's3://satsure-satimg/TCX9021/T44QKM/20220821_T44QKM_TCX9021.tif',
              'proj:epsg': 32644,
              'proj:shape': [10980, 10980],
              'proj:transform': [10, 0, 199980, 0, -10, 2700000, 0, 0, 1],
              roles: ['data'],
              title: 'ndwi',
              type: 'image/tiff; application=geotiff; profile=cloud-optimized'
            }
          },
          bbox: [
            78.75448062575913, 23.323344969540926, 78.9425391054931,
            23.519662014189834
          ],
          geometry: {
            coordinates: [
              [
                [78.9425391054931, 23.519662014189834],
                [78.75448062575913, 23.445640710144843],
                [78.88695906897277, 23.323344969540926],
                [78.9425391054931, 23.519662014189834]
              ]
            ],
            type: 'Polygon'
          },
          id: '09a1e040-2fb7-4d7f-8a91-a4c3a20012ee',
          links: [
            {
              href: 'service=wms&request=GetMap&layers=NDWI&format=image/png&version=1.3.0&TIME=2022-08-21 00:00:00&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6&CRS=EPSG:3857',
              rel: 'wms_url',
              title: 'OGC WMS URL',
              type: 'image/png'
            },
            {
              href: 'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wcs?service=wcs&request=GetCoverage&coverage=NDWI&bbox=78.75448062575913,23.323344969540926,78.9425391054931,23.519662014189834&CRS=EPSG:4326&format=GeoTIFF&version=1.0.0&TIME=2022-08-21 00:00:00&resx=10.0&resy=10.0&response_crs=EPSG:3857&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6',
              rel: 'wcs_url',
              title: 'OGC WCS URL',
              type: 'image/tiff'
            }
          ],
          properties: {
            datetime: '2022-08-21T00:00:00Z',
            'dea:dataset_maturity': 'final',
            gsd: [10, -10],
            instruments: ['sentinel-2'],
            mean_value: 'AOI greater than 1200 ha. Cannot generate stats',
            'odc:file_format': 'GeoTIFF',
            'odc:product_family': 'NDWI',
            'proj:epsg': 3857,
            'proj:transform': [
              78.75448062575913, 10, 0, 23.519662014189834, 0, -10
            ],
            'ss:crop': 'TCX9021.tif',
            'ss:season': '',
            'ss:version': '1.0.0',
            'ss:village': ''
          },
          stac_version: '1.0.0',
          type: 'Feature'
        }
      ]
    },
    map_data: [
      {
        id: 1,
        location: 'Mandki, Madhya Pradesh',
        dates: '28 Feb 2024',
        centerCoordinates: [78.80448062575913, 23.455640710144843],
        coordinates: [
          [78.9425391054931, 23.519662014189834, 0, 0],
          [78.75448062575913, 23.445640710144843, 0, 0],
          [78.88695906897277, 23.323344969540926, 0, 0],
          [78.9425391054931, 23.519662014189834, 0, 0]
        ],
        map_data_url:
          'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wms?service=wms&request=GetMap&layers=NDWI&format=image/png&version=1.3.0&TIME=2022-08-01 00:00:00&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6&CRS=EPSG:3857&api_key=lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      },

      {
        id: 2,
        location: 'Mandki, Madhya Pradesh',
        dates: '4 March 2024',
        centerCoordinates: [78.80448062575913, 23.455640710144843],

        coordinates: [
          [78.9425391054931, 23.519662014189834, 0, 0],
          [78.75448062575913, 23.445640710144843, 0, 0],
          [78.88695906897277, 23.323344969540926, 0, 0],
          [78.9425391054931, 23.519662014189834, 0, 0]
        ],
        map_data_url:
          'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wms?service=wms&request=GetMap&layers=NDWI&format=image/png&version=1.3.0&TIME=2022-08-11 00:00:00&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6&CRS=EPSG:3857&api_key=lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      },

      {
        id: 3,
        location: 'Mandki, Madhya Pradesh',
        dates: '17 April 2024',
        centerCoordinates: [78.80448062575913, 23.455640710144843],

        coordinates: [
          [78.9425391054931, 23.519662014189834, 0, 0],
          [78.75448062575913, 23.445640710144843, 0, 0],
          [78.88695906897277, 23.323344969540926, 0, 0],
          [78.9425391054931, 23.519662014189834, 0, 0]
        ],
        map_data_url:
          'https://beta.api.sparta.satsure.co/v1/data/geoproxy/wms?service=wms&request=GetMap&layers=NDWI&format=image/png&version=1.3.0&TIME=2022-08-21 00:00:00&aoi_id=6cd1c5b2-c0cc-4d5e-84e8-32e31044bec6&CRS=EPSG:3857&api_key=lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y'
      }
    ],
    tiff_url:
      'https://media.satsure.co/sparta-catalogue/Sample_NDWI_Mandki.zip',
    tiff_file: [tiff.ndwiTiff_1, tiff.ndwiTiff_2, tiff.ndwiTiff_3]
  },
  {
    product_name: 'MNDWI',
    product_img: images.mndwi,
    short_desc:
      'MNDWI enhances water features using green and SWIR bands, reducing built-up area interference.',
    legend_img: '',
    description: '',
    tags: [],
    note: '',
    product_data: {
      spatial_coverage: '',
      data_resolution: '',
      source: '',
      temporal_frequency: '',
      data_format: [],
      unit: 'NA',
      bands: [
        {
          name: '',
          value: '',
          explanation: ''
        }
      ]
    },
    use_cases: [
      {
        img: '',
        title: '',
        desc: ''
      }
    ],
    api_data: {
      request: ``,
      response: []
    },

    map_data: [],
    tiff_url: '',
    tiff_file: []
  },
  {
    product_name: 'Agricultural Condition Risk',
    product_img: images.agriRisk,
    short_desc:
      'Crop-specific categorical index is calculated from optical data to monitor crop performance periodically.',
    legend_img: '',
    description: '',
    tags: [],
    note: '',
    product_data: {
      spatial_coverage: '',
      data_resolution: '',
      source: '',
      temporal_frequency: '',
      data_format: [],
      unit: 'NA',
      bands: [
        {
          name: '',
          value: '',
          explanation: ''
        }
      ]
    },
    use_cases: [
      {
        img: '',
        title: '',
        desc: ''
      }
    ],
    api_data: {
      request: ``,
      response: []
    },

    map_data: [],
    tiff_url: '',
    tiff_file: []
  },
  {
    product_name: 'Farm Boundary Detection',
    product_img: images.farmBound,
    short_desc:
      'Accurate delineation of farm boundaries achieved through satellite data and deep learning techniques.',
    legend_img: '',
    description: '',
    tags: [],
    note: '',
    product_data: {
      spatial_coverage: '',
      data_resolution: '',
      source: '',
      temporal_frequency: '',
      data_format: [],
      unit: 'NA',
      bands: [
        {
          name: '',
          value: '',
          explanation: ''
        }
      ]
    },
    use_cases: [
      {
        img: '',
        title: '',
        desc: ''
      }
    ],
    api_data: {
      request: ``,
      response: []
    },

    map_data: [],
    tiff_url: '',
    tiff_file: []
  },
  {
    product_name: 'Irrigation Condition',
    product_img: images.irrigation,
    short_desc:
      'Indicates the status of irrigation on individual farm parcels, leveraging vegetative and water indices.',
    legend_img: '',
    description: '',
    tags: [],
    note: '',
    product_data: {
      spatial_coverage: '',
      data_resolution: '',
      source: '',
      temporal_frequency: '',
      data_format: [],
      unit: 'NA',
      bands: [
        {
          name: '',
          value: '',
          explanation: ''
        }
      ]
    },
    use_cases: [
      {
        img: '',
        title: '',
        desc: ''
      }
    ],
    api_data: {
      request: ``,
      response: []
    },

    map_data: [],
    tiff_url: '',
    tiff_file: []
  },
  {
    product_name: 'Cropping Intensity',
    product_img: images.croppingInt,
    short_desc:
      'Crop intensity evaluates how frequently a farm is utilized for crop cultivation throughout the year.',
    legend_img: '',
    description: '',
    tags: [],
    note: '',
    product_data: {
      spatial_coverage: '',
      data_resolution: '',
      source: '',
      temporal_frequency: '',
      data_format: [],
      unit: 'NA',
      bands: [
        {
          name: '',
          value: '',
          explanation: ''
        }
      ]
    },
    use_cases: [
      {
        img: '',
        title: '',
        desc: ''
      }
    ],
    api_data: {
      request: ``,
      response: []
    },

    map_data: [],
    tiff_url: '',
    tiff_file: []
  },
  {
    product_name: 'SatSure Super Resolution',
    product_img: images.superRes,
    short_desc:
      'Enhances the spatial resolution of satellite images captured using RGB and NIR bands by 4X.',
    legend_img: '',
    description: '',
    tags: [],
    note: '',
    product_data: {
      spatial_coverage: '',
      data_resolution: '',
      source: '',
      temporal_frequency: '',
      data_format: [],
      unit: 'NA',
      bands: [
        {
          name: '',
          value: '',
          explanation: ''
        }
      ]
    },
    use_cases: [
      {
        img: '',
        title: '',
        desc: ''
      }
    ],
    api_data: {
      request: ``,
      response: []
    },

    map_data: [],
    tiff_url: '',
    tiff_file: []
  }
];
