import axios from 'axios';
import { REFRESH_TOKEN_URL } from './url-helper';

import { forceLogout, getToken } from './user-helper';
import { header } from '../utils/constants';
import { store } from '../store/index.js';
import { authAction } from '../store/auth/auth-slice';

let refreshingFunc = undefined;

function isUnauthorizedError(error) {
  const {
    response: { status, data }
  } = error;

  if (data?.msg === 'Token has been revoked') {
    forceLogout();
  }

  return status === 401;
}

axios.interceptors.response.use(
  (res) => res,
  async (error, dispatch) => {
    const storeData = store.getState();

    const { authToken } = storeData?.user;

    const originalConfig = error.config;

    let token = undefined;

    if (authToken?.accessToken) {
      token = authToken?.accessToken;
    }

    // if we don't have token in local storage or error is not 401 just return error and break req.
    if (!token || !isUnauthorizedError(error)) {
      return Promise.reject(error);
    }

    try {
      // the trick here, that `refreshingFunc` is global, e.g. 2 expired requests will get the same function pointer and await same function.
      if (!refreshingFunc) refreshingFunc = refreshTokenRequest();

      const { data } = await refreshingFunc;

      store.dispatch(
        authAction.doRefreshLogin({ data: data?.data, loading: false })
      );
      originalConfig.headers.Authorization = `${data?.data?.accessToken}`;

      // retry original request
      try {
        return await sendRequest(originalConfig);
      } catch (innerError) {
        // if original req failed with 401 again - it means server returned not valid token for refresh request
        if (isUnauthorizedError(innerError)) {
          throw innerError;
        }
      }
      return error;
    } catch (err) {
      forceLogout();
    } finally {
      refreshingFunc = undefined;
    }
  }
);

export const sendRequest = async (args) => {
  try {
    const storeData = store.getState();

    const { authToken } = storeData?.user;
    const { url, headers, Auth, refresh, isToken } = args;
    let headerParams;
    if (Auth) {
      if (headers) {
        if (isToken) {
          header['Content-Type'] = 'multipart/form-data';
          headerParams = {
            ...header,
            Authorization: `${authToken?.accessToken}`
          };
        } else {
          header['Content-Type'] = 'application/json';
          headerParams = {
            ...header,
            Authorization: `${authToken?.accessToken}`
          };
        }
      }
      if (refresh) {
        headerParams = {
          Authorization: getToken()
        };
      }
    }

    const response = await axios({
      ...args,
      headers: headerParams,
      url: url
    });

    return response;
  } catch (error) {
    let status = error?.response?.status;

    return { error, status };
  }
};

export const getRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'get'
  });

  if (status === 200) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data,
    error: error || data,
    status
  };
};

export const postRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'post'
  });

  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const patchRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'patch'
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const deleteRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: 'delete'
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const putRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: 'put'
  });

  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const refreshTokenRequest = async () => {
  try {
    const header = {
      'Content-Type': 'application/json',
      accept: 'application/json'
    };
    header['Authorization'] = getToken();
    const response = await axios({
      url: REFRESH_TOKEN_URL,
      headers: header,
      method: 'POST'
    });
    return response;
  } catch (e) {
    return e;
  }
};
