import React from 'react';

// Images
import Close from '../../assets/images/icons/close.svg';

// CSS
import './index.css';

const saveDraftModal = ({ closeModal, onHandleSaveDraft }) => {
  return (
    <div className="save-draft-sections">
      <div className="save-draft-header">
        <label>Unsaved Changes</label>
        <span className="save-draft-close" onClick={() => closeModal(false)}>
          <img src={Close} alt="close icon" />
        </span>
      </div>
      <p className="save-draft-para">
        Would you like to save current progress as a draft before starting new
        project?
      </p>
      <div className="save-draft-btn">
        <button className="btn discard" onClick={() => closeModal(false)}>
          Discard
        </button>
        <button className="btn draft" onClick={() => onHandleSaveDraft()}>
          Save Draft
        </button>
      </div>
    </div>
  );
};
export default saveDraftModal;
