import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Map, View } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector } from 'ol/source';
import XYZ from 'ol/source/XYZ';
import LayerTile from 'ol/layer/Tile';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Fill, Stroke, Style } from 'ol/style';
import { Group as LayerGroup } from 'ol/layer.js';
// import OSM from 'ol/source/OSM.js';
import TileLayer from 'ol/layer/Tile.js';
import TileWMS from 'ol/source/TileWMS.js';
import { transformExtent } from 'ol/proj';
import * as olExtent from 'ol/extent';
import moment from 'moment';
import { getProductLegendImg } from '../../utils/user-helper';

// Images
import MapControls from '../../components/map-controls';
import UpArrow from '../../assets/images/icons/angle-up.svg';
import DownArrow from '../../assets/images/icons/angle-down.svg';
import { D3_WMS_URL } from '../../utils/url-helper';

// SCSS
import styles from './index.module.scss';
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import { onGetAoiManualDateLayerData } from '../../store/projects/project-actions';
import { projectActions } from '../../store/projects/project-slice';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';

const MapLayer = ({
  aois,
  selectedAOIs,
  selectedDataProducts,
  expand,
  overall,
  productOverall,
  zoomInTreePolygon,
  zoomInSelectedPolygonId,
  zoomInUploadPolygon,
  mapType,
  mapViewType = 'satellite',
  leftLayer,
  rightLayer,
  setMapViewType = () => {}
}) => {
  const dispatch = useDispatch();
  const { productData, aoiData, aoiDatesData, currentOpenLevel } = useSelector(
    (state) => state?.project
  );

  const { isExploreOpen, productIdCode } = useSelector(
    (state) => state?.request
  );

  const { singleMapLayer, singleView, preview, activeDates, dateStatusView } =
    useSelector((state) => state.mapCompare);

  const mapElement = useRef(null);
  const mapRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const location = useLocation();
  const [mapObject, setMapObject] = useState();
  const [isTopArrowDisabled, setIsTopArrowDisabled] = useState(true);
  const [isBottomArrowDisabled, setIsBottomArrowDisabled] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [googleLayer, setGoogleLayer] = useState(mapViewType);
  const [removedLayer, setRemovedLayer] = useState(null);

  mapRef.current = mapObject;
  const satellite = new LayerTile({
    title: 'Satellite',
    type: 'base',
    visible: true,
    source: new XYZ({
      url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
    })
  });

  satellite.set('name', 'satellite');

  const osm = new LayerTile({
    title: 'OSM',
    type: 'base',
    visible: true,
    source: new XYZ({
      url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
    })
  });

  osm.set('name', 'osm');

  // Vertical Scroll
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      const handleScroll = () => {
        if (scrollContainer.scrollTop === 0) {
          setIsTopArrowDisabled(true);
        } else {
          setIsTopArrowDisabled(false);
        }

        if (
          scrollContainer.scrollTop + scrollContainer.clientHeight ===
          scrollContainer.scrollHeight
        ) {
          setIsBottomArrowDisabled(true);
        } else {
          setIsBottomArrowDisabled(false);
        }
      };

      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollContainerRef.current]);

  useEffect(() => {
    if (aoiDatesData.length > 6) {
      setIsBottomArrowDisabled(false);
      setIsTopArrowDisabled(false);
    } else {
      setIsBottomArrowDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiDatesData]);

  // Initial Map
  useEffect(() => {
    const isSatelliteView = googleLayer === 'satellite';
    const initialMap = new Map({
      target: mapElement.current,
      layers: isSatelliteView ? [satellite] : [osm],
      view: new View({
        center: [8804131.622620502, 2409371.619506935],
        zoom: 5
      }),
      controls: []
    });

    // Create a vector layer to hold the drawn features
    const vectorSource = new Vector();
    const vectorLayer = new VectorLayer({
      source: vectorSource
    });
    initialMap.addLayer(vectorLayer);

    initialMap.on('loadstart', function () {
      initialMap.getTargetElement().classList.add('spinner');
    });

    initialMap.on('loadend', function () {
      initialMap.getTargetElement().classList.remove('spinner');
    });

    setMapObject(initialMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // zoomInUploadPolygon
  useEffect(() => {
    if (mapObject && zoomInUploadPolygon) {
      getZoomInUploadedPolyon(zoomInUploadPolygon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapObject, zoomInUploadPolygon]);

  const getZoomInUploadedPolyon = (zoomInUploadPolygon) => {
    mapObject.getLayers().forEach((layer) => {
      if (layer && layer.get('name') === 'polygon') {
        mapObject.removeLayer(layer);
      }
    });

    const source = new VectorSource();
    let copiedAOIs = [];
    if (aois) {
      copiedAOIs = [...aois, zoomInUploadPolygon];
    }

    copiedAOIs.forEach((aoi) => {
      const features = new GeoJSON({
        defaultDataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(aoi.aoi);

      features.forEach((feature) => {
        feature.set('id', aoi.id);
        feature.set('isUploadedPolygon', aoi === zoomInUploadPolygon);
      });
      source.addFeatures(features);
    });

    const polygonHistoryLayer = new VectorLayer({
      source: source,
      style: function (feature) {
        const isUploadedPolygon = feature.get('isUploadedPolygon');
        if (isUploadedPolygon) {
          return new Style({
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, 1)',
              width: 1,
              zIndex: 999
            })
          });
        } else {
          return new Style({
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, 1)',
              width: 1,
              zIndex: 999
            }),
            fill: new Fill({
              color: 'rgba(20,238,217,80%)'
            })
          });
        }
      }
    });

    polygonHistoryLayer.set('name', 'polygon');

    mapObject.addLayer(polygonHistoryLayer);

    mapObject.getView().fit(source.getExtent(), {
      size: mapObject.getSize(),
      maxZoom: 14,
      duration: 2000,
      padding: [200, 200, 200, 200]
    });
  };

  // zoomInSelectedPolygonId
  useEffect(() => {
    if (mapObject && zoomInSelectedPolygonId) {
      getZoomInPolyons(zoomInSelectedPolygonId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapObject, zoomInSelectedPolygonId]);

  const getZoomInPolyons = (zoomInSelectedPolygonId) => {
    // Initialize variables to store the combined extent
    let combinedExtent = null;
    mapObject.getLayers().forEach((layer) => {
      if (layer && layer.get('name') === 'polygon') {
        const features = layer.getSource().getFeatures();
        for (const feature of features) {
          const featureId = feature.get('id');
          if (featureId === zoomInSelectedPolygonId) {
            const featureExtent = feature.getGeometry().getExtent();
            if (combinedExtent === null) {
              combinedExtent = featureExtent.slice();
            } else {
              olExtent.extend(combinedExtent, featureExtent);
            }
            // Add a new style for highlighting the feature boundary
            const highlightStyle = new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 1)',
                width: 3,
                zIndex: 999
              }),
              fill: new Fill({
                color: 'rgba(20,238,217,80%)'
              })
            });
            feature.setStyle(highlightStyle);
          } else {
            // Remove any existing styles to revert to the default style
            feature.setStyle(null);
          }
        }
      }
    });
    if (combinedExtent !== null) {
      // Fit the view to the combined extent of the focused features
      mapObject.getView().fit(combinedExtent, {
        size: mapObject.getSize(),
        maxZoom: 14,
        duration: 2000,
        padding: [200, 200, 200, 200]
      });
    }
  };

  // zoomInTreePolygon
  useEffect(() => {
    if ((mapObject && zoomInTreePolygon) || zoomInTreePolygon === undefined) {
      getZoomInTreePolyons(zoomInTreePolygon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapObject, zoomInTreePolygon]);

  const getZoomInTreePolyons = (zoomInTreePolygon) => {
    // Initialize variables to store the combined extent

    let combinedExtent = null;
    mapObject?.getLayers().forEach((layer) => {
      if (layer && layer.get('name') === 'treeRegion') {
        const features = layer.getSource().getFeatures();
        for (const feature of features) {
          const featureId = feature.get('id');
          if (featureId === zoomInTreePolygon?.aoi_id) {
            const featureExtent = feature.getGeometry().getExtent();
            if (combinedExtent === null) {
              combinedExtent = featureExtent.slice();
            } else {
              olExtent.extend(combinedExtent, featureExtent);
            }
            // Add a new style for highlighting the feature boundary
            const highlightStyle = new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 1)',
                width: 3,
                zIndex: 999
              }),
              fill: new Fill({
                color: 'rgba(47,128,237,0.70)'
              })
            });
            feature.setStyle(highlightStyle);
          } else {
            // Remove any existing styles to revert to the default style
            feature.setStyle(null);
          }
        }
      }
    });
    if (combinedExtent !== null) {
      // Fit the view to the combined extent of the focused features
      mapObject.getView().fit(combinedExtent, {
        size: mapObject.getSize(),
        maxZoom: 18,
        duration: 3000,
        padding: [50, 50, 50, 50]
      });
    }
  };

  // Layer Switch
  const layerSwitch = (layerName) => {
    const view = mapObject.getView();
    const currentZoom = view.getZoom();
    const currentCenter = view.getCenter();

    //zoom at the previous state
    mapObject.once('rendercomplete', () => {
      view.setZoom(currentZoom);
      view.setCenter(currentCenter);
    });

    // Clear existing layers
    mapObject.getLayers().clear();

    if (layerName === 'Default') {
      mapObject.addLayer(osm);
    } else if (layerName === 'satellite') {
      mapObject.addLayer(satellite);
    }

    setGoogleLayer(layerName);
    setMapViewType(layerName);
  };

  // Create Request Flow Layer
  useEffect(() => {
    if (
      mapObject &&
      overall?.length !== 0 &&
      location.pathname !== '/d3/browse'
    ) {
      if (
        productOverall &&
        selectedDataProducts?.length !== 0 &&
        selectedDataProducts?.length !== 0
      ) {
        plotOverallAvailability(productOverall);
      } else {
        plotOverallAvailability(overall);
      }
      if (!isPolygonPresent) {
        var view = mapObject.getView();
        view.setCenter([8790109.819560181, 2343497.068402029]);
        view.setZoom(5);
      }
    } else if (mapObject) {
      mapObject.getLayers().forEach((layer) => {
        if (layer && layer.get('name') === 'available-layer') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapObject, location, googleLayer, overall, productOverall]);

  const plotOverallAvailability = (geoJSON) => {
    if (mapObject) {
      mapObject?.getLayers().forEach((layer) => {
        if (layer && layer.get('name') === 'available-layer') {
          mapObject.removeLayer(layer);
        }
      });

      const source = new VectorSource();

      geoJSON &&
        geoJSON?.features &&
        geoJSON?.features.length !== 0 &&
        geoJSON?.features?.forEach((geojson) => {
          const features = new GeoJSON({
            defaultDataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          }).readFeatures(geojson.geometry);

          source.addFeatures(features);
        });

      const polygonVectorLayer = new VectorLayer({
        source: source,
        style: new Style({
          stroke: new Stroke({
            color: 'rgba(255, 255, 255, 1)',
            width: 1,
            zIndex: 999
          }),
          fill: new Fill({
            color: 'rgba(30, 224, 205, 0.1)'
          })
        })
      });

      polygonVectorLayer.set('name', 'available-layer');

      mapObject.addLayer(polygonVectorLayer);
    }
  };

  const isPolygonPresent = () => {
    mapObject.getLayers().forEach((layer) => {
      if (layer && layer.get('name') === 'polygon') {
        return true;
      }
    });
    return false;
  };

  useEffect(() => {
    if (mapObject && aois?.length > 0 && selectedAOIs.length > 0) {
      plotPolygon();
    }
    if (mapObject && (!selectedAOIs || selectedAOIs.length === 0)) {
      var view = mapObject.getView();
      view.setCenter([8790109.819560181, 2343497.068402029]);
      view.setZoom(5);
      mapObject.getLayers().forEach((layer) => {
        if (layer && layer.get('name') === 'polygon') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aois, mapObject, selectedAOIs, googleLayer]);

  const plotPolygon = () => {
    mapObject.getLayers().forEach((layer) => {
      if (layer && layer.get('name') === 'polygon') {
        mapObject.removeLayer(layer);
      }
    });

    const source = new VectorSource();

    aois.forEach((aoi) => {
      if (selectedAOIs.includes(aoi.id)) {
        const features = new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(aoi.aoi);

        features.forEach((feature) => {
          feature.set('id', aoi.id);
        });

        source.addFeatures(features);
      }
    });

    const polygonVectorLayer = new VectorLayer({
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, 1)',
          width: 1,
          zIndex: 999
        }),
        fill: new Fill({
          color: 'rgba(20,238,217,80%)'
        })
      })
    });

    polygonVectorLayer.set('name', 'polygon');
    mapObject.addLayer(polygonVectorLayer);

    mapObject.getView().fit(source.getExtent(), {
      size: mapObject.getSize(),
      maxZoom: 14,
      duration: 2000,
      padding: [200, 200, 200, 200]
    });
  };

  // Project Tree Region Aoi Polygon
  useEffect(() => {
    if (mapObject && productData?.request_aoi_data?.length > 0 && !preview) {
      plotProjectTreeRegionPoly(productData?.request_aoi_data);
    } else if (mapObject && productData?.length === 0) {
      var view = mapObject.getView();
      view.setCenter([8790109.819560181, 2343497.068402029]);
      view.setZoom(1);
      mapObject.getLayers().forEach((layer) => {
        if (layer && layer.get('name') === 'treeRegion') {
          mapObject.removeLayer(layer);
        }
      });
    } else if (mapObject && preview) {
      mapObject.getLayers().forEach((layer) => {
        if (layer && layer.get('name') === 'treeRegion') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, mapObject, googleLayer, preview]);

  const plotProjectTreeRegionPoly = (plotTreeRegionPolygon) => {
    mapObject.getLayers().forEach((layer) => {
      if (layer && layer.get('name') === 'treeRegion') {
        mapObject.removeLayer(layer);
      }
    });

    const source = new VectorSource();

    plotTreeRegionPolygon.forEach((regionAoi) => {
      let regionPolygon = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: regionAoi.geometry
          }
        ]
      };

      const features = new GeoJSON({
        defaultDataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(regionPolygon);

      features.forEach((feature) => {
        feature.set('id', regionAoi.aoi_id);
      });
      source.addFeatures(features);
    });

    const treeRegionpolygonVectorLayer = new VectorLayer({
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, 1)',
          width: 1,
          zIndex: 999
        }),
        fill: new Fill({
          color: 'rgba(20,238,217,80%)'
        })
      })
    });

    treeRegionpolygonVectorLayer.set('name', 'treeRegion');
    mapObject.addLayer(treeRegionpolygonVectorLayer);

    mapObject.getView().fit(source.getExtent(), {
      size: mapObject.getSize(),
      maxZoom: 14,
      duration: 2000,
      padding: [200, 200, 200, 200]
    });
  };

  useEffect(() => {
    if (mapObject) {
      if (location.pathname === '/d3/browse') {
        const targetZoom = 4;

        mapObject.getView().animate({
          zoom: targetZoom,
          duration: 3000
        });

        mapObject.getLayers().forEach((layer) => {
          if (layer && layer.get('name') === 'polygon') {
            setRemovedLayer(layer);
            mapObject.removeLayer(layer);
          }
        });
      } else if (isExploreOpen && location.pathname === '/d3' && removedLayer) {
        removedLayer.set('name', 'polygon');
        mapObject.addLayer(removedLayer);
        const layerExtent = removedLayer.getSource().getExtent();
        const padding = [100, 100, 100, 100];
        mapObject.getView().fit(layerExtent, {
          size: mapObject.getSize(),
          maxZoom: 14,
          duration: 2000,
          padding: padding
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mapObject, removedLayer]);

  //Aoi Date Scroll
  const handleTopClick = () => {
    scrollContainerRef.current.classList.add('scroll-animation');
    scrollContainerRef.current.scrollTop -= 50;

    setTimeout(() => {
      scrollContainerRef.current.classList.remove('scroll-animation');
    }, 300);
  };

  const handleBottomClick = () => {
    scrollContainerRef.current.classList.add('scroll-animation');
    scrollContainerRef.current.scrollTop += 50;

    setTimeout(() => {
      scrollContainerRef.current.classList.remove('scroll-animation');
    }, 300);
  };

  // Single Map Screen Layer
  useEffect(() => {
    if (
      singleMapLayer &&
      singleMapLayer.layerData &&
      singleMapLayer.layerData.length !== 0 &&
      aoiData.length !== 0
    ) {
      onPreviewSingleLayer();
    } else if (mapObject && aoiData.length === 0) {
      mapObject.getLayers().forEach(function (layer) {
        // var view = mapObject.getView();
        // view.setCenter([8790109.819560181, 2343497.068402029]);
        // view.setZoom(1);
        if (layer && layer.get('name') === 'singleLayer') {
          mapObject.removeLayer(layer);
        }
      });
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleMapLayer, preview, aoiData, googleLayer]);

  const onPreviewSingleLayer = () => {
    if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLayer') {
          mapObject.removeLayer(layer);
        }
      });
      let links = singleMapLayer.layerData?.links.filter(
        (type) => type.rel === 'wms_url'
      );
      let URL = `${D3_WMS_URL}${links[0]?.href}&api_key=CIEVeK86adStvoGcykZZCSxsBVOLTr28S_wknANWYoY`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(singleMapLayer.layerData?.geometry)
      });

      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      let singleTransform = transformExtent(
        singleMapLayer.layerData?.bbox,
        'EPSG:4326',
        'EPSG:3857'
      );

      mapObject.getView().fit(singleTransform, {
        duration: 3000,
        padding: [200, 200, 200, 200]
      });

      // const opacity = parseFloat(layerOpacity);
      // wmsLayer.setOpacity(opacity);
      mapObject.addLayer(wmsLayer);
      wmsLayer.set('name', 'singleLayer');
      // getArea(wmsLayerSingle?.wms);
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
  };

  // Compare Map Left Screen Layer
  useEffect(() => {
    if (
      leftLayer &&
      leftLayer.layerData &&
      leftLayer.layerData.length !== 0 &&
      mapType === 'singleLeftLayer' &&
      mapObject !== undefined
    ) {
      onPreviewComparerLeftLayer();
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLeftLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftLayer, preview, googleLayer, mapObject]);

  const onPreviewComparerLeftLayer = () => {
    if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLeftLayer') {
          mapObject.removeLayer(layer);
        }
      });
      let links = leftLayer.layerData?.links.filter(
        (type) => type.rel === 'wms_url'
      );
      let URL = `${D3_WMS_URL}${links[0]?.href}&api_key=CIEVeK86adStvoGcykZZCSxsBVOLTr28S_wknANWYoY`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(leftLayer.layerData?.geometry)
      });

      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      let singleTransform = transformExtent(
        leftLayer.layerData?.bbox,
        'EPSG:4326',
        'EPSG:3857'
      );

      mapObject.getView().fit(singleTransform, {
        duration: 3000,
        padding: [200, 200, 200, 200]
      });

      // const opacity = parseFloat(layerOpacity);
      // wmsLayer.setOpacity(opacity);
      wmsLayer.set('name', 'singleLeftLayer');
      mapObject.addLayer(wmsLayer);
      // getArea(wmsLayerSingle?.wms);
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLeftLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
  };

  // Compare Map Right Screen Layer
  useEffect(() => {
    if (
      rightLayer &&
      rightLayer.layerData &&
      rightLayer.layerData.length !== 0 &&
      mapType === 'singleRightLayer' &&
      mapObject !== undefined
    ) {
      onPreviewCompareRightLayer();
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleRightLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightLayer, preview, googleLayer, mapObject]);

  const onPreviewCompareRightLayer = () => {
    if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleRightLayer') {
          mapObject.removeLayer(layer);
        }
      });
      let links = rightLayer.layerData?.links.filter(
        (type) => type.rel === 'wms_url'
      );
      let URL = `${D3_WMS_URL}${links[0]?.href}&api_key=CIEVeK86adStvoGcykZZCSxsBVOLTr28S_wknANWYoY`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(rightLayer.layerData?.geometry)
      });

      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      let singleTransform = transformExtent(
        rightLayer?.layerData.bbox,
        'EPSG:4326',
        'EPSG:3857'
      );

      mapObject.getView().fit(singleTransform, {
        duration: 3000,
        padding: [200, 200, 200, 200]
      });
      mapObject.addLayer(wmsLayer);
      wmsLayer.set('name', 'singleRightLayer');
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleRightLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
  };

  // singleMergeMapLayer
  useEffect(() => {
    if (
      leftLayer &&
      leftLayer?.layerData &&
      leftLayer?.layerData.length !== 0 &&
      rightLayer &&
      rightLayer?.layerData &&
      rightLayer?.layerData.length !== 0 &&
      mapObject !== undefined
    ) {
      singleMergeMapLayer();
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleMergeMapLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftLayer?.layerData, rightLayer?.layerData, mapObject, googleLayer]);

  const singleMergeMapLayer = () => {
    if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleMergeMapLayer') {
          mapObject.removeLayer(layer);
        }
      });
      let links = leftLayer?.layerData?.links.filter(
        (type) => type.rel === 'wms_url'
      );
      let secondLinks = rightLayer?.layerData?.links.filter(
        (type) => type.rel === 'wms_url'
      );

      let URL = `${D3_WMS_URL}${links[0]?.href}&api_key=CIEVeK86adStvoGcykZZCSxsBVOLTr28S_wknANWYoY`;
      let LAYER_URL = `${D3_WMS_URL}${secondLinks[0]?.href}&api_key=CIEVeK86adStvoGcykZZCSxsBVOLTr28S_wknANWYoY`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(leftLayer?.layerData?.geometry)
      });
      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      const wmsSecondLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: LAYER_URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      let singleTransform = transformExtent(
        leftLayer?.layerData?.bbox,
        'EPSG:4326',
        'EPSG:3857'
      );

      mapObject.getView().fit(singleTransform, {
        duration: 3000,
        padding: [200, 200, 200, 200]
      });
      wmsLayer.set('name', 'singleMergeMapLayer');
      wmsSecondLayer.set('name', 'singleMergeMapLayer');
      mapObject.addLayer(wmsLayer);
      mapObject.addLayer(wmsSecondLayer);
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleMergeMapLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
  };

  const handleDateCheckClickList = (aoiDate, generationType) => {
    dispatch(mapCompareAction.doManualUploadBtntatus({ data: '' }));
    if (currentOpenLevel === aoiDate.id) {
      dispatch(projectActions.doAoiDatesId({ data: '' }));
      dispatch(mapCompareAction.doEnableCompare({ data: true }));
      dispatch(mapCompareAction.doSetActiveDates({ data: [] }));
      dispatch(mapCompareAction.doSetSingleMapLayer({ data: [], id: '' }));
    } else {
      if (generationType === 'manual') {
        dispatch(
          mapCompareAction.doManualDataProcessingDate({ data: aoiDate })
        );
        dispatch(onGetAoiManualDateLayerData(aoiDate));
      } else if (generationType === 'automated') {
        dispatch(mapCompareAction.doSetActiveDates({ data: [aoiDate.id] }));
        dispatch(
          mapCompareAction.doSetSingleMapLayer({
            data: aoiDate,
            id: aoiDate.id
          })
        );
      }
    }
  };

  return (
    <div ref={mapElement} id="map" className={styles.map_wrapper}>
      <ul className={styles.map_layer_switch_sections}>
        <li
          className={googleLayer === 'Default' && styles.active}
          onClick={() => {
            if (googleLayer !== 'Default') {
              layerSwitch('Default');
            }
          }}
        >
          Street
        </li>
        <li
          className={googleLayer === 'satellite' && styles.active}
          onClick={() => {
            if (googleLayer !== 'satellite') {
              layerSwitch('satellite');
            }
          }}
        >
          Satellite
        </li>
      </ul>

      {mapObject !== undefined && (
        <MapControls
          map={mapObject}
          setMap={setMapObject}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        />
      )}

      {singleMapLayer?.layerData &&
      singleMapLayer?.layerData?.length !== 0 &&
      mapType === 'singleLayer' &&
      productIdCode ? (
        <div
          className={`${styles.common_layer_product_legend} ${styles.single_layer_product_legend}`}
        >
          <div
            className={styles.layer_legend}
            data-testid="product-code-element"
          >
            <img src={getProductLegendImg(productIdCode)} alt="product img" />
          </div>
        </div>
      ) : leftLayer?.layerData &&
        leftLayer?.layerData.length !== 0 &&
        mapType === 'singleLeftLayer' &&
        productIdCode ? (
        <div className={styles.common_layer_product_legend}>
          <div className={styles.layer_legend}>
            <img src={getProductLegendImg(productIdCode)} alt="product img" />
          </div>
        </div>
      ) : rightLayer?.layerData &&
        rightLayer?.layerData.length !== 0 &&
        mapType === 'singleRightLayer' &&
        productIdCode ? (
        <div className={styles.common_layer_product_legend}>
          <div className={styles.layer_legend}>
            <img src={getProductLegendImg(productIdCode)} alt="product img" />
          </div>
        </div>
      ) : leftLayer?.layerData &&
        leftLayer?.layerData.length !== 0 &&
        rightLayer?.layerData &&
        rightLayer?.layerData.length !== 0 &&
        productIdCode ? (
        <div
          className={`${styles.common_layer_product_legend} ${styles.multi_layer_product_legend}`}
        >
          <div className={styles.layer_legend}>
            <img src={getProductLegendImg(productIdCode)} alt="product img" />
          </div>
          <div className={styles.layer_legend}>
            <img src={getProductLegendImg(productIdCode)} alt="product img" />
          </div>
        </div>
      ) : null}

      {singleView && (
        <>
          {preview && aoiDatesData.length !== 0 && (
            <div
              className={`${styles.product_available_date_container} ${
                isFullScreen || expand ? styles.active : ''
              }`}
            >
              {/* <div className={styles.onclick_date_picker_sections}>
            <label>Date</label>
            <span>
              <img src={DownArrow} alt="down-arrow" />
            </span>
          </div> */}
              <div className={styles.date_scroll_sections}>
                <ul className={styles.date_list} ref={scrollContainerRef}>
                  {aoiDatesData.map((item) => {
                    return (
                      <li className={styles.particular_date}>
                        <label
                          className={styles.date_horizontal_tree_line}
                        ></label>
                        <label
                          className={styles.date_vertical_tree_line}
                        ></label>
                        <span
                          className={`${styles.date_value} ${
                            activeDates.includes(item.id) && styles.active
                          }`}
                          onClick={() => {
                            if (!dateStatusView) {
                              handleDateCheckClickList(
                                item,
                                aoiData?.generation_type
                              );
                            }
                          }}
                        >
                          {aoiData?.generation_type === 'manual' ? (
                            <>{moment(item?.date).format('DDMMMYY')}</>
                          ) : (
                            aoiData?.generation_type === 'automated' && (
                              <>
                                {moment(item?.properties?.datetime).format(
                                  'DDMMMYY'
                                )}
                              </>
                            )
                          )}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <button
                  className={`${styles.onclick_scroll_btn} ${styles.top} ${
                    isTopArrowDisabled ? styles.disabled : ''
                  }`}
                  onClick={() => handleTopClick()}
                  disabled={isTopArrowDisabled}
                >
                  <img src={UpArrow} alt="down-arrow" />
                </button>
                <button
                  className={`${styles.onclick_scroll_btn} ${styles.bottom} ${
                    isBottomArrowDisabled ? styles.disabled : ''
                  }`}
                  onClick={() => handleBottomClick()}
                  disabled={isBottomArrowDisabled}
                >
                  <img src={DownArrow} alt="down-arrow" />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MapLayer;
