import { ProductData } from './ProductData';
/**
 * Represents a product.
 */
export class Product {
  /**
   * Constructs an instance of Product.
   * @param {object} productData - The data object containing product information.
   */
  constructor(productData) {
    this.assignProductData(productData);
  }

  /**
   * Assigns product data to the instance properties.
   * @param {object} productData - The data object containing product information.
   */
  assignProductData(productData) {
    this.action_status = productData.action_status;
    this.created_on = productData.created_on;
    this.description = productData.description;
    this.due_date = productData.due_date;
    this.id = productData.id;
    this.name = productData.name;
    this.request_aoi_data = productData.request_aoi_data;
    this.request_product_data = productData.request_product_data.map(
      (item) => new ProductData(item)
    );
    this.requested_by_email = productData.requested_by_email;
    this.requested_by_name = productData.requested_by_name;
    this.role = productData.role;
    this.status_in_percentage = productData.status_in_percentage;
    this.updated_on = productData.updated_on;
  }

  /**
   * Retrieves product information.
   * @returns {object} - An object containing product information.
   */
  getProductInfo() {
    return {
      action_status: this.action_status,
      created_on: this.created_on,
      description: this.description,
      due_date: this.due_date,
      id: this.id,
      name: this.name,
      request_aoi_data: this.request_aoi_data,
      request_product_data: this.request_product_data.map((item) =>
        item.getProductDataInfo()
      ),
      requested_by_email: this.requested_by_email,
      requested_by_name: this.requested_by_name,
      role: this.role,
      status_in_percentage: this.status_in_percentage,
      updated_on: this.updated_on
    };
  }
}
