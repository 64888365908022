// user-slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserList } from '../../model/UserList';
import { UserDetailsData, UserState } from '../../types/user.types';

const initialState: UserState = {
  userData: null,
  usersDetailsData: { userEmail: '' },
  error: null,
  emailVerifyLoader: false,
  emailVerify: undefined,
  authToken: undefined,
  loading: false,
  industryOptions: [],
  roleOptions: [],
  userPermissions: undefined
};

const userDataSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserDataSuccess(state, action: PayloadAction<UserList>) {
      state.userData = action.payload;
      state.error = null;
    },
    fetchUserDataFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setUserDetails(state, action: PayloadAction<UserDetailsData>) {
      state.usersDetailsData = action.payload;
    }
  }
});

export const { fetchUserDataSuccess, fetchUserDataFailure, setUserDetails } =
  userDataSlice.actions;
export default userDataSlice.reducer;
