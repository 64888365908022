import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import Explore from '../explore';
import {
  getProducts,
  getAvailableProducts
} from '../../services/products-service';
import Search from '../../assets/images/sidebar/search.svg';
import Explore_main from '../../assets/images/sidebar/Pin.svg';
import Search_bg from '../../assets/images/header/Search_White.svg';
import Explore_bg from '../../assets/images/header/Pin_White.svg';
import info from '../../assets/images/icons/info-i.svg';

//css
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { requestAction } from '../../store/requests/request-slice';
import { getDataProducts } from '../../store/requests/request-actions';

const CurrentRequest = ({
  mapObject,
  aois,
  historyAOIs,
  setHistoryAOIs,
  setAOIs,
  selectedAOIs,
  setSelectedAOIs,
  uploadAOIError,
  setUploadAOIError,
  selectedButton,
  setSelectedButton,
  selectedDataProducts,
  setSelectedDataProducts,
  setMoreProductsOption,
  moreProductsOption,
  setZoomInSelectedPolygonId,
  setZoomInUploadPolygonId
}) => {
  const dispatch = useDispatch();

  const { dataProducts, requestForm } = useSelector((state) => state?.request);
  const [productsOption, setProductsOption] = useState([]);
  const [availableProductsOption, setAvailableProductsOption] = useState([]);

  useEffect(() => {
    dispatch(getDataProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (dataProducts && dataProducts.length !== 0) {
      onHandleProducts(dataProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProducts]);

  useEffect(() => {
    let selectedGeometry = [];
    aois.forEach((aoi) => {
      if (typeof aoi.aoi === 'string') {
        selectedGeometry.push(JSON.parse(aoi.aoi));
      } else {
        if (aoi?.aoi?.features) {
          aoi?.aoi.features.map((item) => {
            selectedGeometry.push(item.geometry);
          });
        } else {
          selectedGeometry.push(aoi?.aoi);
        }
      }
    });
    if (selectedGeometry.length != 0) fetchAvailableProducts(selectedGeometry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAOIs, productsOption]);
  const fetchAvailableProducts = async (geometry) => {
    const { data, status } = await getAvailableProducts({
      geometry: geometry
    });

    if (status === 200) {
      // getOverAllProduct();
      let availableProductsArray = data.data.map((item) => {
        let new_obj = getSopDefaultValue(item?.product_sop?.input_data);

        return {
          extraction: item?.is_extraction_enabled,
          crop_specific: item?.crop_specific,
          crops: item.crops,
          crop: '',
          gran_data: '',
          extraction_data: '',
          label: item.name,
          value: item.id,
          sop: item.product_sop,
          sop_data: new_obj,
          desc: item.description,
          product_code: item.code
        };
      });
      setAvailableProductsOption([...availableProductsArray]);

      if (productsOption.length != 0 && availableProductsArray.length != 0) {
        setMoreProductsOption(
          productsOption.filter(
            (item1) =>
              !availableProductsArray
                .map((item2) => item2.value)
                .includes(item1.value)
          )
        );
      }
    }
  };

  const onHandleProducts = (data) => {
    let arr = data.map((item) => {
      let new_obj = getSopDefaultValue(item.product_sop?.input_data);
      return {
        extraction: item?.is_extraction_enabled,
        crop_specific: item?.crop_specific,
        crops: item.crops,
        crop: '',
        gran_data: '',
        extraction_data: '',
        label: item.name,
        value: item.id,
        sop: item.product_sop,
        sop_data: new_obj,
        desc: item.description,
        product_code: item.code
      };
    });
    setProductsOption([...arr]);
  };

  const getSopDefaultValue = (obj) => {
    let new_boj = [];
    new_boj = obj?.map((datas) => {
      return {
        name: datas.name,
        input_data: datas.input_data.map((sops) => {
          return {
            name: sops.name,
            input_data: sops?.input_data?.map((inputs) => {
              if (datas.name === 'QA/QC') {
                return {
                  type: inputs?.type,
                  selected_value: [inputs?.default_value],
                  is_editable: inputs?.is_editable
                };
              } else {
                return {
                  type: inputs?.type,
                  selected_value: [inputs?.default_value],
                  options: inputs?.options
                };
              }
            })
          };
        })
      };
    });

    return new_boj;
  };

  const handleDataProductSelection = (product) => {
    let dataProduct = [...selectedDataProducts];
    let isPresent = dataProduct.some((item) => item.value === product.value);
    if (isPresent) {
      const updatedDataProducts = dataProduct.filter(
        (item) => item.value !== product.value
      );
      setSelectedDataProducts(updatedDataProducts);
      dispatch(
        requestAction.doRequstFormUpdate({
          data: {
            ...requestForm,
            selectedDataProducts: updatedDataProducts
          }
        })
      );
    } else {
      const copiedProduct = {
        ...product,
        startDate: '',
        endDate: '',
        format: ''
      };
      setSelectedDataProducts([...dataProduct, copiedProduct]);
      dispatch(
        requestAction.doRequstFormUpdate({
          data: {
            ...requestForm,
            selectedDataProducts: [...dataProduct, copiedProduct]
          }
        })
      );
    }
  };

  const handleButtonClick = (buttonType) => {
    setSelectedButton(buttonType);
  };

  return (
    <>
      <div className="request_container">
        <div className="request_header">
          <div
            className={`data request-btn ${
              selectedButton === 'data' ? 'selected' : ''
            }`}
            onClick={() => handleButtonClick('data')}
          >
            <img
              src={selectedButton === 'data' ? Search_bg : Search}
              alt="search"
            />
            Data Product
          </div>
          <div
            className={`region request-btn ${
              selectedButton === 'region' ? 'selected' : ''
            }`}
            onClick={() => handleButtonClick('region')}
          >
            <img
              src={selectedButton === 'region' ? Explore_bg : Explore_main}
              alt="locations"
            />
            Region
          </div>
        </div>
      </div>
      <div className="request_body">
        {selectedButton === 'region' && (
          <Explore
            mapObject={mapObject}
            aois={aois}
            historyAOIs={historyAOIs}
            setHistoryAOIs={setHistoryAOIs}
            setAOIs={setAOIs}
            selectedAOIs={selectedAOIs}
            setSelectedAOIs={setSelectedAOIs}
            uploadAOIError={uploadAOIError}
            setUploadAOIError={setUploadAOIError}
            setZoomInSelectedPolygonId={setZoomInSelectedPolygonId}
            setZoomInUploadPolygonId={setZoomInUploadPolygonId}
          />
        )}
        {selectedButton === 'data' && (
          <div className="data-product-wrapper">
            <div className="data-product-selector">
              <span className="data-product">
                <Select
                  classNamePrefix="data-product-select"
                  placeholder="Select Data Product"
                  options={productsOption.map((option) => ({
                    ...option,
                    isDisabled: selectedDataProducts.some(
                      (selectedProduct) =>
                        selectedProduct.value === option.value
                    )
                  }))}
                  value={''}
                  onChange={(e) => {
                    handleDataProductSelection(e);
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isDisabled
                        ? 'defaultColor'
                        : 'white',
                      '&:hover': {
                        backgroundColor: state.isDisabled
                          ? '#f2f2f2'
                          : '#3DB267',
                        color: state.isDisabled ? '#C7C7CC' : 'white'
                      }
                    })
                  }}
                ></Select>
              </span>
            </div>
            {selectedAOIs.length === 0 && (
              <div className="available-product">
                <div className="available-products-header">
                  <h1 className="card-section-header">Available Products</h1>
                  <div>
                    <img
                      src={info}
                      alt="info"
                      className="available-products-info"
                    />
                    <div className="available-prodcuts-tooltip">
                      <div className="top-triangle"></div>
                      <div className="available-prodcuts-tooltip-content">
                        <p>Available data products in selected region.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <span>
                  Please
                  <span
                    className="select-region"
                    onClick={() => setSelectedButton('region')}
                  >
                    Select Region
                  </span>
                  above to see the available data products
                </span>
              </div>
            )}
            {selectedAOIs.length > 0 && availableProductsOption.length != 0 ? (
              <div className="product-card-panel">
                <div className="product-card-section">
                  <div className="available-products-header">
                    <h1 className="card-section-header">Available Products</h1>
                    <div>
                      <img
                        src={info}
                        alt="info"
                        className="available-products-info"
                      />
                      <div className="available-prodcuts-tooltip">
                        <div className="top-triangle"></div>
                        <div className="available-prodcuts-tooltip-content">
                          <p>Available data products in selected region.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cards-section">
                    {availableProductsOption?.map((dataProduct, index) => (
                      <div
                        className={`cards-wrapper ${
                          selectedDataProducts.some(
                            (item) => item.value === dataProduct.value
                          )
                            ? 'selected'
                            : ''
                        }`}
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDataProductSelection(dataProduct);
                        }}
                      >
                        <div className="cards-header">
                          <label>{dataProduct.label}</label>
                          <label className="main">
                            <input
                              className="data_checkbox"
                              type="checkbox"
                              checked={selectedDataProducts.some(
                                (item) => item.value === dataProduct.value
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <span className="mark"></span>
                          </label>
                        </div>
                        <div className="product-desc">{dataProduct.desc}</div>
                        {/* <p className="read-more">Read More</p> */}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="product-card-section">
                  <h1 className="card-section-header">More Products</h1>
                  <div className="cards-section">
                    {moreProductsOption?.map((dataProduct, index) => (
                      <div
                        className={`cards-wrapper ${
                          selectedDataProducts.some(
                            (item) => item.value === dataProduct.value
                          )
                            ? 'selected'
                            : ''
                        }`}
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDataProductSelection(dataProduct);
                        }}
                      >
                        <div className="cards-header">
                          <label>{dataProduct.label}</label>
                          <label className="main">
                            <input
                              className="data_checkbox"
                              type="checkbox"
                              checked={selectedDataProducts.some(
                                (item) => item.value === dataProduct.value
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <span className="mark"></span>
                          </label>
                        </div>
                        <div className="product-desc">{dataProduct.desc}</div>
                        {/* <p className="read-more">Read More</p> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="product-card-panel">
                <div className="product-card-section">
                  <h1 className="card-section-header-all">All Products</h1>
                  <div className="cards-section">
                    {productsOption?.map((dataProduct, index) => (
                      <div
                        className={`cards-wrapper ${
                          selectedDataProducts.some(
                            (item) => item.value === dataProduct.value
                          )
                            ? 'selected'
                            : ''
                        }`}
                        // key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDataProductSelection(dataProduct);
                        }}
                      >
                        <div className="cards-header">
                          <label>{dataProduct.label}</label>
                          <label className="main">
                            <input
                              className="data_checkbox"
                              type="checkbox"
                              checked={selectedDataProducts.some(
                                (item) => item.value === dataProduct.value
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <span className="mark"></span>
                          </label>
                        </div>
                        <div className="product-desc">{dataProduct.desc}</div>
                        {/* <p className="read-more">Read More</p> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentRequest;
