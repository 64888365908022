import React from 'react';
import Select, { createFilter } from 'react-select';
import { useSelector } from 'react-redux';
import CustomMultiValue from '../customMultiSelectValue';
import closeIcon from '../../assets/images/icons/close.svg';
import { update } from 'react-spring';

const ProjectMembers = ({
  onCloseModal,
  modalType,
  projectAdminOption,
  projectAdmin,
  onChangeAdminHandler,
  projectMemberOption,
  projectMember,
  onChangeMemberHandler,
  onCreateProject,
  setSteps,
  onUpdateProject,
  canUpdateProject
}) => {
  const { requestData } = useSelector((state) => state.project);
  const customProps = {
    modalType: modalType
  };

  const components = {
    MultiValue: (props) => (
      <CustomMultiValue {...props} modalType={customProps.modalType} />
    )
  };
  return (
    <>
      <div className="modal-header">
        {modalType === 'update' ? (
          <p data-testid="project-member-heading">Project member details</p>
        ) : (
          <p data-testid="heading">Add Members</p>
        )}
        <img
          src={closeIcon}
          alt="close icon"
          onClick={() => {
            if (modalType !== update) window.location.reload();
            else onCloseModal();
          }}
        />
      </div>
      <div className="modal-body">
        <div
          className="project-name-sections"
          data-testid="project-admin-sections"
        >
          <label>
            Project admins<sup>*</sup>
          </label>
          <Select
            classNamePrefix={'project-select'}
            placeholder="Select admins"
            isMulti
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            options={projectAdminOption}
            defaultValue={modalType === 'update' && requestData?.project_admin}
            isClearable={false}
            isDisabled={modalType === 'update' ? !canUpdateProject : false}
            value={projectAdmin}
            filterOption={createFilter({
              matchFrom: 'start'
            })}
            onChange={(e, action) => onChangeAdminHandler(e, action)}
            components={components}
            openMenuOnClick={false}
          />
          {modalType === 'create-new' && (
            <div className="disclaimer">
              <p>You are a project owner by default.</p>
            </div>
          )}
        </div>
        <div
          className="project-name-sections"
          data-testid="project-member-sections"
        >
          <label>
            Project members<sup>*</sup>
          </label>
          <Select
            classNamePrefix={'project-select'}
            placeholder="Select members"
            isMulti
            menuPlacement="auto"
            isDisabled={modalType === 'update' ? !canUpdateProject : false}
            isClearable={false}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            options={projectMemberOption}
            filterOption={createFilter({
              matchFrom: 'start'
            })}
            value={projectMember}
            onChange={(e, action) => onChangeMemberHandler(e, action)}
            components={components}
            openMenuOnClick={false}
          />
        </div>

        <div className="disclaimer">
          <p>Disclaimer : The entire organisation can view this project.</p>
        </div>
      </div>
      <div className="create-step-btn">
        <div>
          <label className="steps">2/2</label>
        </div>
        <div className="create-step">
          <button
            className="btn btn-cancel-discard"
            onClick={() => setSteps(1)}
          >
            Back
          </button>
          {modalType == 'update' ? (
            <button
              className="create-btn"
              data-testid="close-btn"
              disabled={projectMember.length === 0}
              onClick={(e) => {
                if (canUpdateProject) onUpdateProject();
                else onCloseModal();
              }}
            >
              {canUpdateProject ? 'Edit Project' : 'Close'}
            </button>
          ) : (
            <button
              className="create-btn create-project-button"
              onClick={() => onCreateProject()}
            >
              Create Project
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectMembers;
