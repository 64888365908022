import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState, UserDetailsData } from '../../types/user.types';

const initialState: UserState = {
  emailVerifyLoader: false,
  emailVerify: {},
  usersDetailsData: null,
  authToken: {},
  loading: false,
  industryOptions: [],
  roleOptions: [],
  userPermissions: {},
  userData: null,
  error: null
};

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    verifyUserEmail(state, action: PayloadAction<any>) {
      state.emailVerify = action.payload.data;
      state.emailVerifyLoader = action.payload.loading;
    },
    doLogin(state, action: PayloadAction<any>) {
      const { role, accessToken, refreshToken, ...userDetails } =
        action.payload.data;

      state.usersDetailsData = userDetails as UserDetailsData;
      state.userPermissions = role;
      state.authToken = {
        accessToken,
        refreshToken
      };
      state.loading = action.payload.loading;
    },
    doRefreshLogin(state, action: PayloadAction<any>) {
      state.authToken = action.payload.data;
    },
    getIndustryOption(state, action: PayloadAction<any>) {
      state.industryOptions = action.payload.data.map((item: any) => ({
        label: item.name,
        value: item.id
      }));
    },
    getRoleOption(state, action: PayloadAction<any>) {
      state.roleOptions = action.payload.data.map((item: any) => ({
        label: item.name,
        value: item.id
      }));
    }
  }
});

export const authAction = authSlice.actions;
export default authSlice.reducer;
