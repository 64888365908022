import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

// SCSS
import styles from './index.module.scss';

// Images
import SpartaLogo from '../../assets/images/Sparta Logo.svg';
import ProfileIcon from '../../assets/images/header/User.svg';
import Logout from '../../assets/images/icons/logout.svg';
import ProfileActiveIcon from '../../assets/images/header/User_White.svg';
import Setting from '../../assets/images/header/settings.svg';
import close from '../../assets/images/cross.svg';

//helper function
import { onLogout } from '../../store/auth/auth-actions';
import { requestAction } from '../../store/requests/request-slice.js';
import { projectActions } from '../../store/projects/project-slice';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';

const discardModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '40rem',
    padding: '1rem',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '0.8rem'
  }
};

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const dropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  const { requestForm } = useSelector((state) => state?.request);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target)
      ) {
        closeNotificationDropdown();
      }
    };

    if (showNotificationDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotificationDropdown]);

  const toggleSettings = () => {
    if (
      requestForm.requestName ||
      requestForm.requestName === 'Untitled Request' ||
      requestForm?.skip
    ) {
      setOpenDiscardModal(true);
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(false);
      navigate('/settings');
    }
  };

  const closeNotificationDropdown = () => {
    setShowNotificationDropdown(false);
  };

  const handleLogout = async () => {
    dispatch(onLogout());
  };

  const handleProfileClick = () => {
    setIsDropdownOpen(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleLogoClick = () => {
    navigate('/d3');
    dispatch(projectActions.doClearProjectData());
    dispatch(mapCompareAction.clearMapStates());
  };

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.common_logo_sections}>
        <div
          className={styles.brand_logo}
          onClick={() => {
            handleLogoClick();
          }}
        >
          <img src={SpartaLogo} alt="sparta logo" />
        </div>
      </div>
      <ul
        className={`${styles.common_logo_sections} ${styles.profile_icon}`}
        data-testid="logo-sections"
      >
        <li className={styles.profile_list_item}>
          <label
            className={styles.profile_icons}
            onClick={() => {
              handleProfileClick();
            }}
          >
            <img
              src={
                location?.[1] === 'settings' ? ProfileActiveIcon : ProfileIcon
              }
              loading="lazy"
              alt="document"
            />
          </label>
          <div className={styles.profile_tool_tip}>
            <p>Profile</p>
          </div>
          <ul
            ref={dropdownRef}
            className={styles.profile_dropdown_menu}
            style={{ display: isDropdownOpen ? 'block' : 'none' }}
            data-testid="logout-dropdown"
          >
            <li
              className={styles.profile_list}
              onClick={() => {
                toggleSettings();
              }}
            >
              <label>
                <img src={Setting} alt="setting" />
              </label>
              <span>Settings</span>
            </li>
            <li className={styles.profile_list} onClick={() => handleLogout()}>
              <label>
                <img src={Logout} alt="logout" />
              </label>
              <span>Logout</span>
            </li>
          </ul>
        </li>
      </ul>
      <Modal isOpen={openDiscardModal} style={discardModalStyles}>
        <div className={styles.discard_header_modal}>
          <p>Discard Current Request</p>
          <span>
            <img
              src={close}
              alt="close"
              onClick={(e) => {
                setOpenDiscardModal(false);
                e.target.focus();
              }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setOpenDiscardModal(false);
                }
              }}
            />
          </span>
        </div>
        <div className={styles.discard_modal_content}>
          <p>
            Would you like to discard current request before creating new one?
          </p>
        </div>
        <div className={styles.discard_modal_footer}>
          <button
            className={`${styles.discard_btn} ${styles.btn_cancel_discard}`}
            onClick={(e) => setOpenDiscardModal(false)}
          >
            Cancel
          </button>
          <button
            className={`${styles.discard_btn} ${styles.btn_remove_discard}`}
            onClick={(e) => {
              navigate('/settings');
              dispatch(requestAction.doRequstFormUpdate({ data: {} }));
              setOpenDiscardModal(false);
            }}
          >
            Discard
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Navbar;
