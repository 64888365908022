import { store } from '../store';

export const checkUserPermisson = (params) => {
  const storeData = store.getState();
  const { userPermissions } = storeData?.user;

  let access =
    userPermissions?.permissions &&
    userPermissions?.permissions?.some(
      (item) => item.permission_key === params
    );

  return access ? true : false;
};

export const checkProjectLevelPermission = (params) => {
  const storeData = store.getState();

  const { requestData } = storeData?.project;

  let access =
    requestData?.role?.permissions &&
    requestData?.role?.permissions.some(
      (item) => item.permission_key === params
    );

  return access ? true : false;
};

export const checkProductLevelPermission = (object, params) => {
  let access =
    object?.role?.permissions &&
    object?.role?.permissions?.some((item) => item.permission_key === params);
  return access ? true : false;
};

export const checkAoiLevelPermission = (params, data) => {
  const storeData = store.getState();
  const { aoiData, productData, requestData } = storeData?.project;

  let access;
  if (!access) {
    access =
      data?.role?.permissions &&
      data?.role?.permissions.some((item) => item.permission_key === params);
  }
  if (!access) {
    access =
      aoiData?.role?.permissions &&
      aoiData?.role?.permissions.some((item) => item.permission_key === params);
  }
  if (!access) {
    access =
      productData?.role?.permissions &&
      productData?.role?.permissions.some(
        (item) => item.permission_key === params
      );
  }
  if (!access) {
    access =
      requestData?.role?.permissions &&
      requestData?.role?.permissions.some(
        (item) => item.permission_key === params
      );
  }
  return access ? true : false;
};
