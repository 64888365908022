import React from 'react';
import LoginComponent from '../../components/login';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_RECAPTCHA } from '../../utils/constants';

//Css for login component
import './login.css';

// Images
import spartaLogo from '../../assets/images/logo/sparta.svg';
import backgroundBanner from '../../assets/images/banner/landng_banner.png';

const Login = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA}>
      <div className="login-container">
        <div className="banner-sections">
          <div className="banner-img">
            <img alt="sparta Logo" src={backgroundBanner} />
          </div>
          <div className="sparta-logo-sections">
            <div className="sparta-logo">
              <img className="" alt="sparta Logo" src={spartaLogo} />
            </div>
          </div>
        </div>
        <LoginComponent />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
