import React from 'react';

//CSS
import './index.css';

//Images
import EmptyDraftImg from '../../assets/images/drafts/empty-draft.svg';

const EmptyDraft = () => {
  return (
    <div className="drafts-container">
      <div className="drafts-wrapper">
        <div>
          <img src={EmptyDraftImg} alt="draft-emty" />
        </div>
        <h2 className="">You don't have any saved drafts.</h2>
        <label className="empty-draft-sub-header">
          Saving a draft allows you to keep track of request before submission.
        </label>
      </div>
    </div>
  );
};

export default EmptyDraft;
