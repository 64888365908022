import React from 'react';
import Modal from 'react-modal';

import Close from '../../assets/images/icons/close.svg';

const DescriptionModal = ({
  descStyles,
  setDescribeModal,
  describeModal,
  descriptionContent
}) => {
  return (
    <Modal
      isOpen={describeModal}
      ariaHideApp={false}
      onRequestClose={() => {
        setDescribeModal(false);
      }}
      style={descStyles}
    >
      <div className="popup-wrapper">
        <div className="rejection-header">
          <h1>Description</h1>
          <label
            onClick={() => {
              setDescribeModal(false);
            }}
          >
            <img src={Close} alt="close icon" />
          </label>
        </div>
        <div className="description-content">
          <p>{descriptionContent}</p>
        </div>
      </div>
    </Modal>
  );
};

export default DescriptionModal;
