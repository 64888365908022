import React, { useState } from 'react';

// Images
import Close from '../../assets/images/icons/close.svg';
import Error from '../../assets/images/icons/error.svg';

// CSS
import './index.css';

const DuplicateCardModal = ({ closeModal }) => {
  return (
    <div className="save-draft-sections">
      <div className="save-draft-header">
        <label>Unsaved Changes</label>
        <span className="save-draft-close" onClick={() => closeModal(false)}>
          <img src={Close} alt="close icon" />
        </span>
      </div>
      <div className="save-draft-para">
        <img src={Error} alt="error" />
        <br />
        <p>
          You're attempting to request two identical data products. Each data
          product must have unique specifications.
        </p>
        <br />
        <p>
          For assistance, <span>Read more here</span>.
        </p>
      </div>
      <div className="save-draft-btn">
        <button
          className="btn draft duplicate-okay"
          onClick={() => closeModal(false)}
        >
          Okay
        </button>
      </div>
    </div>
  );
};
export default DuplicateCardModal;
