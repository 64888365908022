import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Helpers
import { getNameInitials } from '../../utils/function-helper';

// Redux
import { projectActions } from '../../store/projects/project-slice';
import { onGetRequestListData } from '../../store/projects/project-actions';

// Image
import { images } from '../../assets/images';
import { RootState } from '../../store';
import {
  ProjectAdmin,
  ProjectData,
  ProjectListProps
} from '../../types/project.types';

// Types

const ProjectList: React.FC<ProjectListProps> = ({ drawerHeight }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectData } = useSelector((state: RootState) => state.project);
  const [viewType, setViewType] = useState<'list-view' | 'tile-view'>(
    'list-view'
  );

  const handleProjectCardClick = (projectList: ProjectData) => {
    dispatch(onGetRequestListData(projectList.id) as any);
    dispatch(projectActions.doRequestStatus({ data: 'request' }));
    dispatch(projectActions.doProjectId({ data: projectList.id }));
    navigate('/d3/browse/project/' + projectList.id, { replace: true });
  };

  const joinElementsAfterFourthIndex = (array: ProjectAdmin[]) => {
    const emails = array.map((obj) => obj.email);
    return emails.slice(3).join(',');
  };

  const handleViewTypeChange = () => {
    setViewType('tile-view');
  };

  return (
    <>
      <div className="project-heading">
        <h1>Projects</h1>
        <div className="project-view-toggle-btn">
          <div className="request_header">
            <div
              className={`data request-btn ${viewType === 'tile-view' ? 'selected viewType' : 'viewType'}`}
              onClick={handleViewTypeChange}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleViewTypeChange();
                }
              }}
            >
              <img
                src={
                  viewType === 'tile-view' ? images.tileBgIcon : images.tileIcon
                }
                alt="Search"
              />
            </div>
            <div
              className={`region request-btn ${viewType === 'list-view' ? 'selected viewType' : 'viewType'}`}
              onClick={() => setViewType('list-view')}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setViewType('list-view');
                }
              }}
              data-testid="list-view-btn"
            >
              <img
                src={
                  viewType === 'list-view' ? images.listBgIcon : images.listIcon
                }
                alt="locations"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`projects-container${viewType === 'list-view' ? ' list-view-table' : ''}`}
        style={{ maxHeight: drawerHeight === 90 ? '76vh' : '41vh' }}
        data-testid="projects-container"
      >
        {projectData?.length !== 0 && viewType === 'tile-view' && (
          <>
            {projectData?.map((item: ProjectData, index: any) => (
              <div
                className="project-wrapper"
                onClick={() => handleProjectCardClick(item)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleProjectCardClick(item);
                  }
                }}
                key={item.id}
                data-testid={`project-wrapper-${index}`}
              >
                <h1 className="project-header">{item.name}</h1>
                <div className="project-requests">
                  <label>Requests</label>
                  <span>{item?.request_count}</span>
                </div>
              </div>
            ))}
          </>
        )}
        {projectData?.length !== 0 && viewType === 'list-view' && (
          <div style={{ width: '100%' }}>
            <table className="request-table-list project-list-header">
              <thead>
                <tr>
                  <th>PROJECT NAME</th>
                  <th>REQUESTS</th>
                  <th>PROJECT CREATED BY</th>
                  <th>PROJECT ADMIN</th>
                </tr>
              </thead>
            </table>
            <table className="request-table-list project-list">
              <tbody>
                {projectData?.map((item: ProjectData) => (
                  <tr
                    className="request-table-row"
                    key={item.id}
                    onClick={() => handleProjectCardClick(item)}
                    data-testid={`request-table-row-${item.id}`}
                  >
                    <td>
                      <span className="request-image">
                        <img src={images.request} alt="Request icon" />
                      </span>
                      <label>{item?.name}</label>
                    </td>
                    <td>0/{item?.request_count}</td>
                    <td>
                      <div className="created-by-col">
                        <div
                          className="full-name-avatar"
                          data-tooltip={item.project_created_by}
                        >
                          <p>
                            {getNameInitials(item?.project_created_by_name)}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="avatar-table-data">
                      <div className="project-admins">
                        {item?.project_admin
                          ?.slice(0, 4)
                          .map(
                            (
                              names: { email: string; name: string },
                              index: number
                            ) => (
                              <div
                                key={names.email}
                                data-tooltip={
                                  index === 3
                                    ? joinElementsAfterFourthIndex(
                                        item?.project_admin
                                      )
                                    : names.email
                                }
                                className={`full-name-avatar${index === 3 ? ' avatar-clubbed' : ''}`}
                                style={{
                                  right: `${index * 4}px`,
                                  zIndex: `${4 - index}`
                                }}
                              >
                                {index === 3 ? (
                                  <p>+{item?.project_admin?.length - 3}</p>
                                ) : (
                                  <p>{getNameInitials(names.name)}</p>
                                )}
                              </div>
                            )
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectList;
