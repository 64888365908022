import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components, createFilter } from 'react-select';
import moment from 'moment';
import { toast } from 'react-toastify';

import ProgressBar from '../progressbar';

import { projectActions } from '../../store/projects/project-slice';
import {
  onGetAoiListData,
  onGetProductListData
} from '../../store/projects/project-actions';

// Image
import Profile from '../../assets/images/icons/profile-button.svg';

// CSS
import 'pure-react-carousel/dist/react-carousel.es.css';
import './index.css';

//SERVICES
import { getNumberOfFiles } from '../../services/products-service';
import {
  createProductAdmin,
  getResourceUsers
} from '../../services/project-tree-services';

//HELPERS
import { getProgressColor, getStatusIcon } from '../../utils/user-helper';
import { getNameInitials } from '../../utils/function-helper';
import {
  checkProjectLevelPermission,
  checkProductLevelPermission
} from '../../utils/permission-helper';

const RequestProductCard = ({ requestProductdata }) => {
  const { requestData } = useSelector((state) => state.project);
  const selectRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [numberOfFiles, setNumberOfFiles] = useState('');
  const [dataProductAdminClicked, setDataProductAdminClicked] = useState(false);
  const [dataProductAdminOptions, setDataProductAdminOptions] = useState([]);
  const { productData } = useSelector((state) => state.project);
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div className="project-member-option-div">
        {props.data.value === '' ? (
          <img
            src={Profile}
            alt="profile"
            style={{ marginRight: '0.6rem', width: '16%' }}
          />
        ) : (
          <div className="full-name-avatar project-member-avatar">
            <p>{getNameInitials(props.data.label)}</p>
          </div>
        )}

        <div>
          <p className="project-member-option-label">{props.data.label}</p>
          <p className="project-member-option-email">{props.data.email}</p>
        </div>
      </div>
    </Option>
  );
  const fetchNumberOfFiles = async () => {
    let geometryList = [];
    if (productData?.request_aoi_data && requestProductdata?.sop) {
      productData?.request_aoi_data?.map((feature) =>
        geometryList.push(feature.geometry)
      );
      let filesExpectedPayload = {
        satellite:
          requestProductdata?.sop?.input_data[0]?.input_data[0]?.input_data[0]?.selected_value[0].value.split(
            ' '
          )[0],
        processing_level:
          requestProductdata?.sop?.input_data[0]?.input_data[0]?.input_data[0]?.selected_value[0].value.split(
            ' '
          )[1],
        cloud_perc:
          '0,' +
          requestProductdata?.sop?.input_data[0]?.input_data[2]?.input_data[0]
            ?.selected_value[0],
        aoi: geometryList,
        from_date: requestProductdata?.from_date,
        to_date: requestProductdata?.to_date
      };
      const { data, status } = await getNumberOfFiles(filesExpectedPayload);

      if (status === 200) {
        setNumberOfFiles(data.count);
      }
    }
  };

  useEffect(() => {
    fetchNumberOfFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        event.target.className !== 'full-name-avatar project-member-avatar' &&
        !event.target.closest('.full-name-avatar')
      ) {
        setDataProductAdminClicked(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef]);

  const fetchAllUsers = async () => {
    const { data, status } = await getResourceUsers(
      'project',
      requestData.id,
      ''
    );

    if (status === 200) {
      let userArray = [];
      if (requestProductdata?.product_admin?.length !== 0) {
        userArray.push({
          value: '',
          label: 'Unassigned',
          email: '',
          role_id: 2
        });
      }

      data?.data?.map((user) => {
        if (user.email !== requestProductdata?.product_admin?.email) {
          userArray.push({
            value: user.id,
            label: user.name,
            email: user.email,
            role_id: 2
          });
        }
        return null;
      });
      setDataProductAdminOptions(userArray);
      setDataProductAdminClicked(!dataProductAdminClicked);
    }
  };

  const handleDataProductAdmin = async (value) => {
    const payload = {
      request_product_id: requestProductdata.id,
      user_id: value
    };

    const { status } = await createProductAdmin(payload);
    if (status === 200) {
      dispatch(onGetProductListData(productData.id));
    }
  };

  const handleProductRequest = (product) => {
    if (!dataProductAdminClicked) {
      dispatch(projectActions.doRequestStatus({ data: 'aoi' }));
      dispatch(onGetAoiListData(product.id));
      dispatch(projectActions.doProductId({ data: product.id }));
      navigate(`/d3/browse/product/` + product.id, { replace: true });
    }
  };

  return (
    <li className="request-card">
      <div
        className="request-card-header"
        onClick={() => handleProductRequest(requestProductdata)}
      >
        <h2 data-testid="request-card-header">{requestProductdata.name}</h2>
        <div>
          {requestProductdata &&
          requestProductdata?.product_admin &&
          requestProductdata?.product_admin?.length === 0 ? (
            <img
              src={Profile}
              alt=""
              title="Unassigned"
              onClick={(e) => {
                e.stopPropagation();
                if (checkProjectLevelPermission('product_admin.create')) {
                  fetchAllUsers();
                } else {
                  toast.error('Permission denied');
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.stopPropagation();
                  if (checkProjectLevelPermission('product_admin.create')) {
                    fetchAllUsers();
                  } else {
                    toast.error('Permission denied');
                  }
                }
              }}
              tabIndex="0"
            />
          ) : (
            <div
              className="full-name-avatar"
              data-tooltip={
                requestProductdata?.product_admin &&
                requestProductdata?.product_admin.length
                  ? requestProductdata?.product_admin[0]?.email
                  : ''
              }
              onClick={(e) => {
                e.stopPropagation();
                if (
                  checkProjectLevelPermission('product_admin.update') ||
                  checkProductLevelPermission(
                    requestProductdata,
                    'product_admin.update'
                  )
                ) {
                  fetchAllUsers();
                } else {
                  toast.error('Permission denied');
                }
              }}
            >
              <p>
                {getNameInitials(
                  requestProductdata?.product_admin &&
                    requestProductdata?.product_admin.length
                    ? requestProductdata?.product_admin[0]?.name
                    : ''
                )}
              </p>
            </div>
          )}

          {dataProductAdminClicked && (
            <Select
              autoFocus
              id="request-admin-dropdown"
              ref={selectRef}
              placeholder={
                <div className="project-member-placeholder">
                  <p>Add assignee</p>
                </div>
              }
              menuIsOpen={true}
              classNamePrefix={'data-product-admins'}
              components={{ Option: IconOption }}
              options={dataProductAdminOptions}
              filterOption={createFilter({ matchFrom: 'start' })}
              onChange={(e) => {
                handleDataProductAdmin(e.value);
                setDataProductAdminClicked(false);
              }}
            />
          )}
        </div>
      </div>
      <div className="request-status">
        <label className="progress-bar-wrapper">
          <ProgressBar
            progress={requestProductdata?.status_in_percentage}
            color={getProgressColor(requestProductdata?.status_in_percentage)}
          />
        </label>
        <span>
          {getStatusIcon(requestProductdata?.status_in_percentage)?.title}
        </span>
      </div>
      <ul className="request-card-body">
        <div className="request-card-container">
          <li className="request-list">
            <label className="list-title">Details</label>
            <span
              className="card-list-value list-details"
              data-testid="request-details"
              onClick={() => handleProductRequest(requestProductdata)}
            >
              View Workflow
            </span>
          </li>
          <li className="request-list">
            <label className="list-title">Region</label>
            <span className="card-list-value" data-testid="request-region-1">
              {requestProductdata?.aoi_data?.join(', ')}
            </span>
          </li>
          <li className="date-rang-list">
            <label className="list-title">Date Range</label>
            <span className="card-list-value" data-testid="request-date-range">
              {moment(requestProductdata?.from_date).format('DD/MM/YYYY')} -{' '}
              {moment(requestProductdata?.to_date).format('DD/MM/YYYY')}
            </span>
          </li>
          <li className="file-list">
            <p>No. of files expected :{numberOfFiles}</p>
          </li>
          <li className="request-list">
            <label className="list-title">Format</label>
            <span className="card-list-value" data-testid="request-format">
              {requestProductdata?.data_delivery_format}
            </span>
          </li>
        </div>
      </ul>
    </li>
  );
};
export default RequestProductCard;
