import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Redux
import { projectActions } from '../../store/projects/project-slice';
import { onGetAoiManualDateLayerData } from '../../store/projects/project-actions';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';

// Image
import { images } from '../../assets/images/index.js';

// SCSS
import './index.css';
import { getDateStatusIcon } from '../../utils/user-helper';

const ProjectAoiDetail = ({ numberOfFiles }) => {
  const mountRef = useRef(false);
  const dispatch = useDispatch();
  const [isSopExpanded, setIsSopExpanded] = useState(true);
  const [isTrigger, setTrigger] = useState(false);
  const { productData, aoiData, aoiDatesData, currentOpenLevel } = useSelector(
    (state) => state.project
  );
  const { singleMapLayer } = useSelector((state) => state.mapCompare);

  useEffect(() => {
    if (mountRef.current) {
      if (
        singleMapLayer &&
        singleMapLayer?.layerData &&
        singleMapLayer?.layerData.length !== 0 &&
        isTrigger
      ) {
        onHandleDownload(singleMapLayer?.layerData);
      }
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleMapLayer]);

  const handleDateCheckClickList = (aoiDate, generationType) => {
    dispatch(projectActions.doAoiDatesId({ data: aoiDate.id }));
    if (generationType === 'manual') {
      dispatch(mapCompareAction.doManualDataProcessingDate({ data: aoiDate }));
      dispatch(onGetAoiManualDateLayerData(aoiDate));
    } else if (generationType === 'automated') {
      dispatch(mapCompareAction.doSetActiveDates({ data: [aoiDate.id] }));
      dispatch(
        mapCompareAction.doSetSingleMapLayer({
          data: aoiDate,
          id: aoiDate.id
        })
      );
    }
  };

  const onAoiDownload = (data, type) => {
    if (type === 'manual') {
      if (
        singleMapLayer &&
        singleMapLayer.layerData &&
        singleMapLayer.layerData.length !== 0
      ) {
        onHandleDownload(singleMapLayer.layerData);
      } else {
        dispatch(projectActions.doAoiDatesId({ data: data.id }));
        dispatch(onGetAoiManualDateLayerData(data));
        setTrigger(true);
      }
    } else {
      onHandleDownload(data);
    }
  };

  const onHandleDownload = (data) => {
    let url = data?.links[1];
    if (url.href) {
      const link = document.createElement('a');
      link.setAttribute('download', 'file');
      link.setAttribute('target', '_blank');
      link.href = url.href;
      link.click();
    }
    setTrigger(false);
  };

  return (
    <>
      <div
        className="region-expand-collapse"
        onClick={() => setIsSopExpanded(!isSopExpanded)}
        onKeyDown={() => {
          setIsSopExpanded(!isSopExpanded);
        }}
        tabIndex={0}
      >
        <div className="expand-arrow">
          <img
            src={images.expandArrow}
            alt="arrow"
            style={{
              transform: isSopExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'
            }}
          />
        </div>
        <h2 className="work-flow-header">AOI Dates</h2>
      </div>
      {isSopExpanded && (
        <div className="project-container">
          <div className="left-div">
            <div className="project-left-header">
              <h3>Dates</h3>
            </div>
            <div className="date-section">
              <div className="scrollable-dates">
                {aoiDatesData?.map((item, index) => (
                  <div
                    className={
                      currentOpenLevel === item.id
                        ? 'region-dates-container active'
                        : 'region-dates-container'
                    }
                    onClick={() => {
                      handleDateCheckClickList(item, aoiData?.generation_type);
                    }}
                  >
                    <div className="region-dates-wrapper">
                      {aoiData?.generation_type === 'manual' ? (
                        <span>
                          <img
                            src={getDateStatusIcon(item?.status)?.img}
                            alt=""
                          />
                        </span>
                      ) : (
                        <span>
                          {
                            <span>
                              <img src={getDateStatusIcon('3')?.img} alt="" />
                            </span>
                          }
                        </span>
                      )}

                      <div key={index} className="aoi-item">
                        {aoiData?.generation_type === 'manual' ? (
                          <>{moment(item?.date).format('DDMMMYY')}</>
                        ) : (
                          aoiData?.generation_type === 'automated' && (
                            <>
                              {moment(item?.properties?.datetime).format(
                                'DDMMMYY'
                              )}
                            </>
                          )
                        )}
                      </div>
                    </div>
                    <span
                      className="download-wrapper"
                      onClick={(e) => {
                        e.stopPropagation();
                        onAoiDownload(item, aoiData?.generation_type);
                      }}
                    >
                      <img src={images.downloadActive} alt="download"></img>
                    </span>
                  </div>
                ))}
                {/* )} */}
              </div>
            </div>
          </div>
          <div className="right-div">
            <ul className="tree-aoi-details">
              <li>
                <label className="tree-aoi-head">Requested By</label>
                <span className="tree-aoi-head-value">
                  {productData?.requested_by_name}
                </span>
              </li>
              <li>
                <label className="tree-aoi-head">Request Created On</label>
                <span className="tree-aoi-head-value">
                  {moment(productData?.created_on).format('DD/MM/YYYY')}
                </span>
              </li>
              <li>
                <label className="tree-aoi-head">Date Range</label>
                <div>
                  <span className="tree-aoi-head-value">
                    {moment(aoiData?.from_date).format('DD/MM/YYYY')} -
                    {moment(aoiData?.to_date).format('DD/MM/YYYY')}
                  </span>
                  <h2 className="tree-aoi-head-value">
                    No. of files expected : {numberOfFiles}
                  </h2>
                </div>
              </li>
              <li>
                <label className="tree-aoi-head">Format</label>

                <span className="tree-aoi-head-value">
                  {aoiData?.data_delivery_format || '-'}
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectAoiDetail;
