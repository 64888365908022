import React, { useState } from 'react';
import Modal from 'react-modal';

import './index.css';

import sopArrow from '../../assets/images/sop-arrow.svg';
import Info from '../../assets/images/icons/info-tooltip.svg';
import infoBlack from '../../assets/images/icons/Info-tooltip-black.svg';
import Close from '../../assets/images/icons/close.svg';

import { getInputFields } from '../../utils/sop-helper';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '46.4rem',
    height: '28.7rem',
    padding: '0'
  }
};

const SOP = ({ sopSelected, setUpdatedSop }) => {
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [dataProcessingModalOpen, setDataProcessingModalOpen] = useState(false);

  const [maskingOption, setMaskingOption] = useState([]);

  const [formData, setFormData] = useState(sopSelected.sop_data);

  const openDataProcessingModal = () => {
    setDataProcessingModalOpen(true);
  };

  const closeModal = () => {
    setDataProcessingModalOpen(false);
  };

  const handledataaccess = (dataaccess) => {
    if (dataaccess?.name === 'Download') {
      setDownloadModalOpen(true);
    }
  };

  const copyresponse = () => {};

  const onChangeHandle = (e, firstIndex, secondIndex, thirdIndex) => {
    const obj = [...JSON.parse(JSON.stringify(formData))];

    obj[firstIndex]?.input_data[secondIndex]?.input_data[thirdIndex][
      'selected_value'
    ].splice(0, 1, e);
    setFormData([...obj]);
    setUpdatedSop([...obj]);
  };

  return (
    <>
      <div className="sop-cards-container">
        <div className="sop-cards-wrapper">
          {sopSelected &&
            sopSelected.sop &&
            sopSelected.sop.input_data?.map((item, firstIndex) => {
              return (
                <>
                  <div className="sop-cards">
                    <div className="sop-card-header">
                      {item.name}
                      {item.name === 'Data Selection' && (
                        <div
                          onClick={openDataProcessingModal}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              openDataProcessingModal();
                            }
                          }}
                          tabIndex="0"
                          className="img-tooltip"
                        >
                          <img src={Info} alt="info" />
                        </div>
                      )}
                      {item.name === 'Data Processing' && (
                        <div
                          onClick={openDataProcessingModal}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              openDataProcessingModal();
                            }
                          }}
                          tabIndex="0"
                          className="img-tooltip"
                        >
                          <img src={Info} alt="info" />
                        </div>
                      )}
                    </div>
                    <div className="sop-card-body">
                      <div className="card-wrapper">
                        <ul className="sop-card-list">
                          {item.name === 'QA/QC' ? (
                            <p className="list-item-heading">
                              This data product is passed through following
                              quality checks.
                            </p>
                          ) : null}
                          {item.input_data.map((inputs, secondIndex) => {
                            return (
                              <>
                                {item.name !== 'Data Access' ? (
                                  <>
                                    {inputs.name !== 'Upload Sowing Output' && (
                                      <li className="list-item">
                                        <div
                                          className={
                                            item.name === 'QA/QC'
                                              ? 'left-list-item'
                                              : 'list-item-left'
                                          }
                                        >
                                          {inputs?.name === 'Download Images' &&
                                          inputs?.input_data[0].type ===
                                            'download_link' ? (
                                            <div
                                              style={{
                                                whiteSpace: 'nowrap',
                                                cursor: 'no-drop',
                                                fontSize: '14px',
                                                color: 'grey',
                                                fontWeight: 500
                                              }}
                                            >
                                              {inputs?.name}
                                            </div>
                                          ) : (
                                            <p>{inputs?.name}</p>
                                          )}
                                        </div>
                                        <div
                                          className={
                                            item.name === 'QA/QC'
                                              ? 'right-list-item'
                                              : 'list-item-right'
                                          }
                                        >
                                          {inputs.input_data
                                            ? inputs?.input_data.map(
                                                (fields, thirdIndex) => {
                                                  let disabled =
                                                    !fields?.is_editable;
                                                  let flow = 'request';
                                                  let parentAoiId = null;
                                                  let setFiles = [];
                                                  let setReCallQuantity = false;
                                                  return (
                                                    <>
                                                      {fields.type !==
                                                      'Boolean' ? (
                                                        <label
                                                          className={`${
                                                            fields.type ===
                                                            'slider'
                                                              ? 'slider-item'
                                                              : 'sop-selection-feild'
                                                          }`}
                                                        >
                                                          {getInputFields(
                                                            flow,
                                                            fields.type,
                                                            fields.options,
                                                            formData,
                                                            onChangeHandle,
                                                            firstIndex,
                                                            secondIndex,
                                                            thirdIndex,
                                                            disabled,
                                                            parentAoiId,
                                                            setFiles,
                                                            setReCallQuantity,
                                                            maskingOption,
                                                            setMaskingOption
                                                          )}
                                                        </label>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )
                                            : '-'}
                                        </div>
                                      </li>
                                    )}
                                  </>
                                ) : (
                                  <li className="data-access">
                                    <button
                                      disabled={true}
                                      className="data-access-list"
                                      onClick={() => {
                                        handledataaccess(inputs);
                                      }}
                                    >
                                      <p className="disabled-details">
                                        Wait for request completion to enable.
                                      </p>
                                      <label
                                        className={
                                          inputs?.name === 'Download' ||
                                          inputs?.name ===
                                            'Download Extraction' ||
                                          inputs?.name === 'Preview'
                                            ? 'full-flex-basic'
                                            : ''
                                        }
                                      >
                                        {inputs?.name}
                                      </label>
                                      {inputs?.name === 'Request API' && (
                                        <span
                                          onClick={() => {
                                            copyresponse();
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <path
                                              d="M6.0013 2.15894C3.88421 2.15894 2.16797 3.87518 2.16797 5.99227V10.7302C2.16797 11.0063 2.39183 11.2302 2.66797 11.2302C2.94411 11.2302 3.16797 11.0063 3.16797 10.7302V5.99227C3.16797 4.42746 4.4365 3.15894 6.0013 3.15894H10.6765C10.9527 3.15894 11.1765 2.93508 11.1765 2.65894C11.1765 2.38279 10.9527 2.15894 10.6765 2.15894H6.0013Z"
                                              fill="#3DB267"
                                            />
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M12.2697 4.52111C10.109 4.27963 7.89359 4.27963 5.73295 4.52111C5.11744 4.5899 4.62281 5.0743 4.55026 5.69459C4.29401 7.88557 4.29401 10.099 4.55026 12.2899C4.62281 12.9102 5.11744 13.3946 5.73295 13.4634C7.89359 13.7049 10.109 13.7049 12.2697 13.4634C12.8852 13.3946 13.3798 12.9102 13.4524 12.2899C13.7086 10.099 13.7086 7.88557 13.4524 5.69459C13.3798 5.0743 12.8852 4.5899 12.2697 4.52111ZM5.84403 5.51492C7.93084 5.28169 10.0718 5.28169 12.1586 5.51492C12.3168 5.53261 12.4412 5.65774 12.4591 5.81075C12.7064 7.92456 12.7064 10.06 12.4591 12.1738C12.4412 12.3268 12.3168 12.4519 12.1586 12.4696C10.0718 12.7028 7.93084 12.7028 5.84403 12.4696C5.68579 12.4519 5.56139 12.3268 5.54349 12.1738C5.29627 10.06 5.29627 7.92456 5.54349 5.81075C5.56139 5.65774 5.68579 5.53261 5.84403 5.51492Z"
                                              fill="#3DB267"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                      {inputs?.name === 'Response API' && (
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <path
                                              d="M6.0013 2.15894C3.88421 2.15894 2.16797 3.87518 2.16797 5.99227V10.7302C2.16797 11.0063 2.39183 11.2302 2.66797 11.2302C2.94411 11.2302 3.16797 11.0063 3.16797 10.7302V5.99227C3.16797 4.42746 4.4365 3.15894 6.0013 3.15894H10.6765C10.9527 3.15894 11.1765 2.93508 11.1765 2.65894C11.1765 2.38279 10.9527 2.15894 10.6765 2.15894H6.0013Z"
                                              fill="#3DB267"
                                            />
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M12.2697 4.52111C10.109 4.27963 7.89359 4.27963 5.73295 4.52111C5.11744 4.5899 4.62281 5.0743 4.55026 5.69459C4.29401 7.88557 4.29401 10.099 4.55026 12.2899C4.62281 12.9102 5.11744 13.3946 5.73295 13.4634C7.89359 13.7049 10.109 13.7049 12.2697 13.4634C12.8852 13.3946 13.3798 12.9102 13.4524 12.2899C13.7086 10.099 13.7086 7.88557 13.4524 5.69459C13.3798 5.0743 12.8852 4.5899 12.2697 4.52111ZM5.84403 5.51492C7.93084 5.28169 10.0718 5.28169 12.1586 5.51492C12.3168 5.53261 12.4412 5.65774 12.4591 5.81075C12.7064 7.92456 12.7064 10.06 12.4591 12.1738C12.4412 12.3268 12.3168 12.4519 12.1586 12.4696C10.0718 12.7028 7.93084 12.7028 5.84403 12.4696C5.68579 12.4519 5.56139 12.3268 5.54349 12.1738C5.29627 10.06 5.29627 7.92456 5.54349 5.81075C5.56139 5.65774 5.68579 5.53261 5.84403 5.51492Z"
                                              fill="#3DB267"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                    </button>
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {firstIndex !== sopSelected.sop.input_data.length - 1 && (
                    <div className="next-process">
                      <div className="sop-image-wrapper">
                        <img src={sopArrow} alt="right-arrow" />
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
      <Modal
        isOpen={dataProcessingModalOpen}
        style={modalStyles}
        onRequestClose={closeModal}
      >
        <div className="details-modal-header">
          <span className="details-info">
            <img src={infoBlack} alt="infoBlack" />
          </span>
          <h1>Data Selection Details</h1>
          <div></div>
          <span
            className="details-info-close"
            onClick={closeModal}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                closeModal();
              }
            }}
            tabIndex="0"
          >
            <img src={Close} alt="Close" />
          </span>
        </div>
        <div className="details-modal-content">
          <div className="details-modal-left">
            <ul>
              <li>Satellite</li>
              <li>Spectral Bands</li>
              <li>Maximum Cloud %</li>
              <li>File estimation</li>
            </ul>
          </div>

          <div className="details-modal-right">
            <ul>
              <li>Brief introduction about satellite being used.</li>
              <li>Brief introduction about spectral bands being used.</li>
              <li>Significance of cloud percentage</li>
              <li>How expected files are being calculated.</li>
            </ul>
          </div>
        </div>
        <div className="learn-btn-div">
          <button className="learn-btn">Learn more</button>
        </div>
      </Modal>
      <Modal
        isOpen={downloadModalOpen}
        style={modalStyles}
        onRequestClose={() => setDownloadModalOpen(false)}
      >
        <div className="download-modal-header">
          <p>Download</p>
        </div>
        <div className="download-modal-content">
          <p className="aoi-file-name">Aoi file</p>
          <div>
            <input type="radio" value="zip" name="zip" />
            <p className="radio-text">Zip File</p>
          </div>
          <div>
            <input type="radio" value="python" name="python" />
            <p className="radio-text">Python Script</p>
          </div>
        </div>
        <div className="download-modal-footer">
          <button className="cncl" onClick={() => setDownloadModalOpen(false)}>
            Cancel
          </button>
          <button className="download-btn" onClick={() => {}}>
            Download
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SOP;
