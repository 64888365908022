import { getRequest, postRequest } from '../utils/http-helper';
import {
  CREATE_BULK_AOI_QAQC_ADMINS,
  CREATE_QA_QC_ADMIN,
  DOWNLOAD_AOI_URL,
  UPDATE_QA_QC,
  ACTION_DROPDOWN,
  UPDATE_REQUEST_ADMIN,
  UPDATE_PROJECT_USER,
  GET_RESOURCES_USERS,
  CREATE_DATA_PRODCUT_ADMIN,
  PROJECT_TREE_URL,
  PROJECT_TREE_DATASETS_URL,
  GET_BREADCRUMB
} from '../utils/url-helper';

export const getProjectTreeList = async () => {
  try {
    return await getRequest({
      url: PROJECT_TREE_URL,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getRequestTreeList = async (id) => {
  try {
    return await getRequest({
      url: `${PROJECT_TREE_URL}?project_id=${id}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getProductTreeList = async (id) => {
  try {
    return await getRequest({
      url: `${PROJECT_TREE_URL}?request_id=${id}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getAoiTreeList = async (id) => {
  try {
    return await getRequest({
      url: `${PROJECT_TREE_URL}?product_mapping_id=${id}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getAoiAutomatedTreeList = async (id) => {
  try {
    return await getRequest({
      url: `${PROJECT_TREE_DATASETS_URL}${id}/d3-datasets`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getAoiManualTreeList = async (id) => {
  try {
    return await getRequest({
      url: `${PROJECT_TREE_DATASETS_URL}get-aoi-dates?request_aoi_id=${id}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getAoiManualDateLayer = async (aoiMappingId, date) => {
  try {
    return await getRequest({
      url: `${PROJECT_TREE_DATASETS_URL}${aoiMappingId}/d3-datasets?date=${date}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getBreadcrumbList = async (resourceId, resourceType) => {
  try {
    return await getRequest({
      url: `${GET_BREADCRUMB}?resource_id=${resourceId}&resource_type=${resourceType}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getProjectAoiDownload = async (id) => {
  try {
    let URL = `${DOWNLOAD_AOI_URL}/${id}`;
    return await getRequest({
      url: URL,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const updateQaQc = async (payload, requestId) => {
  try {
    return await postRequest({
      url: UPDATE_QA_QC + '/' + requestId,
      data: payload,
      Auth: true,
      headers: true,
      isToken: false
    });
  } catch (e) {
    return e;
  }
};

export const updateAction = async (payload, requestId) => {
  try {
    return await postRequest({
      url: ACTION_DROPDOWN + '/' + requestId + '/d3-update-request-status',
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const updateRequestDelivery = async (payload, requestId) => {
  try {
    return await postRequest({
      url: ACTION_DROPDOWN + '/' + requestId + '/update-request-due-date',
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const updateRequestAdmin = async (payload) => {
  try {
    return await postRequest({
      url: UPDATE_REQUEST_ADMIN,
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const updateProjectUser = async (payload) => {
  try {
    return await postRequest({
      url: UPDATE_PROJECT_USER,
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getSatelliteFiles = async (requestId) => {
  try {
    return await getRequest({
      url: ACTION_DROPDOWN + '/' + requestId + '/download-satellite-images',
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getResourceUsers = async (scope_type, scope_id, scope_level) => {
  let getResourceURL;
  if (scope_level === '') {
    getResourceURL = `${GET_RESOURCES_USERS}?scope_type=${scope_type}&scope_id=${scope_id}`;
  } else {
    getResourceURL = `${GET_RESOURCES_USERS}?scope_type=${scope_type}&scope_id=${scope_id}&scope_level=${scope_level}`;
  }
  try {
    return await getRequest({
      url: getResourceURL,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const createProductAdmin = async (payload) => {
  try {
    return await postRequest({
      url: CREATE_DATA_PRODCUT_ADMIN,
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const updateAoiAdmin = async (payload) => {
  try {
    return await postRequest({
      url: CREATE_QA_QC_ADMIN,
      Auth: true,
      headers: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const bulkUploadAoiQaQcAdmin = async (payload) => {
  try {
    return await postRequest({
      url: CREATE_BULK_AOI_QAQC_ADMINS,
      Auth: true,
      headers: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};
