/**
 * Represents data related to an Area of Interest (AOI).
 */
export class AoiData {
  /**
   * Constructs an instance of AoiData.
   * @param {object} aoiData - The data object containing AOI data.
   */
  constructor(aoiData) {
    this.assignAoiData(aoiData);
  }

  /**
   * Assigns AOI data to the instance properties.
   * @param {object} aoiData - The data object containing AOI data.
   */
  assignAoiData({
    aoi_admin,
    aoi_id,
    aoi_name,
    geometry,
    id,
    product_mapping_id,
    qaqc_admin,
    role,
    sop,
    status
  }) {
    this.aoi_admin = aoi_admin;
    this.aoi_id = aoi_id;
    this.name = aoi_name;
    this.geometry = geometry;
    this.id = id;
    this.product_mapping_id = product_mapping_id;
    this.qaqc_admin = qaqc_admin;
    this.role = role;
    this.sop = sop;
    this.status = status;
  }

  /**
   * Retrieves AOI data information.
   * @returns {object} - An object containing AOI data information.
   */
  getAoiDataInfo() {
    return {
      aoi_admin: this.aoi_admin,
      aoi_id: this.aoi_id,
      name: this.aoi_name,
      geometry: this.geometry,
      id: this.id,
      product_mapping_id: this.product_mapping_id,
      qaqc_admin: this.qaqc_admin,
      role: this.role,
      sop: this.sop,
      status: this.status
    };
  }
}
