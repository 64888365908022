import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Spring, config, animated } from 'react-spring';

import Edit from '../../assets/images/edit.svg';
import ImageDown from '../../assets/images/icons/down-arrow.svg';

//CSS
import './index.css';
import { getDraftEditAction } from '../../store/drafts/drafts-actions';

const styles = {
  contents: {
    willChange: 'transform, opacity, height'
  }
};

const DraftsTreeView = (props) => {
  const dispatch = useDispatch;
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(props.open);
  const [isEdit, setEdit] = useState(false);
  const [treeTitle, setTreeTitle] = useState(props.title);
  // const [visible, setVisible] = useState(props.visible);
  // const [immediate, setImmediate] = useState(false);

  const { children, isActive, isRequest, status, title, onToggle, progress } =
    props;

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setEdit(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleSubmit = (e) => {
    if (e.key === 'Enter') {
      setEdit(false);
    }
  };

  const onHandleChange = (e) => {
    setTreeTitle(e.target.value);
    dispatch(getDraftEditAction({ name: e.target.value }));
  };

  return (
    <li className="treeview-sections">
      <div
        className={`tree-wrapper`}
        onClick={() => {
          toggle();
          onToggle && onToggle();
        }}
      >
        <label
          className={`vertical-line ${
            isRequest === 'project'
              ? 'project-expand'
              : isRequest === 'request'
                ? 'request-expand'
                : ''
          }`}
        ></label>
        <label
          className={`horizontal-line ${
            isRequest === 'project'
              ? 'project-expand'
              : isRequest === 'request'
                ? 'request-expand'
                : ''
          }`}
        ></label>

        {isRequest !== 'request' ? (
          <div className="arrow-icon">
            <img
              src={ImageDown}
              style={{
                transform: open ? 'rotate(0deg)' : 'rotate(-90deg)'
              }}
              alt="down-arrow"
            />
          </div>
        ) : null}

        <div
          className={
            isActive
              ? 'tree-container draft-tree active'
              : `tree-container draft-tree`
          }
        >
          <div className="tree-title-content ">
            <span className="tree-title" title={treeTitle} ref={wrapperRef}>
              {isEdit ? (
                <input
                  type="text"
                  onKeyDown={(e) => onHandleSubmit(e)}
                  onChange={(e) => onHandleChange(e)}
                ></input>
              ) : (
                treeTitle
              )}
            </span>
            {isRequest === 'request' && !isEdit && (
              <span className="tree-title tree-dates">12/03/2023</span>
            )}
          </div>
          {isRequest === 'request' && (
            <div className="tree-edit-button" onClick={() => setEdit(true)}>
              <img src={Edit} alt="edit" />
            </div>
          )}
        </div>
      </div>

      {open && (
        <Spring
          native
          config={{ ...config.default, precision: 0.1 }}
          from={{ height: 0, opacity: 0, transform: 'translate3d(2rem,0,0)' }}
          to={{
            height: open ? 'auto' : 0,
            opacity: open ? 1 : 0,
            transform: open ? 'translate3d(0rem,0,0)' : 'translate3d(2rem,0,0)'
          }}
        >
          {(style) => (
            <div
              className={`child-tree ${
                isRequest === 'project'
                  ? 'project-child-expand'
                  : isRequest === 'request'
                    ? 'request-child-expand'
                    : ''
              }`}
            >
              <animated.ul
                className="project-tree-list"
                style={{ ...style, ...styles.contents }}
              >
                {children}
              </animated.ul>
            </div>
          )}
        </Spring>
      )}
    </li>
  );
};

DraftsTreeView.defaultProps = {
  open: false,
  visible: true
};

export default DraftsTreeView;
