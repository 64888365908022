import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { toast } from 'react-toastify';
import moment from 'moment';

//helper
import { checkAoiLevelPermission } from '../../utils/permission-helper';

// Services
import { updateAoiAdmin } from '../../services/project-tree-services';

// Redux
import { requestAction } from '../../store/requests/request-slice';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';
import { projectActions } from '../../store/projects/project-slice';
import {
  onGetAoiAutomatedDateListData,
  onGetAoiListData,
  onGetAoiManualDateListData
} from '../../store/projects/project-actions';

//images
import ExpandArrow from '../../assets/images/icons/down-arrow-grey.svg';
import Profile from '../../assets/images/icons/profile-button.svg';
import Request from '../../assets/images/icons/request.svg';

const ProjectMainContainer = ({
  tableData,
  onSelectAll,
  selectedCount,
  onChangeHandler,
  navigate,
  fetchDropdownOptions,
  getNameInitials,
  aoiAdminOptions,
  qaqcAdminOptions,
  assigneeOpen,
  qaqcOpen,
  selectAll,
  setAssigneeOpen,
  numberOfFiles
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const dispatch = useDispatch();
  const { aoiData } = useSelector((state) => state.project) || {};

  const onChangeAoiAdmin = async (e, quantity) => {
    let payload = {
      aoi_ids: [quantity?.id],
      aoi_admin: e.value ? e.value : 'unassign'
    };
    const { data, status, error } = await updateAoiAdmin(payload);
    if (status === 200) {
      dispatch(onGetAoiListData(aoiData?.id));
      toast.success(data?.message);
    }
    if (error) {
      toast.error(error?.response?.data?.message);
      setAssigneeOpen('');
    }
  };

  const onChangeQaQcadmin = async (e, quantity) => {
    let payload = {
      aoi_ids: [quantity?.id],
      qaqc_admin: e.value ? e.value : 'unassign'
    };
    const { data, status, error } = await updateAoiAdmin(payload);
    if (status === 200) {
      toast.success(data?.message);
      dispatch(onGetAoiListData(aoiData?.id));
    }
    if (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getPermission = (key) => {
    return checkAoiLevelPermission(key);
  };

  const handleAoiClick = (quantity) => {
    dispatch(projectActions.doClearAoiData());
    dispatch(projectActions.doRequestStatus({ data: 'aoiDate' }));
    dispatch(mapCompareAction.doManualDataProcessingDate({ data: '' }));
    dispatch(requestAction.setProductIdCode(aoiData?.product_code));
    dispatch(
      mapCompareAction.doManualUploadBtntatus({
        data: aoiData?.aoi_data[0]
      })
    );
    dispatch(projectActions.doAoiId({ data: quantity?.id }));
    navigate(`/d3/browse/aoi/${quantity?.id}`, { replace: true });
    dispatch(mapCompareAction.doEnablePreview({ data: true }));
    if (aoiData?.generation_type === 'automated') {
      dispatch(onGetAoiAutomatedDateListData(quantity?.id));
    } else if (aoiData?.generation_type === 'manual') {
      dispatch(onGetAoiManualDateListData(quantity?.id));
    }
  };
  return (
    <div className="project-main-container">
      <div className={`project-user-container ${isExpanded ? 'expanded' : ''}`}>
        <div
          className="region-expand-collapse"
          data-testId="region-expand-collaps"
          onClick={() => toggleExpand()}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              toggleExpand();
            }
          }}
          tabIndex="0"
        >
          <div className="expand-arrow">
            <img
              src={ExpandArrow}
              alt="Pmage"
              style={{
                transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'
              }}
            />
          </div>
          <div className="region-title-wrapper">
            <div className="title-left">
              <h2>Region</h2>
            </div>
            <div className="title-right">
              <h2>Request Details</h2>
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className="table-wrapper">
            <div className="left-div" data-testId="region-checkbox">
              {getPermission('aoi_admin.bulk_create') &&
                tableData?.aoi_data &&
                tableData?.aoi_data?.length > 1 && (
                  <div className="select-all-wrapper">
                    <label>
                      <input
                        type="checkbox"
                        data-testId="region-checkbox"
                        checked={selectAll}
                        onChange={() => onSelectAll(tableData)}
                      />
                    </label>
                    {selectedCount ? (
                      <>
                        <span className="selected-text">
                          {selectedCount} Selected
                        </span>
                      </>
                    ) : null}
                  </div>
                )}
              <table className="region-table" cellspacing="0" cellpadding="0">
                <thead className="region-head">
                  <tr className="region-table-row">
                    {getPermission('aoi_admin.bulk_create') && (
                      <th className="checkbox-head"></th>
                    )}
                    <th className="head-first">SHAPEFILE</th>
                    <th>AOI ADMIN</th>
                    <th>QA/QC</th>
                  </tr>
                </thead>
                <tbody className="region-body">
                  {tableData &&
                    tableData.length !== 0 &&
                    tableData?.aoi_data?.map((quantity, index) => {
                      return (
                        <>
                          <tr
                            className="region-table-row"
                            key={quantity?.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAoiClick(quantity);
                            }}
                          >
                            {getPermission('aoi_admin.bulk_create') ? (
                              <td className="checkbox-body">
                                <input
                                  type="checkbox"
                                  checked={quantity[quantity.id]}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={() => {
                                    onChangeHandler(quantity.id);
                                  }}
                                />
                              </td>
                            ) : null}
                            <td className="region-first-body">
                              <span>
                                <img src={Request} alt="request" />
                              </span>
                              <label>{quantity.name}</label>
                            </td>
                            <td>
                              <div className="select-wrapper">
                                {assigneeOpen === quantity?.id ? (
                                  <Select
                                    autoFocus
                                    className="project-members"
                                    classNamePrefix="project-members"
                                    menuPlacement="auto"
                                    menuIsOpen={true}
                                    filterOption={createFilter({
                                      matchFrom: 'start'
                                    })}
                                    placeholder={
                                      <div className="project-member-placeholder">
                                        <p>Add assignee</p>
                                      </div>
                                    }
                                    options={aoiAdminOptions}
                                    onChange={(e) => {
                                      onChangeAoiAdmin(e, quantity);
                                    }}
                                  ></Select>
                                ) : (
                                  <>
                                    <div>
                                      {quantity?.aoi_admin &&
                                      quantity?.aoi_admin?.length === 0 ? (
                                        <div>
                                          <img
                                            title="Unassigned"
                                            src={Profile}
                                            alt=""
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (
                                                checkAoiLevelPermission(
                                                  'aoi_admin.update',
                                                  quantity
                                                )
                                              ) {
                                                fetchDropdownOptions(
                                                  quantity,
                                                  'aoi_admin'
                                                );
                                              } else {
                                                toast.error(
                                                  'Permission denied for this user'
                                                );
                                              }
                                            }}
                                            onKeyDown={(event) => {
                                              if (
                                                event.key === 'Enter' ||
                                                event.key === ' '
                                              ) {
                                                event.stopPropagation();
                                                if (
                                                  checkAoiLevelPermission(
                                                    'aoi_admin.update',
                                                    quantity
                                                  )
                                                ) {
                                                  fetchDropdownOptions(
                                                    quantity,
                                                    'aoi_admin'
                                                  );
                                                } else {
                                                  toast.error(
                                                    'Permission denied for this user'
                                                  );
                                                }
                                              }
                                            }}
                                            tabIndex="0"
                                          />
                                        </div>
                                      ) : (
                                        quantity?.aoi_admin &&
                                        quantity?.aoi_admin?.length !== 0 && (
                                          <div
                                            className="full-name-avatar"
                                            data-tooltip={
                                              quantity?.aoi_admin[0]?.email
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (
                                                checkAoiLevelPermission(
                                                  'aoi_admin.update',
                                                  quantity
                                                )
                                              ) {
                                                fetchDropdownOptions(
                                                  quantity,
                                                  'aoi_admin'
                                                );
                                              } else {
                                                toast.error(
                                                  'Permission denied for this user'
                                                );
                                              }
                                            }}
                                            onKeyDown={(event) => {
                                              if (
                                                event.key === 'Enter' ||
                                                event.key === ' '
                                              ) {
                                                event.stopPropagation();
                                                if (
                                                  checkAoiLevelPermission(
                                                    'aoi_admin.update',
                                                    quantity
                                                  )
                                                ) {
                                                  fetchDropdownOptions(
                                                    quantity,
                                                    'aoi_admin'
                                                  );
                                                } else {
                                                  toast.error(
                                                    'Permission denied for this user'
                                                  );
                                                }
                                              }
                                            }}
                                            tabIndex="0"
                                          >
                                            <p>
                                              {getNameInitials(
                                                quantity?.aoi_admin[0]?.name
                                              )}
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="select-wrapper">
                                {qaqcOpen === quantity?.id ? (
                                  <Select
                                    autoFocus
                                    className="project-members"
                                    classNamePrefix="project-members"
                                    menuPlacement="auto"
                                    menuIsOpen={true}
                                    filterOption={createFilter({
                                      matchFrom: 'start'
                                    })}
                                    placeholder={
                                      <div className="project-member-placeholder">
                                        <p>Add assignee</p>
                                      </div>
                                    }
                                    options={qaqcAdminOptions}
                                    onChange={(e) =>
                                      onChangeQaQcadmin(e, quantity)
                                    }
                                  ></Select>
                                ) : (
                                  <div>
                                    {quantity?.qaqc_admin &&
                                    quantity?.qaqc_admin.length === 0 ? (
                                      <div>
                                        <img
                                          title="Unassigned"
                                          src={Profile}
                                          alt=""
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (
                                              checkAoiLevelPermission(
                                                'qaqc.update',
                                                quantity
                                              )
                                            ) {
                                              fetchDropdownOptions(
                                                quantity,
                                                'qaqc_admin'
                                              );
                                            } else {
                                              toast.error(
                                                'Permission denied for this user'
                                              );
                                            }
                                          }}
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === 'Enter' ||
                                              event.key === ' '
                                            ) {
                                              event.stopPropagation();
                                              if (
                                                checkAoiLevelPermission(
                                                  'qaqc.update',
                                                  quantity
                                                )
                                              ) {
                                                fetchDropdownOptions(
                                                  quantity,
                                                  'qaqc_admin'
                                                );
                                              } else {
                                                toast.error(
                                                  'Permission denied for this user'
                                                );
                                              }
                                            }
                                          }}
                                          tabIndex="0"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="full-name-avatar"
                                        data-tooltip={
                                          quantity?.qaqc_admin[0]?.email
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (
                                            checkAoiLevelPermission(
                                              'qaqc.update',
                                              quantity
                                            )
                                          ) {
                                            fetchDropdownOptions(
                                              quantity,
                                              'qaqc_admin'
                                            );
                                          } else {
                                            toast.error(
                                              'Permission denied for this user'
                                            );
                                          }
                                        }}
                                        onKeyDown={(event) => {
                                          if (
                                            event.key === 'Enter' ||
                                            event.key === ' '
                                          ) {
                                            event.stopPropagation();
                                            if (
                                              checkAoiLevelPermission(
                                                'qaqc.update',
                                                quantity
                                              )
                                            ) {
                                              fetchDropdownOptions(
                                                quantity,
                                                'qaqc_admin'
                                              );
                                            } else {
                                              toast.error(
                                                'Permission denied for this user'
                                              );
                                            }
                                          }
                                        }}
                                        tabIndex="0"
                                      >
                                        <p>
                                          {getNameInitials(
                                            quantity?.qaqc_admin[0]?.name
                                          )}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="right-div">
              <ul className="tree-aoi-details" data-testid="tree-aoi-details">
                <li>
                  <label className="tree-aoi-head">Date Range</label>
                  <div>
                    <span className="tree-aoi-head-value">
                      {moment(aoiData?.from_date).format('DD/MM/YYYY')} -{' '}
                      {moment(aoiData?.to_date).format('DD/MM/YYYY')}
                    </span>
                    <h2 className="tree-aoi-head-value">
                      No. of files expected :{numberOfFiles}
                    </h2>
                  </div>
                </li>
                <li>
                  <label className="tree-aoi-head">Format</label>
                  <span className="tree-aoi-head-value">
                    {aoiData?.data_delivery_format}
                  </span>
                </li>
                <li>
                  <label className="tree-aoi-head">Data Product Admin</label>
                  <span className="tree-aoi-head-value">
                    {aoiData &&
                    aoiData?.product_admin &&
                    aoiData?.product_admin.length !== 0 ? (
                      <div
                        className="full-name-avatar"
                        data-tooltip={aoiData?.product_admin[0]?.email}
                      >
                        <p>
                          {aoiData?.product_admin[0]?.name &&
                            getNameInitials(aoiData?.product_admin[0]?.name)}
                        </p>
                      </div>
                    ) : (
                      <>
                        <img title="Unassigned" src={Profile} alt="" />
                      </>
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectMainContainer;
