import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import DoubleArrow from '../../assets/images/sidebar/double_arrow.svg';

import './index.css';

const SidebarDocs = ({ tab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  const [activeIndex, setActiveIndex] = useState(4);

  const handleItemClick = (targetPath, index) => {
    if (path[2] !== targetPath) {
      navigate(`/documentation/${targetPath}`);
    } else {
      navigate('/documentation');
    }
    setActiveIndex(index);
  };

  return (
    <div>
      <div className="heading-wrapper">
        <h1 className="setting-header">
          {tab === 'Documentation'
            ? `Settings`
            : tab === 'documentation'
              ? 'Documentation'
              : 'Support'}
        </h1>
      </div>
      {tab === 'documentation' && (
        <ul className="sidebar-tab-list">
          <li>
            <div
              className={
                activeIndex === 0
                  ? 'sidebar-docs-list active'
                  : 'sidebar-docs-list'
              }
              onClick={() => handleItemClick('api-docs', 0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleItemClick('api-docs', 0);
                }
              }}
              tabIndex="0"
            >
              <span
                className={
                  path[2] === 'api-docs'
                    ? 'list-image-wrapper-active'
                    : 'list-image-wrapper'
                }
              >
                <img src={DoubleArrow} alt="dobule arrow" />
              </span>
              <label>API Docs</label>
            </div>
          </li>

          <li>
            <div
              className={
                activeIndex === 1
                  ? 'sidebar-docs-list active'
                  : 'sidebar-docs-list'
              }
              onClick={() => handleItemClick('products', 0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleItemClick('products', 0);
                }
              }}
              tabIndex="0"
            >
              <span
                className={
                  path[2] === 'products'
                    ? 'list-image-wrapper-active '
                    : 'list-image-wrapper '
                }
              >
                <img src={DoubleArrow} alt="dobule arrow" />
              </span>
              <label>Product</label>
            </div>
          </li>

          <li>
            <div
              className={
                activeIndex === 2
                  ? 'sidebar-docs-list active'
                  : 'sidebar-docs-list'
              }
              onClick={() => handleItemClick('use-case', 0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleItemClick('use-case', 0);
                }
              }}
              tabIndex="0"
            >
              <span
                className={
                  path[2] === 'use-case'
                    ? 'list-image-wrapper'
                    : 'list-image-wrapper '
                }
              >
                <img src={DoubleArrow} alt="double arrow" />
              </span>
              <label>Use Cases</label>
            </div>
          </li>

          <li>
            <div
              className={
                activeIndex === 3
                  ? 'sidebar-docs-list active'
                  : 'sidebar-docs-list'
              }
              onClick={() => handleItemClick('use-guide', 0)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleItemClick('use-guide', 0);
                }
              }}
              tabIndex="0"
            >
              <span
                className={
                  path[2] === 'use-guide'
                    ? 'list-image-wrapper'
                    : 'list-image-wrapper '
                }
              >
                <img src={DoubleArrow} alt="double arrow" />
              </span>
              <label>User Guide</label>
            </div>
          </li>

          <li>
            <div className="sidebar-docs-list">
              <span
                className={
                  path[2] === 'faqs'
                    ? 'list-image-wrapper rotate'
                    : 'list-image-wrapper '
                }
              >
                <img src={DoubleArrow} alt="double arrow" />
              </span>
              <label>FAQs</label>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SidebarDocs;
