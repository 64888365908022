import React from 'react';
import Select, { createFilter } from 'react-select';

//images
import Profile from '../../assets/images/icons/profile-button.svg';

import { getNameInitials } from '../../utils/function-helper';

const MultiselectAssignees = ({
  selectedCount,
  admins,
  bulkadminOptions,
  setIsOpenAoi,
  setQAQCopen,
  setAdmins,
  isQAQCopen,
  setSelectedCount,
  onUpdateAoiAdmin,
  onUnchekTableData,
  tableData,
  isOpenAoi
}) => {
  return (
    <>
      {selectedCount > 0 && (
        <div className="multiselect-assignees">
          <div className="assign-title-wrapper">
            <h2>
              Assign AOI {selectedCount} /{' '}
              {tableData?.aoi_data ? tableData?.aoi_data?.length : null}
            </h2>
          </div>
          <div className="assign-body">
            <ul className="assign-admin">
              <li>
                <label className="tree-aoi-head">AOI Admin</label>
                <span className="tree-aoi-head-value">
                  {isOpenAoi ? (
                    <Select
                      className="project-members"
                      classNamePrefix="project-members"
                      menuPlacement="auto"
                      menuIsOpen={true}
                      placeholder={
                        <div className="project-member-placeholder">
                          <p>Add assignee</p>
                        </div>
                      }
                      filterOption={createFilter({ matchFrom: 'start' })}
                      onChange={(e) => {
                        setAdmins({ ...admins, aoi: e });
                        setIsOpenAoi(false);
                      }}
                      options={bulkadminOptions.aoi}
                    ></Select>
                  ) : admins?.aoi === '' ||
                    admins?.aoi?.label === 'Unassigned' ? (
                    <div>
                      <img
                        src={Profile}
                        alt=""
                        onClick={() => setIsOpenAoi(true)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ' ') {
                            setIsOpenAoi(true);
                          }
                        }}
                        tabIndex="0"
                      />
                    </div>
                  ) : (
                    <div
                      className="full-name-avatar"
                      onClick={() => setIsOpenAoi(true)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                          setIsOpenAoi(true);
                        }
                      }}
                      tabIndex="0"
                    >
                      <p>
                        {admins?.aoi
                          ? getNameInitials(admins?.aoi?.label)
                          : 'NA'}
                      </p>
                    </div>
                  )}
                </span>
              </li>
              <li>
                <label className="tree-aoi-head">QA/QC Admin</label>
                <span className="tree-aoi-head-value">
                  {isQAQCopen ? (
                    <Select
                      className="project-members"
                      classNamePrefix="project-members"
                      menuPlacement="auto"
                      menuIsOpen={isQAQCopen}
                      filterOption={createFilter({ matchFrom: 'start' })}
                      placeholder={
                        <div className="project-member-placeholder">
                          <p>Add assignee</p>
                        </div>
                      }
                      onChange={(e) => {
                        setAdmins({ ...admins, qaqc: e });
                        setQAQCopen(false);
                      }}
                      options={bulkadminOptions.qaac}
                    ></Select>
                  ) : admins?.qaqc === '' ||
                    admins?.qaqc?.label === 'Unassigned' ? (
                    <div>
                      <img
                        src={Profile}
                        alt=""
                        onClick={() => {
                          setQAQCopen(true);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ' ') {
                            setQAQCopen(true);
                          }
                        }}
                        tabIndex="0"
                      />
                    </div>
                  ) : (
                    <div
                      className="full-name-avatar"
                      onClick={() => setQAQCopen(true)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                          setQAQCopen(true);
                        }
                      }}
                    >
                      <p>{getNameInitials(admins?.qaqc?.label)}</p>
                    </div>
                  )}
                </span>
              </li>
            </ul>
          </div>
          <div className="assign-bottom">
            <div>
              <button
                className="assign-btn-cancel"
                onClick={() => {
                  setSelectedCount(0);
                  setAdmins({ ...admins, aoi: '', qaqc: '' });
                  onUnchekTableData();
                }}
              >
                Cancel
              </button>
              <button
                className="assign-btn-save"
                disabled={!admins.aoi && !admins?.qaqc}
                onClick={() => onUpdateAoiAdmin()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiselectAssignees;
