import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../containers/dashboard/dashboard';
import Projects from '../containers/projects';
import Login from '../containers/login/login';
import PrivateRoute from './PrivateRoute.jsx';
import Documentation from '../containers/documentation';
import Setting from '../containers/settings/index.jsx';
import NotFound from '../components/notFound';
import Catalog from '../containers/catalog/catalog';

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/d3"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/d3/drafts"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/d3/catalog"
        element={
          <PrivateRoute>
            <Catalog />
          </PrivateRoute>
        }
      />

      <Route
        path="/d3/catalog/:dataProduct"
        element={
          <PrivateRoute>
            <Catalog />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/d3/browse"
        element={
          <PrivateRoute>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/d3/browse/project/:id"
        element={
          <PrivateRoute>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/d3/browse/request/:id"
        element={
          <PrivateRoute>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/d3/browse/product/:id"
        element={
          <PrivateRoute>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/d3/browse/aoi/:id"
        element={
          <PrivateRoute>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        path="/documentation/:url"
        element={
          <PrivateRoute>
            <Documentation />
          </PrivateRoute>
        }
      ></Route>

      <Route
        path="/documentation"
        element={
          <PrivateRoute>
            <Documentation />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <Setting />
          </PrivateRoute>
        }
      ></Route>
      <Route path="/error" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
