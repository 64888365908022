import React, { useState, useEffect, useRef } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import RequestProductCard from '../requestCard';

import './index.css';

import { images } from '../../assets/images';
import ExpandArrow from '../../assets/images/icons/down-arrow-grey.svg';

import { useSelector } from 'react-redux';
import { getTimeStampFormat } from '../../utils/function-helper';

const ProjectReqDetail = ({
  setRequestStatus,
  setActiveRequest,
  getRegionAoiList,
  getTreeProductId,
  expand,
  getTreeRequestId,
  setZoomInTreePolygon
}) => {
  const scrollContainerRef = useRef(null);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(true);
  const [selectedTab, setSelectedTab] = useState('');
  const [regionSelect, setRegionSelect] = useState(null);
  const { productData } = useSelector((state) => state.project);
  const [isSopExpanded, setIsSopExpanded] = useState(true);
  const [isSopDataExpanded, setIsSopDataExpanded] = useState(true);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer.scrollLeft === 0) {
        setIsLeftArrowDisabled(true);
      } else {
        setIsLeftArrowDisabled(false);
      }

      if (
        scrollContainer.scrollLeft + scrollContainer.clientWidth ===
        scrollContainer.scrollWidth
      ) {
        setIsRightArrowDisabled(true);
      } else {
        setIsRightArrowDisabled(false);
      }
    };

    scrollContainer?.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand]);

  useEffect(() => {
    if (
      productData &&
      productData?.request_product_data &&
      productData?.request_product_data.length >= 2
    ) {
      setIsRightArrowDisabled(false);
    } else {
      setIsRightArrowDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLeftClick = () => {
    scrollContainerRef.current.classList.add('scroll-animation');
    scrollContainerRef.current.scrollLeft -= 250;

    setTimeout(() => {
      scrollContainerRef.current.classList.remove('scroll-animation');
    }, 400);
  };

  const handleRightClick = () => {
    scrollContainerRef.current.classList.add('scroll-animation');
    scrollContainerRef.current.scrollLeft += 250;

    setTimeout(() => {
      scrollContainerRef.current.classList.remove('scroll-animation');
    }, 300);
  };

  return (
    <>
      <Tabs className="head-tab">
        <TabList className="request-tab-list">
          <Tab className="request-tab">Workflow</Tab>
        </TabList>
        <TabPanel>{selectedTab === 0 && <div></div>}</TabPanel>
      </Tabs>
      {selectedTab !== 2 && (
        <div
          className="request-detail-container"
          data-testid="request-detail-container"
        >
          <div
            className="region-expand-collapse"
            onClick={() => setIsSopExpanded(!isSopExpanded)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                setIsSopExpanded(!isSopExpanded);
              }
            }}
            tabIndex="0"
          >
            <div className="expand-arrow">
              <img
                src={ExpandArrow}
                alt="Pmage"
                style={{
                  transform: isSopExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'
                }}
              />
            </div>
            <h2 className="work-flow-header">
              Region ({productData?.request_aoi_data?.length})
            </h2>
          </div>
          {isSopExpanded && (
            <div className="request-details">
              <div className="request-aoi">
                <div className="aoi-list">
                  {productData &&
                    productData?.request_aoi_data &&
                    productData?.request_aoi_data?.length !== 0 &&
                    productData?.request_aoi_data?.map((aoi, index) => {
                      return (
                        <div
                          key={index}
                          className={`individual-aoi ${
                            regionSelect === aoi?.aoi_id ? 'selected' : ''
                          }`}
                          onClick={() => {
                            if (regionSelect === aoi?.aoi_id) {
                              setRegionSelect(null);
                              setZoomInTreePolygon();
                            } else {
                              setRegionSelect(aoi?.aoi_id);
                              getRegionAoiList(aoi);
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              if (regionSelect === aoi?.aoi_id) {
                                setRegionSelect(null);
                                setZoomInTreePolygon();
                              } else {
                                setRegionSelect(aoi?.aoi_id);
                                getRegionAoiList(aoi);
                              }
                            }
                          }}
                          tabIndex="0"
                          data-testid={`aoi-label-${index}`}
                        >
                          <img
                            src={images.rectangle}
                            alt={`aoi_image_${index}`}
                          />
                          <p data-testid={`request-region-${index}`}>
                            {aoi?.aoi_name || ''}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="request-info">
                <div className="request-info-1">
                  <div className="request-info-header">
                    <label>Requested By</label>
                    <p data-testid="requested-by">
                      {productData?.requested_by_email}
                    </p>
                  </div>
                  <div className="request-info-header">
                    <label>Request Created On</label>
                    <p>{getTimeStampFormat(productData?.created_on)}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="region-expand-collapse"
            onClick={() => setIsSopDataExpanded(!isSopDataExpanded)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                setIsSopDataExpanded(!isSopDataExpanded);
              }
            }}
            tabIndex="0"
          >
            <div className="expand-arrow">
              <img
                src={ExpandArrow}
                alt="Pmage"
                style={{
                  transform: isSopDataExpanded
                    ? 'rotate(0deg)'
                    : 'rotate(-90deg)'
                }}
              />
            </div>
            <h2 className="work-flow-header">
              Data Product({productData?.request_product_data?.length})
            </h2>
          </div>
          {isSopDataExpanded && (
            <div className="request">
              <div
                className="request-card-sections"
                data-testid="request-card-sections"
                ref={scrollContainerRef}
              >
                {productData &&
                  productData?.request_product_data &&
                  productData?.request_product_data.length !== 0 &&
                  productData?.request_product_data?.map((item, index) => (
                    <RequestProductCard
                      key={index}
                      requestProductdata={item}
                      setRequestStatus={setRequestStatus}
                      setActiveRequest={setActiveRequest}
                      getTreeProductId={getTreeProductId}
                      getTreeRequestId={getTreeRequestId}
                    />
                  ))}
              </div>
              {productData &&
                productData?.request_product_data &&
                productData?.request_product_data.length !== 0 && (
                  <>
                    <button
                      className={`scroll-button left ${
                        isLeftArrowDisabled ? 'disabled' : ''
                      }`}
                      onClick={handleLeftClick}
                      disabled={isLeftArrowDisabled}
                      data-testid="left-scroll"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.33318 7.9999C5.33318 8.1519 5.38451 8.30323 5.48785 8.42656L8.82118 12.4266C9.05651 12.7092 9.47718 12.7479 9.75985 12.5119C10.0425 12.2766 10.0805 11.8566 9.84518 11.5732L6.86118 7.99256L9.73785 4.4179C9.96852 4.13123 9.92318 3.71123 9.63652 3.48056C9.34985 3.2499 8.93052 3.29523 8.69918 3.5819L5.48051 7.5819C5.38251 7.7039 5.33318 7.8519 5.33318 7.9999"
                          fill="#42444A"
                        />
                      </svg>
                    </button>
                    <button
                      className={`scroll-button right ${
                        isRightArrowDisabled ? 'disabled' : ''
                      }`}
                      onClick={handleRightClick}
                      disabled={isRightArrowDisabled}
                      data-testid="right-scroll"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.6667 8.0001C10.6667 7.8481 10.6153 7.69677 10.512 7.57344L7.17867 3.57344C6.94333 3.29077 6.52267 3.2521 6.24 3.4881C5.95733 3.72344 5.91933 4.14344 6.15467 4.42677L9.13867 8.00744L6.262 11.5821C6.03133 11.8688 6.07667 12.2888 6.36333 12.5194C6.65 12.7501 7.06933 12.7048 7.30067 12.4181L10.5193 8.4181C10.6173 8.2961 10.6667 8.1481 10.6667 8.0001Z"
                          fill="#42444A"
                        />
                      </svg>
                    </button>
                  </>
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectReqDetail;
