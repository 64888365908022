import { createSlice } from '@reduxjs/toolkit';

// Modal
import {
  ProjectRepository,
  RequestRepository,
  ProductRepository,
  AoiRepository
} from '../repository';

// Define the project slice
const projectSlice = createSlice({
  name: 'project',
  initialState: {
    // Initial state for project slice
    breadcrumbData: [],
    projectData: [],
    requestData: [],
    productData: [],
    aoiData: [],
    aoiDatesData: [],
    requestStatus: 'project',
    activeRequest: '',
    currentOpenLevel: '',
    projectId: '',
    requestId: '',
    productId: '',
    aoiId: ''
  },
  reducers: {
    // Action creator to update project list data
    getProjectListData(state, action) {
      const projectRepository = new ProjectRepository();
      projectRepository.initializeProjects(action.payload.data || []);
      state.projectData = projectRepository.getProjects();
    },
    // Action creator to update request list data
    getRequestListData(state, action) {
      const requestRepository = new RequestRepository();
      requestRepository.initializeRequests(action.payload.data || []);
      state.requestData = requestRepository.getRequests();
    },
    // Action creator to update product list data
    getProductListData(state, action) {
      const productRepository = new ProductRepository();
      productRepository.initializeProducts(action.payload.data || []);
      state.productData = productRepository.getProducts();
    },
    // Action creator to update AOI list data
    getAoiListData(state, action) {
      const aoiRepository = new AoiRepository();
      aoiRepository.initializeAois(action.payload.data || []);
      state.aoiData = aoiRepository.getAois();
    },
    // Action creator to update AOI dates data
    getAoiDatesData(state, action) {
      state.aoiDatesData = action.payload.data;
    },
    // Action creator to update breadcrumb data
    getBreadCrumbData(state, action) {
      state.breadcrumbData = action.payload.data;
    },
    // Action creator to update request status
    doRequestStatus(state, action) {
      state.requestStatus = action.payload.data;
    },
    // Action creator to update active request
    doActiveStatus(state, action) {
      state.activeRequest = action.payload.data;
    },
    // Action creator to update project ID
    doProjectId(state, action) {
      state.projectId = action.payload.data;
      state.currentOpenLevel = action.payload.data;
    },
    // Action creator to update request ID
    doRequestId(state, action) {
      state.requestId = action.payload.data;
      state.currentOpenLevel = action.payload.data;
    },
    // Action creator to update product ID
    doProductId(state, action) {
      state.productId = action.payload.data;
      state.currentOpenLevel = action.payload.data;
    },
    // Action creator to update AOI ID
    doAoiId(state, action) {
      state.aoiId = action.payload.data;
      state.currentOpenLevel = action.payload.data;
    },
    // Action creator to update AOI dates ID
    doAoiDatesId(state, action) {
      state.currentOpenLevel = action.payload.data;
    },
    // Action creator to clear IDs
    doClearIds(state) {
      state.projectId = '';
      state.requestId = '';
      state.productId = '';
      state.aoiId = '';
      state.currentOpenLevel = '';
    },
    // Action creator to clear project data
    doClearProjectData(state) {
      state.requestData = [];
      state.productData = [];
      state.aoiData = [];
      state.aoiDatesData = [];
      state.breadcrumbData = [];
    },
    // Action creator to clear request data
    doClearRequestData(state) {
      state.productData = [];
      state.aoiData = [];
      state.aoiDatesData = [];
      state.breadcrumbData = [];
    },
    // Action creator to clear product data
    doClearProductData(state) {
      state.aoiData = [];
      state.aoiDatesData = [];
      state.breadcrumbData = [];
    },
    // Action creator to clear AOI data
    doClearAoiData(state) {
      state.aoiDatesData = [];
      state.breadcrumbData = [];
    },
    // Action creator to clear only project data
    doClearOnlyProjectData(state) {
      state.requestData = [];
    }
  }
});

// Export action creators and reducer
export const projectActions = projectSlice.actions;
export default projectSlice.reducer;
