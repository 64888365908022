import { Project } from '../model';

class ProjectRepository {
  constructor() {
    this.projects = [];
  }

  /**
   * Initializes the project repository by converting an array of project data objects
   * into individual Project instances.
   *
   * @param {Array<Object>} projects - An array of objects representing project data.
   */
  initializeProjects(projects) {
    this.projects = projects.map((data) => {
      return new Project(data);
    });
  }

  /**
   * Retrieves all the Project objects in the repository.
   *
   * @returns {Array<Project>} An array containing all the Project instances.
   */
  getProjects() {
    return this.projects;
  }
}

export default ProjectRepository;
