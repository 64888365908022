import { deleteRequest, postRequest } from '../utils/http-helper';
import {
  CHECK_USER_EMAIL_URL,
  LOGIN_URL,
  CREATE_USER_URL,
  LOGOUT_URL
} from '../utils/url-helper';

export const getCheckUserEmail = async (payload) => {
  try {
    return await postRequest({
      url: CHECK_USER_EMAIL_URL,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const getLogin = async (payload) => {
  try {
    return await postRequest({
      url: LOGIN_URL,
      data: payload,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getNewCreateUser = async (payload) => {
  try {
    return await postRequest({
      url: CREATE_USER_URL,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const logout = async () => {
  try {
    return await deleteRequest({
      url: LOGOUT_URL,
      refresh: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};
