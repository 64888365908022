import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import Select, { createFilter } from 'react-select';
import moment from 'moment';
import IconOption from './customDropdownOption';

import { checkProjectLevelPermission } from '../../utils/permission-helper';
import { getNameInitials } from '../../utils/function-helper';
import { getStatusIcon } from '../../utils/user-helper';

import Profile from '../../assets/images/icons/profile-button.svg';
import Down from '../../assets/images/icons/down-arrow-grey.svg';
import ExpandArrow from '../../assets/images/icons/down-arrow-grey.svg';

const RequestTable = ({
  fetchRequestAdminMembers,
  handleRequestRowClick,
  requestAdminClicked,
  requestAdminOptions,
  handleUpdateRequestAdmin,
  requestDates,
  handleDateChange,
  actionDataArray,
  toggleActionDropdown,
  openDropdownIndex,
  handleApprove,
  setToRejectRequestId,
  setIsRejectModalOpen,
  setIsRequestAdminClicked
}) => {
  const canDisplayAction = checkProjectLevelPermission('request.approve');
  const { requestData } = useSelector(
    (state) => state.project || { requestData: null }
  );

  const selectRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        event.target.className !==
          'project-members__input-container css-qbdosj-Input'
      ) {
        setIsRequestAdminClicked(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef]);

  const onSortRequests = (requests) => {
    return requests &&
      requests.request_data &&
      requests.request_data.length !== 0
      ? requests.request_data.sort(
          (a, b) =>
            new Date(b.request_created_on) - new Date(a.request_created_on)
        )
      : [];
  };

  return (
    <div className="request-table-wrapper">
      <table className="request-table">
        <thead class="table-head">
          <tr>
            <th>REQUEST NAME</th>
            <th>REQUEST CREATED BY</th>
            <th>REQUEST ADMIN</th>
            <th>REQUEST DUE DATE</th>
            <th>CREATED ON</th>
            {canDisplayAction && <th>ACTION</th>}
          </tr>
        </thead>
        <tbody class="table-body">
          {onSortRequests(requestData)?.map((request, index) => (
            <tr
              className="request-table-row"
              key={index}
              onClick={(e) => {
                if (request?.action_status === 4) {
                  toast.error('Request is already rejected');
                } else {
                  handleRequestRowClick(request, e);
                  if (
                    location.pathname !== '/d3/browse' &&
                    requestAdminClicked === null
                  ) {
                    navigate(`/d3/browse/request/` + request.id, {
                      replace: true
                    });
                  }
                }
              }}
            >
              <td>
                <span className="request-image">
                  <img
                    src={getStatusIcon(request?.status_in_percentage)?.img}
                    alt="Request icon"
                  />
                </span>
                <label data-testId={`request-row-${index}`}>
                  {request?.name}
                </label>
              </td>

              <td>
                <div className="created-by-col">
                  <>
                    {request &&
                    request?.requested_by_name &&
                    request?.requested_by_name?.length === 0 ? (
                      <>
                        <img
                          key={index}
                          src={Profile}
                          title="Unassigned"
                          alt="Unassigned"
                        />
                      </>
                    ) : (
                      <div
                        className="full-name-avatar"
                        data-testid="full-name-avatar"
                        data-tooltip={
                          request?.requested_by_name
                            ? request?.requested_by_email
                            : `Not Available`
                        }
                      >
                        <p>
                          {request && request?.requested_by_name
                            ? getNameInitials(request?.requested_by_name)
                            : `NA`}
                        </p>
                      </div>
                    )}
                  </>
                </div>
              </td>
              <td>
                <div className="created-by-col">
                  {requestAdminClicked === request.id ? (
                    <Select
                      autoFocus
                      key={index}
                      id="request-admin-dropdown"
                      ref={selectRef}
                      menuPlacement="bottom"
                      menuIsOpen="true"
                      menuPosition="fixed"
                      placeholder={
                        <div className="project-member-placeholder">
                          <p>Add assignee</p>
                        </div>
                      }
                      className="project-members"
                      classNamePrefix={'project-members'}
                      components={{ Option: IconOption }}
                      options={requestAdminOptions}
                      filterOption={createFilter({ matchFrom: 'start' })}
                      onChange={(e) => {
                        handleUpdateRequestAdmin(e.label, e.value, request.id);
                      }}
                      isDisabled={
                        !checkProjectLevelPermission('request_admin.update')
                      }
                      data-testid={`request-admin-select-${index}`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          width: '18rem !important'
                        }),
                        menuList: (provided, state) => ({
                          ...provided,
                          width: '18rem !important'
                        })
                      }}
                    />
                  ) : (
                    <>
                      {request &&
                      request.request_admin &&
                      request?.request_admin?.length === 0 ? (
                        <>
                          <img
                            key={index}
                            src={Profile}
                            title="Unassigned"
                            alt="Unassigned"
                            data-testid={`request-admin-click-${index}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                checkProjectLevelPermission(
                                  'request_admin.create'
                                ) &&
                                !(
                                  actionDataArray[index]?.status === 'Reject' ||
                                  request.action_status === 4
                                )
                              ) {
                                fetchRequestAdminMembers(
                                  request.id,
                                  'project',
                                  requestData.id,
                                  3
                                );
                              }
                            }}
                            style={{
                              cursor:
                                checkProjectLevelPermission(
                                  'request_admin.create'
                                ) &&
                                !(
                                  actionDataArray[index]?.status === 'Reject' ||
                                  request.action_status === 4
                                )
                                  ? 'pointer'
                                  : 'not-allowed'
                            }}
                          />
                        </>
                      ) : (
                        <div
                          className="full-name-avatar"
                          data-tooltip={
                            request?.request_admin
                              ? request?.request_admin[0]?.email
                              : `Not Available`
                          }
                          data-testid={`request-admin-click-${index}`}
                          style={{
                            cursor: !(
                              actionDataArray[index]?.status === 'Reject' ||
                              request.action_status === 4 ||
                              request.action_status === 2
                            )
                              ? 'pointer'
                              : 'not-allowed'
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              checkProjectLevelPermission(
                                'request_admin.update'
                              ) &&
                              !(
                                actionDataArray[index]?.status === 'Reject' ||
                                request.action_status === 4 ||
                                request.action_status === 2
                              )
                            ) {
                              fetchRequestAdminMembers(
                                request.id,
                                'project',
                                requestData.id,
                                3
                              );
                            }
                          }}
                        >
                          <p>
                            {request && request?.request_admin
                              ? getNameInitials(request?.request_admin[0]?.name)
                              : `NA`}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </td>
              <td>
                <div
                  className="request-datepicker-body"
                  onClick={(e) => e.stopPropagation()}
                >
                  <DatePicker
                    className="request-datepicker"
                    placeholderText="Select Date"
                    selected={
                      requestDates && requestDates[index]
                        ? requestDates[index]
                        : requestData.request_data[index].due_date
                    }
                    onChange={(date) => handleDateChange(date, index)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabled={
                      !checkProjectLevelPermission('request_admin.update') ||
                      actionDataArray[index]?.status === 'Reject' ||
                      request.action_status === 4 ||
                      request.action_status === 2
                        ? true
                        : false
                    }
                    minDate={new Date()}
                    showIcon
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.0199 5.97848C11.2152 6.17374 11.2152 6.49032 11.0199 6.68558L8.35323 9.35225C8.15797 9.54751 7.84138 9.54751 7.64612 9.35225L4.97945 6.68558C4.78419 6.49032 4.78419 6.17374 4.97945 5.97848C5.17472 5.78322 5.4913 5.78322 5.68656 5.97848L7.99967 8.29159L10.3128 5.97848C10.508 5.78322 10.8246 5.78322 11.0199 5.97848Z"
                          fill="#696B72"
                        />
                      </svg>
                    }
                    toggleCalendarOnIconClick
                    dateFormat="dd/MM/YYYY"
                    popperPlacement="top"
                    popperModifiers={[
                      {
                        name: 'myModifier',
                        fn: (state) => {
                          state.backgroundColor = '#fff';
                          state.zIndex = '9999';
                          return state;
                        }
                      }
                    ]}
                    data-testid={`request-date-picker-${index}`}
                  />
                </div>
              </td>
              <td>{moment(request.request_created_on).format('DD/MM/YYYY')}</td>

              {canDisplayAction && (
                <td data-testid="actions" className="action-sections">
                  {actionDataArray[index]?.status === 'Approve' ||
                  request.action_status === 2 ? (
                    <>
                      <span className="action-approve">Approved</span>
                      {request?.comment !== null && (
                        <p className="action-status-hover">
                          {request?.comment}
                        </p>
                      )}
                    </>
                  ) : actionDataArray[index]?.status === 'Reject' ||
                    request.action_status === 4 ? (
                    <>
                      <span className="action-reject">Rejected</span>
                      {request?.comment !== null && (
                        <p className="action-status-hover">
                          {request?.comment}
                        </p>
                      )}
                    </>
                  ) : (
                    <span
                      className="action dropdown-toggle"
                      onClick={(e) => toggleActionDropdown(e, index)}
                      data-tip={`Click to ${
                        actionDataArray[index]?.status === 'Approve'
                          ? 'Approve'
                          : 'Reject'
                      }`}
                      data-testid={`action-dropdown-${index}`}
                    >
                      Action
                      <img src={ExpandArrow} alt="expand down arrow" />
                      {openDropdownIndex === index && (
                        <ul className="dropdown-menu-request option-menu">
                          <li
                            className="menu-item approve"
                            onClick={() => {
                              handleApprove(request.id, index);
                            }}
                          >
                            <label className="approve">Approve</label>
                          </li>
                          <li
                            className="menu-item reject"
                            onClick={() => {
                              if (
                                checkProjectLevelPermission('request.reject')
                              ) {
                                if (
                                  request.request_admin.length &&
                                  request?.request_admin.length !== 0
                                ) {
                                  setToRejectRequestId({
                                    requestId: request.id,
                                    rowIndex: index
                                  });
                                  setIsRejectModalOpen(true);
                                } else
                                  toast.error('Please assign a request admin');
                              } else {
                                toast.error('Permission denied');
                              }
                            }}
                          >
                            <label className="reject">Reject</label>
                          </li>
                        </ul>
                      )}
                    </span>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestTable;
