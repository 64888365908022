import React, { useState } from 'react';
import Modal from 'react-modal';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './index.css';

//images
import Close from '../../assets/images/icons/close.svg';
import SOP from '../sop';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0rem',
    width: '37.5rem',
    borderRadius: '0.8rem'
  }
};

const Module = ({
  regionUploadGeoJson,
  region,
  sopSelected,
  setSopSelected,
  modifyCard,
  setModifyCard,
  setUpdatedSop,
  setSelectedButton,
  setExpand
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Tabs className="head-tab">
        <TabList className="module-tab-list">
          <Tab className="module-tab" selectedTabClassName="bg-white">
            Workflow
          </Tab>
        </TabList>
        <TabPanel>
          <div className="module-tab-panel-container">
            <SOP
              regionUploadGeoJson={regionUploadGeoJson}
              region={region}
              sopSelected={sopSelected}
              setSopSelected={setSopSelected}
              modifyCard={modifyCard}
              setModifyCard={setModifyCard}
              setUpdatedSop={setUpdatedSop}
              setSelectedButton={setSelectedButton}
              setExpand={setExpand}
            />
          </div>
        </TabPanel>
      </Tabs>

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="changes-modal-container">
          <div className="modal-header">
            <p>Unsaved Changes</p>
            <img
              src={Close}
              alt="close icon"
              onClick={closeModal}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  closeModal();
                }
              }}
              tabIndex="0"
            ></img>
          </div>
          <div className="changes-body">
            <div className="change-name">
              <label>
                You have made changes.
                <br />
                Do you want to save or discard them?
              </label>
            </div>
            <div className="common-modal-btn">
              <button className="save-button color margin-right">Save</button>
              <button className="discard-btn color" onClick={closeModal}>
                Discard
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Module;
