import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataProductPage from '../../components/dataProductPage';
import styles from './index.module.scss';
import Sidebar from '../../components/sidebar/sidebar';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import DataCatalog from '../../components/dataCatalog';
import SolutionCatalog from '../../components/solutionCatalog';

const Catalog = () => {
  const navigate = useNavigate();

  const [expand, setExpand] = useState(false);
  const [dataProductSelected, setDataProductSelected] = useState('');
  const [sideBarActiveStatus, setSideBarActiveStatus] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [customDrawerHeight, setCustomDrawerHeight] = useState(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [selectedDataProducts, setSelectedDataProducts] = useState([]);
  const [aois, setAOIs] = useState([]);
  const [selectedAOIs, setSelectedAOIs] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const onHandleCloseTreeLevel = () => {};

  useEffect(() => {
    if (dataProductSelected === '') {
      navigate('/d3/catalog');
    } else {
      navigate(`/d3/catalog/${dataProductSelected}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductSelected, navigate]);

  return (
    <div className={styles.catalog_wrapper}>
      <div className={styles.catalog_sections}>
        <Sidebar
          setExpand={setExpand}
          expand={expand}
          sideBarActiveStatus={sideBarActiveStatus}
          setSelectedButton={setSelectedButton}
          setSideBarActiveStatus={setSideBarActiveStatus}
          setCustomDrawerHeight={setCustomDrawerHeight}
          isButtonClicked={isButtonClicked}
          setIsButtonClicked={setIsButtonClicked}
          setSelectedDataProducts={setSelectedDataProducts}
          setAOIs={setAOIs}
          setSelectedAOIs={setSelectedAOIs}
          onCloseTreeLevel={onHandleCloseTreeLevel}
          selectedDataProducts={selectedDataProducts}
          setDrawerVisible={setDrawerVisible}
          aois={aois}
        />
        <div className={styles.catalog_container}>
          <div className={styles.top_heading}>
            <h1 className={styles.header}>Catalog</h1>
            <Tabs
              className={styles.catalog_toggle}
              onSelect={(index) => setActiveTabIndex(index)}
              selectedIndex={activeTabIndex}
            >
              <TabList className={styles.toggle_tab_list}>
                <Tab
                  className={styles.module_tab}
                  selectedTabClassName="bg-white"
                >
                  Data Catalog
                </Tab>
                <Tab
                  className={styles.module_tab}
                  selectedTabClassName="bg-white"
                >
                  Solution Catalog
                </Tab>
              </TabList>
              <TabPanel className={styles.data_panel_call}>
                {activeTabIndex === 0 &&
                  (dataProductSelected === '' ? (
                    <DataCatalog
                      setDataProductSelected={setDataProductSelected}
                    />
                  ) : (
                    <DataProductPage
                      dataProductSelected={dataProductSelected}
                      setDataProductSelected={setDataProductSelected}
                    />
                  ))}
              </TabPanel>
              <TabPanel>{activeTabIndex === 1 && <SolutionCatalog />}</TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
