import React, { useState, useEffect, useMemo } from 'react';
import { Spring, config, animated } from 'react-spring';
import moment from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Helpers
import { getDateStatusIcon } from '../../utils/user-helper';

// Services
import { getAoiManualDateLayer } from '../../services/project-tree-services';

// Component
import TreeHeader from './treeHeader';
import TreeRender from './treeRender';

// Redux
import { projectActions } from '../../store/projects/project-slice';
import {
  onGetAoiAutomatedDateListData,
  onGetAoiListData,
  onGetAoiManualDateListData,
  onGetProductListData,
  onGetRequestListData
} from '../../store/projects/project-actions';
import { requestAction } from '../../store/requests/request-slice';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';

// CSS
import './index.css';

interface TreeProps {
  requestType: string;
  status: number;
  title: string;
  treeDataId: string;
}

interface TreeListDataItem {
  id: string;
  status: number;
  name: string;
}

interface AnimatedStyle {
  height: string;
  opacity: number;
  transform: string;
}

const styles = {
  contents: {
    willChange: 'transform, opacity, height'
  }
};

const TreeNode: React.FC<TreeProps> = ({
  requestType,
  status,
  title,
  treeDataId
}) => {
  const [treeListData, setTreeListData] = useState<TreeListDataItem[]>([]);
  const [type, setType] = useState(requestType);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const {
    requestData,
    productData,
    aoiData,
    aoiDatesData,
    projectId,
    requestId,
    productId,
    aoiId,
    currentOpenLevel
  } = useSelector((state: any) => state.project);

  const { isCompare, activeDates, leftLayer, rightLayer, dateStatusView } =
    useSelector((state: any) => state.mapCompare);

  useEffect(() => {
    const generateTreeListData = (data: any[]): TreeListDataItem[] => {
      if (!data) return [];
      return data.map((item) => ({
        id: item?.id,
        status: item?.action_status,
        name: item?.name
      }));
    };

    if (type === 'project') {
      setTreeListData(generateTreeListData(requestData?.request_data));
    } else if (type === 'request') {
      setTreeListData(generateTreeListData(productData?.request_product_data));
    } else if (type === 'product') {
      setTreeListData(generateTreeListData(aoiData?.aoi_data));
    } else {
      setTreeListData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData, productData, aoiData]);

  const childTreeClassName = useMemo(() => {
    const classNames = {
      project: 'project-child-expand',
      request: 'request-child-expand',
      product: 'product-child-expand',
      aoi: 'quantity-child-expand'
    };
    return (classNames as { [key: string]: string })[requestType] || '';
  }, [requestType]);

  const toggle = (type: string) => {
    setType(type);
    dispatch(
      mapCompareAction.doSetSingleMapLayer({
        data: [],
        id: ''
      })
    );
    switch (type) {
      case 'project':
        dispatch(projectActions.doRequestStatus({ data: 'request' }));
        onCheckProjectId();
        break;
      case 'request':
        dispatch(projectActions.doRequestStatus({ data: 'product' }));
        onCheckRequestId();
        break;
      case 'product':
        dispatch(projectActions.doRequestStatus({ data: 'aoi' }));
        dispatch(mapCompareAction.doManualDataProcessingDate({ data: '' }));
        onCheckProductId();
        break;
      case 'aoi':
        dispatch(projectActions.doRequestStatus({ data: 'aoiDate' }));
        dispatch(mapCompareAction.doManualDataProcessingDate({ data: '' }));
        dispatch(requestAction.setProductIdCode(aoiData?.product_code));
        dispatch(
          mapCompareAction.doManualUploadBtntatus({
            data: aoiData?.aoi_data[0]
          })
        );
        onCheckAoiId();
        break;
      default:
        break;
    }
  };

  const onCheckProjectId = () => {
    dispatch(projectActions.doClearProjectData());
    dispatch(mapCompareAction.doEnablePreview({ data: false }));
    if (treeDataId === projectId) {
      if (location.pathname !== '/d3/browse') {
        navigate('/d3/browse', { replace: true });
      }
      dispatch(projectActions.doProjectId({ data: '' }));
      dispatch(projectActions.doRequestStatus({ data: 'project' }));
    } else {
      dispatch(onGetRequestListData(treeDataId));
      navigate(`/d3/browse/project/` + treeDataId, {
        replace: true
      });
      dispatch(projectActions.doProjectId({ data: treeDataId }));
    }
  };

  const onCheckRequestId = () => {
    dispatch(projectActions.doClearRequestData());
    dispatch(mapCompareAction.doEnablePreview({ data: false }));
    if (treeDataId === requestId) {
      dispatch(projectActions.doRequestId({ data: '' }));
      dispatch(projectActions.doRequestStatus({ data: 'request' }));
      navigate(`/d3/browse/project/${projectId}`, { replace: true });
    } else {
      dispatch(onGetProductListData(treeDataId));
      dispatch(projectActions.doRequestId({ data: treeDataId }));
      navigate(`/d3/browse/request/${treeDataId}`, { replace: true });
    }
  };

  const onCheckProductId = () => {
    dispatch(projectActions.doClearProductData());
    dispatch(mapCompareAction.doEnablePreview({ data: false }));
    if (treeDataId === productId) {
      dispatch(projectActions.doProductId({ data: '' }));
      dispatch(projectActions.doRequestStatus({ data: 'product' }));
      navigate(`/d3/browse/request/${requestId}`, { replace: true });
    } else {
      dispatch(onGetAoiListData(treeDataId));
      dispatch(projectActions.doProductId({ data: treeDataId }));
      navigate(`/d3/browse/product/${treeDataId}`, { replace: true });
    }
  };

  const onCheckAoiId = () => {
    dispatch(projectActions.doClearAoiData());
    if (treeDataId === aoiId) {
      dispatch(projectActions.doAoiId({ data: '' }));
      dispatch(projectActions.doRequestStatus({ data: 'aoi' }));
      navigate(`/d3/browse/product/${productId}`, { replace: true });
      dispatch(mapCompareAction.doEnablePreview({ data: false }));
      dispatch(mapCompareAction.doSetActiveDates({ data: [] }));
    } else {
      if (aoiData?.generation_type === 'automated') {
        dispatch(onGetAoiAutomatedDateListData(treeDataId));
      } else if (aoiData?.generation_type === 'manual') {
        dispatch(onGetAoiManualDateListData(treeDataId));
      }
      dispatch(projectActions.doAoiId({ data: treeDataId }));
      navigate(`/d3/browse/aoi/${treeDataId}`, { replace: true });
      dispatch(mapCompareAction.doEnablePreview({ data: true }));
    }
  };

  const getRequestType = (name: string) =>
    ({ project: 'request', request: 'product', product: 'aoi' })[name] || '';

  const isActive = (): boolean => {
    if (type === 'project') {
      return projectId === treeDataId;
    } else if (type === 'request') {
      return requestId === treeDataId;
    } else if (type === 'product') {
      return productId === treeDataId;
    } else if (type === 'aoi') {
      return aoiId === treeDataId;
    }
    return false;
  };

  const handleDateCheckClickList = (aoiDate: any, generationType: string) => {
    dispatch(mapCompareAction.doManualUploadBtntatus({ data: '' }));
    if (generationType === 'manual') {
      dispatch(mapCompareAction.doManualDataProcessingDate({ data: aoiDate }));
      onHandleManualAoiDate(aoiDate);
    } else if (generationType === 'automated') {
      onHandleWmsLayer(aoiDate, aoiDate.id);
    }
  };

  const onHandleManualAoiDate = async (aoiDate: any) => {
    const { data, status }: any = await getAoiManualDateLayer(
      aoiDate?.aoi_mapping_id,
      aoiDate?.date
    );
    if (status === 200) {
      if (data?.data?.features && data?.data?.features.length >= 0) {
        onHandleWmsLayer(data?.data?.features[0], aoiDate.id);
      } else {
        let response = data?.data?.features ? data?.data?.features : [];
        onHandleWmsLayer(response, aoiDate.id);
      }
    }
  };

  const onHandleWmsLayer = (date: any, id: string) => {
    if (isCompare) {
      multiHandleCheckboxChange(date, id);
    } else {
      singleLayerCheckboxChange(date, id);
    }
  };

  const singleLayerCheckboxChange = (aoiDate: any, id: string) => {
    if (currentOpenLevel === aoiDate?.id) {
      if (!isCompare) {
        dispatch(mapCompareAction.doEnableCompare({ data: true }));
      }
      dispatch(projectActions.doAoiDatesId({ data: '' }));
      dispatch(mapCompareAction.doSetActiveDates({ data: [] }));
      dispatch(mapCompareAction.doSetSingleMapLayer({ data: [], id: '' }));
    } else {
      dispatch(projectActions.doAoiDatesId({ data: id }));
      dispatch(mapCompareAction.doSetActiveDates({ data: [id] }));
      dispatch(mapCompareAction.doEnableCompare({ data: false }));
      dispatch(
        mapCompareAction.doSetSingleMapLayer({
          data: aoiDate,
          id: aoiDate?.id
        })
      );
    }
  };

  const multiHandleCheckboxChange = (aoiDate: any, layerId: string) => {
    if (activeDates.length === 2 && !activeDates.includes(layerId)) {
      dispatch(
        mapCompareAction.doSetActiveDates({ data: [activeDates[1], layerId] })
      );
      if (leftLayer.id !== activeDates[1]) {
        dispatch(
          mapCompareAction.doSetLeftLayer({ data: aoiDate, id: layerId })
        );
      } else {
        dispatch(
          mapCompareAction.doSetRightLayer({ data: aoiDate, id: layerId })
        );
      }
    } else if (activeDates.includes(layerId)) {
      dispatch(
        mapCompareAction.doSetActiveDates({
          data: activeDates.filter((item: string) => item !== layerId)
        })
      );
      if (leftLayer?.id === layerId) {
        dispatch(mapCompareAction.doSetLeftLayer({ data: [], id: '' }));
      } else if (rightLayer?.id === layerId) {
        dispatch(mapCompareAction.doSetRightLayer({ data: [], id: '' }));
      }
    } else {
      dispatch(
        mapCompareAction.doSetActiveDates({ data: [...activeDates, layerId] })
      );
      if (
        leftLayer?.layerData?.length === 0 &&
        rightLayer?.layerData?.length === 0
      ) {
        dispatch(
          mapCompareAction.doSetLeftLayer({ data: aoiDate, id: layerId })
        );
      } else if (
        leftLayer?.layerData?.length !== 0 &&
        rightLayer?.layerData?.length === 0
      ) {
        dispatch(
          mapCompareAction.doSetRightLayer({ data: aoiDate, id: layerId })
        );
      } else if (
        rightLayer?.layerData?.length !== 0 &&
        leftLayer?.layerData?.length === 0
      ) {
        dispatch(
          mapCompareAction.doSetLeftLayer({ data: aoiDate, id: layerId })
        );
      }
    }
  };

  return (
    <li className="treeview-sections">
      <TreeHeader
        toggle={toggle}
        open={isActive()}
        status={status}
        title={title}
        isActive={currentOpenLevel === treeDataId}
        requestType={requestType}
      />
      {isActive() && (
        <Spring<AnimatedStyle>
          config={{ ...config.default, precision: 0.1 }}
          from={{
            height: '0px',
            opacity: 0,
            transform: 'translate3d(2rem,0,0)'
          }}
          to={{
            height: 'auto',
            opacity: 1,
            transform: 'translate3d(0rem,0,0)'
          }}
        >
          {(style) => (
            <div className={`child-tree ${childTreeClassName}`}>
              <animated.ul
                className="project-tree-list"
                style={{ ...style, ...styles.contents }}
              >
                {treeListData && treeListData.length !== 0 && (
                  <TreeRender
                    treeListData={treeListData}
                    requestType={getRequestType(type)}
                  />
                )}

                {type === 'aoi' && aoiDatesData && aoiDatesData.length !== 0
                  ? aoiDatesData.map((aoiDate: any) => (
                      <li key={aoiDate.id} className="date-wrapper">
                        <label className="vertical-line date-list"></label>
                        <label className="treeview-line"></label>
                        <div
                          className={`date-item ${activeDates.includes(aoiDate?.id) ? 'back_gound' : ''} ${dateStatusView || aoiDate?.status === 4 ? 'remove_cursor' : ''}`}
                        >
                          <div className="status-icon">
                            <img
                              src={
                                aoiData?.generation_type === 'automated'
                                  ? (getDateStatusIcon('3') as any)?.img
                                  : aoiData?.generation_type === 'manual' &&
                                    (getDateStatusIcon(aoiDate?.status) as any)
                                      ?.img
                              }
                              alt="Status icon"
                            />
                          </div>
                          <div className="date-text-container">
                            {aoiData?.generation_type === 'manual' ? (
                              <div
                                className="date-checkbox"
                                onClick={() => {
                                  if (
                                    !dateStatusView &&
                                    aoiDate?.status !== 4
                                  ) {
                                    handleDateCheckClickList(
                                      aoiDate,
                                      aoiData?.generation_type
                                    );
                                  }
                                }}
                              >
                                <span
                                  className={`date-text ${aoiDate?.status === 4 ? 'date_bg_color' : ''}`}
                                >
                                  {moment(aoiDate.date).format('DDMMMYY')}
                                </span>
                                {isCompare && (
                                  <label className="custom_checkbox">
                                    <input
                                      onClick={() => {
                                        if (
                                          !dateStatusView &&
                                          aoiDate?.status !== 4
                                        ) {
                                          handleDateCheckClickList(
                                            aoiDate,
                                            aoiData?.generation_type
                                          );
                                        }
                                      }}
                                      disabled={dateStatusView}
                                      className="checkbox_item"
                                      type="checkbox"
                                      checked={activeDates.includes(aoiDate.id)}
                                    />
                                    <span className="checkbox_mark"></span>
                                  </label>
                                )}
                              </div>
                            ) : (
                              aoiData?.generation_type === 'automated' && (
                                <div
                                  className="date-checkbox"
                                  onClick={() => {
                                    if (!dateStatusView) {
                                      handleDateCheckClickList(
                                        aoiDate,
                                        aoiData?.generation_type
                                      );
                                    }
                                  }}
                                >
                                  <span className="date-text">
                                    {moment(aoiDate.properties.datetime).format(
                                      'DDMMMYY'
                                    )}
                                  </span>
                                  {isCompare && (
                                    <label className="custom_checkbox">
                                      <input
                                        onClick={() => {
                                          if (!dateStatusView) {
                                            handleDateCheckClickList(
                                              aoiDate,
                                              aoiData?.generation_type
                                            );
                                          }
                                        }}
                                        disabled={dateStatusView}
                                        className="checkbox_item"
                                        type="checkbox"
                                        checked={activeDates.includes(
                                          aoiDate.id
                                        )}
                                      />
                                      <span className="checkbox_mark"></span>
                                    </label>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </li>
                    ))
                  : null}
              </animated.ul>
            </div>
          )}
        </Spring>
      )}
    </li>
  );
};

export default TreeNode;
