import { combineReducers, configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';

import { persistReducer, persistStore } from 'redux-persist';

import userSlice from './auth/auth-slice';
import requestSlice from './requests/request-slice';
import projectSlice from './projects/project-slice';
import draftsSlice from './drafts/drafts-slice';
import mapCompareSlice from './mapCompare/map-compare-slice';
import userDataSlice from './userManage/user-slice';
import regionSlice from './region/region-slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage
  //blacklist: [], // What you don't wanna to persist
  //whitelist: [] // What you want to persist
};

const rootReducer = combineReducers({
  user: userSlice,
  request: requestSlice,
  project: projectSlice,
  draft: draftsSlice,
  mapCompare: mapCompareSlice,
  userData: userDataSlice,
  region: regionSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

  devTools: process.env.NODE_ENV !== 'production',

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);
