import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import DatePicker from '../date-picker';
import { toast } from 'react-toastify';

// CSS
import 'pure-react-carousel/dist/react-carousel.es.css';
import './index.css';

//Helper Functions
import { formatOptions, granulityOption } from '../../utils/user-helper';

//Services

import { getNumberOfFiles } from '../../services/products-service';

const ModifyRequestCard = ({
  modifyCardData,
  setSelectedButton,
  region,
  regionUploadGeoJson,
  modifyCard,
  setModifyCard,
  setRequestButtonDisabled,
  setOpen,
  selectedAOIs,
  setBackPress,
  confirmRequest,
  setSopSelected,
  setExpand,
  setUpdatedSop
}) => {
  const dropdownRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState({
    label: '(None)',
    value: 0
  });

  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: '',
      endDate: ''
    }
  ]);
  const [numberOfFiles, setNumberOfFiles] = useState('');
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [newLabel, setNewLabel] = useState(
    modifyCardData ? modifyCardData?.label : ''
  );

  const [previousLabel, setPreviousLabel] = useState(
    modifyCardData ? modifyCardData?.label : ''
  );

  const disabledStatus = modifyCardData?.details === '' || region === '';

  const new_region = region
    ?.split(',')
    ?.map((item) => {
      return item?.split('_')[0];
    })
    ?.join(',');

  useEffect(() => {
    if (!modifyCardData || modifyCardData.label === '') {
      setSelectedOption({
        label: '(None)',
        value: 0
      });
    }
  }, [modifyCardData]);

  useEffect(() => {
    if (modifyCardData && modifyCardData?.label === '') {
      setSelectedDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyCardData?.label]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modifyCardData?.endDate !== '' && modifyCardData?.startDate !== '') {
      setSelectedDate({
        ...selectedDate,
        startDate: modifyCardData?.startDate,
        endDate: modifyCardData?.endDate
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modifyCardData?.title === modifyCardData?.title) {
      setSelectedDate([
        {
          startDate: '',
          endDate: ''
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  useEffect(() => {
    if (
      modifyCardData?.sop_data &&
      modifyCardData?.startDate !== '' &&
      modifyCardData?.endDate !== ''
    ) {
      let numberOfFilesPayload = {
        satellite:
          modifyCardData.sop_data[0]?.input_data[0]?.input_data[0]?.selected_value[0]?.value.split(
            ' '
          )[0],
        processing_level:
          modifyCardData.sop_data[0]?.input_data[0]?.input_data[0]?.selected_value[0]?.value.split(
            ' '
          )[1],
        cloud_perc:
          `0,` +
          modifyCardData.sop_data[0]?.input_data[2]?.input_data[0]
            ?.selected_value[0],
        aoi: regionUploadGeoJson.map((item) => {
          if (typeof item.aoi === 'string') {
            return JSON.parse(item.aoi);
          } else {
            if (item?.aoi?.features) {
              item?.aoi?.features?.map((item) => {
                return item.geometry;
              });
            } else {
              return item?.aoi;
            }
          }
        }),
        from_date: convertDate(modifyCardData.startDate),
        to_date: convertDate(modifyCardData.endDate)
      };
      fetchNumberOfFiles(numberOfFilesPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyCardData, region]);

  useEffect(() => {
    areAllProductDetailsFilled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyCard]);

  const convertDate = (dateObj) => {
    const parts = dateObj.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    return year + '-' + month + '-' + day;
  };

  const fetchNumberOfFiles = async (payload) => {
    const { data, status } = await getNumberOfFiles(payload);
    if (status === 200) {
      setNumberOfFiles(data.count);
    }
  };

  const handleRenameItem = () => {
    if (modifyCard.indexOf(modifyCardData) !== 0) {
      setNewLabel(modifyCardData.label);
      setPreviousLabel(modifyCardData.label);
      setIsRenaming(true);
      setIsDropdownOpen(false);
    }
  };

  const handleConfirmRename = () => {
    const finalLabel = newLabel.trim() === '' ? previousLabel : newLabel;
    const isNamePresent = modifyCard.some(
      (item) => item.label.toLowerCase() === newLabel.toLowerCase()
    );
    if (isNamePresent && finalLabel !== previousLabel) {
      toast.error('Name already exists');
    } else {
      setModifyCard(
        modifyCard.map((item) => {
          if (item.label === modifyCardData.label) {
            return { ...item, label: finalLabel };
          }
          return item;
        })
      );
      setIsRenaming(false);
      setIsDropdownOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const areAllProductDetailsFilled = () => {
    modifyCard?.map((product) => {
      if (
        !(
          product.endDate === '' ||
          product.startDate === '' ||
          product.format === '' ||
          region === '' ||
          selectedAOIs === '' ||
          product.crop === ''
        )
      )
        setRequestButtonDisabled(false);
    });
  };

  const getFormatDefaultValue = () => {
    if (modifyCardData?.format !== '') {
      return formatOptions.filter(
        (item) => item.value === modifyCardData?.format
      );
    } else {
      return '';
    }
  };

  const onChangeFormat = (e) => {
    setModifyCard(
      modifyCard.map((item) => {
        if (item.label === modifyCardData.label) {
          return { ...item, format: e.value };
        }
        return item;
      })
    );

    areAllProductDetailsFilled();
  };

  const onChangeCrop = (e) => {
    setModifyCard(
      modifyCard.map((item) => {
        if (item.label === modifyCardData.label) {
          return { ...item, crop: e };
        }
        return item;
      })
    );

    areAllProductDetailsFilled();
  };

  const onChangeGran = (e) => {
    setModifyCard(
      modifyCard.map((item) => {
        if (item.label === modifyCardData.label) {
          return { ...item, gran_data: e };
        }
        return item;
      })
    );
    setSelectedOption(e);
    areAllProductDetailsFilled();
  };

  const onChangeDate = (ranges) => {
    setSelectedDateRange([ranges.selection]);
  };

  const handleCopyItem = (e) => {
    const copied_Index = modifyCard?.findIndex((item) => {
      return item?.value === modifyCardData?.value;
    });
    if (copied_Index === -1 || copied_Index === undefined) {
      // Handle the case when index is not found or undefined
      return;
    }

    const count =
      modifyCard?.filter((item) => item?.value === modifyCardData?.value)
        .length - 1;
    let name =
      modifyCardData?.label?.split('-')?.length === 2
        ? `${modifyCardData?.label?.split('-')[0]}-${count + 1}`
        : `${modifyCardData?.label}-${count + 1}`;

    const copiedItem = {
      value: modifyCardData?.value,
      label: name,
      sop: modifyCardData?.sop,
      sop_data: modifyCardData?.sop_data,
      desc: modifyCardData?.desc,
      startDate: modifyCardData?.startDate,
      endDate: modifyCardData?.endDate,
      format: modifyCardData?.format,
      product_code: modifyCardData?.product_code
    };
    // Including crop, extraction_data, and gran_data only if they are present
    if (modifyCardData?.crop_specific) {
      copiedItem.crop_specific = true;
      copiedItem.crop = modifyCardData?.crop;
      copiedItem.crops = modifyCardData?.crops;
    }
    if (modifyCardData?.extraction) {
      copiedItem.extraction = true;
      copiedItem.extraction_data = modifyCardData?.extraction_data;
    }
    if (modifyCardData?.extraction) {
      copiedItem.gran_data = modifyCardData?.gran_data;
    }
    modifyCard.splice(copied_Index + 1, 0, copiedItem);
    setModifyCard([...modifyCard]);
    toast.success('Data Product Added');
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 10);
  };

  const handleRemoveItem = (e) => {
    setModifyCard(
      modifyCard?.filter((item) => item.label !== modifyCardData.label)
    );
    toast.success('Data Product Removed');
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 20);
  };

  const handleInfoIconClick = () => {
    setIsDropdownOpen(true);
  };

  return (
    <div className="modify-request-card" data-testid="modify-request-card">
      <ul
        className={`modify-request-header ${
          modifyCardData?.label !== ''
            ? 'product-header'
            : 'empty-product-header'
        }`}
        onClick={() => {
          if (modifyCardData?.label !== '' && !confirmRequest && !isRenaming) {
            setBackPress(modifyCardData?.label);
            setOpen(true);
            setSopSelected(modifyCardData);
            setUpdatedSop(modifyCardData?.sop_data);
          }
        }}
      >
        {modifyCardData?.label !== '' ? (
          <>
            <li>
              {isRenaming ? (
                <input
                  className={`rename-text`}
                  type="text"
                  value={newLabel}
                  onChange={(e) => setNewLabel(e.target.value)}
                  onBlur={handleConfirmRename}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleConfirmRename();
                    }
                  }}
                  autoFocus
                />
              ) : (
                <h2>{modifyCardData?.label}</h2>
              )}
            </li>
            {!confirmRequest ? (
              <li className="info-icon">
                <div
                  className="header-icons"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleInfoIconClick();
                  }}
                >
                  <label
                    className="modify-card-info"
                    data-testid="modify-card-info"
                    id={modifyCardData ? modifyCardData.id : ''}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.22222 5.44444C8.89689 5.44444 9.44444 4.89689 9.44444 4.22222C9.44444 3.54756 8.89689 3 8.22222 3C7.54756 3 7 3.54756 7 4.22222C7 4.89689 7.54756 5.44444 8.22222 5.44444ZM8.22222 7.27763C7.54756 7.27763 7 7.82518 7 8.49985C7 9.17452 7.54756 9.72207 8.22222 9.72207C8.89689 9.72207 9.44444 9.17452 9.44444 8.49985C9.44444 7.82518 8.89689 7.27763 8.22222 7.27763ZM7 12.7779C7 12.1033 7.54756 11.5557 8.22222 11.5557C8.89689 11.5557 9.44444 12.1033 9.44444 12.7779C9.44444 13.4526 8.89689 14.0001 8.22222 14.0001C7.54756 14.0001 7 13.4526 7 12.7779Z"
                        fill="#2F80ED"
                      />
                    </svg>
                  </label>

                  <ul
                    ref={dropdownRef}
                    className="dropdown-menu-modal"
                    style={{
                      display: isDropdownOpen && !isRenaming ? 'block' : 'none'
                    }}
                  >
                    {modifyCard?.length !== 0 ? (
                      <>
                        <li
                          className="dropdown-menu-item"
                          onClick={handleCopyItem}
                        >
                          <label></label>
                          <span>Make a Copy</span>
                        </li>
                        <li
                          className="dropdown-menu-item"
                          onClick={() => handleRemoveItem()}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              handleRemoveItem();
                            }
                          }}
                          tabIndex="0"
                        >
                          <label></label>
                          <span>Remove</span>
                        </li>
                        <li
                          className={`dropdown-menu-item ${
                            modifyCard?.indexOf(modifyCardData) === 0
                              ? 'disabled'
                              : ''
                          }`}
                          onClick={() => handleRenameItem()}
                          onKeyDown={(event) => {
                            if (
                              (event.key === 'Enter' || event.key === ' ') &&
                              modifyCard?.indexOf(modifyCardData) !== 0
                            ) {
                              handleRenameItem();
                            }
                          }}
                          style={{
                            pointerEvents:
                              modifyCard?.indexOf(modifyCardData) === 0
                                ? 'none'
                                : 'auto'
                          }}
                        >
                          <label></label>
                          <span>Rename</span>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </li>
            ) : null}
          </>
        ) : (
          <li
            onClick={() => {
              setSelectedButton('data');
              setExpand(false);
            }}
          >
            <h2>Select Data Product</h2>
          </li>
        )}
      </ul>
      <div className="modify-request-content">
        <ul className="modify-request-body">
          {!confirmRequest && (
            <li className="request-list">
              <label className="list-title">Details</label>
              {modifyCardData?.label ? (
                <>
                  <span
                    onClick={() => {
                      if (!confirmRequest && !isRenaming) {
                        setBackPress(modifyCardData?.label);
                        setOpen(true);
                        setSopSelected(modifyCardData);
                        setUpdatedSop(modifyCardData?.sop_data);
                      }
                    }}
                    className={`list-value select-list-value ${
                      confirmRequest && 'list-disable'
                    }`}
                    // className="list-value select-list-value list-disable"
                  >
                    View workflow
                  </span>
                </>
              ) : (
                <span className="list-value empty-list-value">
                  Product to be selected
                </span>
              )}
            </li>
          )}
          <li className="request-list">
            <label className="list-title">Region</label>
            {region !== '' ? (
              <>
                <span
                  className={`list-value select-list-value ${
                    confirmRequest && 'list-disable'
                  }`}
                >
                  {new_region}
                </span>
                <h4 className="list-value-info">{new_region?.split('_')[0]}</h4>
              </>
            ) : (
              <span
                onClick={() => {
                  setSelectedButton('region');
                  setExpand(false);
                }}
                className="list-value"
              >
                Select Region
              </span>
            )}
          </li>

          {modifyCardData && modifyCardData.crop_specific === true ? (
            <li className="request-list" style={{ paddingBottom: '10px' }}>
              <label className="list-title">Crop</label>
              {!confirmRequest ? (
                <span className="list-value-item">
                  <Select
                    placeholder="Select Crop"
                    classNamePrefix={'react-select'}
                    menuPlacement="bottom"
                    className="react-select react-select-primary"
                    isDisabled={
                      confirmRequest ||
                      modifyCardData?.details === '' ||
                      region === ''
                    }
                    value={modifyCardData?.crop}
                    options={modifyCardData?.crops?.map((crop) => ({
                      value: crop.id,
                      label: crop.name
                    }))}
                    onChange={(e) => {
                      onChangeCrop(e);
                    }}
                  />
                </span>
              ) : (
                <span className="date-disabled">
                  {modifyCardData?.crop?.label}
                </span>
              )}
            </li>
          ) : (
            <span className="date-disabled">{modifyCardData?.crop?.value}</span>
          )}

          <li className="request-list">
            <label className="list-title">Date Range</label>
            {!confirmRequest ? (
              <span
                className="list-value-item"
                data-toggle={disabledStatus ? 'tooltip' : ''}
                data-placement="center"
                title={disabledStatus ? 'Select region first' : ''}
              >
                <DatePicker
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                  selectedDate={selectedDate}
                  onChangeDate={onChangeDate}
                  disabledStatus={
                    modifyCardData?.details === '' || region === ''
                  }
                  setSelectedDate={setSelectedDate}
                  setModifyCard={setModifyCard}
                  modifyCard={modifyCard}
                  UploadGeoJsonGeometry={regionUploadGeoJson}
                  modifyCardData={modifyCardData}
                  isDisabled={confirmRequest}
                />
              </span>
            ) : (
              <span className="date-disabled">
                {modifyCardData?.startDate} - {modifyCardData?.endDate}
              </span>
            )}
          </li>

          <li className="request-list">
            {modifyCardData?.startDate !== '' ||
            modifyCardData?.endDate !== '' ? (
              <label className="file-expected">
                No. of files expected : {numberOfFiles}
              </label>
            ) : null}
          </li>
          <li className="request-list" style={{ paddingBottom: '10px' }}>
            <label className="list-title">Format</label>
            {!confirmRequest ? (
              <span className="list-value-item">
                <Select
                  placeholder="Select Format"
                  classNamePrefix={'react-select'}
                  menuPlacement="auto"
                  className="react-select react-select-primary"
                  defaultValue={getFormatDefaultValue()}
                  value={getFormatDefaultValue() || ''}
                  options={formatOptions}
                  isDisabled={
                    confirmRequest ||
                    modifyCardData?.details === '' ||
                    region === '' ||
                    modifyCardData?.startDate === '' ||
                    modifyCardData?.endDate === ''
                  }
                  onChange={(e) => {
                    onChangeFormat(e);
                  }}
                />
              </span>
            ) : (
              <span className="date-disabled">{modifyCardData.format}</span>
            )}
          </li>

          {modifyCardData && modifyCardData.extraction === true ? (
            <li className="request-list">
              <label className="list-title">Extraction Granularity*</label>
              {!confirmRequest ? (
                <span className="list-value-item-crop">
                  <Select
                    placeholder="Granularity"
                    classNamePrefix={'react-select'}
                    menuPlacement="auto"
                    className="react-select react-select-primary"
                    isDisabled={
                      confirmRequest ||
                      modifyCardData?.details === '' ||
                      region === ''
                    }
                    value={selectedOption}
                    options={granulityOption.map((item) => {
                      return {
                        label: item.name,
                        value: item.id
                      };
                    })}
                    onChange={(e) => {
                      onChangeGran(e);
                      setSelectedOption(e);
                    }}
                  />
                </span>
              ) : (
                <span className="date-disabled">
                  {modifyCardData?.gran_data?.label || '-'}
                </span>
              )}
            </li>
          ) : (
            <span className="date-disabled">
              {modifyCardData?.gran_data?.label}
            </span>
          )}
        </ul>
      </div>
    </div>
  );
};
export default ModifyRequestCard;
