/**
 * Represents a project.
 */
export class Project {
  /**
   * Constructs an instance of Project.
   * @param {object} projectData - The data object containing project information.
   */
  constructor(projectData) {
    this.assignProjectData(projectData);
  }

  /**
   * Assigns project data to the instance properties.
   * @param {object} projectData - The data object containing project information.
   */
  assignProjectData(projectData) {
    this.id = projectData.id;
    this.name = projectData.name;
    this.project_admin = projectData.project_admin;
    this.project_created_by = projectData.project_created_by;
    this.project_created_by_name = projectData.project_created_by_name;
    this.request_count = projectData.request_count;
    this.status_in_percentage = projectData.status_in_percentage;
  }

  /**
   * Retrieves project information.
   * @returns {object} - An object containing project information.
   */
  getProjectInfo() {
    return {
      id: this.id,
      name: this.name,
      project_admin: this.project_admin,
      project_created_by: this.project_created_by,
      project_created_by_name: this.project_created_by_name,
      request_count: this.request_count,
      status_in_percentage: this.status_in_percentage
    };
  }
}
