import React from 'react';
import Select from 'react-select';
import DatePicker from '../date-picker';

import './index.css';

const DraftProductCard = ({ cardData }) => {
  return (
    <div className="draft-product-card">
      <div className="modify-request-card">
        <ul className="modify-request-header draft-card-header">
          <li onClick={() => {}}>
            <h2>{cardData?.product_name}</h2>
          </li>
        </ul>
        <div className="modify-request-content draft-content">
          <ul className="modify-request-body draft-wrapper-list">
            <li className="request-list">
              <label className="list-title">Details</label>
              <span
                onClick={() => {}}
                className="list-value select-list-value list-disable"
              >
                View workflow
              </span>
            </li>
            <li className="request-list">
              <label className="list-title">Region</label>

              <span
                className="list-value select-list-value
                    list-disable"
              >
                {/* Haryana_Ambala */}
                {cardData?.aoi_id.join(',')}
              </span>
              <h4 className="list-value-info">Haryana_Ambala</h4>
            </li>

            <li className="request-list">
              <label className="list-title">Date Range</label>
              <span className="list-value-item" data-placement="center">
                <DatePicker
                  isDisabled={true}
                  draftData={cardData}
                  type="drafts"
                />
              </span>
            </li>
            <li className="request-list">
              {/* <label className="file-expected">
                No. of files expected :{numberOfFiles}
              </label> */}
            </li>

            <li className="request-list" style={{ paddingBottom: '10px' }}>
              <label className="list-title">Format</label>
              <span className="list-value-item">
                <Select
                  placeholder="Select Format"
                  classNamePrefix={'react-select'}
                  menuPlacement="auto"
                  className="react-select react-select-primary"
                  value={{
                    label: cardData?.data_delivery_format,
                    value: cardData?.data_delivery_format
                  }}
                  isDisabled={true}
                />
              </span>
            </li>
            {/* <li className="request-list" style={{ paddingBottom: '10px' }}>
              <label className="list-title">Crop</label>

              <span className="list-value-item">
                <Select
                  placeholder="Select Crop"
                  classNamePrefix={'react-select'}
                  menuPlacement="auto"
                  className="react-select react-select-primary"
                  isDisabled={true}
                  // value={modifyCardData?.crop}
                  // options={modifyCardData?.crops.map((crop) => ({
                  //   value: crop.id,
                  //   label: crop.name
                  // }))}
                  onChange={(e) => {
                    // onChangeCrop(e);
                  }}
                />
              </span>
            </li>
            <li
              className="request-list"
              data-testid="extraction-dropdown"
              style={{ paddingBottom: '10px' }}>
              <label className="list-title">Extraction</label>
              <span className="list-value-item-crop">
                <Select
                  placeholder="Choose Option"
                  classNamePrefix={'react-select'}
                  menuPlacement="auto"
                  className="react-select react-select-primary"
                  // options={extractionOptions}
                  isDisabled={true}
                  // value={modifyCardData?.extraction_data || null}
                />
              </span>
            </li>
            <li className="request-list">
              <label className="list-title">Granularity</label>
              <span className="list-value-item-crop">
                <Select
                  placeholder="Granularity"
                  classNamePrefix={'react-select'}
                  menuPlacement="auto"
                  className="react-select react-select-primary"
                  isDisabled={true}
                  // value={modifyCardData?.gran_data || null}
                  // options={granulityOption.map((item) => {
                  //   return {
                  //     label: item.name,
                  //     value: item.id
                  //   };
                  // })}
                />
              </span>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DraftProductCard;
