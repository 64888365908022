import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import './index.css';
const RangeSliders = ({ value, onChangeHandler, disabled }) => {
  const [values, setValues] = useState(value);
  return (
    <Range
      step={1}
      min={0}
      max={100}
      disabled={disabled}
      values={values}
      onChange={(newValues) => {
        setValues(newValues);
        onChangeHandler(newValues[0]);
      }}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '6px',
            width: '95%',
            background: getTrackBackground({
              values,
              colors: ['#3DB267', '#BDBDBD'],
              min: 0,
              max: 100
            })
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          className="tooltips"
          {...props}
          style={{
            ...props.style,
            height: '18px',
            width: '18px',
            backgroundColor: '#3DB267',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              color: '#077636',
              fontSize: '14px',
              fontWeight: '600',
              position: 'absolute',
              top: '-25px',
              width: '38px'
            }}
          >
            {values[0]} %
          </div>
        </div>
      )}
    />
  );
};
export default RangeSliders;
