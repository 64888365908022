import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import ProjectList from '../ProjectList';
import ProjectRequest from '../ProjectRequest';
import ProjectReqDetail from '../ProjectReqDetail';
import ProjectAoi from '../projectAoi';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';
import { getObjectByLevel, getLevelAndId } from '../../utils/function-helper';

// Css
import './index.css';

//Images
import { images } from '../../assets/images';

const ProjectModule = ({
  projectTreeListData,
  getTreeQuantityId,
  getRegionAoiList,
  drawerHeight,
  setDrawerHeight,
  parentAoiId,
  expand,
  setReCallQuantity,
  setBackPressed,
  setZoomInTreePolygon
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { breadcrumbData, requestStatus } = useSelector(
    (state) => state.project
  );
  const { singleMapLayer } = useSelector((state) => state.mapCompare);

  const handleBackClick = (currentLevel) => {
    dispatch(
      mapCompareAction.doSetSingleMapLayer({
        data: [],
        id: ''
      })
    );
    dispatch(mapCompareAction.doSetActiveDates({ data: [] }));
    if (breadcrumbData && breadcrumbData.length != 0) {
      setBackPressed(true);
      if (currentLevel === -1) {
        navigate(`/d3/browse`, {
          replace: true
        });
      } else
        navigate(`/d3/browse/` + currentLevel.level + `/` + currentLevel.id, {
          replace: true
        });
    }
  };

  let header = null;
  if (location.pathname !== '/d3/browse' && breadcrumbData) {
    const project = breadcrumbData[0];
    const request = breadcrumbData[1];
    const product = breadcrumbData[2];
    const aoi = breadcrumbData[3];

    const levelName = getLevelAndId(location.pathname).name;
    header = (
      <div className="project-detail-header">
        <span
          onClick={() =>
            handleBackClick(
              getObjectByLevel(
                breadcrumbData,
                getLevelAndId(location.pathname).name
              )
            )
          }
        >
          <img src={images.back} alt="back" />
        </span>
        {project && project?.id !== null && levelName && (
          <h2
            className="request-name-header"
            onClick={() => handleBackClick(breadcrumbData[0])}
          >
            {project.name}
          </h2>
        )}
        {request &&
          request?.id !== null &&
          levelName &&
          levelName !== 'project' && (
            <>
              <img src={images.down} alt="right-arrow" />
              <h2
                className="request-name-header"
                onClick={() => handleBackClick(breadcrumbData[1])}
              >
                {request.name}
              </h2>
            </>
          )}
        {product &&
          product?.id !== null &&
          (levelName === 'product' || levelName === 'aoi') && (
            <>
              <img src={images.down} alt="right-arrow" />
              <h2
                className="request-name-header"
                onClick={() => handleBackClick(breadcrumbData[2])}
              >
                {product.name}
              </h2>
            </>
          )}
        {aoi && aoi?.id !== null && levelName === 'aoi' && (
          <>
            <img src={images.down} alt="right-arrow" />
            <h2
              className="request-name-header"
              onClick={() => handleBackClick(breadcrumbData[3])}
            >
              {aoi.name}
            </h2>
          </>
        )}
        {singleMapLayer && singleMapLayer?.layerData?.length !== 0 && (
          <>
            <img src={images.down} alt="right-arrow" />
            <h2 className="request-name-header">
              {moment(singleMapLayer?.layerData?.properties?.datetime).format(
                'DDMMMYY'
              )}
            </h2>
          </>
        )}
      </div>
    );
  } else if (requestStatus !== 'project') {
    header = (
      <div className="project-detail-header">
        <span>
          <img src={images.back} alt="back" />
        </span>
        <h2 className="request-name-header">Projects</h2>
      </div>
    );
  }

  return (
    <>
      {header}
      {requestStatus === 'project' ? (
        <ProjectList
          projectTreeListData={projectTreeListData}
          drawerHeight={drawerHeight}
        />
      ) : requestStatus === 'request' ? (
        <ProjectRequest />
      ) : requestStatus === 'product' ? (
        <ProjectReqDetail
          setZoomInTreePolygon={setZoomInTreePolygon}
          getRegionAoiList={getRegionAoiList}
          expand={expand}
        />
      ) : (
        (requestStatus === 'aoi' || requestStatus === 'aoiDate') && (
          <ProjectAoi
            getTreeQuantityId={getTreeQuantityId}
            setDrawerHeight={setDrawerHeight}
            parentAoiId={parentAoiId}
            setReCallQuantity={setReCallQuantity}
          />
        )
      )}
    </>
  );
};

export default ProjectModule;
