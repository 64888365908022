import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import SaveDraftModalPopUp from '../popupmodal/saveDraftModal';
import DuplicateCardModal from '../popupmodal/duplicatePopup';

//
import ModifyRequestCard from './modify-req-card';
import Back from '../../assets/images/icons/left-arrow.svg';
import RightArrow from '../../assets/images/icons/right-arrow.svg';
import Right from '../../assets/images/right.svg';

// CSS
import './index.css';
import Module from '../module';
import { useDispatch } from 'react-redux';
import { requestAction } from '../../store/requests/request-slice';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0rem',
    borderRadius: '1.6rem',
    background: '#fff',
    width: '36.9rem',
    boxShadow: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25)'
  }
};

const duplicateCardStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0rem',
    borderRadius: '0.8rem',
    background: '#fff',
    width: '40.3rem',
    boxShadow: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25)'
  }
};

const ModifyRequest = ({
  modifyCard,
  setSelectedButton,
  aois,
  selectedAOIs,
  setModifyCard,
  setConfirmRequest,
  confirmRequest,
  requestButtonDisabled,
  setRequestButtonDisabled,
  setExpand,
  expand,
  setIsButtonClicked
}) => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [saveDraftModal, setSaveDraftModal] = useState(false);
  const [backpress, setBackPress] = useState('');
  const [sopSelected, setSopSelected] = useState('');
  const [updatedSop, setUpdatedSop] = useState([]);
  const [openDuplicateCardModal, setOpenDuplicateCardModal] = useState(false);

  const selectedAOIsName = aois
    ?.filter((aoi) => selectedAOIs.includes(aoi.id))
    ?.map((item) => item.name)
    ?.join(' ,');

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer.scrollLeft === 0) {
        setIsLeftArrowDisabled(true);
      } else {
        setIsLeftArrowDisabled(false);
      }

      if (
        scrollContainer.scrollLeft + scrollContainer.clientWidth ===
        scrollContainer.scrollWidth
      ) {
        setIsRightArrowDisabled(true);
      } else {
        setIsRightArrowDisabled(false);
      }
    };

    scrollContainer?.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand]);

  useEffect(() => {
    if (modifyCard?.length >= 2) {
      setIsRightArrowDisabled(false);
    } else {
      setIsRightArrowDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyCard]);

  useEffect(() => {
    const hasEmptyFields = modifyCard?.some(
      (item) =>
        item.value === '' ||
        item.label === '' ||
        item.details === '' ||
        item.format === '' ||
        item.endDate === '' ||
        item.startDate === ''
    );

    if (
      modifyCard?.length !== 0 &&
      selectedAOIs?.length !== 0 &&
      !hasEmptyFields
    ) {
      setRequestButtonDisabled(false);
    } else {
      setRequestButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyCard, selectedAOIs]);

  const onHandleLeftClick = () => {
    scrollContainerRef.current.classList.add('scroll-animation');
    scrollContainerRef.current.scrollLeft -= 250;

    setTimeout(() => {
      scrollContainerRef.current.classList.remove('scroll-animation');
    }, 400);
  };

  const onHandleRightClick = () => {
    scrollContainerRef.current.classList.add('scroll-animation');
    scrollContainerRef.current.scrollLeft += 250;

    setTimeout(() => {
      scrollContainerRef.current.classList.remove('scroll-animation');
    }, 300);
  };

  const onBackPress = (type) => {
    dispatch(requestAction.doClearState());
    if (isOpen) {
      setOpen(false);
    } else if (confirmRequest) {
      setConfirmRequest(false);
      setIsButtonClicked(true);
    }
  };

  const closeModal = () => {
    setSaveDraftModal(false);
    if (openDuplicateCardModal) {
      setOpenDuplicateCardModal(false);
    }
  };

  const areProductCardsEqual = (card1, card2) => {
    if (card1.crop_specific && card2.crop_specific) {
      return (
        card1.sop_data.join(' ') === card2.sop_data.join(' ') &&
        card1.startDate === card2.startDate &&
        card1.endDate === card2.endDate &&
        card1.format === card2.format &&
        card1.product_code === card2.product_code &&
        card1.crop?.value === card2.crop?.value &&
        card1.extraction_data?.value === card2.extraction_data?.value &&
        card1.gran_data?.value === card2.gran_data?.value
      );
    } else {
      if (card1.extraction && card2.extraction) {
        return (
          card1.sop_data.join(' ') === card2.sop_data.join(' ') &&
          card1.startDate === card2.startDate &&
          card1.endDate === card2.endDate &&
          card1.format === card2.format &&
          card1.product_code === card2.product_code &&
          card1.extraction_data?.value === card2.extraction_data?.value &&
          card1.gran_data?.value === card2.gran_data?.value
        );
      } else {
        return (
          card1.sop_data.join(' ') === card2.sop_data.join(' ') &&
          card1.startDate === card2.startDate &&
          card1.endDate === card2.endDate &&
          card1.format === card2.format &&
          card1.product_code === card2.product_code
        );
      }
    }
  };

  const onHandleRequest = () => {
    let isDuplicateProductCard = false;
    modifyCard.forEach((card1, i) => {
      modifyCard.slice(i + 1).forEach((card2, j) => {
        if (areProductCardsEqual(card1, card2)) {
          isDuplicateProductCard = true;
        }
      });

      if (isDuplicateProductCard) {
        return;
      }
    });
    if (isDuplicateProductCard) {
      setOpenDuplicateCardModal(true);
    } else setConfirmRequest(true);
    setExpand(true);
    setIsButtonClicked(false);
  };

  const onHandleSave = () => {
    let dataObj = modifyCard.map((item) => ({ ...item }));
    let findObj = dataObj.map((item) => {
      if (item.label === sopSelected.label) {
        Object.assign(item, sopSelected);
        let updatedObj = updatedSop.map((item) => ({ ...item }));
        item.sop_data = [...updatedObj];
        return item;
      } else return item;
    });
    setModifyCard([...findObj]);

    setOpen(false);
  };

  const onHandleSaveDraft = () => {};

  return (
    <>
      <div className="dragable-header">
        <div className="request-header">
          <div className="headings">
            {isOpen || confirmRequest ? (
              <div className="image-wrapper" onClick={() => onBackPress()}>
                <img src={Back} alt="back icon"></img>
              </div>
            ) : null}
            <label className="request-heading">
              <span>Modify Request</span>
              {isOpen || confirmRequest ? (
                <>
                  <span className="child-arrow">
                    <img src={Right} alt="right-arrow"></img>
                  </span>
                  <span>{backpress}</span>
                </>
              ) : null}
            </label>
          </div>
        </div>

        <div
          className="dragable-btn"
          data-toggle={requestButtonDisabled ? 'tooltip' : ''}
          data-placement="center"
          title={
            requestButtonDisabled ? 'Specify required details to proceed' : ''
          }
        >
          {isOpen ? (
            <button className="btn save-button" onClick={() => onHandleSave()}>
              Save
            </button>
          ) : (
            <>
              {/* <button
                className="btn btn-green save-draft"
                onClick={() => {
                  setSaveDraftModal(true);
                }}
                disabled={onHandleDraftDisable()}
              >
                Save Draft
              </button> */}
              {!confirmRequest ? (
                <button
                  className={`btn ${
                    requestButtonDisabled
                      ? 'disabled-btn'
                      : 'enabled-request-btn'
                  }`}
                  disabled={requestButtonDisabled}
                  onClick={(e) => {
                    onHandleRequest();
                    setBackPress('Request Summary');
                  }}
                >
                  Proceed
                  <label className="right-arrow" data-testId="right-arrow">
                    <img src={RightArrow} alt="arrow icon" />
                  </label>
                </button>
              ) : null}
            </>
          )}
        </div>
      </div>

      {isOpen ? (
        <Module
          regionUploadGeoJson={aois}
          region={selectedAOIsName}
          sopSelected={sopSelected}
          setSopSelected={setSopSelected}
          modifyCard={modifyCard}
          setModifyCard={setModifyCard}
          setUpdatedSop={setUpdatedSop}
          setSelectedButton={setSelectedButton}
          setExpand={setExpand}
        />
      ) : (
        <>
          <div className="modify-request">
            <div className="custom-scrollbar-container">
              <div className="custom-scrollbar" ref={scrollContainerRef}>
                {modifyCard?.length !== 0 ? (
                  <>
                    {modifyCard?.map((item) => (
                      <ModifyRequestCard
                        setSelectedButton={setSelectedButton}
                        modifyCardData={item}
                        region={selectedAOIsName}
                        regionUploadGeoJson={aois}
                        modifyCard={modifyCard}
                        setModifyCard={setModifyCard}
                        setRequestButtonDisabled={setRequestButtonDisabled}
                        setOpen={setOpen}
                        setBackPress={setBackPress}
                        confirmRequest={confirmRequest}
                        setSopSelected={setSopSelected}
                        setExpand={setExpand}
                        expand={expand}
                        setUpdatedSop={setUpdatedSop}
                      />
                    ))}
                  </>
                ) : (
                  <ModifyRequestCard
                    setSelectedButton={setSelectedButton}
                    modifyCardData={{
                      value: '',
                      label: '',
                      details: '',
                      format: '',
                      endDate: '',
                      startDate: ''
                    }}
                    region={selectedAOIsName}
                    regionUploadGeoJson={aois}
                    setExpand={setExpand}
                    expand={expand}
                    setBackPress={setBackPress}
                    setOpen={setOpen}
                    setSopSelected={setSopSelected}
                  />
                )}
              </div>
              {modifyCard?.length !== 0 && (
                <>
                  <button
                    className={`scroll-button left ${
                      isLeftArrowDisabled ? 'disabled' : ''
                    }`}
                    onClick={onHandleLeftClick}
                    disabled={isLeftArrowDisabled}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.33318 7.9999C5.33318 8.1519 5.38451 8.30323 5.48785 8.42656L8.82118 12.4266C9.05651 12.7092 9.47718 12.7479 9.75985 12.5119C10.0425 12.2766 10.0805 11.8566 9.84518 11.5732L6.86118 7.99256L9.73785 4.4179C9.96852 4.13123 9.92318 3.71123 9.63652 3.48056C9.34985 3.2499 8.93052 3.29523 8.69918 3.5819L5.48051 7.5819C5.38251 7.7039 5.33318 7.8519 5.33318 7.9999"
                        fill="#42444A"
                      />
                    </svg>
                  </button>
                  <button
                    className={`scroll-button right ${
                      isRightArrowDisabled ? 'disabled' : ''
                    }`}
                    onClick={onHandleRightClick}
                    disabled={isRightArrowDisabled}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6667 8.0001C10.6667 7.8481 10.6153 7.69677 10.512 7.57344L7.17867 3.57344C6.94333 3.29077 6.52267 3.2521 6.24 3.4881C5.95733 3.72344 5.91933 4.14344 6.15467 4.42677L9.13867 8.00744L6.262 11.5821C6.03133 11.8688 6.07667 12.2888 6.36333 12.5194C6.65 12.7501 7.06933 12.7048 7.30067 12.4181L10.5193 8.4181C10.6173 8.2961 10.6667 8.1481 10.6667 8.0001Z"
                        fill="#42444A"
                      />
                    </svg>
                  </button>
                </>
              )}
            </div>
          </div>
          <Modal
            isOpen={saveDraftModal}
            ariaHideApp={false}
            style={customStyles}
            onRequestClose={closeModal}
          >
            {saveDraftModal && (
              <SaveDraftModalPopUp
                closeModal={setSaveDraftModal}
                onHandleSaveDraft={onHandleSaveDraft}
              />
            )}
          </Modal>
          {/* for duplicate product cards */}
          <Modal
            isOpen={openDuplicateCardModal}
            ariaHideApp={false}
            style={duplicateCardStyles}
            onRequestClose={closeModal}
          >
            <DuplicateCardModal closeModal={setOpenDuplicateCardModal} />
          </Modal>
        </>
      )}
    </>
  );
};
export default ModifyRequest;
