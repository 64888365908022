import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DraftProductCard from '../draftProductCard';

import Modal from 'react-modal';

import './index.css';

// Images
import Close from '../../assets/images/icons/close.svg';
import NoDrafts from '../noDrafts';
import { deleteDraftAction } from '../../store/drafts/drafts-actions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0rem',
    borderRadius: '1.6rem',
    background: '#fff',
    width: '36.9rem',
    boxShadow: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25)'
  }
};

const Drafts = ({ expand, navigateRequest, draftProject }) => {
  const dispatch = useDispatch();
  const { draftProductData } = useSelector((state) => state.draft);
  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState('');

  const onHandleDelete = () => {
    dispatch(deleteDraftAction());
  };

  return (
    <div className="draft-container">
      {draftProductData && draftProductData.length !== 0 ? (
        <div className={expand ? 'draft-wrapper' : 'draft-wrapper active'}>
          <div className="draft-header">
            <div className="draft-header-wrapper">
              <h1>{draftProject}</h1>
              <span>Last modified: A week ago</span>
            </div>
            <div className="draft-button-wrapper">
              <button
                className="btn-cancel"
                onClick={() => {
                  setModalOpen(true);
                  setDeleteRequest(draftProductData);
                }}
              >
                Delete
              </button>
              <button
                className="btn-edit"
                onClick={() => navigateRequest(draftProductData)}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="draft-body">
            {draftProductData && draftProductData.length !== 0
              ? draftProductData.request_data.map((item) => {
                  return <DraftProductCard cardData={item} />;
                })
              : null}
          </div>
        </div>
      ) : (
        <NoDrafts expand={expand} />
      )}
      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        style={customStyles}
        onRequestClose={() => setModalOpen(false)}
      >
        <div className="save-draft-sections">
          <div className="save-draft-header">
            <label>Delete Draft</label>
            <span
              className="save-draft-close"
              onClick={() => setModalOpen(false)}
            >
              <img src={Close} alt="close" />
            </span>
          </div>
          <p className="save-draft-para">
            Do you really want to delete {draftProductData?.name} ? This process
            cannot be undone.
          </p>
          <div className="save-draft-btn">
            <button className="btn discard" onClick={() => setModalOpen(false)}>
              Cancel
            </button>
            <button
              className="btn draft-delete"
              onClick={() => onHandleDelete()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Drafts;
