import React from 'react';
import Modal from 'react-modal';
import './index.css';

const DeleteAoiModal = ({
  aoi,
  isOpen,
  trianglePosition,
  closeModal,
  handleDelete,
  customModalStyles
}) => {
  return (
    <Modal
      isOpen={isOpen}
      style={customModalStyles}
      ariaHideApp={false}
      onRequestClose={closeModal}
    >
      <div className="delete-aoi-modal-content">
        <div
          className={
            trianglePosition === 'top' ? 'triangle' : 'bottom-triangle'
          }
        ></div>
        <div className="delete-aoi-modal-body">
          <div className="delete-aoi-modal-title">
            Are you sure you want to delete the Shapefile
            <span className="aoi-name-text"> “{`${aoi?.name}`}”</span>?
          </div>
          <div className="delete-aoi-modal">
            <button
              className="btn cancel-delete-modal-button"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="btn delete-aoi-modal-button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAoiModal;
