import React from 'react';
import ReactDOM from 'react-dom';
import Routers from './routes';
import * as Sentry from '@sentry/react';
import './assets/styles/common.css';
import './assets/styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import { datadogRum } from '@datadog/browser-rum';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Redux
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';

if (process.env.NODE_ENV !== 'development') {
  // DataDog
  datadogRum.init({
    applicationId: 'db5986ce-4550-4f2f-8ce9-34166d1ff6c0',
    clientToken: 'pub4b939ac884739b197b86c634fed52ade',
    // site refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'sparta-d3-dashboard',
    env: 'sparta-production',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  // Sentry;
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.05, // Capture 5% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = (ReactDOM as any).createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routers />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
