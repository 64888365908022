import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RegionState {
  aoiUploadingStatusList: string[];
}

const initialState: RegionState = {
  aoiUploadingStatusList: []
};

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    addAoiUploadStatus(state, action: PayloadAction<string[]>) {
      state.aoiUploadingStatusList = [
        ...state.aoiUploadingStatusList,
        ...action.payload
      ];
    },
    removeAoiUploadStatus(state, action: PayloadAction<string[]>) {
      state.aoiUploadingStatusList = state.aoiUploadingStatusList.filter(
        (aoi) => !action.payload.includes(aoi)
      );
    }
  }
});

export const regionAction = regionSlice.actions;

export default regionSlice.reducer;
