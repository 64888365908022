export interface User {
  id: number;
  name: string;
  email: string;
  is_verified: boolean;
  organization_id: string;
  resource_role_id: number;
  last_login: string;
  is_active: boolean;
}

export class UserList {
  private userList: User[];

  constructor(userList: User[] = []) {
    this.userList = userList;
  }

  public getAllUsers(): User[] {
    return this.userList;
  }

  public filter(predicate: (item: User) => boolean): User[] {
    return this.userList.filter(predicate);
  }
}
