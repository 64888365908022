import React, { Dispatch, SetStateAction } from 'react';
import TablePagination from '@mui/material/TablePagination';
import styles from './index.module.scss';
import { User } from '@sentry/react';

interface PaginationState {
  page_no: number;
  page_size: number;
}

interface PaginationProps {
  pagination: PaginationState;
  filteredData: User[];
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const Pagination: React.FC<PaginationProps> = ({
  pagination,
  filteredData,
  handlePageChange,
  handleRowsPerPageChange
}) => {
  const dataLength = filteredData ? filteredData.length : 0;

  return (
    <div className={styles.pagination_wrapper}>
      <div className={styles.pagination_container}>
        <TablePagination
          className={styles.user_pagination}
          page={pagination.page_no - 1}
          count={dataLength}
          rowsPerPageOptions={[10, 20, 50]}
          rowsPerPage={pagination.page_size}
          onPageChange={handlePageChange} // Ensure the function signature matches
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );
};

export default Pagination;
