import React from 'react';
import DatePicker from 'react-datepicker';
import CustomDateInput from '../customDateInput/customDateInput';
import { isValidNameFormat } from '../../utils/function-helper';

import closeIcon from '../../assets/images/icons/close.svg';
import Close from '../../assets/images/icons/close_small.svg';
import Upload from '../../assets/images/icons/upload.svg';

import '../createProject/createProjectModal.css';

const ProjectDetails = ({
  onCloseModal,
  projectDetails,
  canUpdateProject,
  setProjectDetails,
  handleFileChange,
  handleRemoveFile,
  setSteps
}) => {
  return (
    <>
      <div className="modal-header">
        <p>Project Details</p>
        <img
          src={closeIcon}
          alt="close icon"
          onClick={() => {
            onCloseModal('');
          }}
        />
      </div>
      <div className="modal-body">
        <div className="project-name-sections">
          <label>
            Project Name<sup>*</sup>
          </label>
          <input
            type="text"
            className="input-box"
            disabled={!canUpdateProject}
            value={projectDetails.projectName}
            data-testid="project-name"
            onChange={(e) => {
              setProjectDetails({
                ...projectDetails,
                projectName: e.target.value
              });
            }}
          />
          {!isValidNameFormat(projectDetails?.projectName) &&
            projectDetails?.projectName !== 0 && (
              <p>Only alphanumeric characters allowed</p>
            )}
        </div>
        <div className="project-name-sections">
          <label>
            Project Due Date<sup>*</sup>
          </label>
          <div
            className={`date-picker-input project-due-date-box  ${!canUpdateProject ? `disabled-due-date` : ``}`}
          >
            <DatePicker
              placeholderText="Select due date"
              customInput={<CustomDateInput />}
              selected={projectDetails.projectDueDate}
              className="date-picker"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              disabled={!canUpdateProject}
              dateFormat={'dd/MM/yyyy'}
              onChange={(date) => {
                setProjectDetails({
                  ...projectDetails,
                  projectDueDate: date
                });
              }}
              minDate={new Date()}
            />
          </div>
        </div>

        <div className="project-upload-sections">
          <label>Files</label>
          <span
            className={`upload-project ${!canUpdateProject ? `disabled-upload-project` : ``}`}
            onClick={() => document.getElementById('pdfUpload').click()}
          >
            <label htmlFor="pdfUpload">
              <button>Upload files</button>
            </label>
            <input
              type="file"
              id="pdfUpload"
              accept=".pdf"
              style={{ display: 'none' }}
              disabled={!canUpdateProject}
              onChange={(e) => handleFileChange(e)}
              data-testid="pdfUpload"
            />
            <img src={Upload} alt="Upload" />
          </span>
          {projectDetails &&
            projectDetails.uploadedFiles &&
            projectDetails.uploadedFiles.length > 0 && (
              <div className="uploaded-files">
                <ul>
                  {projectDetails.uploadedFiles.length !== 0 &&
                    projectDetails.uploadedFiles.map((file, index) => (
                      <>
                        <li key={index}>
                          {file.name}
                          {canUpdateProject && (
                            <img
                              src={Close}
                              alt="Close"
                              onClick={() => handleRemoveFile(index)}
                              className="close-icon"
                            />
                          )}
                        </li>
                      </>
                    ))}
                </ul>
              </div>
            )}
        </div>
        <div className="project-description-sections">
          <label>Description</label>
          <textarea
            name="freeform"
            rows="5"
            cols="8"
            disabled={!canUpdateProject}
            value={projectDetails.description}
            className={` ${!canUpdateProject ? `disabled-due-date` : ``}`}
            placeholder="Description"
            onChange={(e) => {
              setProjectDetails({
                ...projectDetails,
                description: e.target.value
              });
            }}
            data-testid="project-desc"
          ></textarea>
        </div>
      </div>
      <div className="create-step-btn">
        <div>
          <label className="steps">1/2</label>
        </div>
        <div className="create-step">
          <button
            className="btn btn-cancel-discard"
            onClick={() => onCloseModal()}
          >
            Skip
          </button>
          <button
            className="create-btn"
            onClick={() => setSteps(2)}
            disabled={!isValidNameFormat(projectDetails?.projectName)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
