import React, { useEffect } from 'react';

//SCSS
import styles from './index.module.scss';

// Images
import zoom_in from '../../assets/images/icons/zoom-in.svg';
import zoom_out from '../../assets/images/icons/zoom-out.svg';
import fullScreen from '../../assets/images/icons/fullscreen.svg';
import returnFullScreen from '../../assets/images/icons/returnFullScreen.svg';

const MapControls = ({ map, isFullScreen, setIsFullScreen }) => {
  const mapZoom = (zoomValue) => {
    const view = map.getView();
    const currentZoom = map.getView().getZoom();
    view.animate({
      zoom: currentZoom + zoomValue,
      duration: 300
    });
  };

  const handleFullScreen = () => {
    const element = map?.getTargetElement();
    if (!isFullScreen) {
      if (element?.requestFullscreen) {
        element.requestFullscreen();
      } else if (element?.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element?.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      // Exit full screen mode
      if (document?.exitFullscreen) {
        document?.exitFullscreen();
      } else if (document?.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document?.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (map) map.updateSize();
    };

    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <ul className={styles.map_controls_container}>
      <li className={`${styles.map_control_list} ${styles.zoom_in}`}>
        <div className={styles.map_control_icons}>
          <img onClick={() => mapZoom(1)} src={zoom_in} alt="zoom-in" />
        </div>
        <div className={styles.control_hover}>
          <label>Zoom in</label>
          <span></span>
        </div>
      </li>
      <li className={`${styles.map_control_list} ${styles.zoom_out}`}>
        <div className={styles.map_control_icons}>
          <img onClick={() => mapZoom(-1)} src={zoom_out} alt="zoom-out" />
        </div>
        <div className={styles.control_hover}>
          <label>Zoom out</label>
          <span></span>
        </div>
      </li>

      {/* <li className={`${styles.map_control_list} ${styles.layer_opacity}`}>
        <div className={styles.map_control_icons}>
          <img src={layerOpacity} alt="opacity" />
        </div>
        <div className={styles.control_hover}>
          <label>Opacity</label>
          <span></span>
        </div>
      </li> */}
      <li className={`${styles.map_control_list} ${styles.full_screen}`}>
        <div className={styles.map_control_icons}>
          <img
            onClick={() => handleFullScreen()}
            src={isFullScreen ? returnFullScreen : fullScreen}
            alt={isFullScreen ? 'Exit full screen' : 'Full Screen'}
          />
        </div>
        <div className={styles.control_hover}>
          <label>{isFullScreen ? 'Exit full screen' : 'Full Screen'}</label>
          <span></span>
        </div>
      </li>
    </ul>
  );
};

export default MapControls;
