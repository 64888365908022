import {
  deleteRequest
  //  getRequest, postRequest
} from '../utils/http-helper';
// import { DRAFTS_URL } from '../utils/url-helper';
import productData from '../assets/sample.json';

//Ignore it
//after added URL it will remove it
const projectData = {
  data: {
    data: [
      {
        id: '1234',
        name: 'Project-1'
      }
    ]
  },
  status: 200
};

const requestData = {
  data: {
    id: 'project-id',
    requests: [
      {
        id: 'request-id-1',
        name: 'request-name-1',
        created_dttm: '',
        updated_dttm: ''
      },
      {
        id: 'request-id-2',
        name: 'request-name-2',
        created_dttm: '',
        updated_dttm: ''
      },
      {
        id: 'request-id-3',
        name: 'request-name-3',
        created_dttm: '',
        updated_dttm: ''
      }
    ]
  },
  status: 200
};

export const getDraftTreeData = async () => {
  try {
    // return await getRequest({
    //   url: DRAFTS_URL
    // });
    return projectData;
  } catch (e) {
    return e;
  }
};

export const getDraftRequestData = async (id) => {
  try {
    // return await getRequest({
    //   url:""
    // })
    return requestData;
  } catch (e) {
    return e;
  }
};

export const getDraftProductData = async (id) => {
  try {
    // return await getRequest({
    //   url:""
    // })
    return { data: productData, status: 200 };
  } catch (e) {
    return e;
  }
};

export const getDraftEdit = async (payload) => {
  try {
    // return await postRequest({
    //   url: '',
    //   data: payload
    // });
  } catch (e) {
    return e;
  }
};

export const deleteDraft = async () => {
  try {
    return await deleteRequest({
      url: ''
    });
  } catch (e) {
    return e;
  }
};
