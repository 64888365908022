import React from 'react';
import calendar from '../../assets/images/icons/calendar.svg';

const CustomDateInput = ({ value, onClick, onChange, ref }) => (
  <div className="custom-date-input" onClick={onClick}>
    <input
      placeholder="Select due date"
      value={value}
      onChange={onChange}
      ref={ref}
    />
    <img src={calendar} alt="calendar" />
  </div>
);

export default CustomDateInput;
