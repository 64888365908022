import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConfirmRequest from '../confirmRequest';
import ModifyRequestCard from '../../components/modify-request-card';
import ProjectModule from '../../components/projectModule';

// Images
import Drag from '../../assets/images/icons/Drag.svg';

// CSS
import './index.css';

//helper
import {
  MIN_DRAWER_HEIGHT,
  MID_DRAWER_HEIGHT,
  MAX_DRAWER_HEIGHT
} from '../../utils/constants';

const CustomDrawer = ({
  projectTreeListData,
  getTreeQuantityId,
  setSelectedButton,
  selectedDataProducts,
  getRegionAoiList,
  aois,
  selectedAOIs,
  setSelectedDataProducts,
  setDrawerHeight,
  drawerHeight,
  setSelectedAOIs,
  historyAOIs,
  setHistoryAOIs,
  setAOIs,
  setExpand,
  expand,
  setIsButtonClicked,
  parentAoiId,
  setReCallQuantity,
  setCustomDrawerHeight,
  setBackPressed,
  setZoomInTreePolygon
}) => {
  const location = useLocation();
  const { isExploreOpen } = useSelector((state) => state?.request);
  const [requestButtonDisabled, setRequestButtonDisabled] = useState(true);
  const [confirmRequest, setConfirmRequest] = useState(false);

  const handleClick = () => {
    if (drawerHeight === MIN_DRAWER_HEIGHT) {
      setDrawerHeight(MID_DRAWER_HEIGHT);
    } else if (drawerHeight === MID_DRAWER_HEIGHT) {
      setDrawerHeight(MAX_DRAWER_HEIGHT);
    } else {
      setDrawerHeight(MIN_DRAWER_HEIGHT);
    }
  };

  useEffect(() => {
    setConfirmRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (confirmRequest) {
      setDrawerHeight(MID_DRAWER_HEIGHT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmRequest]);

  useEffect(() => {
    setConfirmRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={expand ? 'drawer-section' : 'drawer-section active'}>
      <div
        className="resizable"
        style={{ height: `${drawerHeight}vh`, transition: 'height 0.3s ease' }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            className="draggable"
            id="Draggable"
            draggable="true"
            onClick={() => handleClick()}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleClick();
              }
            }}
            tabIndex="0"
            data-tooltip={
              drawerHeight === MIN_DRAWER_HEIGHT ||
              drawerHeight === MID_DRAWER_HEIGHT
                ? 'Expand panel'
                : 'Collapse panel'
            }
          >
            <label>
              <img src={Drag} alt="drag" />
            </label>
            <span className="tooltip">
              {drawerHeight === MIN_DRAWER_HEIGHT ||
              drawerHeight === MID_DRAWER_HEIGHT
                ? 'Expand panel'
                : 'Collapse panel'}
            </span>
          </div>
        </div>
        <div
          data-testid="draggable-content"
          className={confirmRequest ? 'draggable-content' : null}
        >
          <div
            className={`modify-card-screen ${
              confirmRequest ? 'width-70' : 'width-100'
            }`}
          >
            <div className="dragable-body">
              {isExploreOpen && location.pathname === '/d3' && (
                <ModifyRequestCard
                  setSelectedButton={setSelectedButton}
                  modifyCard={selectedDataProducts}
                  selectedAOIs={selectedAOIs}
                  setModifyCard={setSelectedDataProducts}
                  aois={aois}
                  setConfirmRequest={setConfirmRequest}
                  confirmRequest={confirmRequest}
                  drawerHeight={drawerHeight}
                  requestButtonDisabled={requestButtonDisabled}
                  setRequestButtonDisabled={setRequestButtonDisabled}
                  setExpand={setExpand}
                  expand={expand}
                  setIsButtonClicked={setIsButtonClicked}
                />
              )}
              {location.pathname.includes('/d3/browse') && (
                <ProjectModule
                  projectTreeListData={projectTreeListData}
                  setReCallQuantity={setReCallQuantity}
                  setZoomInTreePolygon={setZoomInTreePolygon}
                  getTreeQuantityId={getTreeQuantityId}
                  getRegionAoiList={getRegionAoiList}
                  setDrawerHeight={setDrawerHeight}
                  parentAoiId={parentAoiId}
                  expand={expand}
                  drawerHeight={drawerHeight}
                  setBackPressed={setBackPressed}
                />
              )}
            </div>
          </div>
          {confirmRequest && (
            <div className="confirmRequest">
              <ConfirmRequest
                setConfirmRequest={setConfirmRequest}
                selectedDataProducts={selectedDataProducts}
                selectedAOIs={selectedAOIs}
                setSelectedDataProducts={setSelectedDataProducts}
                setSelectedButton={setSelectedButton}
                setRequestButtonDisabled={setRequestButtonDisabled}
                setSelectedAOIs={setSelectedAOIs}
                aois={aois}
                historyAOIs={historyAOIs}
                setHistoryAOIs={setHistoryAOIs}
                setAOIs={setAOIs}
                setIsButtonClicked={setIsButtonClicked}
                setExpand={setExpand}
                setCustomDrawerHeight={setCustomDrawerHeight}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomDrawer;
