import { postRequest } from '../utils/http-helper';
import { PRODUCT_AVAILABLE_DATE_URL } from '../utils/url-helper';

export const getProductAvailableDates = async (productId, payload) => {
  try {
    return await postRequest({
      url: `${PRODUCT_AVAILABLE_DATE_URL}/${productId}/d3-available-dates`,
      data: payload,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};
