import React from 'react';
import { components } from 'react-select';
import { getNameInitials } from '../../utils/function-helper';
import Profile from '../../assets/images/icons/profile-button.svg';

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    <div className="project-member-option-div">
      {props.data.value === '' ? (
        <img
          src={Profile}
          alt=""
          style={{ marginRight: '0.5rem', width: '3rem' }}
        />
      ) : (
        <div className="full-name-avatar project-member-avatar">
          <p>{getNameInitials(props.data.label)}</p>
        </div>
      )}

      <div
        className={props.data.value === '' && `project-member-option-details`}
      >
        <p className="project-member-option-label">{props.data.label}</p>
        <p
          data-testid="project-mem-email"
          className="project-member-option-email"
        >
          {props.data.email}
        </p>
      </div>
    </div>
  </Option>
);

export default IconOption;
