import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../types';

// COMPONENTS
import { UserStatusToggle, UserActionsDropdown } from './userToggleBtns';

// SCSS
import styles from './index.module.scss';

// IMAGES
import { images } from '../../assets/images';

// HELPER
import { getNameInitials, getLastSeen } from '../../utils/function-helper';
import { getRoleName, roleOptions } from '../../utils/constants';

// SERVICES
import { updateUser } from '../../services/setting-services';
import { fetchUserData } from '../../store/userManage/user-actions';
import { UserTableProps } from '../../types/user.types';

const UserTable: React.FC<UserTableProps> = ({
  usersData,
  hasPermission,
  handleEdit
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { usersDetailsData } = useSelector((state: RootState) => state.user);

  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);

  const formatRoleOptions = (userRoleId: number) => {
    return roleOptions
      .filter((option) => option.id !== userRoleId)
      .map((option) => ({
        label: getRoleName(option.id),
        value: option.id
      }));
  };

  const handleRoleChange = async (
    selectedOption: any,
    index: number,
    userRoleId: number
  ) => {
    const userId = usersData[index].id;
    const roleId = selectedOption.value;

    try {
      const response = await updateUser(userId, { role_id: roleId });
      const { data, status } = response as { data: any; status: number };

      if (status === 200) {
        const newSelectedRoles = [...selectedRoles];
        newSelectedRoles[index] = selectedOption;
        setSelectedRoles(newSelectedRoles);
        dispatch(fetchUserData());

        const filteredOptions = formatRoleOptions(userRoleId);
        if (!filteredOptions.find((option) => option.value === roleId)) {
          filteredOptions.push(selectedOption);
        }

        toast.success(data?.message);
      } else {
        toast.error(data?.message || 'Failed to update role');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  };

  useEffect(() => {
    setSelectedRoles(
      usersData
        .map((userDetails) => {
          const defaultRoleOption = roleOptions.find(
            (option) => option.id === userDetails.resource_role_id
          );
          const isCurrentUser =
            userDetails.email === usersDetailsData?.userEmail;
          const youLabel = isCurrentUser ? ' (you)' : '';
          return defaultRoleOption
            ? {
                label: getRoleName(defaultRoleOption.id) + youLabel,
                value: defaultRoleOption.id
              }
            : null;
        })
        .filter(Boolean)
    );
  }, [usersData, usersDetailsData]);

  return (
    <table className={styles.user_table}>
      <thead className={styles.user_table_head}>
        <tr className={styles.user_table_header}>
          <th className={`${styles.width_20} ${styles.padding_left}`}>NAME</th>
          {hasPermission && (
            <th className={` ${styles.width_20}`}>LAST ACTIVE</th>
          )}
          <th className={` ${styles.width_30}`}>EMAIL ADDRESS</th>
          <th className={` ${styles.width_20} ${styles.padding_left}`}>
            ROLE
            <span className={styles.role_icon}>
              <div className={styles.remove_icon}>
                <span>
                  <img src={images.helpCircleIcon} alt="Role Icon" />
                </span>
                <div className={styles.remove_icon_tooltip}>
                  Super admin hold the highest level of access and control.
                </div>
              </div>
            </span>
          </th>
          {hasPermission && (
            <>
              <th
                className={` ${styles.width_10} ${styles.table_data_align} ${styles.padding_left}`}
                data-testId="user-status-toggle"
              >
                STATUS
              </th>
              <th></th>
            </>
          )}
        </tr>
      </thead>
      <tbody className={styles.user_table_body}>
        {usersData.map((userDetails, index) => (
          <tr key={userDetails.id} className={styles.table_data}>
            <td className={`${styles.name} ${styles.width_20}`}>
              <div className={styles.initial_main_block}>
                <div
                  data-testId="user-initials"
                  data-initials={getNameInitials(userDetails?.name)}
                ></div>
                <div className={styles.initial_name}>{userDetails.name}</div>
              </div>
            </td>
            {hasPermission && (
              <td className={`${styles.last_active} ${styles.width_20}`}>
                {getLastSeen(userDetails?.last_login)}
              </td>
            )}
            <td className={`${styles.filter_label} ${styles.width_30}`}>
              {userDetails.email}
            </td>
            <td className={`${styles.filter_label} ${styles.width_20}`}>
              {hasPermission ? (
                <Select
                  classNamePrefix={'react-select-user'}
                  data-testId="role-dropdown"
                  value={selectedRoles[index] || null}
                  onChange={(selectedOption) =>
                    handleRoleChange(
                      selectedOption,
                      index,
                      userDetails.resource_role_id
                    )
                  }
                  options={formatRoleOptions(userDetails.resource_role_id)}
                />
              ) : (
                <div>
                  {getRoleName(userDetails.resource_role_id)}{' '}
                  {userDetails.email === usersDetailsData?.userEmail && (
                    <span>(you)</span>
                  )}
                </div>
              )}
            </td>

            {hasPermission && (
              <>
                <UserStatusToggle
                  usersData={usersData}
                  index={index}
                  userDetails={userDetails}
                />
                <UserActionsDropdown
                  handleEdit={() => handleEdit(userDetails.id.toString())}
                  userId={userDetails.id}
                />
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserTable;
