import { getRequest, postRequest, putRequest } from '../utils/http-helper';
import {
  PRODUCT_URL,
  AVAILABLE_PRODUCT_URL,
  REGIONS_URL,
  CREATE_REQUEST_URL,
  CREATE_PROJECT_URL,
  GET_ALL_USERS_URL,
  GET_ALL_PROJECTS_URL,
  GET_FILES_NUMBER_URL,
  PRE_SIGNED_URL,
  PRE_SIGNED_DOWNLOAD_URL,
  CHECK_REQUEST_PERMISSION_URL,
  EXTRACTION_DOWNLOAD,
  UPDATE_PROJECT,
  UPDATE_PROJECT_USERS
  // GET_LIST_OF_FILES,
  // DRAFTS_URL,
} from '../utils/url-helper';

export const getProducts = async () => {
  try {
    return await getRequest({
      url: PRODUCT_URL,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getAvailableProducts = async (payload) => {
  try {
    return await postRequest({
      data: payload,
      url: AVAILABLE_PRODUCT_URL,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getNumberOfFiles = async (payload) => {
  try {
    return await postRequest({
      data: payload,
      url: GET_FILES_NUMBER_URL,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getRegionsList = async (url) => {
  try {
    return await getRequest({
      url: url,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getRegions = async (param) => {
  try {
    return await getRequest({
      url: REGIONS_URL.concat('?search_param=' + param),
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getRegionsGeometry = async (id) => {
  try {
    return await getRequest({
      url: `${REGIONS_URL}?region_id=${id}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const postCreateRequest = async (payload) => {
  try {
    return await postRequest({
      data: payload,
      url: CREATE_REQUEST_URL,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const postCreateProject = async (payload) => {
  try {
    return await postRequest({
      data: payload,
      url: CREATE_PROJECT_URL,
      headers: true,
      Auth: true,
      isToken: true
    });
  } catch (e) {
    return e;
  }
};

export const getAllUsers = async () => {
  try {
    return await getRequest({
      url: GET_ALL_USERS_URL,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getAllProjects = async () => {
  try {
    return await getRequest({
      url: GET_ALL_PROJECTS_URL,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getSignedUrl = async (fileName, parentAoiId) => {
  try {
    return await getRequest({
      url: `${PRE_SIGNED_URL}?file_name=d3/${fileName}&request_id=${parentAoiId}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getSignedDownloadUrl = async (tifPathName) => {
  try {
    return await getRequest({
      url: `${PRE_SIGNED_DOWNLOAD_URL}?file_name=d3/${tifPathName}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const uploadTiffFile = async (link, payload) => {
  try {
    return await postRequest({
      url: link,
      headers: true,
      Auth: true,
      isToken: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const onCheckRequestPermisson = async (id) => {
  try {
    return await getRequest({
      url: `${CHECK_REQUEST_PERMISSION_URL}/${id}/request`,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const onGetDownloadExtraction = async (id) => {
  try {
    return await getRequest({
      url: `${EXTRACTION_DOWNLOAD}?aoi_request_id=${id}`,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const updateProjectDetails = async (formData, projectId) => {
  try {
    return await putRequest({
      url: `${UPDATE_PROJECT}/${projectId}`,
      headers: true,
      Auth: true,
      data: formData,
      isToken: true
    });
  } catch (e) {
    return e;
  }
};

export const updateProjectUsers = async (payload, projectId) => {
  try {
    return await putRequest({
      url: `${UPDATE_PROJECT_USERS}/${projectId}`,
      headers: true,
      Auth: true,
      data: payload,
      isToken: false
    });
  } catch (e) {
    return e;
  }
};
