import React from 'react';

const styles = {
  progressParent: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: 40,
    height: 3,
    border: '0.0648rem solid #2F80ED'
  },
  progressChild: {
    textAlign: 'right',
    height: '100%',
    width: '100%',
    backgroundColor: '#2F80ED'
  }
};

const ProgressBar = ({ color, progress }) => {
  return (
    <div
      style={{ ...styles.progressParent, border: `0.0648rem solid ${color}` }}
    >
      <div
        style={{
          ...styles.progressChild,
          width: `${progress}%`,
          backgroundColor: color
        }}
      />
    </div>
  );
};

export default ProgressBar;
