import React, { useRef, useState } from 'react';
//SCSS
import styles from '../../containers/catalog/index.module.scss';

type DataType = 'request' | 'response';

interface RequestResponseDataProps {
  dataType: DataType;
  product: any;
}

const RequestResponseData: React.FC<RequestResponseDataProps> = ({
  dataType,
  product
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const responseBodyRef = useRef<HTMLDivElement>(null);
  const handleCopy = () => {
    if (responseBodyRef.current) {
      const textToCopy = responseBodyRef.current.textContent ?? '';
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((err) => {
          console.error('Error copying text: ', err);
        });
    }
  };
  return (
    <li className={styles.api_access_response}>
      <div className={styles.response_header}>
        <p className={styles.response_header_content}>
          {dataType === 'request' ? 'Request' : 'Response'}
        </p>
        <p
          onClick={handleCopy}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleCopy();
            }
          }}
          tabIndex={0}
        >
          {copied ? 'Copied' : 'Copy'}
        </p>
      </div>
      <div className={styles.response_body} ref={responseBodyRef}>
        <pre>
          {dataType === 'request' ? (
            <code>{product?.api_data[dataType]}</code>
          ) : (
            JSON.stringify(product?.api_data[dataType], null, 2)
          )}
        </pre>
      </div>
    </li>
  );
};

export default RequestResponseData;
