import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Fuse from 'fuse.js';
import { toast } from 'react-toastify';
import SelectPicker from '../select-picker';
import Modal from 'react-modal';

// Services
import { getRegionsList } from '../../services/products-service';
import { RID_LIST_URL } from '../../utils/url-helper';

// Components
// import Pagination from '../pagination/pagination';

//Images
import { images } from '../../assets/images';
import Search from '../../assets/images/sidebar/search.svg';
import Copy from '../../assets/images/icons/copy-black.svg';

// SCSS
import styles from './index.module.scss';

interface RegionData {
  region_id: string;
  region_level: string;
  name: string;
  parent_name: string;
}

interface RegionFilter {
  regionLevel: string;
  data: RegionData[];
}

// interface Pagination {
//   page_no: number;
//   page_size: number;
// }

interface RIDListData {
  [key: string]: string;
}

interface CheckedRIDListItem {
  name: string;
  id: string;
}

const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0rem',
    borderRadius: '1.6rem',
    background: '#fff',
    boxShadow: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25)',
    width: '80%',
    height: '85%'
  }
};

const ListRid: React.FC<{
  getRegionsSelectedRids: (checkedRegionIds: CheckedRIDListItem[]) => void;
  isOpenRidList: boolean;
  isCloseRidLIstModal: () => void;
}> = ({ getRegionsSelectedRids, isOpenRidList, isCloseRidLIstModal }) => {
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<RegionFilter[]>([]);
  const [regionTableHeaderData, setRegionTableHeaderData] = useState<{
    countryRid: string[];
  }>({ countryRid: [] });
  const [regionTableData, setRegionTableData] = useState<
    RegionData[] | undefined
  >();
  const [ridListData, setRidListData] = useState<RIDListData[]>([
    { Country: 'india' }
  ]);
  const [allRegionTableData, setAllRegionTableData] = useState<
    RegionData[] | undefined
  >(undefined);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  // const [pagination, setPagination] = useState<Pagination>({
  //   page_no: 1,
  //   page_size: 10
  // });
  const [checkedRegionIds, setCheckedRegionIds] = useState<
    CheckedRIDListItem[]
  >([]);

  useEffect(() => {
    fetchRegionDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Country
  const fetchRegionDataList = async () => {
    let urlLink = RID_LIST_URL;
    const response = await getRegionsList(urlLink);
    const { data, status } = response as { data: any; status: number };
    if (status === 200) {
      setRegionTableHeaderData({
        ...regionTableHeaderData,
        countryRid: ['State', 'rid']
      });
      const filterData: RegionFilter = {
        regionLevel: data?.data[0]?.region_level ?? '',
        data: data?.data ?? []
      };
      setFilters([...filters, filterData]);
      setRegionTableData(data?.data);
      setAllRegionTableData(data?.data);
    }
  };

  const selectonChangeOptions = (
    e: { value: string; label: string; regionLevel: string },
    regionLevel: string
  ) => {
    let urlLink = `${RID_LIST_URL}?region_id=${e.value}`;
    listFunction(urlLink, regionLevel, e.label);
  };

  const onChageRidList = (ridList: RegionData, regionLevel: string) => {
    let urlLink = `${RID_LIST_URL}?region_id=${ridList.region_id}`;
    listFunction(urlLink, regionLevel, ridList.name);
  };

  const getBreadcrumbsFlow = (filter: RegionFilter, index: number) => {
    setRegionTableData(filter?.data);
    setAllRegionTableData(filter?.data);
    setRegionTableHeaderData({
      ...regionTableHeaderData,
      countryRid: [filter?.data[0]?.region_level, 'rid']
    });
    let slicedArray = filters.slice(0, index + 1);
    setFilters(slicedArray);
  };

  const listFunction = async (
    urlLink: string,
    regionLevel: string,
    regionLevelValue: string
  ) => {
    const filterData = [...filters];
    const copiedRidList = [...ridListData];
    const response = await getRegionsList(urlLink);
    const { data, status, error } = response as {
      data: any;
      status: number;
      error?: any;
    };
    // setPagination({
    //   page_no: 1,
    //   page_size: 10
    // });
    const updatedRegionIndex = ridListData.findIndex(
      (item) => Object.keys(item)[0] === regionLevel
    );
    if (updatedRegionIndex === -1) {
      setRidListData([...copiedRidList, { [regionLevel]: regionLevelValue }]);
    } else {
      copiedRidList.splice(updatedRegionIndex);
      copiedRidList.push({
        [regionLevel]: regionLevelValue
      });
      setRidListData(copiedRidList);
    }
    if (status === 200) {
      setSearch('');
      setRegionTableHeaderData({
        ...regionTableHeaderData,
        countryRid: [data?.data[0]?.region_level, 'rid']
      });

      const updatedIndex = filters.findIndex(
        (item) => item.regionLevel === regionLevel
      );
      filterData.splice(updatedIndex + 1, filterData.length);
      filterData.push({
        regionLevel: data?.data[0]?.region_level,
        data: data?.data
      });
      setFilters([...filterData]);
    }
    setRegionTableData(data?.data);
    setAllRegionTableData(data?.data);
    if (error) {
      toast.error(error?.message);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value !== '') {
      const options = {
        keys: ['name', 'region_id'],
        includeScore: true,
        includeMatches: true,
        threshold: 0.0
      };

      if (allRegionTableData) {
        const fuse = new Fuse(allRegionTableData, options);
        const result = fuse.search(e.target.value);
        setRegionTableData(result.map((item) => item.item));
      }
    } else {
      setRegionTableData(allRegionTableData || []);
    }
  };

  // Clear
  const clearFilter = () => {
    setSearch('');
    // setPagination({
    //   page_no: 1,
    //   page_size: 10
    // });
    setCheckedRegionIds([]);
    setRegionTableHeaderData({
      ...regionTableHeaderData,
      countryRid: ['State', 'rid']
    });
    let filterData = [...filters];
    let fil = filterData.slice(0, 1);
    setFilters([...fil]);
    setRegionTableData(fil[0]?.data);
    setAllRegionTableData(fil[0]?.data);
    setRidListData([{ Country: 'india' }]);
  };

  const handleCheckboxClick = (regionId: string, regionName: string) => {
    const updatedCheckedRegionIds = [...checkedRegionIds];
    const index = updatedCheckedRegionIds.findIndex(
      (updatedCheckedRegionId) => updatedCheckedRegionId.id === regionId
    );

    if (index !== -1) {
      updatedCheckedRegionIds.splice(index, 1);
    } else {
      updatedCheckedRegionIds.push({
        name: regionName,
        id: regionId
      });
    }

    setCheckedRegionIds(updatedCheckedRegionIds);
  };

  const handleHeaderCheckboxClick = () => {
    if (checkedRegionIds.length === (regionTableData?.length ?? 0)) {
      setCheckedRegionIds([]);
    } else {
      const allRegionIds =
        regionTableData?.map((item) => ({
          id: item.region_id,
          name: item.name
        })) ?? [];
      setCheckedRegionIds(allRegionIds);
    }
  };

  /**
   * Handles the page change in pagination.
   * @param event - The event object.
   * @param newPage - The new page number.
   */
  // const handlePageChange = (
  //   event: React.ChangeEvent<unknown>,
  //   newPage: number
  // ) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     page_no: newPage + 1
  //   }));
  // };

  /**
   * Handles the change in number of rows per page.
   * @param event - The event object.
   */
  // const handleRowsPerPageChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const newSize = parseInt(event.target.value, 10);
  //   const newPageNo =
  //     Math.floor(((pagination.page_no - 1) * pagination.page_size) / newSize) +
  //     1;
  //   setPagination({
  //     page_no: newPageNo,
  //     page_size: newSize
  //   });
  // };

  // const startIndex = (pagination.page_no - 1) * pagination.page_size;
  // const endIndex = startIndex + pagination.page_size;
  // const paginatedData = regionTableData?.slice(startIndex, endIndex);

  const tableDynamic = (listOfRidsData: RegionData[] | undefined) => {
    if (listOfRidsData && listOfRidsData.length > 0) {
      return listOfRidsData.map((item) => {
        const isState = item.region_level === 'State';

        return (
          <tr className={styles.table_tr} key={item.region_id}>
            <td className={styles.width_15}>
              {!isState && (
                <label className={styles.ridCheckbox}>
                  <input
                    className={styles.checkbox_item}
                    type="checkbox"
                    onChange={() =>
                      handleCheckboxClick(item.region_id, item.name)
                    }
                    checked={checkedRegionIds.some(
                      (checkedRegionId) => checkedRegionId.id === item.region_id
                    )}
                  />
                  <span className={styles.checkbox_mark}></span>
                </label>
              )}
            </td>
            <td
              className={`${styles.list_name} ${styles.width_42}`}
              onClick={() => onChageRidList(item, item.region_level)}
            >
              {item.name}
            </td>
            <td className={styles.width_42}>
              <label>{item.region_id}</label>
              <span
                className={styles.copy_icon}
                onClick={() => copyToClipboard(item.region_id)}
              >
                <img src={Copy} alt="Copy" />
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <div className={styles.no_data_fond}>
          <label>
            <img src={images.fileSearching} alt="Searching" />
          </label>
          <span>Sorry, no results found !</span>
        </div>
      );
    }
  };

  const checkedCount = checkedRegionIds?.length ?? 0;

  return (
    <Modal isOpen={isOpenRidList} style={customStyles} ariaHideApp={false}>
      <div className={styles.rid_list_container}>
        <div className={styles.rid_header}>
          <label>List of RIDs</label>
          <span
            onClick={() => {
              clearFilter();
              isCloseRidLIstModal();
            }}
          >
            <img src={images.closeIcon} alt="Close" />
          </span>
        </div>

        <ul className={styles.country_list}>
          <li>
            <span>Country</span>
          </li>

          {filters.length > 0 &&
            filters.map((filter, index) => {
              if (filter.data.length > 0) {
                const { parent_name } = filter.data[0];
                return (
                  <li
                    key={parent_name}
                    onClick={() => getBreadcrumbsFlow(filter, index)}
                  >
                    <label>
                      <img src={images.rightArrow} alt="arrow" />
                    </label>
                    <span>{parent_name}</span>
                  </li>
                );
              }
              return null;
            })}
        </ul>

        <div className={styles.search_filter_sections}>
          <div className={styles.search_input_sections}>
            <label className={styles.search_icon}>
              <img src={Search} alt="search icon" />
            </label>
            <span>
              <input
                type="search"
                name="search"
                value={search}
                placeholder="Search in current table"
                onChange={(e) => onSearchHandler(e)}
              />
            </span>
          </div>
          <div className={styles.filter_modal}>
            {isFilter && (
              <label
                className={styles.clear_filter}
                onClick={() => {
                  clearFilter();
                }}
              >
                Clear all filters
              </label>
            )}
            <div
              className={styles.filter_btn}
              onClick={() => {
                setIsFilter(!isFilter);
              }}
            >
              <label>Filters</label>
              <span
                className={`${isFilter ? styles.arrow_down : styles.arrow_up}`}
              >
                <img src={images.expandArrow} alt="arrow" />
              </span>
            </div>
          </div>
        </div>

        {isFilter && (
          <ul className={styles.selection_filter_section}>
            <li>
              <label>Country</label>
              <span>
                <Select
                  placeholder="Select country"
                  classNamePrefix={'rid-list react-select'}
                  defaultValue={{ value: 'India', label: 'India' }}
                  isDisabled={true}
                  onChange={() => {}}
                />
              </span>
            </li>

            {filters.length !== 0 &&
              filters.map((filter, index) => {
                const selectedValue = ridListData.find(
                  (item) => Object.keys(item)[0] === filter.regionLevel
                );
                return (
                  filter.data.length !== 0 && (
                    <li key={index}>
                      <label>{filter.regionLevel}</label>
                      <span>
                        <SelectPicker
                          index={filter.regionLevel}
                          keyValue={index}
                          placeHolder={`Select ${filter.regionLevel}`}
                          customClassName={'rid-list'}
                          selectValue={
                            selectedValue?.[filter.regionLevel] || null
                          }
                          selectOptions={filter.data}
                          onChangeOptions={(e: any) =>
                            selectonChangeOptions(e, filter.regionLevel)
                          }
                        />
                      </span>
                    </li>
                  )
                );
              })}
          </ul>
        )}

        <table className={styles.rid_list_table}>
          <thead>
            {regionTableData?.length !== 0 && regionTableData && (
              <tr>
                <th className={styles.width_15}>
                  {regionTableData[0]?.region_level !== 'State' && (
                    <label className={styles.ridCheckbox}>
                      <input
                        className={styles.checkbox_item}
                        onClick={handleHeaderCheckboxClick}
                        type="checkbox"
                        checked={
                          checkedRegionIds.length === regionTableData.length
                        }
                      />
                      <span className={styles.checkbox_mark}></span>
                    </label>
                  )}
                </th>
                {regionTableHeaderData?.countryRid &&
                  regionTableHeaderData?.countryRid?.length !== 0 &&
                  regionTableHeaderData?.countryRid?.map((item, index) => (
                    <th className={styles.width_42} key={item}>
                      {index === 0
                        ? `${item} (${regionTableData.length})`
                        : item}
                    </th>
                  ))}
              </tr>
            )}
          </thead>
          <tbody
            className={`${styles.table_body_container} ${isFilter ? styles.filter_expanded : ''}`}
          >
            {tableDynamic(regionTableData)}
          </tbody>
        </table>

        {/* <Pagination
          filteredData={regionTableData}
          pagination={{ ...pagination, setPagination: setPagination }}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        /> */}

        <ul className={styles.add_region_sections}>
          <li className={styles.selected_count}>
            {checkedCount !== 0 ? (
              <>
                <label>{checkedCount}</label>
                <span>Selected RIDs</span>
              </>
            ) : (
              <p>Not selected any RID</p>
            )}
          </li>
          <li>
            <button
              className={styles.add_region_btn}
              disabled={checkedCount === 0}
              onClick={() => {
                getRegionsSelectedRids(checkedRegionIds);
                clearFilter();
              }}
            >
              Add region
            </button>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default ListRid;
