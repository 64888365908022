import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Helpers
import {
  filterOptionsBasedOnEmail,
  getNameInitials
} from '../../utils/function-helper';

// Services
import {
  getResourceUsers,
  bulkUploadAoiQaQcAdmin
} from '../../services/project-tree-services';
import { getNumberOfFiles } from '../../services/products-service';

// Redux
import { onGetAoiListData } from '../../store/projects/project-actions';
import { projectActions } from '../../store/projects/project-slice';

// Components
import ProjectMainContainer from './projectMainContainer';
import ProjectAoiDetail from '../projectAoiDetail';
import MultiselectAssignees from './multiSelectAssigne';
import ProjectSop from '../projectSop';

// Images
import ExpandArrow from '../../assets/images/icons/down-arrow-grey.svg';

// SCSS
import './index.css';

const ProjectAoi = ({
  productIdValueData,
  setDrawerHeight,
  parentAoiId,
  setReCallQuantity
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { requestData, aoiData, aoiDatesData } = useSelector(
    (state) => state.project
  );

  const [numberOfFiles, setNumberOfFiles] = useState('');
  const [isSopExpanded, setIsSopExpanded] = useState(true);
  const [assigneeOpen, setAssigneeOpen] = useState('');
  const [qaqcOpen, setQaQcOpen] = useState('');

  const [aoiAdminOptions, setAoiAdminOptions] = useState([]);
  const [qaqcAdminOptions, setQaqcAdminOptions] = useState([]);

  const [bulkadminOptions, setBulkAdminOption] = useState({
    aoi: [],
    qaac: []
  });

  const [isOpenAoi, setIsOpenAoi] = useState(false);
  const [isQAQCopen, setQAQCopen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const [admins, setAdmins] = useState({
    aoi: '',
    qaqc: ''
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        assigneeOpen.length !== 0 ||
        admins.aoi !== '' ||
        event.target.className !== 'project-members__menu-list' ||
        event.target.className !==
          'project-members__input-container css-qbdosj-Input'
      ) {
        setAssigneeOpen('');
        setIsOpenAoi(false);
      }

      if (
        qaqcOpen.length !== 0 ||
        admins.qaqc !== '' ||
        event.target.className !== 'project-members__menu-list' ||
        event.target.className !==
          'project-members__input-container css-qbdosj-Input'
      ) {
        setQaQcOpen('');
        setQAQCopen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (aoiData && aoiData?.aoi_data && aoiData?.aoi_data.length !== 0) {
      let data = JSON.parse(JSON.stringify(aoiData));
      data?.aoi_data.forEach((item) => {
        item[item.id] = false;
      });
      setTableData(data);
      setAssigneeOpen('');
      setQaQcOpen('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiData]);

  useEffect(() => {
    fetchNumberOfFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCount > 0) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCount]);

  const fetchOptions = async (admin_type) => {
    const { data, status } = await getResourceUsers(
      'project',
      requestData.id,
      ''
    );
    if (status === 200) {
      let userArray = [];
      userArray.push({
        value: '',
        label: 'Unassigned',
        email: '',
        role_id: 2
      });
      data?.data?.map((user) => {
        userArray.push({
          value: user.id,
          label: user.name,
          email: user.email,
          role_id: 2
        });
      });

      setBulkAdminOption({
        ...bulkadminOptions,
        aoi: userArray,
        qaac: userArray
      });
    }
  };

  const fetchNumberOfFiles = async () => {
    let geometryList = [];
    if (aoiData?.aoi_data && aoiData?.sop) {
      aoiData?.aoi_data?.map((feature) => {
        geometryList.push(feature.geometry);
      });
      let filesExpectedPayload = {
        satellite:
          aoiData?.sop?.input_data[0]?.input_data[0]?.input_data[0]?.selected_value[0].value.split(
            ' '
          )[0],
        processing_level:
          aoiData?.sop?.input_data[0]?.input_data[0]?.input_data[0]?.selected_value[0].value.split(
            ' '
          )[1],
        cloud_perc:
          '0,' +
          aoiData?.sop?.input_data[0]?.input_data[2]?.input_data[0]
            ?.selected_value[0],
        aoi: geometryList,
        from_date: aoiData?.from_date,
        to_date: aoiData?.to_date
      };
      const { data, status } = await getNumberOfFiles(filesExpectedPayload);

      if (status === 200) {
        setNumberOfFiles(data.count);
      }
    }
  };

  const fetchDropdownOptions = async (quantity, admin_type) => {
    const { data, status } = await getResourceUsers(
      'project',
      requestData.id,
      ''
    );
    if (status === 200) {
      let userArray = [];
      userArray.push({
        value: '',
        label: 'Unassigned',
        email: '',
        role_id: 2
      });
      data?.data?.map((user) => {
        userArray.push({
          value: user.id,
          label: user.name,
          email: user.email,
          role_id: 2
        });
      });

      if (quantity && quantity.aoi_admin && quantity.qaqc_admin) {
        if (admin_type === 'aoi_admin') {
          setAoiAdminOptions(
            filterOptionsBasedOnEmail(userArray, quantity.aoi_admin)
          );
          setAssigneeOpen(quantity?.id);
        }

        if (admin_type === 'qaqc_admin') {
          setQaqcAdminOptions(
            filterOptionsBasedOnEmail(userArray, quantity.qaqc_admin)
          );
          setQaQcOpen(quantity?.id);
        }
      }
    }
  };

  const handleAoiClick = () => {
    dispatch(projectActions.doRequestStatus({ data: 'aoiDate' }));
  };

  const onChangeHandler = (id) => {
    let data = JSON.parse(JSON.stringify(tableData));
    data?.aoi_data.forEach((item) => {
      if (id === item.id) {
        item[item.id] = !item[item.id];
      }
    });

    const count = data?.aoi_data.filter((obj) => obj[obj.id] === true).length;

    if (data?.aoi_data.length === count) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    setSelectedCount(count);
    setTableData(data);
  };

  const onSelectAll = (data) => {
    let parsedData = JSON.parse(JSON.stringify(data));
    parsedData?.aoi_data.forEach((item) => {
      item[item.id] = !selectAll;
    });
    if (!selectAll) {
      setSelectedCount(parsedData?.aoi_data.length);
    } else {
      setSelectedCount('');
    }
    setSelectAll(!selectAll);
    setTableData(parsedData);
  };

  const onUpdateAoiAdmin = async () => {
    let listSelectedAoi = tableData?.aoi_data.filter(
      (obj) => obj[obj.id] === true
    );
    let listAoiId = listSelectedAoi.map((item) => item?.id);

    let payload = {
      aoi_ids: listAoiId
    };

    if (admins?.aoi) {
      payload['aoi_admin'] = admins?.aoi?.value
        ? admins?.aoi?.value
        : 'unassign';
    }
    if (admins?.qaqc) {
      payload['qaqc_admin'] = admins?.qaqc?.value
        ? admins?.qaqc?.value
        : 'unassign';
    }

    const { data, status, error } = await bulkUploadAoiQaQcAdmin(payload);

    if (status === 200) {
      toast.success(data?.message);

      setAdmins({ ...admins, aoi: '', qaqc: '' });

      setSelectedCount(0);

      onUnchekTableData();

      dispatch(onGetAoiListData(aoiData?.id));
    }
    if (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const onUnchekTableData = () => {
    let parseData = JSON.parse(JSON.stringify(tableData));
    parseData?.aoi_data.forEach((item) => {
      item[item.id] = false;
    });

    setSelectAll(false);
    setTableData(parseData);
  };

  return (
    <>
      {aoiDatesData?.length === 0 && (
        <>
          <ProjectMainContainer
            tableData={tableData}
            onSelectAll={onSelectAll}
            selectedCount={selectedCount}
            onChangeHandler={onChangeHandler}
            handleAoiClick={handleAoiClick}
            navigate={navigate}
            fetchDropdownOptions={fetchDropdownOptions}
            getNameInitials={getNameInitials}
            aoiAdminOptions={aoiAdminOptions}
            qaqcAdminOptions={qaqcAdminOptions}
            assigneeOpen={assigneeOpen}
            qaqcOpen={qaqcOpen}
            selectAll={selectAll}
            setAssigneeOpen={setAssigneeOpen}
            numberOfFiles={numberOfFiles}
          />

          {selectedCount > 0 && (
            <MultiselectAssignees
              selectedCount={selectedCount}
              tableData={tableData}
              admins={admins}
              bulkadminOptions={bulkadminOptions}
              setIsOpenAoi={setIsOpenAoi}
              setQAQCopen={setQAQCopen}
              setAdmins={setAdmins}
              isQAQCopen={isQAQCopen}
              setSelectedCount={setSelectedCount}
              onUpdateAoiAdmin={onUpdateAoiAdmin}
              onUnchekTableData={onUnchekTableData}
              isOpenAoi={isOpenAoi}
            />
          )}
        </>
      )}
      {aoiDatesData?.length !== 0 && (
        <ProjectAoiDetail
          aoiData={aoiData}
          productIdValueData={productIdValueData}
          setDrawerHeight={setDrawerHeight}
          numberOfFiles={numberOfFiles}
          parentAoiId={parentAoiId}
        />
      )}

      <div
        className={`project-sop-container ${isSopExpanded ? 'expanded' : ''}`}
      >
        <div
          className="region-expand-collapse"
          onClick={() => setIsSopExpanded(!isSopExpanded)}
        >
          <div className="expand-arrow">
            <img
              src={ExpandArrow}
              alt="Pmage"
              style={{
                transform: isSopExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'
              }}
            />
          </div>
          <div className="region-title-wrapper">
            <h2 className="work-flow-header">
              WorkFlow of {aoiData?.product_name}
            </h2>
          </div>
        </div>
        {isSopExpanded && (
          <ProjectSop
            productIdValueData={productIdValueData}
            setDrawerHeight={setDrawerHeight}
            parentAoiId={parentAoiId}
            setReCallQuantity={setReCallQuantity}
            aoiData={aoiData}
          />
        )}
      </div>
    </>
  );
};

export default ProjectAoi;
