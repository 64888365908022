import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { updateQaQc } from '../../services/project-tree-services';
import { toast } from 'react-toastify';
// Image
import { images } from '../../assets/images';

const QaQcCheckModal = ({
  fields,
  inputs,
  item,
  qaQcStatus,
  sopQaQcData,
  setSopQaQcData
}) => {
  const qaQcDropdownRef = useRef(null);
  const [qaQcCheckshowModal, setQaQcCheckShowModal] = useState(false);
  const { dataProcessingDate } = useSelector((state) => state.mapCompare);

  // OutSide on Click
  useEffect(() => {
    document.addEventListener('mousedown', qaQcHandleClickOutside);
    return () => {
      document.removeEventListener('mousedown', qaQcHandleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const qaQcHandleClickOutside = (event) => {
    if (
      qaQcDropdownRef.current &&
      !qaQcDropdownRef.current.contains(event.target)
    ) {
      setQaQcCheckShowModal(false);
    }
  };
  const updatingQaChecks = async (check_value, check_name) => {
    let new_data = [...sopQaQcData];

    let payload = {
      qa_qc: {
        [check_name]: check_value
      }
    };
    const { data, status, error } = await updateQaQc(
      payload,
      dataProcessingDate.id
    );
    setQaQcCheckShowModal(false);
    if (status === 200) {
      new_data.splice(2, 1, data?.message);
      setSopQaQcData(new_data);
    }
    if (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {fields && fields?.selected_value[0] === null ? (
        <>
          <div className="sop-selection-checks">
            <button
              className="sop-selection-icon check-qa-qc-hover"
              onClick={(e) => {
                updatingQaChecks(true, inputs.name);
              }}
              disabled={qaQcStatus}
            >
              <img src={images.greenTick} alt="icon for pass" />
            </button>
            <button
              className="sop-selection-icon check-qa-qc-hover"
              onClick={(e) => {
                updatingQaChecks(false, inputs.name);
              }}
              disabled={qaQcStatus}
            >
              <img src={images.redCross} alt="icon for fail" />
            </button>
          </div>
        </>
      ) : fields?.selected_value[0] === true ? (
        <div className="sop-selection-checks">
          <button
            className="sop-selection-valid-icon"
            disabled={qaQcStatus}
            onClick={() => {
              setQaQcCheckShowModal(true);
            }}
          >
            <img src={images.correct_qa} alt="checked" />
          </button>
        </div>
      ) : (
        <div className="sop-selection-checks">
          <button
            className="sop-selection-valid-icon"
            disabled={qaQcStatus}
            onClick={() => {
              setQaQcCheckShowModal(true);
            }}
          >
            <img src={images.wrong_qa} alt="Wrong" />
          </button>
        </div>
      )}
      {item?.name === 'QA/QC' && fields?.selected_value[0] !== null && (
        <ul
          ref={qaQcDropdownRef}
          className={`qa-check-validations ${
            qaQcCheckshowModal ? 'qa-qc-check-add' : 'qa-qc-check-remove'
          }`}
        >
          {fields.selected_value[0] === false && (
            <li
              onClick={(e) => {
                updatingQaChecks(true, inputs.name);
              }}
            >
              <label>
                <img
                  src={images.correct_qa}
                  alt="checked"
                  className="qa-icon-check"
                />
              </label>
              <span>Pass</span>
            </li>
          )}
          {fields.selected_value[0] === true && (
            <li
              onClick={(e) => {
                updatingQaChecks(false, inputs.name);
              }}
            >
              <label>
                <img
                  src={images.wrong_qa}
                  alt="Wrong"
                  className="qa-icon-check"
                />
              </label>
              <span>Fail</span>
            </li>
          )}
          {fields.selected_value[0] !== null && (
            <li
              onClick={(e) => {
                updatingQaChecks(null, inputs.name);
              }}
            >
              <span>Reset</span>
            </li>
          )}
        </ul>
      )}
    </>
  );
};

export default QaQcCheckModal;
