import { Aoi } from '../model';

class AoiRepository {
  constructor() {
    this.aois = [];
  }

  /**
   * Initializes the aois repository by converting an array of aois data objects
   * into individual aois instances.
   *
   * @param {Array<Object>} aois - An array of objects representing aois data.
   */
  initializeAois(aois) {
    this.aois = new Aoi(aois);
  }

  /**
   * Retrieves all the aoi objects in the repository.
   *
   * @returns {Array<Aoi>} An array containing all the aois instances.
   */
  getAois() {
    return this.aois;
  }
}

export default AoiRepository;
