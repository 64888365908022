export const header = {
  'Content-Type': 'application/json',
  accept: 'application/json',
  'Access-Control-Allow-Origin': '*'
};

export const GOOGLE_RECAPTCHA = '6Leys1ckAAAAAME7hlA2pIrchYc5NjnTsqTLdcv3';

export const USER_DETAILS_KEY = 'user-data';

export const REQUEST = 1;
export const INPROGRESS = 2;
export const COMPLETED = 3;
export const DECLINED = 4;

export const CLIENT_ID =
  '960465184139-bla0vqfg7msu3n32jjst10c69omsnh6g.apps.googleusercontent.com';

/* eslint-disable no-useless-escape */

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&^#]{8,}$/;

export const DATE_SLASH_FORMAT = 'DD/MM/YYYY';

export var NDVI_SELECTED_FORMAT = {
  satellite: '',
  processing_level: '',
  bands: {},
  cloud_perc: '',
  formula: null,
  stacking: {
    method: '',
    days: ''
  },
  masking: []
};

export const roleOptions = [
  { value: 'superadmin', label: 'Super Admin', id: 0 },
  { value: 'admin', label: 'Admin', id: 1 },
  { value: 'user', label: 'User', id: 2 }
];

export const catalogTabs = [
  'All',
  'Agriculture',
  'Insurance',
  'Climate',
  'Utilities'
];

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '41.9rem',
    height: '47rem',
    padding: '0rem',
    borderRadius: '0.8rem',
    zIndex: '10000',
    overflow: 'hidden'
  }
};

export const getRoleName = (roleId) => {
  switch (roleId) {
    case 0:
      return 'Super Admin';
    case 1:
      return 'Admin';
    case 2:
      return 'User';
    default:
      return '';
  }
};

export const MIN_DRAWER_HEIGHT = 11;
export const MID_DRAWER_HEIGHT = 55;
export const MAX_DRAWER_HEIGHT = 90;
