import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

// Helpers
import { getTimeStampFormat } from '../../utils/function-helper';
import { getInputFields } from '../../utils/sop-helper';

// Image
import { images } from '../../assets/images';

// Services
import {
  getProjectAoiDownload,
  getSatelliteFiles
} from '../../services/project-tree-services';
import {
  getSignedDownloadUrl,
  onGetDownloadExtraction
} from '../../services/products-service';

// Component
import QaQcCheckModal from './qa-qc-check';
import PopupModal from '../popupmodal';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '46.4rem',
    height: '28.7rem',
    padding: '0'
  }
};

const descStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    width: '726px',
    height: '403px',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.29)'
  }
};

const ProjectSop = ({ setDrawerHeight, parentAoiId, setReCallQuantity }) => {
  const [dataProcessingModalOpen, setDataProcessingModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [treeDownloadModalOpen, setTreeDownloadModalOpen] = useState(false);
  const [responseCopied, setResponseCopied] = useState(false);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [qaQcData, setQaQcData] = useState();
  const [sopQaQcData, setSopQaQcData] = useState([]);
  const [qaQcStatus, setQaQcStatus] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [satelliteData, setSatelliteData] = useState([]);

  const { aoiData, aoiDatesData } = useSelector((state) => state.project);
  const { dataProcessingDate, singleMapLayer } = useSelector(
    (state) => state.mapCompare
  );

  useEffect(() => {
    if (isModalOpen) {
      getSatelliteData(parentAoiId?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function openDataProcessingModal() {
    setDataProcessingModalOpen(true);
  }

  function closeModal() {
    setDataProcessingModalOpen(false);
  }

  const onChangeHandle = () => {};

  const handledataaccess = (dataaccess) => {
    if (dataaccess?.name === 'Download') {
      if (singleMapLayer?.layerData && singleMapLayer?.layerData.length == 0) {
        setTreeDownloadModalOpen(true);
      } else {
        let url = singleMapLayer?.layerData?.links[1]?.href;
        onHandleDownload(url);
      }
    } else if (dataaccess?.name === 'Preview') {
      setDrawerHeight(95);
    } else if (dataaccess?.name === 'Download Extraction') {
      if (parentAoiId) {
        onHandleDownloadExtraction(parentAoiId);
      }
    }
  };

  const onHandleDownloadExtraction = async (id) => {
    const { data, status } = await onGetDownloadExtraction(id);
    if (status === 200) {
      onHandleDownload(data?.resource?.extraction_url);
    }
  };

  const copyresponse = () => {
    navigator.clipboard.writeText(singleMapLayer?.layerData);
    setResponseCopied(true);
    setTimeout(() => setResponseCopied(false), 3000);
  };

  const onDownloadAoi = async () => {
    const { data, status, error } = await getProjectAoiDownload(
      parentAoiId?.id
    );

    if (status === 200) {
      let url = data?.data?.url;
      onHandleDownload(url);
      setTreeDownloadModalOpen(false);
    }
    if (error) {
      toast.error(error);
    }
  };

  const downloadTifFile = async (tifFilePath) => {
    const { data, status, error } = await getSignedDownloadUrl(tifFilePath);

    if (status === 200) {
      let url = data?.data?.url;
      onHandleDownload(url);
    }
    if (error) {
      toast.error(error.message);
    }
  };

  const onHandleDownload = (url) => {
    if (url) {
      const link = document.createElement('a');
      link.setAttribute('download', 'file');
      link.setAttribute('target', '_blank');
      link.href = url;
      link.click();
    }
  };

  const getManualDisabled = (dataProcessingDate) => {
    var disable = false;
    if (dataProcessingDate?.length !== 0 && dataProcessingDate?.status === 3) {
      disable = false;
    } else {
      disable = true;
    }
    return disable;
  };

  const getAutomatedDisabled = (sopQaQcData) => {
    let checksData = sopQaQcData?.find((item) => item.name === 'QA/QC');
    var disable = false;
    checksData?.input_data.forEach((element) => {
      element?.input_data?.forEach((check) => {
        if (!check?.selected_value[0]) {
          disable = true;
        }
      });
    });
    return disable;
  };

  const getSatelliteData = async (id) => {
    const response = await getSatelliteFiles(id);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      setSatelliteData(response?.data?.data);
    } else {
      toast.error(response?.data?.message);
    }
  };

  useEffect(() => {
    if (aoiData?.generation_type === 'automated') {
      setQaQcData(false);
    } else if (aoiData?.generation_type === 'manual') {
      setQaQcData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiDatesData]);

  useEffect(() => {
    if (dataProcessingDate?.length !== 0) {
      setSopQaQcData(dataProcessingDate?.qa_qc?.input_data);
      setQaQcStatus(false);
    } else {
      setSopQaQcData(aoiData?.sop?.input_data);
      setQaQcStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProcessingDate, aoiData]);

  return (
    <>
      <div className="sop-cards-container" data-testid="sop-cards-container">
        <div className="sop-cards-wrapper">
          {aoiData?.length !== 0 &&
            sopQaQcData &&
            sopQaQcData.length !== 0 &&
            sopQaQcData?.map((item, firstIndex) => {
              return (
                <>
                  <div className="sop-cards">
                    <div className="sop-card-header">
                      {item.name}
                      {item.name === 'Data Selection' && (
                        <div
                          onClick={openDataProcessingModal}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              openDataProcessingModal();
                            }
                          }}
                          className="img-tooltip"
                          tabIndex="0"
                        >
                          <img src={images.info} alt="info" />
                        </div>
                      )}
                      {item.name === 'Data Processing' && (
                        <div
                          onClick={openDataProcessingModal}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              openDataProcessingModal();
                            }
                          }}
                          className="img-tooltip"
                          tabIndex="0"
                        >
                          <img src={images.info} alt="info" />
                        </div>
                      )}
                    </div>
                    <div className="project sop-card-body">
                      <div className="card-wrapper">
                        <ul className="sop-card-list">
                          {item.name === 'QA/QC' ? (
                            <p className="list-item-heading">
                              This data product is passed through following
                              quality checks.
                            </p>
                          ) : null}
                          {item.input_data?.map((inputs, secondIndex) => {
                            return (
                              <>
                                {item.name !== 'Data Access' ? (
                                  <>
                                    <li
                                      className={`list-item ${
                                        inputs?.name ===
                                          'Upload Sowing Output' &&
                                        'list-item-top'
                                      } ${item.name === 'QA/QC' && 'aq-qc-li'}`}
                                    >
                                      <div
                                        className={
                                          item.name === 'QA/QC'
                                            ? 'left-list-item'
                                            : 'list-item-left'
                                        }
                                        onClick={
                                          inputs?.name === 'Download Images' &&
                                          inputs?.input_data[0].type ===
                                            'download_link'
                                            ? handleClick
                                            : null
                                        }
                                        onKeyDown={(event) => {
                                          if (event.key === 'Enter') {
                                            if (
                                              inputs?.name ===
                                                'Download Images' &&
                                              inputs?.input_data[0].type ===
                                                'download_link'
                                            ) {
                                              handleClick();
                                            }
                                          }
                                        }}
                                        tabIndex={
                                          inputs?.name === 'Download Images' &&
                                          inputs?.input_data[0].type ===
                                            'download_link'
                                            ? '0'
                                            : '-1'
                                        } // Make the div focusable conditionally
                                      >
                                        {inputs?.name === 'Download Images' &&
                                        inputs?.input_data[0].type ===
                                          'download_link' ? (
                                          parentAoiId ? (
                                            <div
                                              style={{
                                                color: '#2F80ED',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                whiteSpace: 'nowrap'
                                              }}
                                            >
                                              {inputs?.name}
                                            </div>
                                          ) : (
                                            <button
                                              disabled
                                              style={{
                                                color: 'grey',
                                                fontSize: '14px',
                                                cursor: 'no-drop',
                                                whiteSpace: 'nowrap',
                                                fontWeight: 500
                                              }}
                                              title="Select any ShapeFile"
                                            >
                                              {inputs?.name}
                                            </button>
                                          )
                                        ) : (
                                          <p>{inputs?.name}</p>
                                        )}
                                      </div>

                                      <div
                                        className={
                                          item.name === 'QA/QC'
                                            ? 'right-list-item'
                                            : 'list-item-right'
                                        }
                                      >
                                        {inputs.input_data
                                          ? inputs?.input_data.map(
                                              (fields, thirdIndex) => {
                                                let disabled = true;
                                                let flow = 'generator';
                                                return (
                                                  <>
                                                    {fields.type !==
                                                    'Boolean' ? (
                                                      <label
                                                        className={`${
                                                          fields.type ===
                                                          'slider'
                                                            ? 'slider-item'
                                                            : 'sop-selection-feild'
                                                        }`}
                                                      >
                                                        {getInputFields(
                                                          flow,
                                                          fields.type,
                                                          fields.options,
                                                          aoiData?.sop
                                                            ?.input_data,
                                                          onChangeHandle,
                                                          firstIndex,
                                                          secondIndex,
                                                          thirdIndex,
                                                          disabled,
                                                          parentAoiId,
                                                          setFiles,
                                                          setReCallQuantity,
                                                          aoiData
                                                        )}
                                                      </label>
                                                    ) : (
                                                      <>
                                                        {aoiData?.generation_type ===
                                                          'manual' &&
                                                        qaQcData &&
                                                        !qaQcStatus ? (
                                                          <QaQcCheckModal
                                                            qaQcStatus={
                                                              qaQcStatus
                                                            }
                                                            fields={fields}
                                                            item={item}
                                                            inputs={inputs}
                                                            sopQaQcData={
                                                              sopQaQcData
                                                            }
                                                            setSopQaQcData={
                                                              setSopQaQcData
                                                            }
                                                          />
                                                        ) : (
                                                          aoiData?.generation_type ===
                                                            'automated' &&
                                                          (fields
                                                            .selected_value[0] ===
                                                          true ? (
                                                            <img
                                                              src={
                                                                images.correct_qa
                                                              }
                                                              alt="checked"
                                                              className="qa-icon"
                                                            />
                                                          ) : fields
                                                              .selected_value[0] ===
                                                            false ? (
                                                            <img
                                                              src={
                                                                images.wrong_qa
                                                              }
                                                              alt="Cross icon"
                                                              className="qa-icon"
                                                            />
                                                          ) : null)
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )
                                          : '-'}
                                      </div>
                                    </li>
                                    {inputs?.name === 'Upload Files' &&
                                    item.name === 'Data Processing' &&
                                    !qaQcStatus ? (
                                      <li>
                                        <ul className="files-list">
                                          <li>
                                            <label
                                              onClick={() => {
                                                downloadTifFile(
                                                  item?.file_names
                                                );
                                              }}
                                            >
                                              {dataProcessingDate?.file_name}
                                            </label>
                                            <span>
                                              {getTimeStampFormat(
                                                dataProcessingDate?.updated_time
                                              )}
                                            </span>
                                          </li>
                                        </ul>
                                      </li>
                                    ) : (
                                      <>
                                        {aoiData?.generation_type ===
                                          'manual' &&
                                          aoiDatesData?.length !== 0 &&
                                          inputs?.name === 'Upload Files' &&
                                          item?.name === 'Data Processing' && (
                                            <li>
                                              <ul className="files-list">
                                                {aoiDatesData &&
                                                  aoiDatesData.length !== 0 &&
                                                  aoiDatesData?.map((item) => (
                                                    <li>
                                                      <label
                                                        onClick={() => {
                                                          downloadTifFile(
                                                            item?.file_name
                                                          );
                                                        }}
                                                      >
                                                        {item?.file_name}
                                                      </label>
                                                      <span>
                                                        {getTimeStampFormat(
                                                          item?.updated_time
                                                        )}
                                                      </span>
                                                    </li>
                                                  ))}
                                              </ul>
                                            </li>
                                          )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {inputs?.name !== 'Request API' &&
                                      inputs?.name !== 'Response API' && (
                                        <li className="data-access">
                                          <button
                                            disabled={
                                              aoiData?.generation_type ===
                                              'automated'
                                                ? getAutomatedDisabled(
                                                    sopQaQcData
                                                  )
                                                : aoiData?.generation_type ===
                                                    'manual'
                                                  ? getManualDisabled(
                                                      dataProcessingDate
                                                    )
                                                  : true
                                            }
                                            className="data-access-list"
                                            onClick={() => {
                                              handledataaccess(inputs);
                                            }}
                                          >
                                            <p className="disabled-details">
                                              Wait for request completion to
                                              enable.
                                            </p>
                                            <label
                                              className={
                                                inputs?.name === 'Download' ||
                                                inputs?.name ===
                                                  'Download Extraction' ||
                                                inputs?.name === 'Preview'
                                                  ? 'full-flex-basic'
                                                  : ''
                                              }
                                            >
                                              {inputs?.name}
                                            </label>
                                            {inputs?.name === 'Request API' && (
                                              <>
                                                <span
                                                  onClick={() => {
                                                    copyresponse();
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M6.0013 2.15894C3.88421 2.15894 2.16797 3.87518 2.16797 5.99227V10.7302C2.16797 11.0063 2.39183 11.2302 2.66797 11.2302C2.94411 11.2302 3.16797 11.0063 3.16797 10.7302V5.99227C3.16797 4.42746 4.4365 3.15894 6.0013 3.15894H10.6765C10.9527 3.15894 11.1765 2.93508 11.1765 2.65894C11.1765 2.38279 10.9527 2.15894 10.6765 2.15894H6.0013Z"
                                                      fill="#3DB267"
                                                    />
                                                    <path
                                                      fill-rule="evenodd"
                                                      clip-rule="evenodd"
                                                      d="M12.2697 4.52111C10.109 4.27963 7.89359 4.27963 5.73295 4.52111C5.11744 4.5899 4.62281 5.0743 4.55026 5.69459C4.29401 7.88557 4.29401 10.099 4.55026 12.2899C4.62281 12.9102 5.11744 13.3946 5.73295 13.4634C7.89359 13.7049 10.109 13.7049 12.2697 13.4634C12.8852 13.3946 13.3798 12.9102 13.4524 12.2899C13.7086 10.099 13.7086 7.88557 13.4524 5.69459C13.3798 5.0743 12.8852 4.5899 12.2697 4.52111ZM5.84403 5.51492C7.93084 5.28169 10.0718 5.28169 12.1586 5.51492C12.3168 5.53261 12.4412 5.65774 12.4591 5.81075C12.7064 7.92456 12.7064 10.06 12.4591 12.1738C12.4412 12.3268 12.3168 12.4519 12.1586 12.4696C10.0718 12.7028 7.93084 12.7028 5.84403 12.4696C5.68579 12.4519 5.56139 12.3268 5.54349 12.1738C5.29627 10.06 5.29627 7.92456 5.54349 5.81075C5.56139 5.65774 5.68579 5.53261 5.84403 5.51492Z"
                                                      fill="#3DB267"
                                                    />
                                                  </svg>
                                                </span>
                                              </>
                                            )}

                                            {inputs?.name ===
                                              'Response API' && (
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M6.0013 2.15894C3.88421 2.15894 2.16797 3.87518 2.16797 5.99227V10.7302C2.16797 11.0063 2.39183 11.2302 2.66797 11.2302C2.94411 11.2302 3.16797 11.0063 3.16797 10.7302V5.99227C3.16797 4.42746 4.4365 3.15894 6.0013 3.15894H10.6765C10.9527 3.15894 11.1765 2.93508 11.1765 2.65894C11.1765 2.38279 10.9527 2.15894 10.6765 2.15894H6.0013Z"
                                                    fill="#3DB267"
                                                  />
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M12.2697 4.52111C10.109 4.27963 7.89359 4.27963 5.73295 4.52111C5.11744 4.5899 4.62281 5.0743 4.55026 5.69459C4.29401 7.88557 4.29401 10.099 4.55026 12.2899C4.62281 12.9102 5.11744 13.3946 5.73295 13.4634C7.89359 13.7049 10.109 13.7049 12.2697 13.4634C12.8852 13.3946 13.3798 12.9102 13.4524 12.2899C13.7086 10.099 13.7086 7.88557 13.4524 5.69459C13.3798 5.0743 12.8852 4.5899 12.2697 4.52111ZM5.84403 5.51492C7.93084 5.28169 10.0718 5.28169 12.1586 5.51492C12.3168 5.53261 12.4412 5.65774 12.4591 5.81075C12.7064 7.92456 12.7064 10.06 12.4591 12.1738C12.4412 12.3268 12.3168 12.4519 12.1586 12.4696C10.0718 12.7028 7.93084 12.7028 5.84403 12.4696C5.68579 12.4519 5.56139 12.3268 5.54349 12.1738C5.29627 10.06 5.29627 7.92456 5.54349 5.81075C5.56139 5.65774 5.68579 5.53261 5.84403 5.51492Z"
                                                    fill="#3DB267"
                                                  />
                                                </svg>
                                              </span>
                                            )}
                                          </button>
                                        </li>
                                      )}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {firstIndex !== sopQaQcData.length - 1 && (
                    <div className="next-process">
                      <div className="sop-image-wrapper">
                        <img src={images.sopArrow} alt="right-arrow" />
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
      <Modal
        isOpen={dataProcessingModalOpen}
        style={modalStyles}
        onRequestClose={closeModal}
      >
        <div className="details-modal-header">
          <span className="details-info">
            <img src={images.infoBlack} alt="info" />
          </span>
          <h1>Data Selection Details</h1>
          <div></div>
          <span
            className="details-info-close"
            onClick={closeModal}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                closeModal();
              }
            }}
            tabIndex="0"
          >
            <img src={images.closeIcon} alt="close" />
          </span>
        </div>
        <div className="details-modal-content">
          <div className="details-modal-left">
            <ul>
              <li>Satellite</li>
              <li>Spectral Bands</li>
              <li>Maximum Cloud %</li>
              <li>File estimation</li>
            </ul>
          </div>

          <div className="details-modal-right">
            <ul>
              <li>Brief introduction about satellite being used.</li>
              <li>Brief introduction about spectral bands being used.</li>
              <li>Significance of cloud percentage</li>
              <li>How expected files are being calculated.</li>
            </ul>
          </div>
        </div>
        <div className="learn-btn-div">
          <button className="learn-btn">Learn more</button>
        </div>
      </Modal>
      <Modal
        isOpen={treeDownloadModalOpen}
        style={modalStyles}
        onRequestClose={() => {
          setChecked(false);
          setTreeDownloadModalOpen(false);
        }}
      >
        <div className="download-modal-header">
          <p>Download</p>
        </div>
        <div className="download-modal-content">
          <p className="aoi-file-name">Aoi file</p>
          <div>
            <input
              type="radio"
              value="zip"
              name="zip"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            />
            <p className="radio-text">Zip File</p>
          </div>
          {/* <div>
            <input type="radio" value="python" name="python" />
            <p className="radio-text">Python Script</p>
          </div> */}
        </div>
        <div className="download-modal-footer">
          <button
            className="cncl"
            onClick={() => {
              setTreeDownloadModalOpen(false);
              setChecked(false);
            }}
          >
            Cancel
          </button>
          <button
            className="download-btn"
            disabled={!checked}
            onClick={() => {
              onDownloadAoi();
            }}
          >
            Download
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        style={descStyles}
        onRequestClose={handleCloseModal}
      >
        <div className="popup-wrapper">
          <div className="rejection-header">
            <h1>Download Files Link:</h1>
          </div>
          <div className="description-content">
            {satelliteData.map((item, index) => (
              <div key={index}>
                {Object.keys(item).map((key) => (
                  <div key={key}>
                    {key === 'FCC' ? (
                      <div className="satellite-data-container">
                        <h2 className="satellite-data-header">FCC Data</h2>
                        {Object.keys(item[key]).map((nestedKey) => (
                          <p
                            key={nestedKey}
                            style={{
                              color: '#2F80ED',
                              textDecoration: 'underline',
                              padding: '4px'
                            }}
                          >
                            <a href={item[key][nestedKey]}>{nestedKey}</a>
                          </p>
                        ))}
                      </div>
                    ) : (
                      <div className="satellite-data-container">
                        <h2 className="satellite-data-header">NDVI Data</h2>
                        {Object.keys(item[key]).map((nestedKey) => (
                          <p
                            key={nestedKey}
                            className="satellite-data-dates"
                            style={{
                              color: '#2F80ED',
                              textDecoration: 'underline',
                              padding: '4px'
                            }}
                          >
                            <a href={item[key][nestedKey]}>{nestedKey}</a>
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <PopupModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default ProjectSop;
