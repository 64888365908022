//services
import {
  deleteDraft,
  getDraftEdit,
  getDraftProductData,
  getDraftRequestData,
  getDraftTreeData
} from '../../services/draft-services.js';
import { draftAction } from './drafts-slice.js';

export const getDraftTree = (payload) => {
  return async (dispatch) => {
    const { data, status } = await getDraftTreeData(payload);
    if (status === 200) {
      dispatch(draftAction.storeDraftTreeData({ data: data?.data }));
    }
  };
};

export const getDraftRequest = (id) => {
  return async (dispatch) => {
    const { data, status } = await getDraftRequestData(id);
    if (status === 200) {
      dispatch(draftAction.storeDraftRequest({ data: data?.requests }));
    }
  };
};

export const getDraftProductAction = (id) => {
  return async (dispatch) => {
    const { data, status } = await getDraftProductData(id);
    if (status === 200) {
      dispatch(draftAction.storeDraftProduct({ data: data }));
    }
  };
};

export const getDraftEditAction = (payload) => {
  return async (dispatch) => {
    const { data, status } = await getDraftEdit(payload);
    if (status === 200) {
      dispatch(draftAction.editDraftTree({ data: data }));
    }
  };
};

export const deleteDraftAction = () => {
  return async (dispatch) => {
    const { data, status } = await deleteDraft();
    if (status === 200) {
      dispatch(draftAction.deleteDraftTree({ data: data }));
    }
  };
};
