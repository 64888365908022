import { createSlice } from '@reduxjs/toolkit';

const draftSlice = createSlice({
  name: 'draft',
  initialState: {
    draftTreeData: [],
    draftRequestData: [],
    draftProductData: [],
    editDraftTree: [],
    deleteDraft: []
  },
  reducers: {
    //this function will store the draft tree project data
    storeDraftTreeData(state, action) {
      state.draftTreeData = action.payload.data;
    },
    //this function will store the draft tree request data
    storeDraftRequest(state, action) {
      state.draftRequestData = action.payload.data;
    },
    //this funciton will store the draft product data
    storeDraftProduct(state, action) {
      state.draftProductData = action.payload.data;
    },
    //This Action will store the edit Drafts Tree Requests
    editDraftTree(state, action) {
      state.editDraftTree = action.payload.data;
    },
    //This Action will store the delete Drafts the tree response
    deleteDraftTree(state, action) {
      state.deleteDraft = action.payload.data;
    },
    //clearing the store
    clearState(state) {
      state.draftProductData = [];
    }
  }
});

export const draftAction = draftSlice.actions;
export default draftSlice.reducer;
