import React, { useEffect, useState } from 'react';

//SCSS
import styles from '../../containers/catalog/index.module.scss';

//IMAGES
import { images } from '../../assets/images';

//COMPONENT
import RequestResponseData from './requestResponseData';
import CatalogMapComponent from './catalogMapComponent';
import DataCard from '../dataCatalog/dataCard';
import { dataCatalogSample } from '../../assets/dataCatalog';

interface DataCatalogProps {
  setDataProductSelected: (data: any) => void;
  dataProductSelected: string;
}

const DataProductPage: React.FC<DataCatalogProps> = ({
  setDataProductSelected,
  dataProductSelected
}) => {
  const [product, setProduct] = useState<any>(null);

  useEffect(() => {
    const foundProduct: any = dataCatalogSample.find(
      (product) => product.product_name === dataProductSelected
    );
    setProduct(foundProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductSelected]);

  return (
    <div className={styles.data_product_wrapper}>
      <div className={styles.data_product_header}>
        <span>
          <img
            src={images.leftArrow}
            alt="back arrow"
            onClick={() => setDataProductSelected('')}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setDataProductSelected('');
              }
            }}
            tabIndex={0}
          />
        </span>
        <label>{product?.product_name}</label>
      </div>

      <div className={styles.data_product_body}>
        <div className={styles.data_product_description}>
          <h6>Product Description</h6>
          <p>{product?.description}</p>
        </div>

        <table>
          <thead>
            <tr>
              <th className={styles.width_15}>Spatial Coverage</th>
              <th className={styles.width_15}>Data Resolution</th>
              <th className={styles.width_15}>Source</th>
              <th className={styles.width_15}>Temporal Frequency</th>
              <th className={styles.width_15}>Data Format</th>
              <th className={styles.width_15}>Unit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.width_15}>
                {product?.product_data?.spatial_coverage}
              </td>
              <td className={styles.width_15}>
                {product?.product_data?.data_resolution}
              </td>
              <td className={styles.width_15}>
                {product?.product_data?.source}
              </td>
              <td className={styles.width_15}>
                {product?.product_data?.temporal_frequency}
              </td>
              <td className={styles.width_15}>
                {product?.product_data?.data_format.join(', ')}
              </td>
              <td className={styles.width_15}>{product?.product_data?.unit}</td>
            </tr>
          </tbody>
        </table>

        <p className={styles.notes}>Note : *{product?.note}</p>

        <table>
          <thead>
            <tr>
              <th className={styles.width_15}>Band</th>
              <th className={styles.width_15}>Values</th>
              <th className={styles.width_70}>Explanation</th>
            </tr>
          </thead>
          <tbody>
            {product?.product_data?.bands.map((band: any, index: any) => (
              <tr key={band.id}>
                <td className={styles.width_15}>{band.name}</td>
                <td className={styles.width_15}>{band.value}</td>
                <td className={`${styles.width_70} ${styles.text_align_left}`}>
                  {band.explanation}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.data_product_tags}>
          <h6>Tags</h6>
          <ul className={styles.tag_list}>
            {product?.tags.map((tag: any, index: any) => (
              <li key={index}>{tag}</li>
            ))}
          </ul>
        </div>

        <div className={styles.data_product_output}>
          <h6>Sample Output</h6>
          <div className={styles.map_continer}>
            {product && (
              <CatalogMapComponent
                product={product}
                map_data={product?.map_data}
              />
            )}
          </div>
        </div>

        <div className={styles.api_access_wrapper}>
          <h6>API Access</h6>
          <ul className={styles.api_access_body}>
            <RequestResponseData dataType="request" product={product} />
            <RequestResponseData dataType="response" product={product} />
          </ul>
        </div>
        <div className={styles.usecase_card_wrapper}>
          <h6>Use Case</h6>
          <ul className={styles.usecase_wrapper}>
            {product?.use_cases.map((useCase: any, index: any) => (
              <DataCard
                key={useCase.id}
                imageUrl={useCase.img}
                title={useCase.title}
                description={useCase.desc}
                setDataProductSelected={setDataProductSelected}
                clickable={false}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DataProductPage;
