import React from 'react';
import DatePicker from 'react-datepicker';
import CustomDateInput from '../customDateInput/customDateInput';
import { isValidNameFormat } from '../../utils/function-helper';
import closeIcon from '../../assets/images/icons/close.svg';
import Upload from '../../assets/images/icons/upload.svg';
import Close from '../../assets/images/icons/close_small.svg';

const CreateProjectDetails = ({
  onCloseModal,
  modalType,
  setShowModal,
  projectName,
  setProjectName,
  dueDate,
  setDueDate,
  handleFileChange,
  uploadedFiles,
  handleRemoveFile,
  projectDesc,
  setProjectDesc,
  setSteps,
  setUploadedFiles
}) => {
  return (
    <>
      <div className="modal-header">
        <p>Create new project</p>
        <img
          src={closeIcon}
          alt="close icon"
          onClick={() => {
            if (modalType === 'create-new-modal') {
              setShowModal(false);
              setDueDate(null);
              setProjectName('');
              setUploadedFiles([]);
              setProjectDesc('');
            } else {
              window.location.reload();
            }
          }}
        />
      </div>
      <div className="modal-body">
        <div className="project-name-sections">
          <label>
            Project Name<sup>*</sup>
          </label>
          <input
            type="text"
            className="input-box"
            placeholder="Enter Project Name"
            value={projectName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.startsWith(' ')) {
                setProjectName(inputValue.trimStart());
              } else {
                setProjectName(inputValue);
              }
            }}
            data-testid="project-name"
          />
          {!isValidNameFormat(projectName) && projectName.length !== 0 && (
            <p>Only alphanumeric characters allowed</p>
          )}
        </div>
        <div className="project-name-sections">
          <label>Project Due Date</label>
          <DatePicker
            placeholderText="Select due date"
            selected={dueDate}
            className="date-picker"
            wrapperClassName="create-due-date"
            popperClassName="project-due-date-popper"
            value={dueDate}
            onChange={(date) => setDueDate(date)}
            customInput={<CustomDateInput />}
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            scrollableYearDropdown
            data-testid="project due date"
            minDate={new Date()}
          />
        </div>

        <div className="project-upload-sections">
          <label>Files</label>
          <span
            className="upload-project"
            onClick={() => document.getElementById('pdfUpload').click()}
          >
            <label htmlFor="pdfUpload">
              <button>Upload files</button>
            </label>
            <input
              type="file"
              id="pdfUpload"
              data-testid="pdfUpload"
              accept=".pdf,.docx,.xlsx,.csv"
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e)}
            />
            <img src={Upload} alt="Upload" />
          </span>
          {uploadedFiles && uploadedFiles.length > 0 && (
            <div className="uploaded-files">
              <ul>
                {uploadedFiles.length > 0 && (
                  <div className="uploaded-files">
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={file.id}>
                          {file.name}
                          <img
                            src={Close}
                            alt="Close"
                            onClick={() => handleRemoveFile(index)}
                            className="close-icon"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="project-description-sections">
          <label>Description</label>
          <textarea
            name="freeform"
            rows="5"
            cols="8"
            placeholder="Type use case and pain points for this project"
            value={projectDesc}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.startsWith(' ')) {
                setProjectDesc(inputValue.trimStart());
              } else {
                setProjectDesc(inputValue);
              }
            }}
            data-testid="project desc"
          ></textarea>
        </div>
      </div>
      <div className="create-step-btn">
        <div>
          <label className="steps">1/2</label>
        </div>
        <div className="create-step">
          <button
            className="btn btn-cancel-discard"
            onClick={() => {
              onCloseModal();
            }}
          >
            Skip
          </button>
          <button
            className="create-btn"
            disabled={!isValidNameFormat(projectName)}
            onClick={() => setSteps(2)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateProjectDetails;
