export class Project {
  name: string;
  dueDate: Date | null;
  file: File[];
  description: string;
  members: [];

  /**
   * Constructs a new Project instance.
   * @param {string} name - The name of the project.
   * @param {Date | null} dueDate - The due date of the project.
   * @param {string} description - Description of the project.
   * @param {any[]} members - Array containing members of the project.
   */

  constructor(
    name: string,
    dueDate: Date | null,
    file: File[],
    description: string,
    members: []
  ) {
    this.name = name;
    this.dueDate = dueDate;
    this.file = file;
    this.description = description;
    this.members = members;
  }

  /**
   * Serializes a Project object into a JSON string.
   * @param {Project} project - The project object to serialize.
   * @returns {string} A JSON string representing the project.
   */
  static serialize(project: Project): string {
    return JSON.stringify(project);
  }

  /**
   * Deserializes a JSON string into a Project object.
   * @param {string} json - The JSON string to deserialize.
   * @returns {Project} The deserialized Project object.
   */
  static deserialize(json: string): Project {
    const obj = JSON.parse(json);
    return new Project(
      obj.name,
      obj.dueDate,
      obj.file,
      obj.description,
      obj.members
    );
  }
}
