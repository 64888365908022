import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import Modal from 'react-modal';
import CreateProject from '../createProject/createProjectModal';
import Loader from '../loader';
import CustomDateInput from '../customDateInput/customDateInput.jsx';

// Images
import Close from '../../assets/images/icons/close.svg';
import Check from '../../assets/images/checked.png';
import add from '../../assets/images/icons/add.svg';

import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

import {
  getAllUsers,
  postCreateRequest,
  getAllProjects
} from '../../services/products-service';

import { getUserDetails } from '../../utils/user-helper';
import { USER_DETAILS_KEY } from '../../utils/constants';
import { checkUserPermisson } from '../../utils/permission-helper.js';
import { requestAction } from '../../store/requests/request-slice';
import { checkRequestPermisson } from '../../store/requests/request-actions';
import { onGetRequestListData } from '../../store/projects/project-actions.js';
import { isValidNameFormat } from '../../utils/function-helper';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0rem',
    borderRadius: '1.6rem',
    background: '#fff',
    width: '46.8rem',
    height: '26.2rem',
    boxShadow: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25)',
    overflow: 'hidden'
  }
};

const ConfirmRequest = ({
  setConfirmRequest,
  selectedAOIs,
  selectedDataProducts,
  setSelectedDataProducts,
  setRequestButtonDisabled,
  setSelectedAOIs,
  aois,
  historyAOIs,
  setHistoryAOIs,
  setAOIs,
  setIsButtonClicked,
  setExpand,
  setSelectedButton
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { requestForm, requestPermission } = useSelector(
    (state) => state?.request
  );

  const [dueDate, setDueDate] = useState();
  const [access, setAccess] = useState(false);
  const [selectedProjectOption, setSelectedProjectOption] = useState();
  const [newProject, setNewProject] = useState({});
  const [requestName, setRequestName] = useState('');
  const [requestDesc, setRequestDesc] = useState('');
  const [confirmRequestBtn, setConfirmRequestBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [totalMembers, setTotalMembers] = useState([]);
  const [modalType, setModalType] = useState('');
  const [requestFormData, setRequestForm] = useState({
    projectName: '',
    requestName: '',
    requestDueDate: null,
    skip: ''
  });
  const [ProjectOptions, setProjectOptions] = useState([
    { name: 'Create New', id: 1, description: '', created_date: '' }
  ]);

  const { Option } = components;
  const renderIcon = (id) => {
    if (id === 1) {
      return <img src={add} />;
    }
    return null;
  };

  const IconOption = (props) => (
    <Option
      className={props.data.id === 1 ? 'create-new-option' : ''}
      {...props}
    >
      {renderIcon(props.data.id)}
      {props.data.name}
    </Option>
  );

  const isFormValid =
    isValidNameFormat(requestFormData?.requestName) &&
    requestFormData?.projectName &&
    requestFormData?.requestName?.trim() &&
    !access &&
    requestFormData?.requestDueDate;

  const closeModal = () => {
    setRequestModal(false);
    dispatch(requestAction.doRequstFormUpdate({ data: { skip: true } }));
  };

  useEffect(() => {
    fetchAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAllProjects();
    if (requestForm) {
      setRequestForm(requestForm);
      setSelectedProjectOption(requestForm.projectName);
      setRequestName(requestForm.requestName);
      setDueDate(requestForm.requestDueDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestForm]);

  useEffect(() => {
    const foundOption = ProjectOptions.find(
      (option) => option.id === newProject.id
    );

    if (foundOption) {
      setSelectedProjectOption(foundOption);
    } else {
      setSelectedProjectOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProject, ProjectOptions]);

  const fetchAllProjects = async () => {
    const { data, status } = await getAllProjects();

    if (status === 200) {
      setProjectOptions([
        { name: 'Create New', id: 1, description: '', created_date: '' },
        ...data.data
      ]);
    }

    fetchAllUsers();
  };

  const fetchAllUsers = async () => {
    const { data, status } = await getAllUsers();
    if (status === 200) {
      let userArray = [];
      data?.data?.map((user, index) => {
        if (user.id !== getUserDetails(USER_DETAILS_KEY).userId) {
          userArray.push({
            value: user.id,
            label: user.name,
            email: user.email,
            role_id: 2,
            index: index
          });
        }
      });
      setTotalMembers(userArray);
      // setDeliveryInchargeOptions(userArray);
    }
  };

  function handleProjectChange(e) {
    if (e.name === 'Create New') {
      setShowModal(true);
      setModalType('create-new-modal');
    } else {
      setRequestForm({
        ...requestFormData,
        projectName: { label: e.name, value: e.id }
      });

      dispatch(checkRequestPermisson(e.id));
    }
  }

  useEffect(() => {
    if (
      requestPermission &&
      requestPermission?.permissions &&
      requestPermission?.permissions.length !== 0
    ) {
      let valid = requestPermission.permissions.some(
        (item) => item.permission_key === 'request.create'
      );

      if (valid) {
        setAccess(false);
      } else {
        setAccess(true);
        toast.error(
          "user doesn't have permission to create request under this project"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPermission]);

  function calculateSopValue(product_card, product) {
    let sop_value = {
      satellite: '',
      processing_level: '',
      bands: {},
      cloud_perc: '',
      formula: null,
      stacking: {
        method: '',
        days: ''
      },
      masking: [],
      region: [],
      raster_header: '',
      cygnus_needed: '',
      acr_table_update_flag: '',
      season: ''
    };
    if (product === 'ACR') {
      sop_value['product'] = product;
      sop_value.region = aois.map((item) => item.name.split('_')[1] || null);
    }
    product_card.sop_data?.map((data_item) => {
      if (
        data_item.name === 'Data Selection' ||
        data_item.name === 'Data Processing'
      ) {
        data_item.input_data?.map((value_item) => {
          switch (value_item.name) {
            case 'Satellite':
            case 'Satellite Selection':
              value_item.input_data?.map((satellite_item) => {
                if (satellite_item.selected_value?.length == 1) {
                  sop_value.satellite =
                    satellite_item.selected_value[0].value.split(' ')[0] || '';
                  sop_value.processing_level =
                    satellite_item.selected_value[0].value.split(' ')[1] || '';
                }
              });
              break;
            case 'Season':
              value_item.input_data?.map((satellite_item) => {
                if (satellite_item.selected_value?.length === 1) {
                  sop_value.season = satellite_item.selected_value[0];
                }
              });
              break;
            case 'Cygnus Needed':
              value_item.input_data?.map((satellite_item) => {
                if (satellite_item.selected_value?.length === 1) {
                  sop_value.cygnus_needed = Number(
                    satellite_item.selected_value[0].value
                  );
                }
              });
              break;
            case 'Table Update Flag':
              value_item.input_data?.map((satellite_item) => {
                if (satellite_item.selected_value?.length === 1) {
                  sop_value.acr_table_update_flag = Number(
                    satellite_item.selected_value[0].value
                  );
                }
              });
              break;
            case 'Raster Header':
              value_item.input_data?.map((satellite_item) => {
                if (satellite_item.selected_value?.length === 1) {
                  sop_value.raster_header = satellite_item.selected_value[0];
                }
              });
              break;
            case 'Spectral Bands':
              let band1, band2;

              value_item.input_data?.map((band_item, band_index) => {
                if (band_index == 0) {
                  band1 = band_item.selected_value[0].value;
                } else if (band_index == 1) {
                  band2 = band_item.selected_value[0].value;
                }
              });
              sop_value.bands = {
                band1: band1,
                band2: band2
              };
              break;
            case 'Band Selection':
              value_item.input_data?.map((band_item) => {
                if (band_item.type === 'multiselect_dropdown') {
                  sop_value.bands = {
                    bands_list: band_item.selected_value[0]?.map(
                      (item) => item.value
                    )
                  };
                }
              });
              break;
            case 'Max allowed cloud %':
              value_item.input_data?.map((cloud_item) => {
                sop_value.cloud_perc = '0,' + cloud_item.selected_value[0];
              });
              break;
            case 'Formula':
              value_item.input_data?.map((formula_item) => {
                if (formula_item.selected_value?.length == 1) {
                  sop_value.formula = formula_item.selected_value[0].value;
                } else {
                  sop_value.formula = formula_item.selected_value?.map(
                    (item) => item.value
                  );
                }
              });
              break;
            case 'Stacking':
              value_item.input_data?.map((stacking_item, stacking_index) => {
                if (stacking_item.type === 'dropdown') {
                  if (stacking_index == 0) {
                    sop_value.stacking.method =
                      stacking_item.selected_value[0]?.value;
                  } else if (stacking_index == 1) {
                    sop_value.stacking.days = Number(
                      stacking_item.selected_value[0]?.value
                    );
                  }
                }
              });
              break;
            case 'Masking':
              value_item.input_data?.map((masking_item) => {
                if (masking_item.type === 'multiselect_dropdown') {
                  sop_value.masking = masking_item.selected_value[0]?.map(
                    (item) => Number(item.value)
                  );
                }
              });
              break;
          }
        });
      }
    });
    return sop_value;
  }

  const createRequest = async () => {
    if (selectedProjectOption?.id === 1) {
      toast.error('Please select a valid project.');
    } else {
      let request_details = selectedDataProducts.map((product_card) => {
        let extraction_required = false;
        if ([1, 2, 3].includes(product_card?.gran_data?.value)) {
          extraction_required = true;
        }
        return {
          d3_product_id: product_card.value,
          product_name: product_card.label,
          aoi_id: selectedAOIs,
          platform_sop: calculateSopValue(product_card, product_card.label),
          sop: {
            sop_id: product_card.sop.sop_id,
            name: product_card.sop.name,
            input_data: [...product_card.sop_data]
          },
          data_delivery_format: product_card.format,
          from_date: product_card.startDate.split('/').reverse().join('-'),
          to_date: product_card.endDate.split('/').reverse().join('-'),
          crop: product_card?.crop?.value || null,
          extraction_required: extraction_required,
          extraction_granularity: product_card?.gran_data?.value || null
        };
      });

      let createRequestpayload = {
        name: requestFormData?.requestName,
        description: requestDesc,
        due_date: requestFormData.requestDueDate
          ? moment(requestFormData.requestDueDate)
              .format('YYYY-MM-DD')
              .toString()
          : '',
        project_id: requestFormData?.projectName?.value,
        request_data: request_details
      };
      setConfirmRequestBtn(true);
      const data = await postCreateRequest(createRequestpayload);

      if (data.status === 200) {
        setRequestModal(true);
        setConfirmRequestBtn(false);
        dispatch(requestAction.doRequstFormUpdate({ data: {} }));
      } else if (data.error?.message) {
        toast.error(data?.error?.response?.data?.message);
        setConfirmRequestBtn(false);
      }
    }
  };

  const getOptions = () => {
    let userAccess = checkUserPermisson('project.create');
    let option = ProjectOptions.filter((item) => {
      if (userAccess) {
        return item;
      } else if (item.name !== 'Create New') {
        return item;
      }
    });

    return option;
  };

  return (
    <>
      <CreateProject
        showModal={showModal}
        setShowModal={setShowModal}
        modalType={modalType}
        setModalType={setModalType}
      />
      <div className="confirm-request-body">
        <div className="confirm-request-header">
          <h1>Request Details</h1>
        </div>
        <div className="confirm-request-content">
          <div className="input-div">
            <label className="required">Project</label>
            <Select
              className={'project'}
              placeholder="Select Project"
              classNamePrefix={'project-select'}
              value={
                requestFormData?.projectName
                  ? {
                      name: requestFormData?.projectName?.label,
                      id: requestFormData?.projectName?.value
                    }
                  : selectedProjectOption
              }
              options={getOptions()}
              components={{ Option: IconOption }}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
              onChange={(e) => {
                handleProjectChange(e);
              }}
            />
            <div>
              {requestFormData?.projectName && (
                <p
                  onClick={() => {
                    dispatch(
                      onGetRequestListData(requestFormData?.projectName?.value)
                    );
                    setShowModal(true);
                    setModalType('update');
                  }}
                >
                  View Project Details
                </p>
              )}
            </div>
          </div>
          <div className="input-div">
            <label className="required">Request Name</label>
            <input
              type="text"
              placeholder="Enter request name"
              value={requestFormData.requestName}
              className="input-box"
              onChange={(e) => {
                setRequestForm({
                  ...requestFormData,
                  requestName: e.target.value
                });
                setRequestName(e.target.value);
              }}
            />
            {requestFormData?.requestName &&
              !isValidNameFormat(requestFormData?.requestName) && (
                <p className="error_confirm_request">
                  Only alphanumeric characters allowed
                </p>
              )}
          </div>
          <div className="input-div">
            <label className="required">Request due date</label>
            <div className="date-picker-input">
              <DatePicker
                placeholderText="Select due date"
                className="request-datepicker"
                wrapperClassName="request-datepicker-wrapper"
                classNamePrefix="request-due-date"
                popperClassName="request-datepicker-popper"
                selected={requestFormData.requestDueDate}
                onChange={(date) => {
                  setRequestForm({ ...requestFormData, requestDueDate: date });
                }}
                customInput={<CustomDateInput />}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="confirm-button-div">
            <button
              className="request-button loaders"
              onClick={(e) => {
                if (!isFormValid) {
                  toast.error('Please enter all the request details.');
                } else if (requestFormData?.projectName?.value === 1) {
                  toast.error('Please enter valid project name.');
                } else {
                  createRequest(e);
                }
              }}
              disabled={!isFormValid}
            >
              {confirmRequestBtn ? <Loader /> : <label>Confirm Request</label>}
            </button>
          </div>
        </div>
      </div>

      <Modal isOpen={requestModal} ariaHideApp={false} style={customStyles}>
        <div className="conf-req-sections">
          <div className="conf-req-header">
            <label>Request Submitted Successfully</label>
            <span
              className="conf-req-close"
              onClick={() => {
                window.location.reload();
              }}
            >
              <img src={Close} alt="Close" />
            </span>
          </div>
          <span className="conf-img">
            <img src={Check} alt="success" />
          </span>
          <p className="conf-req-para">
            Your request has been submitted successfully!
          </p>
          <div className="conf-req-btn">
            <div className="view-project-button">
              <button
                onClick={() => {
                  closeModal();
                  setConfirmRequest(false);
                  setSelectedDataProducts([]);
                  setSelectedAOIs([]);
                  setRequestButtonDisabled(true);
                  navigate('/d3/browse');
                  setIsButtonClicked(true);
                  setAOIs([]);
                  setExpand(false);
                }}
              >
                View Project
              </button>
            </div>
            <div>
              <button
                className="btn discard"
                onClick={() => {
                  closeModal();
                  setConfirmRequest(false);
                  setSelectedButton('data');
                  setAOIs([]);
                  setHistoryAOIs([...historyAOIs, ...aois]);
                  setSelectedDataProducts([]);
                  setSelectedAOIs([]);
                  setIsButtonClicked(true);
                  setRequestButtonDisabled(true);
                }}
              >
                Start New
              </button>
              <button
                className="btn request"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmRequest;
