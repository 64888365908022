import { Product } from '../model';

class ProductRepository {
  constructor() {
    this.products = [];
  }

  /**
   * Initializes the products repository by converting an array of products data objects
   * into individual products instances.
   *
   * @param {Array<Object>} products - An array of objects representing products data.
   */
  initializeProducts(products) {
    this.products = new Product(products);
    // this.products = products.map((data) => {
    //     return new Product(data);
    // });
  }

  /**
   * Retrieves all the products objects in the repository.
   *
   * @returns {Array<Product>} An array containing all the products instances.
   */
  getProducts() {
    return this.products;
  }
}

export default ProductRepository;
