import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from '../../store/projects/project-slice';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';
import CreateProject from '../createProject/createProjectModal';
import { requestAction } from '../../store/requests/request-slice.js';
import { MID_DRAWER_HEIGHT, MAX_DRAWER_HEIGHT } from '../../utils/constants';

//Images
// import { images } from '../../assets/images';
import Projects from '../../assets/images/sidebar/projects.svg';
import Plus from '../../assets/images/sidebar/Color.svg';
import ProjectsActive from '../../assets/images/sidebar/projectsActive.svg';
import Right from '../../assets/images/right.svg';
import close from '../../assets/images/cross.svg';
// import DraftsActive from '../../assets/images/sidebar/bookmark_manager_active.svg';
// import Drafts from '../../assets/images/sidebar/bookmark_manager.svg';

// SCSS
import styles from './index.module.scss';
import { images } from '../../assets/images';

const discardModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '36.9rem',
    padding: '1rem'
  }
};

const Sidebar = ({
  children,
  expand,
  setExpand,
  setSelectedButton,
  setCustomDrawerHeight,
  isButtonClicked,
  setIsButtonClicked,
  setSelectedDataProducts,
  setSelectedAOIs,
  setAOIs,
  onCloseTreeLevel,
  selectedDataProducts,
  aois,
  setDrawerVisible
}) => {
  const mountRef = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { requestForm } = useSelector((state) => state.request || {});
  // const { draftTreeData } = useSelector((state) => state.draft);

  const [createNew, setCreateNew] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);

  useEffect(() => {
    if (mountRef.current) {
      if (!createNew) {
        setIsButtonClicked(true);
        setExpand(false);
        dispatch(requestAction.enableExploreSection());
        setSelectedButton('data');
        setCustomDrawerHeight(MID_DRAWER_HEIGHT);
        setDrawerVisible(true);
      }
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNew]);

  const toggleCreateNew = () => {
    if (
      aois.length !== 0 ||
      selectedDataProducts.length !== 0 ||
      requestForm.requestName ||
      requestForm.requestName === 'Untitled Request' ||
      requestForm?.skip
    ) {
      setOpenDiscardModal(true);
    } else {
      setCreateNew(true);
      setExpand(true);
      dispatch(requestAction.disableExploreSection());
      navigate('/d3');
      setSelectedButton('');
      setSelectedDataProducts([]);
      setSelectedAOIs([]);
      setAOIs([]);
      onCloseTreeLevel();
      setDrawerVisible(false);
    }
  };

  const toggleProjects = () => {
    setCustomDrawerHeight(MAX_DRAWER_HEIGHT);
    setExpand(false);
    navigate('/d3/browse');
    dispatch(projectActions.doClearProjectData());
    dispatch(mapCompareAction.clearMapStates());
    dispatch(projectActions.doClearIds());
  };

  const createRequest = () => {
    setSelectedButton('data');
  };

  const toggleCatalog = () => {
    setExpand(false);
    navigate('/d3/catalog');
  };

  // const toggleDrafts = () => {
  //   setExpand(false);
  //   setCustomDrawerHeight(MID_DRAWER_HEIGHT);
  //   setIsButtonClicked(true);
  //   setDrawerVisible(true);
  //   navigate('/d3/drafts');
  // };

  return (
    <div className={styles.sidebar_wrapper}>
      <div className={styles.sidebar_container}>
        <ul className={styles.sidebar_sections}>
          <li
            className={styles.sidebar_list}
            onClick={() => {
              toggleCreateNew();
            }}
          >
            <label>
              <img alt="plus" src={Plus} />
            </label>
            <span>Create</span>
          </li>
          {requestForm?.skip === '' && (
            <li
              className={`${styles.sidebar_list} ${styles.create_new_project} ${
                location.pathname === '/d3' && styles.active
              }`}
              onClick={() => {
                createRequest();
                dispatch(requestAction.enableExploreSection());
                navigate('/d3');
                onCloseTreeLevel();
                dispatch(projectActions.doClearProjectData());
              }}
            >
              <span>{requestForm?.requestName}</span>
            </li>
          )}
          {requestForm?.skip && (
            <li
              className={`${styles.sidebar_list} ${styles.create_new_project} ${
                location.pathname === '/d3'
              }`}
              onClick={() => {
                createRequest();
                dispatch(requestAction.enableExploreSection());
                navigate('/d3');
                onCloseTreeLevel();
                dispatch(projectActions.doClearProjectData());
              }}
            >
              <span>Untitled Request</span>
            </li>
          )}
        </ul>

        <div>
          <ul className={styles.sidebar_sections}>
            <li
              className={`${styles.sidebar_list} ${
                location?.pathname === '/d3/catalog' && styles.active
              }`}
              onClick={() => {
                setSelectedButton('');
                toggleCatalog();
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setSelectedButton('');
                  toggleCatalog();
                }
              }}
              tabIndex="0"
            >
              <label>
                <img
                  alt="catalog"
                  src={
                    location?.pathname.startsWith('/d3/catalog')
                      ? images.catalogActive
                      : images.catalog
                  }
                />
              </label>
              <span>Catalog</span>
            </li>
          </ul>

          <ul className={styles.sidebar_sections}>
            <li
              className={`${styles.sidebar_list} ${
                location?.pathname === '/d3/browse' && styles.active
              }`}
              onClick={() => {
                setSelectedButton('');
                toggleProjects();
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setSelectedButton('');
                  toggleProjects();
                }
              }}
              tabIndex="0"
            >
              <label>
                <img
                  alt="projects"
                  src={
                    location?.pathname.startsWith('/d3/browse')
                      ? ProjectsActive
                      : Projects
                  }
                />
              </label>
              <span>Projects</span>
            </li>
          </ul>
        </div>
      </div>

      {isButtonClicked && (
        <div className={styles.sidebar_collapse_expand_container}>
          <div className={styles.sidebar_collapse_section}>
            <div
              className={`${styles.expand_collapse} ${expand && styles.collapse}`}
            >
              {children}
            </div>

            <div
              className={styles.expand_collapse_arrow}
              onClick={() => setExpand(!expand)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  setExpand(!expand);
                }
              }}
              tabIndex="0"
            >
              <div
                className={`${styles.arrow_icon} ${expand ? '' : styles.arrow_rotate}`}
              >
                <img src={Right} alt="right" />
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal isOpen={openDiscardModal} style={discardModalStyles}>
        <div className={styles.discard_modal_header}>
          <p>Discard Current Request</p>
          <img
            src={close}
            alt="close"
            onClick={(e) => setOpenDiscardModal(false)}
          />
        </div>
        <p className={styles.discard_modal_content}>
          Would you like to discard current request before creating new one?
        </p>
        <div className={styles.discard_modal_footer}>
          <button
            className={styles.cancel_discard_btn}
            onClick={(e) => setOpenDiscardModal(false)}
          >
            Cancel
          </button>
          <button
            className={styles.discard_btn}
            onClick={(e) => {
              dispatch(requestAction.doRequstFormUpdate({ data: {} }));
              setOpenDiscardModal(false);
              if (!location.pathname.startsWith('/d3/browse')) {
                dispatch(projectActions.doClearProjectData());
                dispatch(mapCompareAction.clearMapStates());
                if (location.pathname === '/d3') window.location.reload();
              }
              navigate('/d3');
            }}
          >
            Discard
          </button>
        </div>
      </Modal>

      <CreateProject
        showModal={createNew}
        setShowModal={setCreateNew}
        modalType={'create-new'}
      />
    </div>
  );
};

export default Sidebar;
