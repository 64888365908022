import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

//SERVICES
import { postAddUser, updateUser } from '../../services/setting-services';
import { fetchUserData } from '../../store/userManage/user-actions';
import { roleOptions, customStyles } from '../../utils/constants';

//MODEL
import { User } from '../../model/User';

//IMAGES
import { images } from '../../assets/images';

//SCSS
import styles from './addNewUser.module.scss';

interface AddNewUserProps {
  isOpen: boolean;
  onRequestClose: () => void;
  closeModal: () => void;
  modalType: string;
  userId: string;
}

const AddNewUser: React.FC<AddNewUserProps> = ({
  isOpen,
  onRequestClose,
  closeModal,
  modalType,
  userId
}) => {
  const dispatch: any = useDispatch();
  const userData = useSelector((state: any) => state.userData.userData);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<any>({});

  const isAddUserDisabled = !firstName || !lastName || !email || !role;

  useEffect(() => {
    if (!isOpen) {
      resetFormDetails();
    } else {
      let selectedUser = userData?.find((user: any) => user.id === userId);

      if (modalType === 'edit' && selectedUser) {
        const names = selectedUser.name.split(' ');
        setFirstName(names[0]);
        setLastName(names[1] || '');
        setEmail(selectedUser.email);
        const selectedRole = roleOptions.find(
          (role: any) => role.id === selectedUser.resource_role_id
        );
        setRole(selectedRole);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, userId, userData, modalType]);

  /**
   * ^ Resets the form fields to their initial state.
   */
  const resetFormDetails = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setRole(null);
  };

  const handleEditUser = async () => {
    const selectedUser = userData?.find((user: any) => user.id === userId);

    if (!selectedUser) {
      return;
    }

    const { name, resource_role_id: roleId } = selectedUser;
    const selectedRole = roleOptions.find((role: any) => role.id === roleId);

    if (!selectedRole) {
      return;
    }

    const payload: any = {};

    if (`${firstName} ${lastName}` !== name) {
      payload.name = `${firstName} ${lastName}`;
    }

    if (role.id !== selectedRole.id) {
      payload.role_id = role.id;
    }

    if (Object.keys(payload).length !== 0) {
      const { data, status }: any = await updateUser(userId, payload);

      if (status === 200) {
        closeModal();
        toast.success(data?.message);
        dispatch(fetchUserData());
      }
    } else {
      closeModal();
    }
  };

  const handleCreateUser = async () => {
    const payload = {
      users: [
        {
          name: `${firstName} ${lastName}`,
          email,
          role_id: role ? role.id : null
        }
      ]
    };

    try {
      const { data, status, error }: any = await postAddUser(payload);
      if (status === 200) {
        resetFormDetails();
        closeModal();
        toast.success(data?.message);
        dispatch(fetchUserData());
      } else {
        toast.error(error?.response?.data?.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={customStyles}
    >
      <div className={styles.user_add_modal}>
        {modalType === 'edit' ? (
          <p data-testId="Edit User">Edit user details</p>
        ) : (
          <p data-testId="Add User">Add User</p>
        )}

        <span>
          <img
            src={images.closeIcon}
            alt="close icon"
            onClick={closeModal}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                closeModal();
              }
            }}
            tabIndex={0}
          />
        </span>
      </div>

      <div className={styles.user_modal_body}>
        <div className={styles.user_new_container}>
          <div className={styles.user_new_name}>
            <label>First name*</label>
            <span className={styles.common_input}>
              <input
                type="text"
                placeholder="Enter first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </span>
          </div>

          <div className={styles.user_new_name}>
            <label>Last name*</label>
            <span className={styles.common_input}>
              <input
                type="text"
                placeholder="Enter last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </span>
          </div>
        </div>

        <div className={styles.user_new_email}>
          <label>Email id*</label>
          <span className={styles.common_user_email}>
            <input
              type="email"
              placeholder="Enter email id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={modalType === 'edit' ? styles.edit_email_input : ''}
            />
          </span>
        </div>

        <div className={styles.user_new_email}>
          <label data-testId="Select Role">Role*</label>
          <span className={styles.add_role}>
            <Select
              placeholder="Select role"
              classNamePrefix={'project-select'}
              options={roleOptions}
              menuPlacement="top"
              value={role}
              onChange={setRole as (newValue: any) => void}
            />
          </span>
        </div>
      </div>

      <div className={styles.create_container}>
        <button
          className={`${styles.add_new_cancel}`}
          onClick={closeModal}
          data-testid="Cancel"
        >
          Cancel
        </button>
        <button
          className={`${styles.add_new_button}`}
          onClick={modalType === 'edit' ? handleEditUser : handleCreateUser}
          disabled={isAddUserDisabled}
          data-testId="Add User"
        >
          {modalType === 'edit' ? 'Edit User' : 'Add User'}
        </button>
      </div>
    </Modal>
  );
};

export default AddNewUser;
