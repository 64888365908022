import React from 'react';
import { getNameInitials } from '../../utils/function-helper';
import { getUserDetails } from '../../utils/user-helper';
import { checkProjectLevelPermission } from '../../utils/permission-helper';
import { USER_DETAILS_KEY } from '../../utils/constants';

const CustomMultiValue = ({ data, removeProps, modalType }) => {
  const canUpdateProject = checkProjectLevelPermission('project.update');
  const isEditing = modalType === 'edit';
  const isUser = data.id === getUserDetails(USER_DETAILS_KEY)?.userId;
  const shouldShowButton = isEditing ? isUser || canUpdateProject : !isUser;
  return (
    <div className="multi-select-value">
      <div className="full-name-avatar member-option-avatar">
        <p>{getNameInitials(data.name)}</p>
      </div>
      <span>{data.name}</span>
      {shouldShowButton ? (
        <button onClick={removeProps.onClick}>&#10005;</button>
      ) : null}
    </div>
  );
};

export default CustomMultiValue;
