import { Dispatch } from 'redux';
import { getUserManageDetails } from '../../services/setting-services';
import { fetchUserDataSuccess, fetchUserDataFailure } from './user-slice';

export const fetchUserData = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getUserManageDetails();
      const { data, status } = response as { data: any; status: number };

      if (status === 200) {
        dispatch(fetchUserDataSuccess(data?.data));
      } else {
        dispatch(fetchUserDataFailure('Error fetching user data'));
      }
    } catch (error: any) {
      dispatch(fetchUserDataFailure(error.message));
    }
  };
};
