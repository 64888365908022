import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import SDSControl from 'ol-side-by-side';
import Map from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON.js';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import { Group as LayerGroup } from 'ol/layer.js';
import { Stroke, Style } from 'ol/style.js';
import { transformExtent } from 'ol/proj';

// SCSS
import styles from './index.module.scss';
import 'ol/ol.css';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import MapControls from '../../components/map-controls';
import { D3_WMS_URL } from '../../utils/url-helper';
import { getProductLegendImg } from '../../utils/user-helper';

const MapCompare = () => {
  const { productIdCode } = useSelector((state) => state?.request);
  const { leftLayer, rightLayer } = useSelector((state) => state?.mapCompare);
  const [mapObject, setCompareMapObject] = useState();
  const [mapExtent, setMapExtent] = useState();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const mapElement = useRef(null);
  const mapRef = useRef(null);
  mapRef.current = mapObject;

  useEffect(() => {
    if (
      leftLayer &&
      leftLayer.layerData &&
      leftLayer.layerData.length !== 0 &&
      rightLayer &&
      rightLayer.layerData &&
      rightLayer.layerData.length !== 0
    ) {
      mapLayerMerge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftLayer, rightLayer]);

  const mapLayerMerge = () => {
    let wmsMergeLeftLayer = leftLayer?.layerData;
    let mergeLinkFirst = wmsMergeLeftLayer?.links?.filter(
      (type) => type.rel === 'wms_url'
    );

    const wmsLayer1 = new LayerGroup({
      layers: [
        new TileLayer({
          source: new TileWMS({
            url: `${D3_WMS_URL}${mergeLinkFirst[0]?.href}&api_key=CIEVeK86adStvoGcykZZCSxsBVOLTr28S_wknANWYoY`,
            serverType: undefined,
            transition: 0
          })
        })
      ]
    });

    const singleTransform = transformExtent(
      wmsMergeLeftLayer?.bbox,
      'EPSG:4326',
      'EPSG:3857'
    );

    let wmsMergeRightLayer = rightLayer?.layerData;
    let mergeLinkRight = wmsMergeRightLayer?.links.filter(
      (type) => type.rel === 'wms_url'
    );

    const wmsLayer2 = new TileLayer({
      source: new TileWMS({
        url: `${D3_WMS_URL}${mergeLinkRight[0]?.href}&api_key=CIEVeK86adStvoGcykZZCSxsBVOLTr28S_wknANWYoY`,
        serverType: undefined,
        transition: 0
      })
    });

    const source1 = new VectorSource({
      features: new GeoJSON({
        defaultDataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(wmsMergeRightLayer)
    });

    let sds = new SDSControl();
    let map = new Map({
      controls: defaultControls().extend([
        new FullScreen()
        // new ScaleLine()
      ]),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
          })
        }),
        new VectorLayer({
          source: source1,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, 0.7)',
              width: 5,
              zIndex: 999
            })
          })
        })
      ],
      target: mapElement.current,
      view: new View({
        center: [0, 0],
        zoom: 0
      })
    });
    setMapExtent(singleTransform);
    map.addControl(sds);
    map
      .getView()
      .fit(
        singleTransform,
        { duration: 2000, padding: [50, 50, 50, 50] },
        map.getSize()
      );
    setTimeout(() => {
      compareMap(wmsLayer1, wmsLayer2, map, sds);
    }, 2000);
  };

  const compareMap = (wmsLayer1, wmsLayer2, map, sds) => {
    setCompareMapObject(map);
    sds.open();
    sds.setLeftLayer(wmsLayer1);
    sds.setRightLayer(wmsLayer2);
  };

  const flyToGeometry = () => {
    mapObject
      .getView()
      .fit(mapExtent, { duration: 3000, padding: [50, 50, 50, 50] });
  };

  return (
    <div className={styles.map_comparison_wrapper}>
      <div className={styles.map_comparison_container}>
        <div ref={mapElement} id="map" className={styles.map_sections}>
          <MapControls
            map={mapObject}
            compareLayerName={true}
            flyToGeometry={flyToGeometry}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
          {leftLayer &&
          leftLayer.layerData &&
          leftLayer.layerData.length !== 0 &&
          rightLayer &&
          rightLayer.layerData &&
          rightLayer.layerData.length !== 0 &&
          productIdCode ? (
            <div className={styles.merge_multi_layer_product_legend}>
              <div className={styles.layer_legend}>
                <img
                  src={getProductLegendImg(productIdCode)}
                  alt="product img"
                />
              </div>
              <div className={styles.layer_legend}>
                <img
                  src={getProductLegendImg(productIdCode)}
                  alt="product img"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MapCompare;
