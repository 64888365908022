import { projectActions } from './project-slice';
import {
  getAoiTreeList,
  getProductTreeList,
  getProjectTreeList,
  getRequestTreeList,
  getAoiAutomatedTreeList,
  getAoiManualTreeList,
  getAoiManualDateLayer,
  getBreadcrumbList
} from '../../services/project-tree-services';
import { extractIdsAndNames } from '../../utils/function-helper';
import { mapCompareAction } from '../mapCompare/map-compare-slice';

/**
 * Action creator to fetch project list data.
 */
export const onGetProjectListData = () => {
  return async (dispatch) => {
    const { data, status } = await getProjectTreeList();
    if (status === 200) {
      dispatch(projectActions.getProjectListData({ data: data?.data }));
    }
  };
};

/**
 * Action creator to fetch request list data.
 * @param {string} id - The ID of the project.
 */
export const onGetRequestListData = (id) => {
  return async (dispatch) => {
    const { data, status } = await getRequestTreeList(id);
    if (status === 200) {
      dispatch(projectActions.getRequestListData({ data: data?.data }));
    }
  };
};

/**
 * Action creator to fetch product list data.
 * @param {string} id - The ID of the request.
 */
export const onGetProductListData = (id) => {
  return async (dispatch) => {
    const { data, status } = await getProductTreeList(id);
    if (status === 200) {
      dispatch(projectActions.getProductListData({ data: data?.data }));
    }
  };
};

/**
 * Action creator to fetch AOI list data.
 * @param {string} id - The ID of the product.
 */
export const onGetAoiListData = (id) => {
  return async (dispatch) => {
    const { data, status } = await getAoiTreeList(id);
    if (status === 200) {
      dispatch(projectActions.getAoiListData({ data: data?.data }));
    }
  };
};

/**
 * Action creator to fetch AOI automated date list data.
 * @param {string} id - The ID of the AOI.
 */
export const onGetAoiAutomatedDateListData = (id) => {
  return async (dispatch) => {
    const { data, status } = await getAoiAutomatedTreeList(id);
    if (status === 200) {
      dispatch(
        projectActions.getAoiDatesData({
          data: data?.data?.features || data?.data
        })
      );
    }
  };
};

/**
 * Action creator to fetch AOI manual date list data.
 * @param {string} id - The ID of the AOI.
 */
export const onGetAoiManualDateListData = (id) => {
  return async (dispatch) => {
    const { data, status } = await getAoiManualTreeList(id);
    if (status === 200) {
      dispatch(
        projectActions.getAoiDatesData({
          data: data?.data?.features || data?.data
        })
      );
    }
  };
};

/**
 * Action creator to fetch AOI manual date layer data.
 * @param {Object} aoiDate - The AOI date object containing aoi_mapping_id and date.
 */
export const onGetAoiManualDateLayerData = (aoiDate) => {
  return async (dispatch) => {
    const { data, status } = await getAoiManualDateLayer(
      aoiDate?.aoi_mapping_id,
      aoiDate?.date
    );
    if (status === 200) {
      if (data?.data?.features && data?.data?.features.length >= 0) {
        dispatch(
          mapCompareAction.doSetSingleMapLayer({
            data: data?.data?.features[0],
            id: aoiDate.id
          })
        );
        dispatch(mapCompareAction.doSetActiveDates({ data: [aoiDate.id] }));
      } else {
        dispatch(
          mapCompareAction.doSetSingleMapLayer({
            data: data?.data?.features ? data?.data?.features : [],
            id: aoiDate.id
          })
        );
      }
    }
  };
};

/**
 * Action creator to fetch breadcrumb list data.
 * @param {string} id - The ID of the project, request, or product.
 * @param {string} type - The type of the breadcrumb (project, request, or product).
 */
export const onGetBreadcrumbListData = (id, type, successCallback) => {
  return async (dispatch) => {
    const { data, status } = await getBreadcrumbList(id, type);
    if (status === 200) {
      dispatch(
        projectActions.getBreadCrumbData({
          data: extractIdsAndNames(data?.data)
        })
      );
      if (successCallback) {
        successCallback(extractIdsAndNames(data?.data));
      }
    }
    if (status === 400) {
      window.location.href = '/error';
    }
  };
};
