import React from 'react';
import Navbar from '../components/navbar/navbar';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const { authToken } = useSelector((state) => state?.user);

  const isAuthenticated =
    authToken?.accessToken !== 'null' && authToken?.accessToken;

  return isAuthenticated ? (
    <>
      <Navbar />
      <>{children}</>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
