import React, { useState } from 'react';

/* Helper functions */
import { isPasswordValid } from '../../utils/regex-helper';
import { changePassword } from '../../services/setting-services';
import { toast } from 'react-toastify';

//IMAGES
import passwordshow from '../../assets/images/icons/show-eye.svg';
import passwordhide from '../../assets/images/icons/hide-eye.svg';

// SCSS
import styles from './index.module.scss';

/**
 * <function description>
 *  Defining functional component using React hooks
 * which will manage form data of old and new password
 */
const UpdatePassword = () => {
  const [hideShowPwd, setHideShowPwd] = useState({
    password: false,
    newpassword: false
  });

  const [formData, setFormData] = useState({
    oldPassword: '',
    newpassword: '',
    confirmPassword: ''
  });

  const [error, setError] = useState({
    oldPassword: '',
    newpassword: '',
    confirmPassword: ''
  });

  /**
   * <function description>
   * Function to set the form data and clear the corresponding error msgs
   * whenever the value of input field changes
   */
  const onChangeHandler = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
    setError((prev) => {
      return {
        ...prev,
        [e.target.name]: ''
      };
    });
  };

  /**
   * <function description>
   * Function to handle password update
   * Updating error state
   * dispalying success or failed toast accordingly
   */
  const onUpdatePassword = async () => {
    const errorObj = { ...error };
    if (formData.oldPassword === '') {
      errorObj.oldPassword = 'Enter Old Password ';
    }
    if (formData.newpassword === '') {
      errorObj.newpassword = 'Enter New Pasword';
    }
    if (formData.confirmPassword === '') {
      errorObj.confirmPassword = 'Enter Confirm Passowrd';
    }

    if (!isPasswordValid(formData.newpassword)) {
      errorObj.newpassword = 'Enter Valid Password';
    }

    if (formData.newpassword !== formData.confirmPassword) {
      errorObj.confirmPassword = 'Password Not Matching';
    }

    if (formData.newpassword === formData.confirmPassword) {
      errorObj.confirmPassword = '';
    }
    if (formData.oldPassword === formData.newpassword) {
      errorObj.newpassword = 'New password is same as Old password';
    }

    setError(errorObj);

    if (
      errorObj.oldPassword === '' &&
      errorObj.newpassword === '' &&
      errorObj.confirmPassword === ''
    ) {
      let payload = {
        old_password: formData.oldPassword,
        new_password: formData.newpassword
      };
      const { status, error } = await changePassword(payload);
      if (status === 200) {
        setFormData({
          ...formData,
          newpassword: '',
          oldPassword: '',
          confirmPassword: ''
        });
        toast.success('Password Successfully Updated...!');
      }
      if (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  /**
   * <function description>
   *   Function to disable submit button until all form inputs are entered
   */
  const getDisabled = () => {
    if (
      formData.oldPassword !== '' &&
      formData.newpassword !== '' &&
      formData.confirmPassword !== ''
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className={styles.setting_container}>
      <div className={styles.header}>
        <h1>Change your Password</h1>
        <hr></hr>
      </div>
      <div className={styles.password_details}>
        <div className={styles.password_edit}>
          <ul className={styles.passowrd_order_sections}>
            <li className={styles.password_list}>
              <label>Enter old password</label>
              <span>
                <input
                  className={`${styles.password_input} ${
                    error.oldPassword && styles.warning
                  }`}
                  type={hideShowPwd.password ? 'text' : 'password'}
                  name="oldPassword"
                  value={formData.oldPassword}
                  onChange={(e) => onChangeHandler(e)}
                />
                <div className={styles.password_hide_show}>
                  <img
                    src={hideShowPwd.password ? passwordshow : passwordhide}
                    alt="passwordLogo"
                    onClick={() =>
                      setHideShowPwd({
                        ...hideShowPwd,
                        password: !hideShowPwd.password
                      })
                    }
                  />
                </div>
                <label
                  className={`${styles.invalid_password} ${
                    error.oldPassword ? styles.visible : styles.hidden
                  }`}
                >
                  {error.oldPassword}
                </label>
              </span>
            </li>
            <li className={styles.password_list}>
              <label>New password</label>
              <span>
                <input
                  className={`${styles.password_input} ${
                    error.newpassword && styles.warning
                  }`}
                  type={hideShowPwd.newpassword ? 'text' : 'password'}
                  name="newpassword"
                  value={formData.newpassword}
                  onChange={(e) => onChangeHandler(e)}
                />

                <div className={styles.password_hide_show}>
                  <img
                    src={hideShowPwd.newpassword ? passwordshow : passwordhide}
                    alt="passwordLogo"
                    onClick={() =>
                      setHideShowPwd({
                        ...hideShowPwd,
                        newpassword: !hideShowPwd.newpassword
                      })
                    }
                  />
                </div>
                <label
                  className={`${styles.invalid_password} ${
                    error.newpassword ? styles.visible : styles.hidden
                  }`}
                >
                  {error.newpassword}
                </label>
              </span>
            </li>
            <li className={styles.password_list}>
              <label>Re-enter password</label>
              <span>
                <input
                  className={`${styles.password_input} ${
                    error.confirmPassword && styles.warning
                  }`}
                  type={hideShowPwd.confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={(e) => onChangeHandler(e)}
                />
                <div className={styles.password_hide_show}>
                  <img
                    src={
                      hideShowPwd.confirmPassword ? passwordshow : passwordhide
                    }
                    alt="passwordLogo"
                    onClick={() =>
                      setHideShowPwd({
                        ...hideShowPwd,
                        confirmPassword: !hideShowPwd.confirmPassword
                      })
                    }
                  />
                </div>
                <label
                  className={`${styles.invalid_password} ${
                    error.confirmPassword ? styles.visible : styles.hidden
                  }`}
                >
                  {error.confirmPassword}
                </label>
              </span>
            </li>
          </ul>
          <div className={styles.update_btn}>
            <button
              className={styles.update}
              disabled={getDisabled()}
              onClick={() => onUpdatePassword()}
            >
              Change password
            </button>
          </div>
        </div>
        <div className={styles.password_desc_section}>
          <h1>Password requirements</h1>
          <ul className={styles.passowrd_order_list}>
            <li>Must contain atleast 8 characters</li>
            <li>Must contain atleast one Upper case letter</li>
            <li>Must contain atleast one Lower case letter</li>
            <li>Must contain atleast one number</li>
            <li>Must contain atleast one special character</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
