import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import Close from '../../assets/images/icons/close.svg';
import { isValidReason } from '../../utils/function-helper';

const RejectionModal = ({
  isRejectModalOpen,
  setToRejectRequestId,
  setIsRejectModalOpen,
  customStyles,
  handleReject,
  rejectionReason,
  setRejectionReason
}) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setRejectionReason('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (reason) => {
    if (!isValidReason(reason)) {
      setError(true);
    } else {
      setError(false);
    }
    setRejectionReason(reason);
  };

  return (
    <Modal
      isOpen={isRejectModalOpen}
      ariaHideApp={false}
      onRequestClose={() => {
        setIsRejectModalOpen(false);
        setToRejectRequestId(null);
      }}
      style={customStyles}
    >
      <div className="popup-wrapper">
        <div className="rejection-header">
          <h1>Rejection reason</h1>
          <label
            onClick={() => {
              setIsRejectModalOpen(false);
              setToRejectRequestId(null);
            }}
          >
            <img src={Close} alt="close icon" />
          </label>
        </div>
        <div className="rejection-content">
          <h2>
            Description<sup>*</sup>
          </h2>
          <div className="text-area">
            <textarea
              className={
                rejectionReason === ''
                  ? 'reason-text'
                  : error
                    ? 'reason-text red'
                    : 'reason-text'
              }
              placeholder="Specify details for rejection here.."
              value={rejectionReason}
              onChange={(e) => onChangeHandler(e.target.value)}
              data-testid="rejection-reason"
            />
          </div>
          <div className="button-group popup">
            <button
              className="button-cancel popup"
              onClick={() => {
                setIsRejectModalOpen(false);
                setToRejectRequestId(null);
              }}
            >
              Cancel
            </button>
            <button
              className="button-apply popup"
              onClick={handleReject}
              disabled={!(!error && rejectionReason !== '')}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectionModal;
