import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

//Helpers
import { MAX_DRAWER_HEIGHT } from '../../utils/constants';
import { PROJECT_TREE_DATASETS_URL } from '../../utils/url-helper';
import { getLevelAndId } from '../../utils/function-helper';

// Services
import {
  getAoiManualTreeList,
  getAoiAutomatedTreeList
} from '../../services/project-tree-services';

// Redux
import {
  onGetAoiListData,
  onGetProjectListData,
  onGetBreadcrumbListData,
  onGetProductListData,
  onGetRequestListData
} from '../../store/projects/project-actions';
import { projectActions } from '../../store/projects/project-slice';
import { requestAction } from '../../store/requests/request-slice';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';

// Components
import MapLayer from '../../components/openlayer-map';
import MapCompare from '../../components/openlayer-map-comparison';
import Sidebar from '../../components/sidebar/sidebar';
import CustomDrawer from '../../components/dragable-navbar';
import ProjectTree from '../../components/project-tree';

// Images
import { images } from '../../assets/images';

// SCSS
import styles from '../dashboard/index.module.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    borderRadius: '8px',
    textAlign: 'center'
  }
};

/**
 * @typedef {Object} AOIState
 * @property {number} id
 * @property {number} name
 * @property {string} geoJSON
 */

/**
 * @typedef {Object} SelectedAOIState
 * @property {number} id
 */

const Projects = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { aoiListData, requestForm } = useSelector((state) => state?.request);
  const { productData, aoiData, breadcrumbData, currentOpenLevel } =
    useSelector((state) => state?.project);
  const {
    singleView,
    compareView,
    mergeView,
    overlapView,
    leftLayer,
    rightLayer
  } = useSelector((state) => state.mapCompare);
  const [sideBarActiveStatus, setSideBarActiveStatus] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [aois, setAOIs] = useState(/** @type {AOIState[]} */ ([]));
  const [historyAOIs, setHistoryAOIs] = useState(
    /** @type {AOIState[]} */ ([])
  );
  const [selectedAOIs, setSelectedAOIs] = useState(
    /** @type {SelectedAOIState[]} */ ([])
  );

  const [expand, setExpand] = useState(false);
  const [selectedDataProducts, setSelectedDataProducts] = useState([]);
  const [customDrawerHeight, setCustomDrawerHeight] =
    useState(MAX_DRAWER_HEIGHT);
  const [backpress, setBackPress] = useState(['Modify Request']);

  // Project Tree
  const [mapLayerViewType, setMapLayerViewType] = useState({
    left: 'satellite',
    right: 'satellite'
  });

  const [isButtonClicked, setIsButtonClicked] = useState(true);
  const [backPressed, setBackPressed] = useState(false);
  const [parentAoiId, setParentAoiId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(true);
  const [reCallQuantity, setReCallQuantity] = useState(false);
  const [zoomInTreePolygon, setZoomInTreePolygon] = useState();

  useEffect(() => {
    if (
      location.pathname !== '/d3/browse' &&
      location.pathname.startsWith('/d3/browse')
    ) {
      const successCallback = (successBreadcrumbData) => {
        if (successBreadcrumbData.length !== 0 || backPressed) {
          const name = getLevelAndId(location.pathname).name;
          switch (name) {
            case 'project':
              dispatch(
                onGetRequestListData(getLevelAndId(location.pathname).id)
              );
              dispatch(projectActions.doRequestStatus({ data: 'request' }));
              if (backPressed) {
                dispatch(projectActions.doRequestId({ data: '' }));
                dispatch(mapCompareAction.doEnablePreview({ data: true }));
                dispatch(
                  projectActions.doActiveStatus({
                    data: getLevelAndId(location.pathname).id
                  })
                );
              }
              break;
            case 'request':
              dispatch(
                onGetProductListData(getLevelAndId(location.pathname).id)
              );
              dispatch(projectActions.doRequestStatus({ data: 'product' }));
              if (backPressed) {
                dispatch(projectActions.doProductId({ data: '' }));
                dispatch(mapCompareAction.doEnablePreview({ data: true }));
                dispatch(
                  projectActions.doActiveStatus({
                    data: getLevelAndId(location.pathname).id
                  })
                );
                setParentAoiId('');
              }
              break;
            case 'product':
              dispatch(onGetAoiListData(getLevelAndId(location.pathname).id));
              dispatch(projectActions.doRequestStatus({ data: 'aoi' }));
              if (backPressed) {
                dispatch(
                  projectActions.getAoiDatesData({
                    data: []
                  })
                );
                dispatch(projectActions.doAoiId({ data: '' }));
                dispatch(mapCompareAction.doEnablePreview({ data: false }));
                dispatch(
                  projectActions.doActiveStatus({
                    data: getLevelAndId(location.pathname).id
                  })
                );
                setParentAoiId('');
              }
              break;
            case 'aoi':
              dispatch(onGetAoiListData(successBreadcrumbData[2]?.id));
              getTreeQuantityId(getLevelAndId(location.pathname), {
                generation_type: aoiData?.generation_type,
                status_in_percentage: aoiData?.status_in_percentage
              });
              dispatch(projectActions.doRequestStatus({ data: 'aoiDate' }));
              if (backPressed) {
                dispatch(
                  projectActions.doActiveStatus({
                    data: getLevelAndId(location.pathname).id
                  })
                );
                setParentAoiId('');
              }
              break;
            default:
              console.log('error in loading project details');
              break;
          }
          setBackPressed(false);
        }
      };
      dispatch(
        onGetBreadcrumbListData(
          getLevelAndId(location.pathname).id,
          getLevelAndId(location.pathname).name,
          successCallback
        )
      );
    } else {
      dispatch(projectActions.doClearProjectData());
      dispatch(projectActions.doRequestStatus({ data: 'project' }));
      dispatch(projectActions.doProjectId({ data: '' }));
      dispatch(onGetProjectListData());
      setBackPressed(false);
      setParentAoiId('');
      dispatch(
        projectActions.doActiveStatus(getLevelAndId(location.pathname).id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (
      breadcrumbData.length !== 0 &&
      !backPressed &&
      currentOpenLevel === ''
    ) {
      setExpand(true);
    }
  }, [breadcrumbData, currentOpenLevel]);

  useEffect(() => {
    if (
      productData &&
      productData.action_status &&
      productData.action_status === 4 &&
      getLevelAndId(location.pathname)?.name &&
      getLevelAndId(location.pathname)?.name === 'request'
    ) {
      navigate('/error', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  useEffect(() => {
    if (aoiListData && aoiListData.length !== 0) {
      setHistoryAOIs(aoiListData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiListData]);

  useEffect(() => {
    dispatch(projectActions.doClearProjectData());
    if (
      !requestForm?.selectedDataProducts ||
      requestForm?.selectedDataProducts?.length === 0
    )
      dispatch(requestAction.doRequstFormUpdate({ data: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reCallQuantity) {
      getTreeQuantityId(reCallQuantity, aoiData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reCallQuantity]);

  const getTreeQuantityId = async (treeId, aoiData) => {
    dispatch(requestAction.setProductIdCode(aoiData?.product_code));

    setParentAoiId(treeId);
    if (aoiData?.generation_type === 'automated') {
      let projectTreeDataSetsUrl = `${PROJECT_TREE_DATASETS_URL}${treeId.id}/d3-datasets`;
      const { status, error } = await getAoiAutomatedTreeList(
        projectTreeDataSetsUrl
      );
      if (status === 200) {
        dispatch(projectActions.doRequestStatus({ data: 'aoi' }));
      }
      if (error) {
        toast.error(error?.response?.data?.message);
      }
    } else if (aoiData?.generation_type === 'manual') {
      let projectTreeManualUrl = `${PROJECT_TREE_DATASETS_URL}get-aoi-dates?request_aoi_id=${treeId.id}`;

      const { status } = await getAoiManualTreeList(projectTreeManualUrl);
      if (status === 200) {
        dispatch(projectActions.doRequestStatus({ data: 'aoi' }));
      }
    }
  };

  const getRegionAoiList = (regionAoi) => {
    setZoomInTreePolygon(regionAoi);
  };

  const onHandleCloseTreeLevel = () => {
    dispatch(requestAction.setProductIdCode(''));
  };

  return (
    <div className={styles.dashboard_container}>
      <div className={styles.dashboard_wrapper}>
        {/* Side Bar */}
        <Sidebar
          setExpand={setExpand}
          expand={expand}
          sideBarActiveStatus={sideBarActiveStatus}
          setSelectedButton={setSelectedButton}
          setSideBarActiveStatus={setSideBarActiveStatus}
          setCustomDrawerHeight={setCustomDrawerHeight}
          isButtonClicked={isButtonClicked}
          setIsButtonClicked={setIsButtonClicked}
          setSelectedDataProducts={setSelectedDataProducts}
          setAOIs={setAOIs}
          setSelectedAOIs={setSelectedAOIs}
          onCloseTreeLevel={onHandleCloseTreeLevel}
          selectedDataProducts={selectedDataProducts}
          setDrawerVisible={setDrawerVisible}
          aois={aois}
        >
          <ProjectTree />
        </Sidebar>
        {/* Map */}

        {singleView ? (
          <MapLayer
            mapType="singleLayer"
            expand={expand}
            selectedAOIs={selectedAOIs}
            zoomInTreePolygon={zoomInTreePolygon}
          />
        ) : compareView ? (
          <div className={styles.compare_map_wrapper}>
            <div className={styles.compare_map_container}>
              <div className={styles.compare_map_sections}>
                <div className={styles.map_list}>
                  <MapLayer
                    mapType="singleLeftLayer"
                    selectedAOIs={selectedAOIs}
                    mapViewType={mapLayerViewType.left}
                    leftLayer={leftLayer}
                    setMapViewType={(viewType) => {
                      setMapLayerViewType({
                        ...mapLayerViewType,
                        left: viewType
                      });
                    }}
                  />
                </div>
                <div className={styles.map_list}>
                  <MapLayer
                    mapType="singleRightLayer"
                    selectedAOIs={selectedAOIs}
                    mapViewType={mapLayerViewType.right}
                    rightLayer={rightLayer}
                    setMapViewType={(viewType) => {
                      setMapLayerViewType({
                        ...mapLayerViewType,
                        right: viewType
                      });
                    }}
                  />
                </div>
              </div>
              <button
                className={styles.merge_map_icon}
                disabled={
                  leftLayer?.layerData?.length === 0 ||
                  rightLayer?.layerData?.length === 0
                }
                onClick={() => {
                  setZoomInTreePolygon();
                  setIsOpen(true);
                }}
              >
                <img src={images.merge} alt="merge" />
              </button>
            </div>

            <Modal
              isOpen={modalIsOpen}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className={styles.merge_modal_container}>
                <div className={styles.merge_modal_icon}>
                  <img src={images.mergeBlack} alt="Merge" />
                </div>
                <div className={styles.merge_text}>
                  <p>Do you want to merge maps </p>
                  <p>for better analysis for the same AOI ? </p>
                </div>
                <div className={styles.merge_modal_btn}>
                  <button
                    className={styles.yes_btn}
                    onClick={() => {
                      setIsOpen(false);
                      dispatch(
                        mapCompareAction.doDateStatus({
                          data: true
                        })
                      );
                      dispatch(
                        mapCompareAction.doEnableMergeView({
                          data: true
                        })
                      );
                      dispatch(
                        mapCompareAction.doEnableCompareView({
                          data: false
                        })
                      );
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className={styles.skip_btn}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        ) : mergeView ? (
          <div className={styles.compare_map_wrapper}>
            <MapCompare />
            <button
              className={styles.merge_map_icon}
              onClick={() => {
                dispatch(
                  mapCompareAction.doEnableMergeView({
                    data: false
                  })
                );
                dispatch(mapCompareAction.doEnableOverlapView({ data: true }));
              }}
            >
              <img src={images.mergeSplitOverlay} alt="merge split" />
            </button>
          </div>
        ) : (
          overlapView && (
            <div className={styles.compare_map_wrapper}>
              <MapLayer
                leftLayer={leftLayer}
                rightLayer={rightLayer}
                selectedAOIs={selectedAOIs}
                mapType="singleLayerMergeMap"
              />
              <button
                className={styles.merge_map_icon}
                onClick={() => {
                  dispatch(
                    mapCompareAction.doDateStatus({
                      data: false
                    })
                  );
                  dispatch(
                    mapCompareAction.doEnableCompareView({ data: true })
                  );
                }}
              >
                <img src={images.mergeSplit} alt="merge split" />
              </button>
            </div>
          )
        )}
      </div>
      {singleView && (
        <div className={expand ? 'drawer-section' : 'drawer-section active'}>
          {drawerVisible && (
            <CustomDrawer
              aois={aois}
              historyAOIs={historyAOIs}
              setHistoryAOIs={setHistoryAOIs}
              setAOIs={setAOIs}
              setSelectedAOIs={setSelectedAOIs}
              selectedAOIs={selectedAOIs}
              selectedDataProducts={selectedDataProducts}
              setSelectedDataProducts={setSelectedDataProducts}
              sideBarActiveStatus={sideBarActiveStatus}
              setSideBarActiveStatus={setSideBarActiveStatus}
              drawerHeight={customDrawerHeight}
              setDrawerHeight={setCustomDrawerHeight}
              setBackPress={setBackPress}
              backpress={backpress}
              setExpand={setExpand}
              expand={expand}
              setIsButtonClicked={setIsButtonClicked}
              // project
              getRegionAoiList={getRegionAoiList}
              setReCallQuantity={setReCallQuantity}
              setCustomDrawerHeight={setCustomDrawerHeight}
              setBackPressed={setBackPressed}
              setZoomInTreePolygon={setZoomInTreePolygon}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Projects;
