import addIcon from './addWhite.svg';
import down from './down.svg';
import searchIcon from './search.svg';
import searchActive from './searchActive.svg';
import helpCircleIcon from './icons/helpCircle.svg';
import trashIcon from './trash.svg';
import keyIcon from './key.svg';
import editIcon from './edit.svg';
import closeIcon from './icons/close.svg';
import back from './icons/left-arrow.svg';
import rectangle from './icons/Rectangle.svg';
import remove from './remove.svg';
import catalog from './catalog.svg';
import catalogActive from './catalogActive.svg';
import wrong_qa from './icons/qa_checked_wrong.svg';
import correct_qa from './icons/qa_checked.svg';
import greenTick from './icons/mark_pass.svg';
import redCross from './icons/mark_fail.svg';
import sopArrow from './sop-arrow.svg';
import info from './icons/info-tooltip.svg';
import infoBlack from './icons/Info-tooltip-black.svg';
import expandArrow from './icons/down-arrow-grey.svg';
import listIcon from './icons/list.svg';
import fileSearching from './icons/file_searching.svg';
import listBgIcon from './icons/list_bg.svg';
import tileIcon from './icons/tile.svg';
import tileBgIcon from './icons/tile_bg.svg';
import request from './icons/request.svg';
import vertical from './vertical.svg';
import merge from './icons/merge.svg';
import mergeBlack from './icons/merge-black.svg';
import mergeSplit from './icons/merge-split.svg';
import mergeSplitOverlay from './icons/merge-overlay.svg';
import rightArrow from './icons/right_arrow.svg';
import downloadActive from '../images/download.svg';
import progress from '../images/icons/loader.svg';

//datacatalog
import ndvi from './catalog/ndvi.svg';
import ndwi from './catalog/ndwi.svg';
import mndwi from './catalog/mndwi.svg';
import agriRisk from './catalog/agriRisk.svg';
import farmBound from './catalog/farmBound.svg';
import irrigation from './catalog/irrigation.svg';
import croppingInt from './catalog/croppingInt.svg';
import superRes from './catalog/superRes.svg';

//solutioncatalog
import sales from './catalog/solution/sales.svg';
import supply from './catalog/solution/supply.svg';
import inventory from './catalog/solution/inventory.svg';
import proPlanning from './catalog/solution/proPlanning.svg';
import risk from './catalog/solution/risk.svg';
import claim from './catalog/solution/claim.svg';
import deforestation from './catalog/solution/deforestation.svg';
import proExecution from './catalog/solution/proExecution.svg';

import leftArrow from './icons/leftArrow.svg';
import ndviLegend from './legends/temporal-composite-ndvi.svg';
import ndwiLegend from './legends/temporal-compositendwi.svg';
import pin from './pin.svg';
import useCase from './catalog/usecase.svg';
import comingSoon from './alien.svg';

export const images = {
  addIcon,
  down,
  searchIcon,
  helpCircleIcon,
  trashIcon,
  keyIcon,
  editIcon,
  closeIcon,
  back,
  rectangle,
  remove,
  catalog,
  catalogActive,
  wrong_qa,
  correct_qa,
  greenTick,
  redCross,
  sopArrow,
  info,
  infoBlack,
  expandArrow,
  listIcon,
  listBgIcon,
  tileIcon,
  tileBgIcon,
  request,
  merge,
  mergeBlack,
  mergeSplit,
  mergeSplitOverlay,
  vertical,
  leftArrow,
  comingSoon,
  ndvi,
  fileSearching,
  rightArrow,
  searchActive,
  ndwi,
  ndviLegend,
  ndwiLegend,
  pin,
  useCase,
  mndwi,
  agriRisk,
  farmBound,
  irrigation,
  croppingInt,
  superRes,
  sales,
  supply,
  inventory,
  proPlanning,
  risk,
  claim,
  deforestation,
  proExecution,
  downloadActive,
  expandArrow,
  progress
};
