import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../types';

// SCSS
import styles from './index.module.scss';

// IMAGES
import { images } from '../../assets/images/index';

// COMPONENTS
import UserSearch from './userSearch';
import UserTable from './userTable';
import Pagination from '../pagination/pagination';
import AddNewUser from '../addNewUserPopup/addNewUser';

// SERVICES
import { fetchUserData } from '../../store/userManage/user-actions';

// HELPER
import { checkUserPermisson } from '../../utils/permission-helper';
import { PaginationState } from '../../types/user.types';
import { User, UserList } from '../../model/UserList';

const UserDashboard: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.userData);
  const hasPermission = checkUserPermisson('user.create');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationState>({
    page_no: 1,
    page_size: 10
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [activeStatus, setActiveStatus] = useState<string[]>([]);
  const [data, setData] = useState<User[]>([]);

  const handleRoleCheckboxChange = (role: number) => {
    const updatedRoles = [...selectedRoles];
    if (updatedRoles.includes(role)) {
      updatedRoles.splice(updatedRoles.indexOf(role), 1);
    } else {
      updatedRoles.push(role);
    }
    setSelectedRoles(updatedRoles);
  };

  const handleStatusCheckboxChange = (status: string) => {
    const updatedActiveStatus = [...activeStatus];
    if (updatedActiveStatus.includes(status)) {
      updatedActiveStatus.splice(updatedActiveStatus.indexOf(status), 1);
    } else {
      updatedActiveStatus.push(status);
    }
    setActiveStatus(updatedActiveStatus);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalType('');
    setSelectedUserId('');
  };

  const handleEdit = (id: string) => {
    setShowModal(true);
    setModalType('edit');
    setSelectedUserId(id);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: newPage + 1
    }));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSize = parseInt(event.target.value, 10);
    setPagination({
      ...pagination,
      page_size: newSize
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

  const filteredData = Array.isArray(data)
    ? data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm) ||
          item.email.toLowerCase().includes(searchTerm)
      )
    : [];

  const startIndex = (pagination.page_no - 1) * pagination.page_size;
  const endIndex = startIndex + pagination.page_size;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  useEffect(() => {
    if (userData === null) {
      setData([]);
    } else {
      const userListInstance = new UserList(
        userData instanceof UserList
          ? userData.getAllUsers()
          : (userData as User[])
      );

      const allUsers = userListInstance.getAllUsers();
      const updatedData =
        activeStatus.length === 0
          ? allUsers
          : allUsers.filter((item: User) =>
              activeStatus.includes(item.is_active ? 'active' : 'inactive')
            );

      setData(updatedData);
    }
    setPagination({ page_no: 1, page_size: 10 });
  }, [activeStatus, selectedRoles, userData]);

  useEffect(() => {
    if (userData === null) {
      setData([]);
    } else {
      const userListInstance = new UserList(
        userData instanceof UserList
          ? userData.getAllUsers()
          : (userData as User[])
      );
      const allUsers = userListInstance.getAllUsers();
      const updatedData =
        selectedRoles.length === 0
          ? allUsers
          : allUsers.filter((item: User) =>
              selectedRoles.includes(item.resource_role_id)
            );

      setData(updatedData);
    }
  }, [selectedRoles, userData]);

  useEffect(() => {
    dispatch(fetchUserData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className={styles.user_manage_container}>
        {hasPermission ? (
          <div className={styles.user_manage_header}>
            <h1>User Management</h1>
            <div className={styles.user_align_items}>
              <p>Add, invite and manage your organisation’s members</p>
              <div className={styles.btns_container}>
                <button
                  className={`${styles.user_btns} ${styles.add_new_button}`}
                  onClick={() => {
                    setShowModal(true);
                    setModalType('add');
                  }}
                  data-testId="add-new-user-modal"
                >
                  <span>
                    <img src={images.addIcon} alt="addIcon" />
                  </span>
                  Add new user
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.user_manage_header}>
            <h1>All Users </h1>
            <div className={styles.user_align_items}>
              <p>View list of all the users onboarded on the platform.</p>
            </div>
          </div>
        )}

        <UserSearch
          handleSearch={handleSearch}
          hasPermission={hasPermission}
          handleRoleCheckboxChange={handleRoleCheckboxChange}
          handleStatusCheckboxChange={handleStatusCheckboxChange}
          selectedRoles={selectedRoles}
          activeStatus={activeStatus}
        />
        <UserTable
          usersData={paginatedData}
          hasPermission={hasPermission}
          selectedRoles={selectedRoles}
          handleEdit={handleEdit}
        />
        <Pagination
          filteredData={filteredData}
          pagination={pagination}
          setPagination={setPagination}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
      <AddNewUser
        isOpen={showModal}
        onRequestClose={closeModal}
        closeModal={closeModal}
        modalType={modalType}
        userId={selectedUserId}
      />
    </>
  );
};

export default UserDashboard;
