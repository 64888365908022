import React, { useState } from 'react';

// SCSS
import styles from './index.module.scss';

// IMAGES
import { images } from '../../assets/images';

// COMPONENTS
import DataCard from '../dataCatalog/dataCard';
import { catalogTabs } from '../../utils/constants';
import { solutionCatalogSample, Product } from '../../assets/solutionCatalog';

interface SolutionCatalogProps {
  setDataProductSelected: (data: any) => void;
}

const SolutionCatalog: React.FC<SolutionCatalogProps> = ({
  setDataProductSelected
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('All');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const filteredData = solutionCatalogSample.filter((product: Product) => {
    const productNameLower = product.product_name.toLowerCase();
    const shortDescLower = product.short_desc.toLowerCase();
    return (
      selectedTab === 'All' &&
      (productNameLower.includes(searchQuery.toLowerCase()) ||
        shortDescLower.includes(searchQuery.toLowerCase()))
    );
  });

  return (
    <div className={styles.solution_catalog_wrapper}>
      <div className={styles.filter_field}>
        <div className={styles.filter_tabs} data-testId="data-card">
          {catalogTabs.map((tab: string) => (
            <button
              key={tab}
              className={`${styles.filter_tags_btns} ${selectedTab === tab ? styles.btn_selected : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className={styles.filter_search}>
          <label className={styles.catalog_search}>
            <input
              type="text"
              className={styles.catalog_search_input}
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </label>
          <button className={styles.search_icon}>
            <span>
              <img src={images.searchActive} alt="Search Icon" />
            </span>
          </button>
        </div>
      </div>

      <ul className={styles.card_wrapper}>
        {filteredData.map((product: Product, index: number) => (
          <DataCard
            key={index}
            imageUrl={product.product_img}
            title={product.product_name}
            description={product.short_desc}
            setDataProductSelected={setDataProductSelected}
            clickable={
              product.product_name === 'NDVI' || product.product_name === 'NDWI'
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default SolutionCatalog;
