export const D3_BASE_URL = process.env.REACT_APP_BASE_URL;

export const D3_VERSION = 'v1';

export const AUTH_BASE_URL = `${D3_BASE_URL}/v1/auth`;

// SPARTA AUTH URL's
export const SPARTA_AUTH_BASE_URL = `${D3_BASE_URL}/v1/auth`;

export const SPARTA_USER_BASE_URL = `${D3_BASE_URL}/v1/user`;

// Sparta Dashboard Url
export const INDUSTRY_URL = `${SPARTA_USER_BASE_URL}/industry`;

export const ROLE_URL = `${SPARTA_USER_BASE_URL}/role`;

export const CHECK_USER_EMAIL_URL = `${SPARTA_AUTH_BASE_URL}/check-user-email`;

export const LOGIN_URL = `${SPARTA_AUTH_BASE_URL}/login`;

export const CREATE_USER_URL = `${SPARTA_AUTH_BASE_URL}/create-user`;

export const LOGOUT_URL = `${SPARTA_AUTH_BASE_URL}/logout`;

export const REFRESH_TOKEN_URL = `${AUTH_BASE_URL}/refresh-both-tokens`;

export const UPDATE_PASSWORD_URL = `${SPARTA_USER_BASE_URL}/update-password`;

export const USER_DETAILS = `${SPARTA_USER_BASE_URL}/user-details`;

export const SUBMIT_URL = `${SPARTA_USER_BASE_URL}`;

/* Data Request URL's */
export const PRODUCT_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-data-products`;
export const REGIONS_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-region`;
export const RID_LIST_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-rid-list`;
export const CREATE_PROJECT_URL = `${D3_BASE_URL}/${D3_VERSION}/projects/d3-project`;
export const CREATE_REQUEST_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-new-data-request`;
export const GET_ALL_USERS_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-get-all-users`;
export const GET_ALL_PROJECTS_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-get-all-projects`;
export const GET_FILES_NUMBER_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-get-file_count_from_cc`;
export const USER_AOI_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-user-aoi`;
export const UPDATE_PROJECT = `${D3_BASE_URL}/${D3_VERSION}/projects/d3-project`;
export const UPDATE_PROJECT_USERS = `${D3_BASE_URL}/${D3_VERSION}/projects/d3-project-users`;

// Product Available Dates
export const OVER_ALL_PRODUCT_AVAILABLITY_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-overall-product-available-regions`;
export const PRODUCT_AVAILABLE_DATE_URL = `${D3_BASE_URL}/${D3_VERSION}/data/product`;

// Project Tree
export const PROJECT_TREE_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-projects-tree`;
export const GET_BREADCRUMB = `${D3_BASE_URL}/${D3_VERSION}/resource/view`;
export const PROJECT_TREE_DATASETS_URL = `${D3_BASE_URL}/${D3_VERSION}/data/`;
export const AVAILABLE_PRODUCT_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-available-data-products`;
export const DOWNLOAD_AOI_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-bulk-download-url`;
export const UPDATE_QA_QC = `${D3_BASE_URL}/${D3_VERSION}/data/update-qa-qc`;
export const PRE_SIGNED_URL = `${D3_BASE_URL}/${D3_VERSION}/data/generate-pre-signed-upload-url`;
export const PRE_SIGNED_DOWNLOAD_URL = `${D3_BASE_URL}/${D3_VERSION}/data/generate-pre-signed-download-url`;
export const GET_LIST_OF_FILES = `${D3_BASE_URL}/${D3_VERSION}/data`;
export const D3_WMS_URL = `${D3_BASE_URL}/v1/data/geoproxy/d3-wms?`;

export const CHECK_REQUEST_PERMISSION_URL = `${D3_BASE_URL}/${D3_VERSION}/user/get-user-permission`;
export const ACTION_DROPDOWN = `${D3_BASE_URL}/${D3_VERSION}/data`;
export const UPDATE_REQUEST_ADMIN = `${D3_BASE_URL}/${D3_VERSION}/data/d3-update-request-admin`;
export const UPDATE_PROJECT_USER = `${D3_BASE_URL}/${D3_VERSION}/data/update-project-user`;
export const GET_RESOURCES_USERS = `${D3_BASE_URL}/${D3_VERSION}/data/d3-get-resource-users`;
export const CREATE_DATA_PRODCUT_ADMIN = `${D3_BASE_URL}/${D3_VERSION}/data/create-product-admin`;
export const EXTRACTION_DOWNLOAD = `${D3_BASE_URL}/${D3_VERSION}/extraction-request/search`;
export const CREATE_QA_QC_ADMIN = `${D3_BASE_URL}/${D3_VERSION}/aoi-request/create-aoi-qaqc-admin`;
export const CREATE_BULK_AOI_QAQC_ADMINS = `${D3_BASE_URL}/${D3_VERSION}/aoi-request/create-aoi-qaqc-admin`;

// Drafts URL
export const DRAFTS_URL = `${D3_BASE_URL}/${D3_VERSION}/data/d3-draft`;

//userManage
export const GET_ALL_USERS = `${D3_BASE_URL}/${D3_VERSION}/user-management/users`;
export const ADD_ROLE = `${D3_BASE_URL}/${D3_VERSION}/user-management/user`;
export const UPDATE_ROLE = `${D3_BASE_URL}/${D3_VERSION}/user-management`;
