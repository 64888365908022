import React from 'react';
import Select, { components, createFilter, OptionProps } from 'react-select';
import DatePicker from 'react-datepicker';
import CustomDateInput from '../customDateInput/customDateInput';
import { isValidNameFormat } from '../../utils/function-helper';
import { ProjectOption } from '../../types/create.types';
import { RequestForm } from '../../model/RequestForm';

import add from '../../assets/images/icons/add.svg';
import closeIcon from '../../assets/images/icons/close.svg';

interface CreateRequestProps {
  projectOptions: ProjectOption[];
  onCloseModal: () => void;
  requestFormData: RequestForm;
  setRequestForm: React.Dispatch<React.SetStateAction<RequestForm>>;
  handleProjectChange: (selectedOption: ProjectOption | null) => void;
  onCreateRequest: () => void;
}

const CreateRequest: React.FC<CreateRequestProps> = ({
  projectOptions,
  onCloseModal,
  requestFormData,
  setRequestForm,
  handleProjectChange,
  onCreateRequest
}) => {
  const { Option } = components;

  const renderIcon = (id: number | undefined) => {
    if (id === 1) {
      return <img src={add} alt="add icon" />;
    }
    return null;
  };

  const handleProjectSelectChange = (selectedOption: ProjectOption | null) => {
    setRequestForm({
      ...requestFormData,
      projectName: selectedOption
    });
    handleProjectChange(selectedOption);
  };

  const IconOption: React.FC<OptionProps<ProjectOption>> = (props) => (
    <Option
      className={props.data.id === 1 ? 'create-new-option' : ''}
      {...props}
    >
      {renderIcon(props.data.id)}
      {props.data.label}
    </Option>
  );

  return (
    <>
      <div className="modal-header">
        <p>Create New Request</p>
        <img
          src={closeIcon}
          alt="close icon"
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
      <div className="modal-body">
        <div className="project-name-sections">
          <label>
            Project<sup>*</sup>
          </label>
          <Select
            classNamePrefix="project-select"
            placeholder="Select project"
            value={requestFormData?.projectName}
            options={projectOptions}
            components={{ Option: IconOption }}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={(selectedOption) =>
              handleProjectSelectChange(selectedOption as ProjectOption)
            }
            filterOption={createFilter({
              matchFrom: 'start'
            })}
            isSearchable={true}
            data-testid="project-name"
          />
        </div>

        <div className="project-name-sections">
          <label>
            Request Name<sup>*</sup>
          </label>
          <input
            type="text"
            className="input-box"
            placeholder="Enter request name"
            value={requestFormData?.requestName || ''}
            onChange={(e) =>
              setRequestForm({
                ...requestFormData,
                requestName: e.target.value
              })
            }
            data-testid="project-name-input"
          />
          {!isValidNameFormat(requestFormData?.requestName) &&
            requestFormData?.requestName?.length !== 0 && (
              <p>Only alphanumeric characters allowed</p>
            )}
        </div>

        <div className="project-name-sections">
          <label>
            Request Due Date<sup>*</sup>
          </label>
          <div>
            <DatePicker
              placeholderText="Select due date"
              wrapperClassName="create-due-date"
              popperClassName="request-due-date-popper"
              selected={requestFormData?.requestDueDate}
              className="request-date-picker-popper"
              onChange={(date) =>
                setRequestForm({
                  ...requestFormData,
                  requestDueDate: date
                })
              }
              customInput={
                <CustomDateInput
                  value={undefined}
                  onClick={undefined}
                  onChange={undefined}
                  ref={undefined}
                />
              }
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              minDate={new Date()}
            />
          </div>
        </div>
      </div>
      <div className="create-btn-div">
        <button
          className="btn btn-cancel-discard"
          onClick={() => {
            onCloseModal();
          }}
        >
          Skip
        </button>
        <button
          className="create-btn"
          onClick={() => onCreateRequest()}
          disabled={
            !isValidNameFormat(requestFormData?.requestName) ||
            !requestFormData?.projectName
          }
        >
          Start
        </button>
      </div>
    </>
  );
};

export default CreateRequest;
