import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select, { createFilter } from 'react-select';
import moment from 'moment';
import IconOption from './customDropdownOption';

import { checkProjectLevelPermission } from '../../utils/permission-helper';
import {
  getNameInitials,
  getTimeStampFormat,
  joinElementsAfterFourthIndex
} from '../../utils/function-helper';

import Profile from '../../assets/images/icons/profile-button.svg';
import AddMember from '../../assets/images/icons/add-member.svg';

const RequestInformation = ({
  addMemberClicked,
  setAddMemberClicked,
  projectAdminOption,
  setDescribeModal,
  handleUpdateProjectUsers,
  projectMemberOptions
}) => {
  const selectRef = useRef(null);

  const { requestData } = useSelector(
    (state) => state.project || { requestData: null }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        event.target.className !==
          'project-admins__input-container css-qbdosj-Input'
      ) {
        setAddMemberClicked(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef]);

  return (
    <div className="project-user-detail" data-testid="project-user-detail">
      <ul>
        <li>
          <label> Project Created by</label>
          <div className="project-members-data">
            <>
              {requestData &&
              requestData.created_by_name &&
              requestData?.created_by_name?.length === 0 ? (
                <>
                  <img src={Profile} title="Unassigned" alt="" />
                </>
              ) : (
                <div
                  className="full-name-avatar"
                  data-tooltip={
                    requestData?.created_by_name
                      ? requestData?.created_by_email
                      : `Not Available`
                  }
                >
                  <p>
                    {requestData && requestData?.created_by_name
                      ? getNameInitials(requestData?.created_by_name)
                      : `NA`}
                  </p>
                </div>
              )}
            </>
          </div>
        </li>

        <li>
          <label>Project Created On</label>
          <span>{getTimeStampFormat(requestData?.created_on)}</span>
        </li>
        <li>
          <label> Project Due Date</label>
          <span>
            {requestData?.due_date
              ? moment(requestData?.due_date).format('DD/MM/YYYY')
              : '-'}
          </span>
        </li>
        <li>
          <label> Last Updated On</label>
          <span>{getTimeStampFormat(requestData?.updated_on)}</span>
        </li>
      </ul>
      <ul style={{ paddingTop: '20px' }}>
        <li>
          <label> Project Admin</label>
          <div
            className="project-members-data"
            data-testid="project-member-add"
          >
            {requestData &&
              requestData.length !== 0 &&
              requestData?.project_admin &&
              requestData?.project_admin?.map((admin, index) => {
                if (index < 4) {
                  return (
                    <div
                      className={`full-name-avatar${
                        index === 3 ? ' avatar-clubbed' : ''
                      }`}
                      style={{
                        right: `${index + 4 * index}px`,
                        zIndex: `${4 - index}`
                      }}
                      data-tooltip={
                        index === 3
                          ? joinElementsAfterFourthIndex(
                              requestData?.project_admin
                            )
                          : admin.email
                      }
                    >
                      {index === 3 ? (
                        <p>+{requestData.project_admin.length - index}</p>
                      ) : (
                        <p>{getNameInitials(admin.name)}</p>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            <img
              src={AddMember}
              alt=""
              onClick={(e) => {
                if (checkProjectLevelPermission('project_admin.create')) {
                  if (addMemberClicked === null) {
                    setAddMemberClicked('project admin');
                  } else {
                    setAddMemberClicked(null);
                  }
                } else {
                  toast.error('Permission denied');
                }
              }}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  checkProjectLevelPermission('project_admin.create')
                ) {
                  if (addMemberClicked === null) {
                    setAddMemberClicked('project admin');
                  } else {
                    setAddMemberClicked(null);
                  }
                }
              }}
              style={{
                marginLeft:
                  requestData?.project_admin?.length === 1 ? '5px' : '0',
                cursor: checkProjectLevelPermission('project_admin.create')
                  ? 'pointer'
                  : 'not-allowed'
              }}
              disabled={!checkProjectLevelPermission('project_admin.create')}
            />
            {addMemberClicked === 'project admin' && (
              <Select
                autoFocus
                id="request-admin-dropdown"
                ref={selectRef}
                menuIsOpen={true}
                placeholder={
                  <div className="project-member-placeholder">
                    <p>Add assignee</p>
                  </div>
                }
                classNamePrefix={'project-admins'}
                closeMenuOnSelect={true}
                components={{ Option: IconOption }}
                options={projectAdminOption}
                filterOption={createFilter({ matchFrom: 'start' })}
                onChange={(e) => {
                  handleUpdateProjectUsers(e, requestData?.id, '', e.value);
                }}
              />
            )}
          </div>
        </li>
        <li>
          <label>Project Member</label>
          <div className="project-members-data">
            {requestData &&
            requestData.length !== 0 &&
            requestData?.project_members &&
            Array.isArray(requestData.project_members)
              ? requestData.project_members.map((member, index) => {
                  if (index < 4) {
                    return (
                      <div
                        className={`full-name-avatar${
                          index === 3 ? ' avatar-clubbed' : ''
                        }`}
                        style={{
                          right: `${index + 4 * index}px`,
                          zIndex: `${4 - index}`
                        }}
                        data-tooltip={
                          index === 3
                            ? joinElementsAfterFourthIndex(
                                requestData?.project_members
                              )
                            : member.email
                        }
                      >
                        {index === 3 ? (
                          <p>+{requestData.project_members.length - index}</p>
                        ) : (
                          <p>{getNameInitials(member.name)}</p>
                        )}
                      </div>
                    );
                  }
                  return null;
                })
              : null}
            <img
              src={AddMember}
              alt="AddMember"
              onClick={(e) => {
                if (checkProjectLevelPermission('project_member.create')) {
                  if (addMemberClicked === null) {
                    setAddMemberClicked('project member');
                  } else {
                    setAddMemberClicked(null);
                  }
                } else {
                  toast.error('Permission denied');
                }
              }}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  checkProjectLevelPermission('project_member.create')
                ) {
                  if (addMemberClicked === null) {
                    setAddMemberClicked('project member');
                  } else {
                    setAddMemberClicked(null);
                  }
                }
              }}
              style={{
                marginLeft:
                  requestData?.project_members?.length === 1 ? '5px' : '0',
                cursor: checkProjectLevelPermission('project_member.create')
                  ? 'pointer'
                  : 'not-allowed'
              }}
              disabled={!checkProjectLevelPermission('project_member.create')}
            />
            {addMemberClicked === 'project member' && (
              <Select
                autoFocus
                id="request-admin-dropdown"
                ref={selectRef}
                placeholder={
                  <div className="project-member-placeholder">
                    <p>Add assignee</p>
                  </div>
                }
                classNamePrefix={'project-admins'}
                components={{ Option: IconOption }}
                options={projectMemberOptions}
                filterOption={createFilter({ matchFrom: 'start' })}
                menuIsOpen={true}
                onChange={(e) => {
                  handleUpdateProjectUsers(e, requestData?.id, e.value, '');
                }}
              />
            )}
          </div>
        </li>

        <li>
          <label> Project Files</label>
          <div className="project_files" data-testid="proejct-files">
            {requestData &&
            requestData.length !== 0 &&
            requestData?.project_files &&
            requestData?.project_files.length !== 0
              ? requestData?.project_files.map((file) => {
                  return <a>{file.name}</a>;
                })
              : '-'}
          </div>
        </li>
        <li>
          <label>Description </label>
          <p className="project_files" onClick={() => setDescribeModal(true)}>
            View Description
          </p>
        </li>
      </ul>
    </div>
  );
};

export default RequestInformation;
