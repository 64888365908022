import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import moment from 'moment';

import ProjectDetails from '../projectDetails/project-details';
import CreateRequest from '../createRequest/create-request';
import CreateProjectDetails from '../createProjectDetails/create-project-details';
import AddAdminMembers from '../projectMembers/project-members';

import { Project } from '../../model/Project';
import { User } from '../../model/User';
import { RequestForm } from '../../model/RequestForm';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  postCreateProject,
  getAllProjects,
  updateProjectDetails,
  updateProjectUsers
} from '../../services/products-service';
import { getResourceUsers } from '../../services/project-tree-services';
import { USER_DETAILS_KEY } from '../../utils/constants';
import { getUserDetails } from '../../utils/user-helper';

import {
  checkUserPermisson,
  checkProjectLevelPermission
} from '../../utils/permission-helper';
import { filterOptionsBasedOnEmail } from '../../utils/function-helper';
import { requestAction } from '../../store/requests/request-slice';
import { projectActions } from '../../store/projects/project-slice';
import { onGetProjectListData } from '../../store/projects/project-actions';

import './createProjectModal.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    padding: '0rem',
    borderRadius: '0.8rem',
    zIndex: '10000'
  }
};

// Define Props interface
interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalType: string | null;
  setModalType: React.Dispatch<React.SetStateAction<string | null>>;
}

const CreateProject: React.FC<Props> = ({
  showModal,
  setShowModal,
  modalType,
  setModalType
}) => {
  const dispatch: any = useDispatch();
  const { requestData } = useSelector((state: any) => state.project || {});
  const { requestForm } = useSelector((state: any) => state?.request || {});

  const [createNewProject, setCreateNewProject] = useState<boolean>(false);
  const [steps, setSteps] = useState<number>(1);
  const [projectOptions, setProjectOptions] = useState<any>([]);
  const [projectAdminOption, setProjectAdminOption] = useState<User[]>([]);
  const [projectMemberOption, setProjectMemberOption] = useState<User[]>([]);

  //project creation state
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [projectName, setProjectName] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [projectAdmin, setProjectAdmin] = useState<User[]>([]);
  const [projectMember, setProjectMember] = useState<User[]>([]);
  const [projectDesc, setProjectDesc] = useState<string>('');

  //request creation state
  const [requestFormData, setRequestFormData] = useState<RequestForm>({
    projectName: null,
    requestName: '',
    requestDueDate: null,
    skip: ''
  });

  //edit project details
  const [projectDetails, setProjectDetails] = useState<any>({
    projectName: '',
    projectDueDate: new Date(),
    uploadedFiles: [],
    removedFiles: [],
    description: '',
    addedMembers: [],
    removedMembers: []
  });

  const canUpdateProject = checkProjectLevelPermission('project.update');

  useEffect(() => {
    fetchAllProjects();
    fetchOrganizationUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (
      requestData?.length !== 0 &&
      requestData?.project_admin &&
      requestData?.project_members
    ) {
      //for edit project details
      if (modalType === 'update') {
        setProjectDetails({
          projectName: requestData.name,
          projectDueDate: requestData.due_date
            ? requestData.due_date
            : new Date(),
          uploadedFiles: requestData.project_files,
          removedFiles: [],
          description: requestData.description,
          addedMembers: [],
          removedMembers: []
        });
        setProjectAdmin(requestData?.project_admin);
        setProjectMember(requestData?.project_members);
        setProjectAdminOption(
          filterOptionsBasedOnEmail(projectAdminOption, [
            ...requestData?.project_admin
          ])
        );
        setProjectMemberOption(
          filterOptionsBasedOnEmail(projectMemberOption, [
            ...requestData?.project_members,
            ...requestData?.project_admin
          ])
        );
      } else {
        setProjectAdmin(requestData?.project_admin);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);

  const resetFormData = () => {
    setProjectAdmin([projectAdmin[0]]);
    setProjectMember([]);
    setDueDate(null);
    setProjectName('');
    setUploadedFiles([]);
    setProjectDesc('');
  };
  const fetchOrganizationUsers = async () => {
    let organizationId = getUserDetails(USER_DETAILS_KEY)?.organization_id;
    const { data, status }: any = await getResourceUsers(
      'organization',
      organizationId,
      ''
    );

    if (status === 200) {
      let userArray: User[] = [];
      let arr = data.data.find(
        (item: User) => item.id === getUserDetails(USER_DETAILS_KEY)?.userId
      );

      setProjectAdmin([
        { name: arr.name, id: arr.id, email: arr.email, role: arr.role }
      ]);

      data?.data?.map((user: User) => {
        userArray.push(user);
      });

      setProjectAdminOption(userArray);

      setProjectMemberOption(
        filterOptionsBasedOnEmail(userArray, [
          { label: arr.name, value: arr.id, email: arr.email, role: arr.role }
        ])
      );
    }
  };

  const fetchAllProjects = async () => {
    const { data, status }: any = await getAllProjects();

    if (status === 200) {
      let arr = [];

      if (checkUserPermisson('project.create')) {
        arr.push({
          label: 'Create New',
          id: 1,
          description: '',
          created_date: ''
        });
      }

      data?.data.map((item: any) => {
        arr.push({
          label: item.name,
          value: item.id
        });
      });

      setProjectOptions(arr);
    }
  };

  function handleProjectChange(e: any) {
    if (e.label === 'Create New') {
      setCreateNewProject(true);
    } else {
      setRequestFormData({ ...requestFormData, projectName: e });
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const allowedFormats = ['.pdf', '.docx', '.xlsx', '.csv'];
      const fileExtension = file.name
        .substring(file.name.lastIndexOf('.'))
        .toLowerCase();

      if (!allowedFormats.includes(fileExtension)) {
        toast.error('Only .pdf, .docx, .xlsx, and .csv formats are accepted');
        return;
      }
      if (modalType === 'update') {
        setProjectDetails({
          ...projectDetails,
          uploadedFiles: [...projectDetails.uploadedFiles, file]
        });
      }
      setUploadedFiles([...uploadedFiles, file]);
    }
  };

  const handleRemoveFile = (index: number) => {
    if (modalType === 'update') {
      let updatedFiles = [...projectDetails.uploadedFiles];
      updatedFiles.splice(index, 1);

      setProjectDetails((prevProjectDetails: any) => ({
        ...prevProjectDetails,
        uploadedFiles: updatedFiles,
        removedFiles: projectDetails.uploadedFiles[index].id
          ? [
              ...prevProjectDetails.removedFiles,
              projectDetails.uploadedFiles[index].id
            ]
          : prevProjectDetails.removedFiles
      }));
    } else {
      const updatedFiles = [...uploadedFiles];
      updatedFiles.splice(index, 1);
      setUploadedFiles(updatedFiles);
    }
  };

  const onChangeAdminHandler = (e: any, action: any) => {
    if (action?.removedValue?.id) {
      if (
        action?.removedValue?.id === getUserDetails(USER_DETAILS_KEY)?.userId
      ) {
        toast.error('You are a project owner by default.');
      } else {
        setProjectDetails((prevProjectDetails: any) => ({
          ...prevProjectDetails,
          removedMembers: [
            ...prevProjectDetails.removedMembers,
            action?.removedValue?.id
          ],
          addedMembers:
            prevProjectDetails?.addedMembers.length !== 0
              ? prevProjectDetails.addedMembers?.filter(
                  (obj: any) => obj.user_id !== action?.removedValue?.id
                )
              : prevProjectDetails.addedMembers
        }));
        const isAdminExistsInOption = projectAdminOption.some(
          (member) => member.id === action.removedValue.id
        );
        if (!isAdminExistsInOption) {
          setProjectAdminOption([...projectAdminOption, action.removedValue]);
        }
        setProjectAdmin(e);
        setProjectMemberOption(
          filterOptionsBasedOnEmail(projectMemberOption, e)
        );
      }
    } else {
      setProjectAdmin(e);
      setProjectMemberOption(filterOptionsBasedOnEmail(projectMemberOption, e));
      setProjectDetails((prevProjectDetails: any) => ({
        ...prevProjectDetails,
        addedMembers: [
          ...prevProjectDetails.addedMembers,
          { user_id: action?.option?.id, role_id: 3 }
        ],
        removedMembers:
          prevProjectDetails?.removedMembers.length !== 0
            ? prevProjectDetails.removedMembers?.filter(
                (obj: any) => obj !== action?.option?.id
              )
            : prevProjectDetails.removedMembers
      }));
    }
  };

  const onChangeMemberHandler = (e: any, action: any) => {
    setProjectMember(e);
    setProjectAdminOption(filterOptionsBasedOnEmail(projectAdminOption, e));

    setProjectDetails((prevProjectDetails: any) => {
      const updatedDetails = { ...prevProjectDetails };

      if (action?.removedValue?.id) {
        updatedDetails.removedMembers = [
          ...updatedDetails.removedMembers,
          action.removedValue.id
        ];
        updatedDetails.addedMembers = updatedDetails.addedMembers.filter(
          (obj: any) => obj.user_id !== action.removedValue.id
        );
        const isMemberExistsInOption = projectMemberOption.some(
          (member) => member.id === action.removedValue.id
        );
        if (!isMemberExistsInOption) {
          setProjectMemberOption([...projectMemberOption, action.removedValue]);
        }
      } else if (action?.option) {
        updatedDetails.addedMembers = [
          ...updatedDetails.addedMembers,
          { user_id: action?.option?.id, role_id: 4 }
        ];
        updatedDetails.removedMembers = updatedDetails.removedMembers.filter(
          (obj: any) => obj !== action.option.id
        );
      }

      return updatedDetails;
    });
  };

  const onCreateRequest = () => {
    const { projectName, requestName, requestDueDate } = requestFormData;
    if (!projectName || !requestName.trim() || !requestDueDate) {
      toast.error('All fields are mandatory.');
      return;
    }
    dispatch(requestAction.doRequstFormUpdate({ data: requestFormData }));
    setShowModal(false);
  };
  const onCloseModal = () => {
    resetFormData();
    if (modalType === 'update') {
      dispatch(projectActions.doClearOnlyProjectData());
      setUploadedFiles([]);
    } else if (modalType === 'create-new') {
      dispatch(requestAction.doRequstFormUpdate({ data: { skip: true } }));
    }

    setShowModal(false);
    setCreateNewProject(false);
    setSteps(0);
  };

  const onUpdateProject = async () => {
    let formData = new FormData();
    if (projectDetails.projectName !== requestData.name)
      formData.append('name', projectDetails.projectName);
    if (projectDetails.projectDueDate !== new Date())
      formData.append(
        'due_date',
        moment(projectDetails.projectDueDate).format('YYYY-MM-DD').toString()
      );
    if (projectDetails.description !== requestData.description)
      formData.append('description', projectDetails.description);
    if (projectDetails.removedFiles.length !== 0)
      formData.append(
        'files_to_be_removed',
        JSON.stringify(projectDetails.removedFiles)
      );
    if (uploadedFiles.length !== 0) {
      uploadedFiles.forEach((item: any) => {
        formData.append('project_files', item);
      });
    }
    if (!formData.entries().next().done) {
      const { status, error }: any = await updateProjectDetails(
        formData,
        requestData?.id
      );
      if (status === 200) {
        dispatch(
          requestAction.doRequstFormUpdate({
            data: {
              ...requestForm,
              projectName: {
                label: projectDetails?.projectName,
                value: requestData?.id
              }
            }
          })
        );
        fetchAllProjects();
        onCloseModal();
        toast.success('Project Details Updated');
      }
      if (error) {
        toast.error('Error in updating the project');
      }
    }

    let userPayload: { [key: string]: any } = {};
    let canUpdateUsers = false;
    if (projectDetails?.addedMembers?.length !== 0) {
      userPayload['user_mapping'] = projectDetails.addedMembers;
      canUpdateUsers = true;
    }

    if (projectDetails?.removedMembers?.length !== 0) {
      userPayload['user_to_be_removed'] = projectDetails?.removedMembers;
      canUpdateUsers = true;
    }
    if (canUpdateUsers) {
      const { userStatus, userError }: any = await updateProjectUsers(
        userPayload,
        requestData?.id
      );

      if (userStatus === 200) {
        toast.success('Project Members Updated');
        onCloseModal();
      }

      if (userError) {
        toast.error('Error in updating the project');
      }
    }
  };

  const onCreateProject = async () => {
    if (
      projectName !== '' &&
      projectAdmin.length !== 0 &&
      projectMember.length !== 0
    ) {
      let member_mapping: any = [];

      if (projectMember.length !== 0) {
        let membersMapping = projectMember.map((item: any) => {
          if (item.value !== getUserDetails(USER_DETAILS_KEY).userId) {
            return { role_id: 4, user_id: item.id };
          }
        });
        member_mapping = member_mapping.concat(membersMapping);
      }

      if (projectAdmin.length !== 0) {
        let projectAdminMapping = projectAdmin.map((item: any) => {
          if (item.value !== getUserDetails(USER_DETAILS_KEY).userId) {
            return { role_id: 3, user_id: item.id };
          }
        });
        member_mapping = member_mapping.concat(projectAdminMapping);
      }

      const project: Project = new Project(
        projectName,
        dueDate,
        uploadedFiles,
        projectDesc,
        member_mapping
      );

      const formData = new FormData();
      formData.append('name', project.name);
      formData.append(
        'due_date',
        project.dueDate
          ? moment(project.dueDate).format('YYYY-MM-DD').toString()
          : ''
      );
      project.file.forEach((item) => {
        formData.append('project_files', item);
      });
      formData.append('description', project.description);
      formData.append('user_mapping', JSON.stringify(project.members));

      const { data, status, error }: any = await postCreateProject(formData);
      if (status === 200) {
        toast.success(data?.message);
        dispatch(
          requestAction.doRequstFormUpdate({
            data: {
              ...requestForm,
              projectName: { label: data?.data?.name, value: data?.data?.id }
            }
          })
        );
        setCreateNewProject(false);
        setSteps(1);
        setRequestFormData({
          ...requestFormData,
          projectName: {
            label: data?.data?.name,
            value: data?.data?.id,
            id: data?.data?.id
          }
        });

        resetFormData();
        fetchAllProjects();

        if (modalType === 'create-new-modal') {
          setShowModal(false);
          dispatch(onGetProjectListData());
        }
      }
      if (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error('Enter all the details');
    }
  };

  return (
    <Modal
      isOpen={showModal}
      ariaHideApp={false}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {!createNewProject &&
        modalType !== 'update' &&
        modalType !== 'create-new-modal' && (
          <CreateRequest
            projectOptions={projectOptions}
            onCloseModal={onCloseModal}
            requestFormData={requestFormData}
            setRequestForm={setRequestFormData}
            handleProjectChange={handleProjectChange}
            onCreateRequest={onCreateRequest}
          />
        )}
      {(createNewProject && steps === 1) ||
      (modalType === 'create-new-modal' && steps !== 2) ? (
        <CreateProjectDetails
          onCloseModal={onCloseModal}
          modalType={modalType}
          setShowModal={setShowModal}
          projectName={projectName}
          setProjectName={setProjectName}
          dueDate={dueDate}
          setDueDate={setDueDate}
          handleFileChange={handleFileChange}
          uploadedFiles={uploadedFiles}
          handleRemoveFile={handleRemoveFile}
          projectDesc={projectDesc}
          setProjectDesc={setProjectDesc}
          setSteps={setSteps}
          setUploadedFiles={setUploadedFiles}
        />
      ) : null}
      {((createNewProject || modalType === 'create-new-modal') &&
        steps === 2) ||
      (modalType === 'update' && steps === 2) ? (
        <AddAdminMembers
          onCloseModal={onCloseModal}
          modalType={modalType}
          projectAdminOption={projectAdminOption}
          projectAdmin={projectAdmin}
          onChangeAdminHandler={onChangeAdminHandler}
          projectMemberOption={projectMemberOption}
          projectMember={projectMember}
          onChangeMemberHandler={onChangeMemberHandler}
          onCreateProject={onCreateProject}
          setSteps={setSteps}
          onUpdateProject={onUpdateProject}
          canUpdateProject={canUpdateProject}
        />
      ) : null}
      {modalType === 'update' && (steps === 1 || steps === 0) && (
        <ProjectDetails
          canUpdateProject={canUpdateProject}
          setSteps={setSteps}
          onCloseModal={onCloseModal}
          projectDetails={projectDetails}
          setProjectDetails={setProjectDetails}
          handleRemoveFile={handleRemoveFile}
          handleFileChange={handleFileChange}
        />
      )}
    </Modal>
  );
};

export default CreateProject;
