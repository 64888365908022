import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

//Api services
import { isValidEmail, isPasswordValid } from '../../utils/regex-helper';
import { getRole, getIndustry } from '../../services/industry-role-services';
import {
  getCheckUserEmail,
  getLogin,
  getNewCreateUser
} from '../../services/auth-services';
import { storeUserDetails } from '../../utils/user-helper';
import { USER_DETAILS_KEY } from '../../utils/constants';
import Loader from '../../components/loader';

// Images
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';
import GoogleIcon from '../../assets/images/google-icon.svg';
import passwordshow from '../../assets/images/icons/show-eye.svg';
import passwordhide from '../../assets/images/icons/hide-eye.svg';

//helper function
import { CLIENT_ID } from '../../utils/constants';

import { useDispatch, useSelector } from 'react-redux';
import {
  onLogin,
  onVerifyUserEmail,
  onGetIndustryOption,
  onGetRoleOptions
} from '../../store/auth/auth-actions';

const LoginComponent = () => {
  const mountRef = useRef(false);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const {
    usersDetailsData,
    loading,
    authToken,
    emailVerifyLoader,
    emailVerify,
    roleOptions,
    industryOptions
  } = useSelector((state) => state.user);

  const [emailVerified, setEmailVerified] = useState('');
  const [passwordVerified, setPasswordVerified] = useState('');
  const [googleGmail, setGoogleGmail] = useState('');
  const [googleToken, setGoogleTokel] = useState('');
  const [industryOption, setIndustryOption] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    industry: null,
    role: null
  });
  const [loaderStatus, setLoaderStatus] = useState({
    emailLoaderStatus: false,
    passwordLoaderStatus: false,
    indRoleLoaderStatus: false
  });
  const [hideShowPwd, setHideShowPwd] = useState({
    password: false,
    reEnterPassword: false
  });
  const [inputvalidations, setInputValidations] = useState({
    email: false,
    password: false,
    industry: false,
    role: false,
    industryName: false,
    roleName: false
  });
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    industryName: '',
    roleName: ''
  });

  /**
   * get email & password value stores it in userData
   * also setInputValidations for email & password
   * @param {string} email  Email Id of the User
   * @param {string} password  Password of the User
   * @param {string} industryName  Industry Name of the User
   * @param {string} roleName  Role Name of the User
   */

  useEffect(() => {
    if (usersDetailsData && authToken && authToken?.accessToken) {
      setEmailVerified('validEmailPassword');
      if (usersDetailsData?.is_verified === false) {
        navigate('/email-verification', {
          state: { email: usersDetailsData?.user_email }
        });
      } else if (
        authToken &&
        authToken?.accessToken === 'null' &&
        authToken?.refreshToken === 'null'
      ) {
        onHandleIndustry();
        setPasswordVerified('passwordSuccess');
      } else {
        storeUserDetails(USER_DETAILS_KEY, usersDetailsData);
        navigate('/d3', { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersDetailsData, authToken]);

  useEffect(() => {
    if (mountRef.current) {
      if (emailVerify && emailVerify.is_registered_user) {
        setEmailVerified('emailSuccess');
      }
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerify]);

  const onHandleIndustry = async () => {
    // const { data, status } = await getIndustry();
    // if (status === 200) {
    //   let arr = data.data.map((item) => {
    //     return {
    //       label: item.name,
    //       value: item.id
    //     };
    //   });
    //   setIndustryOption([...arr]);
    // }
    dispatch(onGetIndustryOption());
    getRolesOption();
  };

  const onChangeHandler = (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
    setInputValidations({ inputvalidations, email: false, password: false });
  };

  /**
   * <function description>
   * Check Email verifications & Get Email Api hitting
   */

  // Email Verifications

  const onVerifyEmail = async () => {
    if (isValidEmail(userData.email)) {
      setLoaderStatus({ ...loaderStatus, emailLoaderStatus: true });
      let emailDetails = {
        email: userData.email
      };

      dispatch(onVerifyUserEmail(emailDetails));
      // const { data, status, error } = await getCheckUserEmail(emailDetails);

      // if (status === 200) {
      //   setLoaderStatus({ ...loaderStatus, emailLoaderStatus: false });
      //   if (data?.is_registered_user) {
      //     setEmailVerified('emailSuccess');
      //   } else {
      //     toast.error('Try Google Sign-in');
      //   }
      // }
      // if (error) {
      //   setLoaderStatus({ ...loaderStatus, emailLoaderStatus: false });
      //   toast.error(error?.response?.data?.message);
      // }
    } else {
      setInputValidations({ inputvalidations, email: true });
    }
  };

  /**
   * <function description>
   * Check Password verifications & Get login Api hitting
   */

  // Password Verifications
  const onVerifyPassword = async () => {
    if (isPasswordValid(userData.password)) {
      const token = await executeRecaptcha('login');
      setLoaderStatus({ ...loaderStatus, passwordLoaderStatus: true });
      let userDetails = {
        email: userData.email,
        password: userData.password,
        google_token: '',
        recaptcha_token: token
      };

      dispatch(onLogin(userDetails));

      // const { data, status, error } = await getLogin(userDetails);
      // if (status === 200) {
      //   setEmailVerified('validEmailPassword');
      //   setLoaderStatus({ ...loaderStatus, passwordLoaderStatus: false });
      //   if (data?.data?.is_verified === false) {
      //     navigate('/email-verification', {
      //       state: { email: data?.data?.user_email }
      //     });
      //   } else {
      //     storeUserDetails(USER_DETAILS_KEY, data.data);
      //     navigate('/d3', { replace: true });
      //   }
      // }
      // if (error) {
      //   setLoaderStatus({ ...loaderStatus, passwordLoaderStatus: false });
      //   toast.error(error?.response?.data?.message);
      // }
    } else {
      setInputValidations({ inputvalidations, password: true });
    }
  };

  /**
   * <function description>
   * @param {string} response for user details
   * Get Google login auth
   */

  // Google Authentication
  const getGoogleLogin = async (response) => {
    setGoogleGmail(jwtDecode(response.credential)?.email);
    setGoogleTokel(response.credential);

    const token = await executeRecaptcha('google_login');

    let userDetails = {
      email: '',
      password: '',
      google_token: response.credential,
      recaptcha_token: token
    };
    dispatch(onLogin(userDetails));
    // const { data, status, error } = await getLogin(userDetails);
    // if (status === 200) {
    //   if (
    //     data.data.accessToken === 'null' &&
    //     data.data.refreshToken === 'null'
    //   ) {
    //     const { data, status } = await getIndustry();
    //     if (status === 200) {
    //       let arr = data.data.map((item) => {
    //         return {
    //           label: item.name,
    //           value: item.id
    //         };
    //       });
    //       setIndustryOption([...arr]);
    //     }
    //     getRolesOption();
    //     setPasswordVerified('passwordSuccess');
    //   } else {
    //     storeUserDetails(USER_DETAILS_KEY, data.data);
    //     navigate('/d3', { replace: true });
    //   }
    // }
    // if (error) {
    //   toast.error(error?.response?.data?.message);
    // }
  };

  /**
   * <function description>
   * If your new user your hitting role options api
   */

  const getRolesOption = async () => {
    dispatch(onGetRoleOptions());
    // const { data, status } = await getRole();
    // if (status === 200) {
    //   let arr = data.data.map((item) => {
    //     return {
    //       label: item.name,
    //       value: item.id
    //     };
    //   });
    //   setRoleOption([...arr]);
    // }
  };

  /**
   * <function description>
   * @param {string} e for user industry name
   */

  // onChangeIndustry
  const onChangeIndustry = (e) => {
    setInputValidations({ ...inputvalidations, industry: false });
    if (e.value !== 4) {
      setUserData({ ...userData, industryName: '' });
    }
  };

  /**
   * <function description>
   * @param {string} e for user select other industry name
   */

  // onChangeIndustryName
  const onChangeIndustryName = (e) => {
    setInputValidations({ ...inputvalidations, industryName: false });
    setUserData({ ...userData, industryName: e.target.value });
  };

  /**
   * <function description>
   * @param {string} e for user role name
   */

  // onChangeRoleName
  const onChangeRoleName = (e) => {
    setUserData({ ...userData, roleName: e.target.value });
    setInputValidations({ ...inputvalidations, roleName: false });
  };

  /**
   * <function description>
   * @param {string} e for user select other role name
   */

  // onChangeRole
  const onChangeRole = (e) => {
    setInputValidations({ ...inputvalidations, role: false });
    if (e.value !== 4) {
      setUserData({ ...userData, roleName: '' });
    }
  };

  /**
   * <function description>
   * Validations for industry & role
   * And then Create new user for dashboard
   */
  // Verify Industry Role
  const onVerifyIndustryRole = async () => {
    let indrolobj = { ...inputvalidations };
    if (selectedOption.industry === null) {
      indrolobj.industry = true;
    }
    if (selectedOption.role === null) {
      indrolobj.role = true;
    }
    if (selectedOption?.industry?.value === 4 && userData.industryName === '') {
      indrolobj.industryName = true;
    }
    if (selectedOption?.role?.value === 4 && userData.roleName === '') {
      indrolobj.roleName = true;
    }
    setInputValidations(indrolobj);
    const token = await executeRecaptcha('login');
    if (
      indrolobj.industry === false &&
      indrolobj.industryName === false &&
      indrolobj.role === false &&
      indrolobj.roleName === false
    ) {
      setLoaderStatus({ loaderStatus, induRoleLoaderStatus: true });
      let createUser = {
        name: '',
        email: '',
        mobile: '9994556719',
        password: '',
        role_id: selectedOption.role?.value,
        role_name: userData.roleName,
        industry_id: selectedOption.industry?.value,
        industry_name: userData.industryName,
        captcha_token: token,
        google_token: googleToken
      };

      const { status, error } = await getNewCreateUser(createUser);
      if (status === 200) {
        setLoaderStatus({ loaderStatus, induRoleLoaderStatus: false });
        navigate('/d3');
      }
      if (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <div className="form-container">
        <div className="common-login-field">
          <h1 className="heading login-heading ">
            Welcome to <br></br>SatSure Sparta
          </h1>
          <div className="login-content">
            {/* Email Id */}
            {emailVerified === '' && passwordVerified === '' && (
              <>
                <div className="form-group">
                  <label className="common-login-field-label">
                    Email <sup>*</sup>
                  </label>
                  <span className="login-input-feild">
                    <input
                      type="email"
                      name="email"
                      value={userData.email}
                      className={`input-text ${
                        inputvalidations.email ? 'red' : 'grey'
                      }`}
                      placeholder="Enter email"
                      onChange={(e) => onChangeHandler(e)}
                      onKeyDown={(e) => e.key === 'Enter' && onVerifyEmail()}
                    />
                  </span>
                  <label
                    className={
                      inputvalidations.email
                        ? 'error-text visible'
                        : 'error-text hidden'
                    }
                  >
                    Please Enter Valid Email
                  </label>
                </div>
                <div className="login-button">
                  <button className="btn-login" onClick={() => onVerifyEmail()}>
                    <label> Continue </label>
                    {emailVerifyLoader ? <Loader /> : null}
                  </button>
                </div>
              </>
            )}

            {/* Password */}
            {emailVerified === 'emailSuccess' && (
              <>
                <div className="form-group">
                  <label className="common-login-field-label">
                    Email <sup>*</sup>
                  </label>
                  <span className="login-input-feild">
                    <input
                      type="email"
                      name="email"
                      value={userData.email}
                      className={`input-text ${
                        inputvalidations.email ? 'red' : 'grey'
                      }`}
                      placeholder="Enter email"
                      onChange={(e) => onChangeHandler(e)}
                      onKeyDown={(e) => e.key === 'Enter' && onVerifyEmail()}
                    />
                  </span>

                  <label
                    className={
                      inputvalidations.email
                        ? 'error-text visible'
                        : 'error-text hidden'
                    }
                  >
                    Please Enter Valid Email
                  </label>
                </div>

                <div className="form-group">
                  <label className="common-login-field-label">
                    Password <sup>*</sup>
                  </label>
                  <span className="login-input-feild">
                    <input
                      type={hideShowPwd.password ? 'text' : 'password'}
                      name="password"
                      value={userData.password}
                      className={
                        inputvalidations.password
                          ? 'input-text red'
                          : 'input-text grey'
                      }
                      placeholder="Enter password"
                      onChange={(e) => onChangeHandler(e)}
                      onKeyDown={(e) => e.key === 'Enter' && onVerifyPassword()}
                    />
                    <img
                      src={hideShowPwd.password ? passwordshow : passwordhide}
                      alt="passwordLogo"
                      onClick={() =>
                        setHideShowPwd({
                          ...hideShowPwd,
                          password: !hideShowPwd.password
                        })
                      }
                      className="passwordIcon"
                    />
                  </span>
                  <label
                    className={
                      inputvalidations.password
                        ? 'error-text visible'
                        : 'error-text hidden'
                    }
                  >
                    Please Enter Valid Password
                  </label>
                </div>
                <div className="forgot-password-text-container">
                  {/* <Link className="forgot-password-text" to="/forgot-password">
                    Forgot password?
                  </Link> */}
                </div>
                <div className="login-button">
                  <button
                    className="btn-login"
                    onClick={() => onVerifyPassword()}
                  >
                    <label> Continue</label>
                    {loading ? <Loader /> : null}
                  </button>
                </div>
              </>
            )}

            {/* Google Auth */}
            {passwordVerified === '' && (
              <>
                <div className="google-login">
                  <GoogleLogin
                    text="continue_with"
                    // context="signin"
                    // type="standard"
                    width="35rem"
                    height="4.4rem"
                    logo_alignment="center"
                    onSuccess={(credentialResponse) => {
                      getGoogleLogin(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </div>
                {/* <div className="signup-link">
                  <label>Don’t have an account?</label>
                  <Link to="/register"> Sign up</Link>
                </div> */}
              </>
            )}

            {/* Industry & Role */}
            {passwordVerified === 'passwordSuccess' && (
              <>
                <div className="continue-mail">
                  <label>
                    <img src={GoogleIcon} alt="Google Logo" />
                  </label>
                  <span>{googleGmail}</span>
                </div>
                <div className="continue-google">
                  <div>
                    <div className="select-sections">
                      <div>
                        <label>
                          Industry <sup>*</sup>
                        </label>
                        <Select
                          placeholder="Select industry"
                          classNamePrefix={
                            inputvalidations.industry
                              ? 'select_red react-select'
                              : 'react-select'
                          }
                          options={industryOptions}
                          value={selectedOption.industry}
                          onChange={(e) => {
                            onChangeIndustry(e);
                            setSelectedOption({
                              ...selectedOption,
                              industry: e
                            });
                          }}
                        />
                      </div>
                      {selectedOption?.industry?.label === 'Others' && (
                        <span className="input-feild">
                          <input
                            type="text"
                            name="industryName"
                            className={
                              inputvalidations.industryName
                                ? 'input-text red'
                                : 'input-text grey'
                            }
                            placeholder="Please specify..."
                            value={userData.industryName}
                            onChange={(e) => {
                              onChangeIndustryName(e);
                            }}
                          />
                        </span>
                      )}
                    </div>
                    <div className="select-sections">
                      <div>
                        <label>
                          Role <sup>*</sup>
                        </label>
                        <Select
                          placeholder="Select role"
                          classNamePrefix={
                            inputvalidations.role
                              ? 'select_red react-select'
                              : 'react-select'
                          }
                          options={roleOptions}
                          value={selectedOption.role}
                          onChange={(e) => {
                            onChangeRole(e);
                            setSelectedOption({ ...selectedOption, role: e });
                          }}
                        />
                      </div>
                      {selectedOption?.role?.label === 'Others' && (
                        <span className="input-feild">
                          <input
                            type="text"
                            name="roleName"
                            className={
                              inputvalidations.roleName
                                ? 'input-text red'
                                : 'input-text grey'
                            }
                            placeholder="Please specify..."
                            value={userData.roleName}
                            onChange={(e) => {
                              onChangeRoleName(e);
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="login-button">
                  <button
                    className="btn-login"
                    onClick={() => onVerifyIndustryRole()}
                  >
                    <label>Continue with Google</label>
                    {loaderStatus.indRoleLoaderStatus ? <Loader /> : null}
                  </button>
                </div>
              </>
            )}

            {/* Use Different Account UI*/}
            {/* {indsuetyRoleVerification !== '' && (
            <div className="final-continue">
              <h1 className="login-heading heading">
                You logged in with your Google account before
              </h1>
              <div className="final-sections">
                <div className="logged-in">
                  <div className="gamil-valid">
                    <label>
                      <img src={GoogleIcon} alt="Google Logo" />
                    </label>
                    <span>{userData.email}</span>
                  </div>
                  <div className="login-button">
                    <button
                      className="btn-login"
                      onClick={() => onVerifyIndustryRole()}>
                      Continue with Google
                    </button>
                  </div>
                </div>
                <div className="signup-link">
                  <Link to="/">Use a different login method</Link>
                </div>
                <hr></hr>
              </div>
            </div>
          )} */}
          </div>
          <div className="powered-by">
            <div className="powered-by-wrapper">
              <label>Powered by</label>
              <div>
                <img src={SatsureLogo} alt="Satsure Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginComponent;
