import React from 'react';
import Select from 'react-select';

const SelectPicker = ({
  index,
  keyValue,
  placeHolder,
  customClassName,
  selectValue,
  selectOptions,
  onChangeOptions
}) => {
  const getOptions = (arr) => {
    return arr.map((item) => {
      return {
        label: item.name,
        value: item.region_id,
        regionLevel: item.region_level
      };
    });
  };

  return (
    <Select
      classNamePrefix={`${customClassName} react-select`}
      key={keyValue}
      placeholder={placeHolder}
      value={selectValue ? { label: selectValue, value: selectValue } : null}
      options={getOptions(selectOptions)}
      onChange={(e) => {
        onChangeOptions(e, index);
      }}
    />
  );
};

export default SelectPicker;
