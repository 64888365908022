import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

import NotFoundSvg from '../../assets/images/banner/notFound.svg';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.notFoundWrapper}>
      <div className={styles.notFoundContainer}>
        <img src={NotFoundSvg} alt="not-found" />
        <h1 className={styles.notFoundHeader}>Oops! Page not found</h1>
        <p className={styles.notFoundSubHeader}>
          Please check the address and try again
        </p>
        <button className={styles.buttonHome} onClick={() => navigate('/d3')}>
          Go Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
