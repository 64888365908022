import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// Component
import MapLayer from '../../components/openlayer-map';
import Sidebar from '../../components/sidebar/sidebar';
import CustomDrawer from '../../components/dragable-navbar';
import DraftsTree from '../../components/draftsTree';
import CurrentRequest from '../../components/currentrequest/currentrequest';
import EmptyDraft from '../../components/emptyDraft';
import Drafts from '../../components/drafts';

//Redux
import {
  getAoiListData,
  getOverallDataProduct
} from '../../store/requests/request-actions';

import { projectActions } from '../../store/projects/project-slice';
import { requestAction } from '../../store/requests/request-slice';
import {
  getDraftProductAction,
  getDraftTree
} from '../../store/drafts/drafts-actions';
import { draftAction } from '../../store/drafts/drafts-slice';

// SCSS
import styles from './index.module.scss';

/**
 * @typedef {Object} AOIState
 * @property {number} id
 * @property {number} name
 * @property {string} geoJSON
 */

/**
 * @typedef {Object} SelectedAOIState
 * @property {number} id
 */

export const defaultDrawerHeight = 90;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { overallData, aoiListData, dataProducts, isExploreOpen, requestForm } =
    useSelector((state) => state?.request);
  const { draftTreeData } = useSelector((state) => state.draft);
  const location = useLocation();
  const path = location.pathname.split('/');
  const [selectedButton, setSelectedButton] = useState('');
  const [aois, setAOIs] = useState(/** @type {AOIState[]} */ ([]));
  const [historyAOIs, setHistoryAOIs] = useState(
    /** @type {AOIState[]} */ ([])
  );

  const [selectedAOIs, setSelectedAOIs] = useState(
    /** @type {SelectedAOIState[]} */ ([])
  );

  const [uploadAOIError, setUploadAOIError] = useState(false);
  const [expand, setExpand] = useState(true);
  const [selectedDataProducts, setSelectedDataProducts] = useState([]);
  const [customDrawerHeight, setCustomDrawerHeight] =
    useState(defaultDrawerHeight);
  const [backpress, setBackPress] = useState(['Modify Request']);
  const [moreProductsOption, setMoreProductsOption] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [productOverall, setProductOverAll] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [zoomInSelectedPolygonId, setZoomInSelectedPolygonId] = useState('');
  const [zoomInUploadPolygon, setZoomInUploadPolygonId] = useState();
  const [draftProject, setDraftProject] = useState('');

  useEffect(() => {
    if (aoiListData && aoiListData.length !== 0) {
      setHistoryAOIs(aoiListData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiListData]);
  useEffect(() => {
    dispatch(projectActions.doClearProjectData());
    dispatch(draftAction.clearState());
    dispatch(getDraftTree());
    fetchData();

    if (
      requestForm?.selectedDataProducts &&
      requestForm?.selectedDataProducts?.length !== 0
    ) {
      setSelectedDataProducts(requestForm?.selectedDataProducts);
      setIsButtonClicked(true);
      setExpand(false);
      dispatch(requestAction.enableExploreSection());
      setSelectedButton('data');
      setDrawerVisible(true);
    } else {
      dispatch(requestAction.doRequstFormUpdate({ data: {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updated_overall = [];
    if (overallData) {
      if (overallData?.length === 0) {
        onOverAllProduct();
      } else {
        selectedDataProducts.map((selected_product) => {
          let selected_data = overallData?.features?.filter((item) => {
            return (
              selected_product?.product_code?.toLowerCase() ===
              item.properties.product_name
            );
          });
          if (selected_data.length !== 0) {
            updated_overall.push(selected_data[0]);
          }
        });

        setProductOverAll({
          features: updated_overall,
          type: 'FeatureCollection'
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataProducts]);

  const fetchData = () => {
    dispatch(getAoiListData());
    onOverAllProduct();
  };

  const onOverAllProduct = () => {
    dispatch(getOverallDataProduct());
  };

  const onHandleCloseTreeLevel = () => {
    dispatch(requestAction.setProductIdCode(''));
  };

  // Drafts
  useEffect(() => {
    if (
      location.pathname === '/d3/drafts' &&
      draftTreeData &&
      draftTreeData.length !== 0
    ) {
      setIsButtonClicked(true);
      setExpand(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftTreeData, location.pathname]);

  const getDraftProduct = (id, title) => {
    dispatch(getDraftProductAction());
    setDraftProject(title);
  };

  const navigateToRequest = (productData) => {
    dispatch(
      requestAction.doRequstFormUpdate({
        data: {
          requestName: productData.name,
          projectName: productData.project,
          skip: ''
        }
      })
    );
    let products = productData.request_data.map((item) => {
      return {
        extraction: false,
        crop_specific: false,
        crops: [],
        crop: '',
        gran_data: '',
        extraction_data: '',
        label: item?.name,
        value: item?.d3_product_id,
        sop: getSopInfo(item),
        sop_data: item?.sop?.input_data,
        desc: 'NDWI is a valuable tool for monitoring and managing water resources, and for understanding the complex interactions between water and the environment.\n\n',
        product_code: 'NDWI',
        startDate: item?.from_date,
        endDate: item?.to_date,
        format: item?.data_delivery_format
      };
    });
    let { selected, removedHistory } = getDraftAoi(
      productData?.request_data[0]?.aoi_id
    );
    setSelectedAOIs(productData?.request_data[0]?.aoi_id);

    setAOIs(selected);

    setHistoryAOIs(removedHistory);

    setSelectedDataProducts(products);
    setSelectedButton('data');
    setExpand(true);
    navigate('/d3');
    setDrawerVisible(true);
    dispatch(requestAction.enableExploreSection());
  };

  const getDraftAoi = (aoi) => {
    let selected = historyAOIs.filter((obj) =>
      aoi.some((obj2) => obj.id === obj2)
    );
    let removedHistory = historyAOIs.filter(
      (obj) => !aoi.some((obj2) => obj.id === obj2)
    );
    return { selected, removedHistory };
  };

  const getSopInfo = (prod) => {
    return dataProducts.find((item) => prod?.d3_product_id === item.id)
      ?.product_sop;
  };

  return (
    <div className={styles.dashboard_container}>
      <div className={styles.dashboard_wrapper}>
        {/* Side Bar */}
        <Sidebar
          setExpand={setExpand}
          expand={expand}
          setSelectedButton={setSelectedButton}
          setProductOverAll={setProductOverAll}
          setCustomDrawerHeight={setCustomDrawerHeight}
          isButtonClicked={isButtonClicked}
          setIsButtonClicked={setIsButtonClicked}
          setSelectedDataProducts={setSelectedDataProducts}
          setAOIs={setAOIs}
          setSelectedAOIs={setSelectedAOIs}
          onCloseTreeLevel={onHandleCloseTreeLevel}
          selectedDataProducts={selectedDataProducts}
          setDrawerVisible={setDrawerVisible}
          aois={aois}
        >
          {isExploreOpen && path[1] === 'd3' && (
            <CurrentRequest
              aois={aois}
              historyAOIs={historyAOIs}
              setHistoryAOIs={setHistoryAOIs}
              setAOIs={setAOIs}
              selectedAOIs={selectedAOIs}
              setSelectedAOIs={setSelectedAOIs}
              uploadAOIError={uploadAOIError}
              setUploadAOIError={setUploadAOIError}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
              selectedDataProducts={selectedDataProducts}
              setSelectedDataProducts={setSelectedDataProducts}
              setMoreProductsOption={setMoreProductsOption}
              moreProductsOption={moreProductsOption}
              setZoomInSelectedPolygonId={setZoomInSelectedPolygonId}
              setZoomInUploadPolygonId={setZoomInUploadPolygonId}
            />
          )}

          {location.pathname === '/d3/drafts' && draftTreeData.length !== 0 ? (
            <DraftsTree getDraftProduct={getDraftProduct} />
          ) : null}
        </Sidebar>
        {/* Map */}

        {path[1] === 'd3' && (
          <MapLayer
            aois={aois}
            selectedAOIs={selectedAOIs}
            expand={expand}
            overall={overallData}
            productOverall={productOverall}
            selectedDataProducts={selectedDataProducts}
            zoomInSelectedPolygonId={zoomInSelectedPolygonId}
            zoomInUploadPolygon={zoomInUploadPolygon}
          />
        )}

        {location.pathname === '/d3/drafts' && draftTreeData.length !== 0 ? (
          <Drafts
            expand={expand}
            navigateRequest={navigateToRequest}
            draftProject={draftProject}
          />
        ) : (
          location.pathname === '/d3/drafts' && <EmptyDraft />
        )}
      </div>

      {location.pathname !== '/d3/drafts' && (
        <>
          {drawerVisible && (
            <CustomDrawer
              aois={aois}
              historyAOIs={historyAOIs}
              setHistoryAOIs={setHistoryAOIs}
              setAOIs={setAOIs}
              setSelectedAOIs={setSelectedAOIs}
              selectedAOIs={selectedAOIs}
              drageBarActiveStatus={selectedButton}
              selectedDataProducts={selectedDataProducts}
              setSelectedButton={setSelectedButton}
              setSelectedDataProducts={setSelectedDataProducts}
              drawerHeight={customDrawerHeight}
              setDrawerHeight={setCustomDrawerHeight}
              setBackPress={setBackPress}
              backpress={backpress}
              setExpand={setExpand}
              expand={expand}
              setIsButtonClicked={setIsButtonClicked}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
