import { toast } from 'react-toastify';
import { persistor } from '../index.js';

//slice
import { authAction } from './auth-slice.ts';

//services
import { getIndustry, getRole } from '../../services/industry-role-services.js';
import {
  getCheckUserEmail,
  getLogin,
  logout
} from '../../services/auth-services.js';

//helper
import { forceLogout } from '../../utils/user-helper.js';

export const onLogin = (payload) => {
  return async (dispatch) => {
    dispatch(authAction.doLogin({ loading: true, data: {} }));
    const { data, status, error } = await getLogin(payload);
    if (status === 200) {
      dispatch(
        authAction.doLogin({
          data: data?.data,
          loading: false
        })
      );
    }
    if (error) {
      dispatch(authAction.doLogin({ loading: false, data: {} }));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const onVerifyUserEmail = (payload) => {
  return async (dispatch) => {
    dispatch(authAction.verifyUserEmail({ loading: true, data: {} }));
    const { data, status, error } = await getCheckUserEmail(payload);
    if (status === 200) {
      if (!data?.is_registered_user) {
        toast.error('Try Google Sign-in');
      }
      dispatch(
        authAction.verifyUserEmail({
          loading: false,
          data: data
        })
      );
    }
    if (error) {
      toast.error(error?.response?.data?.message);
      dispatch(
        authAction.verifyUserEmail({
          loading: false,
          data: {}
        })
      );
    }
  };
};

export const onGetIndustryOption = () => {
  return async (dispatch) => {
    const { status, data } = await getIndustry();
    if (status === 200) {
      dispatch(
        authAction.getIndustryOption({
          data: data?.data
        })
      );
    }
  };
};

export const onGetRoleOptions = () => {
  return async (dispatch) => {
    const { status, data } = await getRole();
    if (status === 200) {
      dispatch(authAction.getRoleOption({ data: data?.data }));
    }
  };
};

export const onLogout = () => {
  return async () => {
    const { status } = await logout();

    if (status === 200) {
      persistor.purge();
      forceLogout();
    }
  };
};
