import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';

// Component
import CreateProject from '../createProject/createProjectModal';
import TreeRender from '../treeview/treeRender';

// Redux
import { onGetProjectListData } from '../../store/projects/project-actions';
import { projectActions } from '../../store/projects/project-slice';
import { mapCompareAction } from '../../store/mapCompare/map-compare-slice';

// Helper Functions
import { checkUserPermisson } from '../../utils/permission-helper';

// Images
import imgCross from '../../assets/images/icons/white_cross.svg';

// CSS
import '../treeview/index.css';
import './index.css';

const ProjectTree = () => {
  const dispatch = useDispatch();
  const { projectData, currentOpenLevel } = useSelector(
    (state) => state.project
  );

  const {
    isCompare,
    isDisableCompare,
    singleMapLayer,
    singleView,
    compareView
  } = useSelector((state) => state.mapCompare);

  const [projectTreeListData, setProjectTreeListData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(onGetProjectListData());
    dispatch(projectActions.doClearIds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectData && projectData.length !== 0) {
      setProjectTreeListData(projectData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  const onSearchProjectTree = (e) => {
    const value = e.target.value.trim();
    if (value) {
      const options = {
        keys: ['name'],
        includeScore: true,
        includeMatches: true,
        threshold: 0.0
      };
      const fuse = new Fuse(projectData, options);
      const result = fuse.search(value);
      setProjectTreeListData(result.map((tree) => tree.item));
    } else {
      setProjectTreeListData(projectData);
    }
  };

  const handleCheckBox = () => {
    dispatch(
      mapCompareAction.doDateStatus({
        data: false
      })
    );
    dispatch(mapCompareAction.doEnableSingleView({ data: !singleView }));
    dispatch(mapCompareAction.doHandleCompare({ data: !isCompare }));
    dispatch(mapCompareAction.doSetActiveDates({ data: [currentOpenLevel] }));
    dispatch(mapCompareAction.doEnableCompareView({ data: !compareView }));
    dispatch(
      mapCompareAction.doSetLeftLayer({
        data: singleMapLayer.layerData,
        id: singleMapLayer.id
      })
    );
  };

  const mappedProjectListData = useMemo(() => {
    return projectTreeListData.map((item) => {
      return {
        id: item?.id,
        status: item?.status_in_percentage,
        name: item?.name
      };
    });
  }, [projectTreeListData]);

  return (
    <div className="projects-comp-content-wrapper">
      <div className="projects-comp-form-group">
        <div className="projects-header">
          <div className="create_new_project">
            {checkUserPermisson('project.create') && (
              <span onClick={() => setShowModal(true)}>Create new project</span>
            )}
          </div>

          <button
            disabled={isDisableCompare}
            className={`btn compare_btn ${isCompare ? 'bg' : ''}`}
            onClick={() => {
              handleCheckBox();
            }}
          >
            Compare
            {isCompare && (
              <img
                className="compare-button-cross"
                src={imgCross}
                alt="cross"
              />
            )}
          </button>
        </div>

        <input
          type="text"
          placeholder="Search Projects"
          onChange={(e) => onSearchProjectTree(e)}
        />
      </div>

      <div className="project-tree-container">
        <ul>
          {projectTreeListData && projectTreeListData.length !== 0 && (
            <TreeRender
              treeListData={mappedProjectListData}
              requestType={'project'}
            />
          )}
        </ul>
      </div>

      <CreateProject
        showModal={showModal}
        setShowModal={setShowModal}
        modalType="create-new-modal"
      />
    </div>
  );
};
export default ProjectTree;
