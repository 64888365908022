/**
 * Represents data related to a request.
 */
export class RequestData {
  /**
   * Constructs an instance of RequestData.
   * @param {object} requestData - The data object containing request data.
   */
  constructor(requestData) {
    this.assignRequestData(requestData);
  }

  /**
   * Assigns request data to the instance properties.
   * @param {object} requestData - The data object containing request data.
   */
  assignRequestData(requestData) {
    this.action_status = requestData.action_status;
    this.comment = requestData.comment;
    this.description = requestData.description;
    this.due_date = requestData.due_date;
    this.id = requestData.id;
    this.name = requestData.name;
    this.request_admin = requestData.request_admin;
    this.request_created_on = requestData.request_created_on;
    this.requested_by_email = requestData.requested_by_email;
    this.requested_by_name = requestData.requested_by_name;
    this.role = requestData.role;
    this.status_in_percentage = requestData.status_in_percentage;
  }

  /**
   * Retrieves request data information.
   * @returns {object} - An object containing request data information.
   */
  getRequestDataInfo() {
    return {
      action_status: this.action_status,
      comment: this.comment,
      description: this.description,
      due_date: this.due_date,
      id: this.id,
      name: this.name,
      request_admin: this.request_admin,
      request_created_on: this.request_created_on,
      requested_by_email: this.requested_by_email,
      requested_by_name: this.requested_by_name,
      role: this.role,
      status_in_percentage: this.status_in_percentage
    };
  }
}
