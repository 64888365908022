import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RequestTable from './projectRequestTable';
import RequestInformation from './projectRequestInformation';
import RejectionModal from './rejectionModal';
import DescriptionModal from './descriptionModal';
import { USER_DETAILS_KEY } from '../../utils/constants';
import { getUserDetails } from '../../utils/user-helper';
import {
  updateAction,
  updateRequestAdmin,
  updateProjectUser,
  getResourceUsers,
  updateRequestDelivery
} from '../../services/project-tree-services';
import { filterOptionsBasedOnEmail } from '../../utils/function-helper';
import { checkProjectLevelPermission } from '../../utils/permission-helper';
import {
  onGetProductListData,
  onGetRequestListData
} from '../../store/projects/project-actions';
import { projectActions } from '../../store/projects/project-slice';

// Images
import { images } from '../../assets/images';

import './index.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    width: '419px',
    height: '370px',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.29)'
  }
};

const descStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    width: '826px',
    height: '503px',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.29)'
  }
};

const ProjectRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestData } = useSelector(
    (state) => state.project || { requestData: null }
  );

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [actionDataArray, setActionDataArray] = useState([]);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [describeModal, setDescribeModal] = useState(false);
  const [toRejectRequestId, setToRejectRequestId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isRequestSectionExpanded, setIsRequestSectionExpanded] =
    useState(true);

  const [requestAdminClicked, setIsRequestAdminClicked] = useState(null);
  const [requestAdminOptions, setRequestAdminOptions] = useState([]);
  const [addMemberClicked, setAddMemberClicked] = useState(null);
  const [projectMemberOptions, setProjectMemberOptions] = useState([]);
  const [projectAdminOption, setProjectAdminOptions] = useState([]);
  const [requestDates, setRequestDates] = useState(
    Array(requestData?.request_data?.length).fill(null)
  );

  useEffect(() => {
    if (requestData && requestData?.id) {
      fetchOrganizationUsers();
      setActionDataArray(
        requestData?.request_data?.map(() => ({
          status: '',
          comment: ''
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);

  const fetchOrganizationUsers = async () => {
    let organizationId = getUserDetails(USER_DETAILS_KEY)?.organization_id;

    const { data, status } = await getResourceUsers(
      'organization',
      organizationId,
      ''
    );
    if (status === 200) {
      let userArray = [];
      data?.data?.map((user) => {
        userArray.push({
          value: user.id,
          label: user.name,
          email: user.email,
          role: user.role
        });
      });
      setProjectAdminOptions(
        filterOptionsBasedOnEmail(
          userArray,
          requestData && requestData.project_admin
            ? [...requestData.project_admin]
            : []
        )
      );
      setProjectMemberOptions(
        filterOptionsBasedOnEmail(userArray, [
          ...requestData?.project_members,
          ...requestData?.project_admin
        ])
      );
    }
  };

  const fetchRequestAdminMembers = async (
    requestId,
    scope_type,
    scope_id,
    scope_level
  ) => {
    const { data, status } = await getResourceUsers(
      scope_type,
      scope_id,
      scope_level
    );

    if (status === 200) {
      let userArray = [];
      userArray.push({
        value: '',
        label: 'Unassigned',
        email: '',
        role_id: 2
      });
      data?.data?.map((user) => {
        userArray.push({
          value: user.id,
          label: user.name,
          email: user.email,
          role_id: user.role
        });
      });
      setRequestAdminOptions(userArray);
      setIsRequestAdminClicked(requestId);
    }
  };

  const handleUpdateRequestAdmin = async (label, userId, requestId) => {
    const payload = {
      request_id: requestId,
      request_admin: userId
    };
    const response = await updateRequestAdmin(payload);
    if (response.status === 200) {
      dispatch(onGetRequestListData(requestData?.id));
      setIsRequestAdminClicked(null);
    } else if (response.status === 400) {
      toast.error(response.error.message);
    }
  };
  const handleUpdateProjectUsers = async (
    selected_user,
    projectId,
    projectMember,
    projectAdmin
  ) => {
    let payload;

    if (projectMember === '') {
      payload = {
        project_id: projectId,
        project_admin: projectAdmin
      };
    } else if (projectAdmin === '') {
      payload = {
        project_id: projectId,
        project_member: projectMember
      };
    }

    const response = await updateProjectUser(payload);
    if (response.status === 200) {
      if (projectAdmin === '') {
        setProjectMemberOptions(
          filterOptionsBasedOnEmail(projectMemberOptions, [selected_user])
        );
      } else if (projectMember === '') {
        setProjectAdminOptions(
          filterOptionsBasedOnEmail(projectAdminOption, [selected_user])
        );
        setProjectMemberOptions(
          filterOptionsBasedOnEmail(projectMemberOptions, [selected_user])
        );
      }
      dispatch(onGetRequestListData(projectId));
      setAddMemberClicked(null);
    } else if (response.status === 400) {
      toast.error(response.error.message);
    }
  };
  const toggleRequestSectionExpand = () => {
    if (!requestAdminClicked)
      setIsRequestSectionExpanded(!isRequestSectionExpanded);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleRequestRowClick = (request, e) => {
    if (!requestAdminClicked) {
      dispatch(projectActions.doRequestStatus({ data: 'product' }));
      dispatch(onGetProductListData(request.id));
      dispatch(projectActions.doRequestId({ data: request.id }));
      navigate('/d3/browse/request/' + request.id, { replace: true });
    }
  };

  const toggleActionDropdown = (e, index) => {
    e.stopPropagation();
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleAction = async (status, requestId, rowIndex) => {
    const payload = {
      status,
      comment: status === 'Reject' ? rejectionReason.trim() : null
    };

    const response = await updateAction(payload, requestId);

    if (response.status === 200) {
      const newArray = [...actionDataArray];
      newArray[rowIndex] = { status, comment: '' };
      setActionDataArray(newArray);
      dispatch(onGetRequestListData(requestData?.id));
    }
    if (response.status === 400) {
      toast.error(response?.error?.response?.data?.message);
    }
  };

  const handleDateChange = async (date, index) => {
    if (!checkProjectLevelPermission('project.update')) {
      toast.error('User does not have permission to update request');
      return;
    }

    const newRequestDates = [...requestDates];
    newRequestDates[index] = date;
    setRequestDates(newRequestDates);

    const requestId = requestData.request_data[index].id;
    const formattedDate = formatDate(date);

    const response = await updateRequestDelivery(
      {
        due_date: formattedDate
      },
      requestId
    );

    if (response.status === 200) {
      dispatch(onGetRequestListData(requestData?.id));
      toast.success(response.data.message);
    } else {
      toast.error('Failed to update request delivery');
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  const handleApprove = (requestId, rowIndex) => {
    const request = requestData.request_data[rowIndex];
    if (!checkProjectLevelPermission('request.approve')) {
      toast.error('Permission denied');
      return;
    }
    if (request.request_admin.length === 0 && !request.due_date) {
      toast.error('Request admin field and due date is mandatory.');
      return;
    }
    if (request.request_admin.length === 0) {
      toast.error('Request admin field is mandatory.');
      return;
    }
    if (!request.due_date) {
      toast.error('Due date field is mandatory.');
      return;
    }
    handleAction('Approve', requestId, rowIndex);
  };

  const handleReject = () => {
    if (toRejectRequestId !== null) {
      handleAction(
        'Reject',
        toRejectRequestId.requestId,
        toRejectRequestId.rowIndex
      );
      setIsRejectModalOpen(false);
      setToRejectRequestId(null);
    }
  };

  return (
    <div className="project-detail-container">
      <div className="project-detail-wrapper">
        <div
          className={`project-user-container ${isExpanded ? 'expanded' : ''}`}
          data-testid="project-user-container"
        >
          <div
            className="project-user-title"
            data-testid="project-user-title"
            onClick={() => toggleExpand()}
          >
            <img
              src={images.expandArrow}
              alt="Pmage"
              style={{
                transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'
              }}
            />
            <h2>Project Details</h2>
          </div>
          {isExpanded && (
            <RequestInformation
              addMemberClicked={addMemberClicked}
              setAddMemberClicked={setAddMemberClicked}
              projectAdminOption={projectAdminOption}
              setDescribeModal={setDescribeModal}
              handleUpdateProjectUsers={handleUpdateProjectUsers}
              projectMemberOptions={projectMemberOptions}
            />
          )}
        </div>

        <div
          className={`project-request-section ${
            isRequestSectionExpanded ? 'expanded' : ''
          }`}
        >
          <div
            className="project-user-title"
            onClick={() => toggleRequestSectionExpand()}
          >
            <img
              src={images.expandArrow}
              alt="Expand Arrow"
              style={{
                transform: isRequestSectionExpanded
                  ? 'rotate(0deg)'
                  : 'rotate(-90deg)'
              }}
            />
            {requestData && requestData.length !== 0 && (
              <h2
                className="project-request-header"
                data-testId="request-section"
              >
                Requests ({requestData?.request_data?.length})
              </h2>
            )}
          </div>
          {isRequestSectionExpanded && (
            <RequestTable
              fetchRequestAdminMembers={fetchRequestAdminMembers}
              handleRequestRowClick={handleRequestRowClick}
              requestAdminClicked={requestAdminClicked}
              requestAdminOptions={requestAdminOptions}
              handleUpdateRequestAdmin={handleUpdateRequestAdmin}
              requestDates={requestDates}
              handleDateChange={handleDateChange}
              actionDataArray={actionDataArray}
              toggleActionDropdown={toggleActionDropdown}
              openDropdownIndex={openDropdownIndex}
              handleApprove={handleApprove}
              setToRejectRequestId={setToRejectRequestId}
              setIsRejectModalOpen={setIsRejectModalOpen}
              setIsRequestAdminClicked={setIsRequestAdminClicked}
            />
          )}
        </div>
      </div>
      {isRejectModalOpen && (
        <RejectionModal
          isRejectModalOpen={isRejectModalOpen}
          customStyles={customStyles}
          handleReject={handleReject}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          setIsRejectModalOpen={setIsRejectModalOpen}
          setToRejectRequestId={setToRejectRequestId}
        />
      )}
      {describeModal && (
        <DescriptionModal
          descStyles={descStyles}
          describeModal={describeModal}
          setDescribeModal={setDescribeModal}
          descriptionContent={requestData?.description}
        />
      )}
    </div>
  );
};

export default ProjectRequest;
