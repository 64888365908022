import React, { useState } from 'react';

import SettingSideBar from '../../components/setting-sidebar';
import Profile from '../../components/profile/profile';
import UpdatePassword from '../../components/updatepassword';
import UserDashboard from '../../components/userManagement';

// SCSS
import styles from './index.module.scss';

const Setting = () => {
  const [active, setActive] = useState('profile');

  return (
    <div className={styles.setting_wrapper}>
      <div className={styles.setting_sections}>
        <SettingSideBar active={active} setActive={setActive} />
      </div>
      <div className={styles.setting_container}>
        {active === 'profile' && <Profile />}
        {active === 'password' && <UpdatePassword />}
        {active === 'user-management' && <UserDashboard />}
      </div>
    </div>
  );
};

export default Setting;
