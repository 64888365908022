import React from 'react';

import './index.css';

import NoDraftsImg from '../../assets/images/drafts/no-drafts-selected.svg';

const NoDrafts = ({ expand }) => {
  return (
    <div className={expand ? 'draft-wrapper' : 'draft-wrapper active'}>
      <div className="no-drafts-wrapper">
        <div>
          <img src={NoDraftsImg} alt="no-draft-empty" />
        </div>
        <label className="no-draft-sub-header">
          You don't have any drafts selected.
        </label>
        <label className="no-draft-sub-header">
          Select drafts to view more details here.
        </label>
      </div>
    </div>
  );
};

export default NoDrafts;
