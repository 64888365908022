import React, { useEffect, useState } from 'react';
import KmlToGeoJSON from '@mapbox/togeojson';
import gjv from 'geojson-validation';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

// Actions
import { regionAction } from '../../store/region/region-slice';

// GeoJson
import SampleGeoJson from '../../assets/json/sample.geojson';

// Services
import { getRegions } from '../../services/products-service';
import { deleteAoi, uploadAoi } from '../../services/aoi-services';

//Components
import DeleteAoiModal from '../delete-aoi-modal';
import ListRid from '../listRids';
import Loader from '../loader';

// Images
import Upload from '../../assets/images/icons/upload.svg';
import Trash from '../../assets/images/icons/trash.svg';
import Location from '../../assets/images/icons/location.svg';
import Back from '../../assets/images/icons/left-arrow.svg';
import redirect from '../../assets/images/icons/blue-redirect-arrow.svg';

// CSS
import './index.css';

const Explore = ({
  aois,
  historyAOIs,
  setHistoryAOIs,
  setAOIs,
  selectedAOIs,
  setSelectedAOIs,
  uploadAOIError,
  setUploadAOIError,
  setZoomInSelectedPolygonId,
  setZoomInUploadPolygonId
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [aoiMarkingMode, setAoiMarkingMode] = useState(true);
  const [showMaxLimitMessage, setShowMaxLimitMessage] = useState(false);
  const [isDeleteAOIModalOpen, setIsDeleteAOIModalOpen] = useState(false);
  const [isRidListModalOpen, setIsRidListModalOpen] = useState(false);
  const [deleteModalPosition, setDeleteModalPosition] = useState({
    top: 0,
    left: 0
  });
  const [trianglePosition, setTrianglePosition] = useState('top');
  const [toDeleteAOI, setToDeleteAOI] = useState(null);
  const [regionOption, setRegionOption] = useState([]);
  const [searchParam, setSearchParam] = useState('');

  const { aoiUploadingStatusList } = useSelector((state) => state.region);

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParam !== '') {
      const toUploadAois = [
        { name: searchParam?.name, id: searchParam?.value }
      ];
      regionUploadAoi(toUploadAois);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  const closeModal = () => {
    setIsDeleteAOIModalOpen(false);
    setToDeleteAOI(null);
    setHoveredItem(null);
  };

  const customDeleteModalStyles = {
    content: {
      top: deleteModalPosition.top - 31,
      left: deleteModalPosition.left - 7,
      background: 'transparent',
      width: 'min-content',
      bottom: 'auto',
      padding: '0rem',
      border: 'none',
      overflow: 'hidden'
    }
  };

  const kmlParser = new DOMParser();
  const onAoiUpload = async (e) => {
    if (e.target.files.length !== 0) {
      setUploadAOIError(false);
      const reader = new FileReader();
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        //for file greater than 10 mb
        const emptyFileDiv = document.getElementById('empty-file-error');
        const emptyFileDiv_div = document.createTextNode(
          `'${e.target.files[0]?.name}' file exceeds maximum limit.`
        );
        emptyFileDiv.appendChild(emptyFileDiv_div);
        setShowMaxLimitMessage(true);
      } else {
        const emptyFileDiv = document.getElementById('empty-file-error');
        emptyFileDiv.innerHTML = '';
        const fileName = e.target.files[0]?.name;
        const fileType = fileName.split('.').pop();
        setShowMaxLimitMessage(false);
        reader.onload = async (e) => {
          const object = e.target.result;

          if (fileType === 'kml' || fileType === 'geojson') {
            try {
              const validJson =
                fileType === 'kml'
                  ? KmlToGeoJSON.kml(
                      kmlParser.parseFromString(object, 'text/xml')
                    )
                  : JSON.parse(object);

              if (validJson.features && validJson.features.length > 0) {
                validationsKmlGeoJson(validJson, fileName);
              } else {
                setUploadAOIError(true);
              }
            } catch (error) {
              setUploadAOIError(true);
            }
          } else {
            setUploadAOIError(true);
          }
        };
        reader.readAsText(e.target.files[0]);
      }
    } else {
      const emptyFileDiv = document.getElementById('empty-file-error');
      const emptyFileDiv_div = document.createTextNode(
        `'${e.target.files[0]?.name}' file is empty.`
      );
      emptyFileDiv.appendChild(emptyFileDiv_div);
    }
  };

  const handleCheckboxChange = (item) => {
    const updatedSelected = selectedAOIs.filter(
      (existingId) => existingId !== item.id
    );
    setSelectedAOIs(updatedSelected);

    const updatedAOIs = aois.filter((aoi) => aoi.id !== item.id);
    setAOIs(updatedAOIs);

    setHistoryAOIs([item, ...historyAOIs]);
  };

  const handleHistoryCheckboxChange = (item) => {
    const updatedHistoryAOIs = historyAOIs.filter((aoi) => aoi.id !== item.id);
    setHistoryAOIs(updatedHistoryAOIs);

    setAOIs([...aois, item]);
    setSelectedAOIs((prevSelected) => [...prevSelected, item.id]);
  };

  const openModal = (e, item) => {
    const divElement = document.getElementById(item.id);
    const rect = divElement.getBoundingClientRect();
    if (window.innerHeight - rect.y > 165) {
      setDeleteModalPosition({ top: rect.y, left: rect.x + rect.width });
      setTrianglePosition('top');
      setIsDeleteAOIModalOpen(true);
      setToDeleteAOI(item);
    } else {
      setDeleteModalPosition({ top: rect.y - 110, left: rect.x + rect.width });
      setTrianglePosition('bottom');
      setIsDeleteAOIModalOpen(true);
      setToDeleteAOI(item);
    }
  };

  const handleDelete = async () => {
    const { data, status, error } = await deleteAoi(toDeleteAOI.id);
    if (status === 200) {
      toast.success(data?.message);
      const updatedHistoryAOIs = historyAOIs.filter(
        (aoi) => aoi.id !== toDeleteAOI.id
      );
      setHistoryAOIs(updatedHistoryAOIs);

      if (selectedAOIs.includes(toDeleteAOI.id)) {
        const updatedSelectedAOIs = selectedAOIs.filter(
          (id) => id !== toDeleteAOI.id
        );
        setSelectedAOIs(updatedSelectedAOIs);
        setAOIs(aois.filter((item) => item.id !== toDeleteAOI.id));
      }
    }
    if (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsDeleteAOIModalOpen(false);
    setHoveredItem(null);
  };

  const validationsKmlGeoJson = async (polygonData, fileName) => {
    if (
      polygonData?.features.every(
        (feature) =>
          feature.geometry.type === 'Polygon' && gjv.isPolygon(feature.geometry)
      )
    ) {
      const existingAOI = historyAOIs.find((aoi) => aoi.name === fileName);
      if (existingAOI) {
        const updatedHistoryAOIs = historyAOIs.filter(
          (aoi) => aoi.name !== fileName
        );
        setHistoryAOIs(updatedHistoryAOIs);
        setAOIs([...aois, existingAOI]);
        setSelectedAOIs((prevSelected) => [...prevSelected, existingAOI.id]);
      } else {
        const existingSelectedAOI = aois.find((aoi) => aoi.name === fileName);
        if (existingSelectedAOI) {
          setSelectedAOIs((prevSelected) => [
            ...prevSelected,
            existingSelectedAOI.id
          ]);
          setAOIs([...aois, existingSelectedAOI]);
        } else {
          dispatch(regionAction.addAoiUploadStatus([fileName]));
          const payload = {
            aoi: [
              {
                name: fileName,
                aoi_geojson: polygonData
              }
            ]
          };
          const { data, status, error } = await uploadAoi(payload);
          if (status === 200) {
            dispatch(regionAction.removeAoiUploadStatus([fileName]));
            const id = data.data?.newly_added_aoi[0]?.id;
            setSelectedAOIs((prevSelected) => [...prevSelected, id]);
            setAOIs([...aois, { id, name: fileName, aoi: polygonData }]);
          }
          if (error) {
            dispatch(regionAction.removeAoiUploadStatus([fileName]));
            toast.error(error?.message);
          }
        }
      }
    } else {
      setUploadAOIError(true);
    }
  };

  const onFeatureFocus = (focusId) => {
    setZoomInSelectedPolygonId(focusId);
  };

  const regionUploadAoi = async (toUploadAois) => {
    let toAddAois = [];
    let toAddSelectedAois = [];

    setSearchParam('');
    setIsRidListModalOpen(false);

    const uploadingAoiName = toUploadAois.map(
      (item) => `${item?.name}_${item?.id}`
    );

    dispatch(regionAction.addAoiUploadStatus(uploadingAoiName));
    const toUploadRidList = toUploadAois.map((item) => item.id);
    const payload = { rid: toUploadRidList };
    const { data, status, error } = await uploadAoi(payload);

    if (status === 200) {
      dispatch(regionAction.removeAoiUploadStatus(uploadingAoiName));

      const newlyAddedAois =
        data?.data?.newly_added_aoi?.map((item) => ({
          id: item?.id,
          name: item?.name,
          aoi: JSON.parse(item?.geometry.replace(/'/g, '"'))
        })) || [];
      const newAoiIds = newlyAddedAois.map((item) => item.id);

      toAddAois = [...toAddAois, ...newlyAddedAois];
      toAddSelectedAois = [...toAddSelectedAois, ...newAoiIds];

      const existingAois = data?.data?.existing_aoi || [];

      if (existingAois.length > 0) {
        const existingAoiCount = existingAois.length;
        const totalAoiCount = newAoiIds.length + existingAoiCount;

        toast.info(
          `${existingAoiCount} out of ${totalAoiCount} selected RIDs are already added`
        );

        const updatedHistoryAOIs = historyAOIs.filter(
          (aoi) =>
            !existingAois.some((existingAoi) => existingAoi.id === aoi.id)
        );

        let existing_aois = historyAOIs.filter((aoi) =>
          existingAois.some((existingAoi) => existingAoi.id === aoi.id)
        );

        if (existing_aois.length !== 0) {
          const toAddExistingAois =
            existingAois?.map((item) => ({
              id: item?.id,
              name: item?.name,
              aoi: JSON.parse(item?.geometry.replace(/'/g, '"'))
            })) || [];
          const toAddExistingAoisIds = toAddExistingAois.map((item) => item.id);

          setHistoryAOIs(updatedHistoryAOIs);
          toAddAois = [...toAddAois, ...toAddExistingAois];
          toAddSelectedAois = [...toAddSelectedAois, ...toAddExistingAoisIds];
        }
      }

      setSelectedAOIs((prevSelected) => [
        ...prevSelected,
        ...toAddSelectedAois
      ]);
      setAOIs((prevAois) => [...prevAois, ...toAddAois]);
    }
    if (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const documentation = () => {
    setIsRidListModalOpen(!isRidListModalOpen);
  };

  const fetchRegionData = async (param, callback) => {
    let arr = [];
    if (!isNaN(param) && param !== '' && param.length > 7) {
      const { data, status } = await getRegions(param);
      if (status === 200) {
        arr = data.data.map((item) => {
          let label;
          label = `${item.region_id}, ${item.name} , ${item.parent_name}`;
          return {
            label: label,
            value: item.region_id,
            sop: item.product_sop,
            level: item.level_name,
            parent_name: item.parent_name,
            region_level: item.region_level,
            region_id: item.region_id,
            name: item.name
          };
        });
        arr = arr.map((option) => ({
          ...option,
          label: (
            <>
              <span>{option.label}</span>
              <span>{option.region_level}</span>
            </>
          )
        }));
      }
    } else if (isNaN(param) && param !== '' && param.length >= 3) {
      const { data, status } = await getRegions(param);
      if (status === 200) {
        arr = data.data.map((item) => {
          let label;
          label = `${item.name} , ${item.parent_name}`;
          return {
            label: label,
            value: item.region_id,
            sop: item.product_sop,
            level: item.level_name,
            parent_name: item.parent_name,
            region_level: item.region_level,
            region_id: item.region_id,
            name: item.name
          };
        });
        arr = arr.map((option) => ({
          ...option,
          label: (
            <div className="custom-select-option">
              <span>{option.label}</span>
              <span
                style={{ float: 'right', color: 'grey' }}
                className="region-level"
              >
                {option.region_level}
              </span>
            </div>
          )
        }));
      }
    }
    setRegionOption(arr);
    callback(arr);
  };

  const loadSuggestions = debounce(fetchRegionData, 1000);

  return (
    <>
      {aoiMarkingMode ? (
        <>
          <div className="content-wrapper">
            <div className="form-group-field">
              <span className="region-selection">
                <AsyncSelect
                  placeholder="Search Region"
                  classNamePrefix={'members'}
                  options={regionOption}
                  loadOptions={loadSuggestions}
                  value={searchParam}
                  onChange={(e) => {
                    setSearchParam(e);
                  }}
                  onKeyDown={() => {
                    const emptyFileDiv =
                      document.getElementById('empty-file-error');
                    emptyFileDiv.innerHTML = '';
                    setShowMaxLimitMessage(false);
                  }}
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: 'inherit',
                      height: 'auto !important'
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      marginRight: '3rem'
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      height: 'auto !important'
                    })
                  }}
                />
              </span>

              <label
                htmlFor="file-upload"
                className={`btn-toggle ${
                  (uploadAOIError || showMaxLimitMessage) && searchParam === ''
                    ? 'error'
                    : ''
                }`}
              >
                Upload Shapefile{/* */}
                <img src={Upload} alt="upload" />
                <input
                  id="file-upload"
                  type="file"
                  accept=".geojson,.kml"
                  onChange={onAoiUpload}
                />
              </label>
              {uploadAOIError && searchParam === '' && (
                <div className="error-message">
                  Upload AOI in correct format.
                </div>
              )}

              <div className="error-message" id="empty-file-error"></div>

              {showMaxLimitMessage && (
                <div className="error-message">
                  Maximum allowed file size is 10 mb.
                </div>
              )}

              <div className="file-constraints">
                <div className="list-of-rid-div">
                  <p
                    className="list-of-rid"
                    onClick={() => {
                      documentation();
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        documentation();
                      }
                    }}
                    tabIndex="0"
                  >
                    List of RIDs
                  </p>
                  <label>
                    <img src={redirect} alt="Up arrow" />
                  </label>
                </div>
                <div className="list-of-rid-div">
                  <a
                    className="list-of-rid"
                    href={SampleGeoJson}
                    download="sample_geojson.geojson"
                  >
                    Download Sample
                  </a>
                </div>
              </div>

              <div className="file-contraints-div">
                <p>Supported format : .kml, .geojson</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card">
              <div className="card-header">
                <p className="title">Selected Shapefile</p>
              </div>
              {aois.length > 0 || aoiUploadingStatusList.length > 0 ? (
                <div className="card-body">
                  {aois.length > 0 &&
                    aois
                      .filter(
                        (item) => !aoiUploadingStatusList.includes(item.name)
                      )
                      .map((item) => {
                        return (
                          <div
                            key={item.id}
                            id={item.id}
                            className={`checkbox-container ${
                              hoveredItem === item.id
                                ? 'checkbox-container-hovered'
                                : ''
                            }`}
                            onMouseEnter={(e) => {
                              setHoveredItem(item.id);
                              const rect = e.target.getBoundingClientRect();
                              setHoverPosition({
                                top: rect.top,
                                left: rect.left
                              });
                            }}
                            onMouseLeave={() => {
                              if (!isDeleteAOIModalOpen) {
                                setHoveredItem(null);
                              }
                            }}
                          >
                            <label className="filename" title={item.name}>
                              {item.name}
                            </label>
                            {hoveredItem === item.id && (
                              <div
                                className="hover-icons"
                                style={{
                                  top: hoverPosition.top,
                                  left: hoverPosition.left
                                }}
                              >
                                <img
                                  src={Location}
                                  alt="Locate"
                                  onClick={() => onFeatureFocus(item.id)}
                                />
                                <img
                                  src={Trash}
                                  alt="Delete"
                                  onClick={(e) => {
                                    openModal(e, item);
                                  }}
                                />
                              </div>
                            )}
                            <input
                              type="checkbox"
                              name="languages"
                              value={item.id}
                              checked={selectedAOIs.includes(item.id)}
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </div>
                        );
                      })}
                  {aoiUploadingStatusList.length > 0 &&
                    aoiUploadingStatusList.map((item) => {
                      return (
                        <div className="checkbox-container">
                          <label className="filename" title={item.name}>
                            {item}
                          </label>
                          <Loader />
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="no-aoi-message">
                  Search, Upload, Mark AOIs to explore Data Products or select
                  from history.
                </div>
              )}
            </div>

            <div className="card">
              <div className="card-header">
                <p className="title">History</p>
              </div>
              {historyAOIs && historyAOIs.length > 0 ? (
                <div className="card-body">
                  {historyAOIs.map((item) => (
                    <div
                      key={item.id}
                      id={item.id}
                      className={`checkbox-container ${
                        hoveredItem === item.id
                          ? 'checkbox-container-hovered'
                          : ''
                      }`}
                      onMouseEnter={(e) => {
                        setHoveredItem(item.id);
                        const rect = e.target.getBoundingClientRect();
                        setHoverPosition({ top: rect.top, left: rect.left });
                      }}
                      onMouseLeave={() => {
                        if (!isDeleteAOIModalOpen) {
                          setHoveredItem(null);
                        }
                      }}
                    >
                      <label className="filename" title={item.name}>
                        {item.name}
                      </label>
                      {hoveredItem === item.id && (
                        <div
                          className="hover-icons"
                          style={{
                            top: hoverPosition.top,
                            left: hoverPosition.left
                          }}
                        >
                          <img
                            src={Trash}
                            alt="Delete"
                            onClick={(e) => {
                              openModal(e, item);
                            }}
                          />
                        </div>
                      )}
                      <input
                        type="checkbox"
                        name="languages"
                        value={item.id}
                        checked={selectedAOIs.includes(item.id)}
                        onChange={() => handleHistoryCheckboxChange(item)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-aoi-message">No past AOIs found.</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="marking-aoi-sections">
          <div className="marking-aoi-header">
            <div className="marking-aoi-back">
              <label
                onClick={() => {
                  setAoiMarkingMode(!aoiMarkingMode);
                }}
              >
                <img src={Back} alt="Back"></img>
              </label>
              <span>Marking Mode</span>
            </div>
            <label className="aoi-save-btn">Save</label>
          </div>
          <div className="marking-aoi-content">
            <p className="marking-aoi-para">
              Select a point on map and start marking points to make a polygon
            </p>
          </div>
        </div>
      )}
      <DeleteAoiModal
        aoi={toDeleteAOI}
        isOpen={isDeleteAOIModalOpen}
        trianglePosition={trianglePosition}
        closeModal={closeModal}
        handleDelete={handleDelete}
        customModalStyles={customDeleteModalStyles}
      />
      <ListRid
        isOpenRidList={isRidListModalOpen}
        isCloseRidLIstModal={documentation}
        getRegionsSelectedRids={regionUploadAoi}
      />
    </>
  );
};

export default Explore;
