import { Request } from '../model';

class RequestRepository {
  constructor() {
    this.requests = [];
  }

  /**
   * Initializes the request repository by converting an array of request data objects
   * into individual Request instances.
   *
   * @param {Array<Object>} requests - An array of objects representing request data.
   */
  initializeRequests(requests) {
    this.requests = new Request(requests);
  }

  /**
   * Retrieves all the Request objects in the repository.
   *
   * @returns {Array<Request>} An array containing all the Request instances.
   */
  getRequests() {
    return this.requests;
  }
}

export default RequestRepository;
