import React from 'react';

// Components
import TreeNode from './treeNode';

interface TreeNodeProps {
  treeListData: {
    id: string;
    status: number;
    name: string;
  }[];
  requestType: string;
}

const TreeRender: React.FC<TreeNodeProps> = ({
  treeListData = [],
  requestType
}) => {
  return (
    <>
      {treeListData.length !== 0 &&
        treeListData.map((item) => {
          const { id, status, name } = item;

          return (
            <TreeNode
              key={id}
              requestType={requestType}
              status={status}
              title={name}
              treeDataId={id}
            />
          );
        })}
    </>
  );
};

export default TreeRender;
