import { deleteRequest, getRequest, postRequest } from '../utils/http-helper';
import {
  USER_AOI_URL,
  OVER_ALL_PRODUCT_AVAILABLITY_URL
} from '../utils/url-helper';

export const onWorldWideAvailablity = async () => {
  try {
    return await getRequest({
      url: OVER_ALL_PRODUCT_AVAILABLITY_URL,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const uploadAoi = async (payload) => {
  try {
    return await postRequest({
      url: USER_AOI_URL,
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getAoiList = async () => {
  try {
    return await getRequest({
      url: USER_AOI_URL,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const deleteAoi = async (payload) => {
  try {
    return await deleteRequest({
      url: `${USER_AOI_URL}?aoi_id=${payload}`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};
