import { AoiData } from './AoiData';
/**
 * Represents an Area of Interest (AOI).
 */
export class Aoi {
  /**
   * Constructs an instance of Aoi.
   * @param {object} aoiData - The data object containing AOI information.
   */
  constructor(aoiData) {
    this.assignAoiData(aoiData);
  }

  /**
   * Assigns AOI data to the instance properties.
   * @param {object} aoiData - The data object containing AOI information.
   */
  assignAoiData({
    aoi_data,
    data_delivery_format,
    from_date,
    generation_type,
    id,
    product_admin,
    product_code,
    product_name,
    role,
    sop,
    status_in_percentage,
    to_date
  }) {
    this.aoi_data = aoi_data.map((item) => new AoiData(item));
    this.data_delivery_format = data_delivery_format;
    this.from_date = from_date;
    this.generation_type = generation_type;
    this.id = id;
    this.product_admin = product_admin;
    this.product_code = product_code;
    this.product_name = product_name;
    this.role = role;
    this.sop = sop;
    this.status_in_percentage = status_in_percentage;
    this.to_date = to_date;
  }

  /**
   * Retrieves AOI information.
   * @returns {object} - An object containing AOI information.
   */
  getAoiInfo() {
    return {
      aoi_data: this.aoi_data.map((item) => AoiData(item)),
      data_delivery_format: this.data_delivery_format,
      from_date: this.from_date,
      generation_type: this.generation_type,
      id: this.id,
      product_admin: this.product_admin,
      product_code: this.product_code,
      product_name: this.product_name,
      role: this.role,
      sop: this.sop,
      status_in_percentage: this.status_in_percentage,
      to_date: this.to_date
    };
  }
}
