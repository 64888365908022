import moment from 'moment';

export const getNameInitials = (name) => {
  const words = name?.split(' ');
  let initials = words?.map((word) => word[0]).join('');
  if (initials?.length > 2) {
    initials = initials.slice(0, 2);
  }
  return initials?.toUpperCase();
};
//for tooltip if the names exceed more than 4
export const joinElementsAfterFourthIndex = (array) => {
  const emails = array.map((obj) => obj.email);
  let tooltipString = emails.slice(3).join(',  ');
  return tooltipString;
};

export const filterOptionsBasedOnEmail = (options, excludeList) => {
  const emailsInOptions = new Set(excludeList.map((obj) => obj.email));
  return options.filter((obj) => !emailsInOptions.has(obj.email));
};

//to get the entity level and id from the URL
export const getLevelAndId = (path) => {
  const parts = path.split('/');

  return {
    id: parts[parts.length - 1],
    name: parts[parts.length - 2]
  };
};

export const extractIdsAndNames = (
  obj,
  levels = ['project', 'request', 'product', 'aoi']
) => {
  const result = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const currentLevel = levels[0];
      result.push({
        level: currentLevel,
        id: obj[key].id,
        name: obj[key].name
      });
      result.push(...extractIdsAndNames(obj[key], [...levels.slice(1)]));
    }
  }
  return result;
};

//getting the id of the entity level which previous of the current level for backtracking
export const getObjectByLevel = (objects, level) => {
  const index = objects.findIndex((obj) => obj.level === level);
  if (index !== -1 && index > 0) {
    return objects[index - 1];
  }
  return -1;
};

//to check the name nomenclature of project/request name
export const isValidNameFormat = (name) => {
  return /^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/.test(name || '');
};

export const isValidReason = (input) => {
  const regexPattern = /\b(?=\w*[A-Za-z])[\w()\-_,.]*[A-Za-z][\w()\-_,.]*\b/gm;
  return regexPattern.test(input);
};

//to convert the time stamp into last seen
export const getLastSeen = (timestamp) => {
  // Convert UTC timestamp to IST using moment.js
  const istTimestamp = moment.utc(timestamp).local().toDate().getTime();

  // Calculate current time in IST
  const now = new Date().getTime();

  const timeDiff = now - istTimestamp;

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days + ' days ago';
  } else if (hours > 0) {
    return hours + ' hours ago';
  } else if (minutes > 0) {
    return minutes + ' minutes ago';
  } else {
    return 'Just now';
  }
};

export const getTimeStampFormat = (date) => {
  return date ? moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss') : '-';
};

export const getDateFormat = (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : '';
};
